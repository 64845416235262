import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Functions from "../../../../../Helpers/Functions"

const Dashboard = (props:any) => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col px-4 py-8 rounded-lg lg:p-10 shadow-profile-isAe gap-y-10 md:px-8 '>
            <h3 className='text-base font-bold text-first lg:text-2xl'>Votre Dashboard :</h3>
            <div className=''>
                <h4 className='font-regular text-first lg:text-lg'>Vous êtes {Functions.renderBusinessType(props.teachr.businessType)} avec Teach’r, vous pouvez désormais faire <br />bénéficier dispositif du crédit d’impôt instantané à vos élèves ! 🥳</h4>
                <p className='text-sm font-regular lg:text-[16px] text-normal'>Rejoignez dès maintenant notre nouvelle plateforme de déclaration de cours particulier.</p>
            </div>
            <Button className='px-20 mx-auto font-bold uppercase border border-solid rounded-xl text-white bg-first border-first w-max ' onClick={ () => navigate('/declarons/dashboard')}> DASHBOARD</Button>
        </div>
    );
};

export default Dashboard;