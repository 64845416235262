const validateBirthName = (value: string) => {
  const patern = /^[a-zA-Z\s]+$/

  if (value) {
    if (value.match(patern)) {
      return {
        isValid: true,
        message: "",
      };
    } else
      return {
        isValid: false,
        message: `Le nom ne doit pas comporter de chiffres, ni de caractères spéciaux.
        `,
      };
  } else {
    return {
      isValid: false,
      message: `Le nom ne doit pas comporter de chiffres, ni de caractères spéciaux.
      `,
    };
  }
};

export default validateBirthName