import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';

const Fifth = (props:any) => {
    const [isAe, setIsAe] = useState<number>()
    const [error, setError] = useState(false)
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAe(parseInt((event.target as HTMLInputElement).value));
    };
    useEffect(()=>{
        props.callBack({
            isAe: isAe
        })
        if ( isAe !== undefined && isAe >= 0) {
            props.nextStep(6)
            setError(false) 
         }else{
            setError(true)
         }
    },[isAe])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
            {error ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Avez-vous déjà eu le statut auto-entrepreneur par le passé ? </label>
                <RadioGroup row
                    value={isAe}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
        </div>
    );
};

export default Fifth;