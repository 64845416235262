import { useEffect, useState } from "react";
// import validateName from "../../../Validations/Name";
// import validateFirstName from "../../../Validations/FirstName";
import validateEmail from "../../../Validations/Email";
import { validatePhoneNumber } from "../../../Validations/Phone";
import StepperStore from "../../../LocalForage/StepperStore";
import { logout, sendPasswordReset } from "../../../Services/FirebaseService";
import CircularProgress from "@mui/material/CircularProgress";
import {
  auth,
  FirebaseAuthServiceErrorCode,
  getJWTToken,
  registerWithEmailAndPassword,
} from "../../../Services/FirebaseService";
import Client from "../../../Objects/models/Client";
import ClientRepository from "../../../Repositories/ClientRepository";
import { useAuthState } from "react-firebase-hooks/auth";
import ApiService from "../../../Services/ApiService";
import { CoursePack } from "../../../Objects/models/CoursePack";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
import AddressRepository from "../../../Repositories/AddressRepository";
import moment from "moment";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { Address } from "../../../Objects/models/Address";
import ChildRepository from "../../../Repositories/ChildRepository";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
import Child from "../../../Objects/models/Child";
import { APIErrorEnum } from "../../../Objects/enums/APIErrorEnum";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Functions from "../../../Helpers/Functions";
import LoaderContext from "../../ComponentsUtils/LoadingContext";
import { browserSessionPersistence } from "firebase/auth";

const Five = (props: any) => {
  const { handleNext, handleBack } = props;
  let timerInterval: any;
  const Swal = require("sweetalert2");
  const [user] = useAuthState(auth);
  const [terms, setTerms] = useState(false);
  const [datas, setDatas] = useState<any>({});
  const [client, setClient] = useState<Client>();
  const [address, setAddress] = useState<Address>();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState({
    phone: "null",
    email: "null",
  });

  useEffect(() => {
    if (user) {
      createClient();
    }
  }, [user]);

  useEffect(() => {
    if (client) {
      createChildren();
      createAddresses();
    }
  }, [client]);
  useEffect(() => {
    StepperStore.getItem("register").then((response) => {
      setDatas(response);
    })
    StepperStore.getItem("addresse").then((response: any) => {
      if (response) {
        setAddress(response.addresseObject);
      }
    });
  }, []);

  const validate = () => {
    let erremail = true;
    let errphone = true;

    if (!validateEmail(email).isValid) {
      setError({
        email: validateEmail(email).message,
        phone: validatePhoneNumber(phone),
   
        });
      erremail = true;
    } else {
      setError({
        email: validateEmail(email).message,
        phone: validatePhoneNumber(phone),
   
        });
      erremail = false;
    }
    if (validatePhoneNumber(phone)) {
      setError({
        email: validateEmail(email).message,
        phone: validatePhoneNumber(phone),
   
        });
      errphone = true;
    } else {
      setError({
        email: validateEmail(email).message,
        phone: validatePhoneNumber(phone),
   
        });
      errphone = false;
    }

    

  
      if (erremail || errphone) {
        return false;
      } else {
        return true;
      }
  };

  const sendMailUpdatePassword = () => {
    sendPasswordReset(email).catch((err) => console.warn(err)
    )
  }
  const createUser = () => {
    const password = Math.random().toString(36).slice(-8);
    registerWithEmailAndPassword(email, password)
      .then(() => {
        setIsLoading(true);
        StepperStore.setItem("setPassword", { password: password });
      }).catch((error) => {
        // if (user) {
        setIsLoading(false);
        logout();
        user?.delete()
        document.getElementById("teachr-loader")?.classList.add("hidden");
        switch (error.code) {
          case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
            Swal.fire({
              icon: "warning",
              title: "Attention !",
              text: "L'adresse mail est déjà utilisée. Un mail a été renvoyé.",
              timer: 2000,
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
            break;

          default:
            Swal.fire({
              icon: "error",
              title: "OOPS.. !",
              text: "Quelque chose s'est mal passée",
              timer: 2000,
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
            break;
        }
        // }
      });
  };
  const createClient = async () => {
    if (user) {
      let clientObject = new Client(
        await user.getIdToken(),
        datas.datas.firstName,
        datas.datas.lastName,
        datas.me === true
          ? Functions.formatPhoneNumber(datas.mePhone)
          : Functions.formatPhoneNumber(phone),
        terms,
        "firebaseMessagingToken", // TODO registrationToken
        true, // TODO parent/child
        false,
        undefined
      );
      if (email) {
        clientObject.mail = email;
      }
      ClientRepository.createClient(clientObject)
        .then((response) => {
          getJWTToken(user).then((token) => {
            ApiService.enableAuth(undefined, undefined, "client " + token);
          });
          setClient(response);
          sendMailUpdatePassword()
          setIsLoading(true);
        })
        .catch((error) => {
          console.warn(error);
          // logout();
          user?.delete()
          document.getElementById("teachr-loader")?.classList.add("hidden");
          document.getElementById("teachr-loader")?.classList.add("hidden");
          setIsLoading(false);
        });
    }
  };
  const createChildren = async () => {
    try {
      const clientWithId = await ClientRepository.getCurrentClient();
      const firstNameChild = datas.datas.firstName;
      const lastName = datas.datas.lastName;
      const classe = 0;
      const birthday = moment("20111031", "YYYY/MM/DD").toDate();
      const additonalInfo = "";
      if (clientWithId["@id"]) {
        const child = new Child(
          firstNameChild,
          lastName,
          clientWithId["@id"],
          classe,
          birthday,
          Functions.formatPhoneNumber(phone),
          additonalInfo
        );
        try {
          await ChildRepository.createChild(child);
          setIsLoading(true);
        } catch (error) {
          console.warn(error);
          setIsLoading(false);
          document.getElementById("teachr-loader")?.classList.add("hidden");
        }
      }
    } catch (error) {
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const createAddresses = async () => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id && address) {
          AddressRepository.createAddressForClient(address, clientWithId.id).then((res: Address) => {
            if (res) {
              if (datas.datas.typePrestation === "Urgence") {
                (async () => {
                  if (res["@id"]) {
                    await inCourseInTheHour(res["@id"]);
                  }
                })();
              } else if (datas.datas.typePrestation === "Ponctuel") {
                (async () => {
                  if (res["@id"]) {
                    await createPonctualCourse(res["@id"]);
                  }
                })();
              } else if (datas.datas.typePrestation === "Stage") {
                (async () => {
                  if (res.id) {
                    await createPackCourse(res.id);
                  }
                })();
              } else if (res.id && datas.datas.typePrestation === "Régulier") {
                (async () => {
                  if (res.id) {
                    await createSubscriptionCourse(res.id);
                  }
                })();
              }
            }
          });
        }
      }
    } catch (error) {
      console.warn(error);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const inCourseInTheHour = async (address_id: string) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient()
        const durations = datas.datas.duration;
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          const address = address_id;
          const paymentMean = "CARD";
          const duration = Math.round(durations);
          const subjectAsked = datas.datas.prestations["@id"];
          const isCourseInTheHour = true;
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 0;
          let endTime = moment(datas.endTime).add(50, "seconds");
          let startDate = moment(new Date().toDateString());

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .set("seconds", 30)
            .toDate();

          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error: any) {
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de prestation en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          logout();
          user?.delete()
          document.getElementById("teachr-loader")?.classList.add("hidden");
          break;
      }
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const createPonctualCourse = async (address_id: string) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        const durations = datas.datas.duration;
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          const address = address_id;
          const paymentMean = "CARD";
          const duration = Math.round(durations);
          const subjectAsked = datas.datas.prestations["@id"];
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 0;
          let startTime = moment(datas.datas.startTime);
          let endTime = moment(datas.datas.endTime);
          let startDate = moment(datas.datas.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de prestation en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'une Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'une Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };
  const createSubscriptionCourse = async (id: number) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          const subjectAsked_id = datas.datas.prestations.id;
          const isRemote = false;
          const address_id = id;
          const daysOfTheWeek = datas.datas.day;
          const durationPerDay = Math.round(datas.datas.duration);
          const paymentMean = "CASH";
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const extraInformation = "";
          let startTime = moment(datas.datas.hour).format("HH:mm");
          let startDate = moment(datas.datas.date).format("Y-MM-DD");
          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isRemote,
            address_id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );

          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            clientWithId.id
          );
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error) {
      console.warn(error);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
    }
  };
  const createPackCourse = async (id: number) => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );

          const childWithId = allChildrenCollection[0].id;
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const address_id = id;
          const subjectAsked_id = datas.datas.prestations.id;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.datas.dates;
          const durationPerDay = Math.round(datas.datas.duration);
          const isRemote = false;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address_id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      console.warn(error);
    }
  };
  const handleSubmit = (event: any) => {
    if (validate()) {
      setIsLoading(true)
      if (remember) {
        createUser()
      } else {
        auth.setPersistence(browserSessionPersistence).then(() => {
          createUser()
        })
      }
    } else {
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false)
    }
    event.preventDefault();
  };


  return (
    <>
      <LoaderContext />
      <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
        <h5 className="text-lg font-regular lg:text-3xl md:text-2xl text-first">
          Dernières informations :
        </h5>
        <div className="flex">
          <p className="xl:text-xl lg:text-lg md:text-base  text-sm  text-[#4d4d4d] ">
            Pour que nous puissons confirmer votre demande et vous avertir
            par mail de vos réponses merci de compléter{" "}
            <strong>vos informations personnelles</strong>
          </p>
        </div>
        <div>
          <div className="mt-6 grid /w-4/5 gap-4 mx-auto lg:grid-cols-2" >       
            <div>
              <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-gray-200 focus:border-1">
                <label className="block pl-2 mb-2 lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                  Adresse email
                </label>
                <input
                  type="text"
                  id="email"
                  className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                  placeholder="Ex : johndoe@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="flex w-full mx-auto mb-2 -mt-3 space-x-2 translate-y-4 ">
                {error.email === "null" || error.email === ""
                  ? " "
                  : validateEmail(email).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.email === "null" || error.email === ""
                    ? null
                    : validateEmail(email).message}
                </p>
              </div>
            </div>
            {/* {datas.me === true ? (
              ""
            ) : ( */}
            <div>
              <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-gray-200 focus:border-1">
                <label className="block pl-2 mb-2 lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                  Numéro de téléphone
                </label>
                <input
                  type="text"
                  id="phone"
                  className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                  placeholder="Ex : 07 XX XX XX XX"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="flex w-full mx-auto mb-4 -mt-3 space-x-2 translate-y-4 ">
                {error.phone === "null" || error.phone === "" ? " " : ""}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.phone === "null" || error.phone === ""
                    ? null
                    : validatePhoneNumber(phone)}
                </p>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="hidden /w-4/5 mx-auto -mt-4 //-translate-y-6 md:block gap-x-10">
          <div className="">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "24px",
                // marginLeft: "4rem",
                fontFamily: "Nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "Nunito",
                    },
                  }}
                  onClick={() => setTerms(!terms)}
                />
              }
              label={
                <Typography style={{ fontFamily: "nunito" }}>
                  J'accepte recevoir des offres promotionnelles de la part de
                  Teach'r
                </Typography>
              }
            />
          </div>
    
          <div className=" font-sans //-mt-8">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "24px",
                // marginLeft: "4rem",
                fontFamily: "nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "nunito",
                    },
                  }}
                  onClick={() => setRemember(!remember)}
                />
              }
              label={
                <Typography style={{ fontFamily: "nunito" }}>
                  Rester connecté
                </Typography>
              }
            />
          </div>
        </div>
        <div className="block /w-4/5 pb-4 mx-auto mt-8 space-y-2 //-mt-4 md:hidden">
          <div className="">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "16px",
                // marginLeft: "4rem",
                fontFamily: "Nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "Nunito",
                    },
                  }}
                  onClick={() => setTerms(!terms)}
                />
              }
              label={
                <Typography
                  style={{ fontFamily: "nunito" }}
                  className="text-sm lg:text-lg md:text-base"
                >
                  J'accepte recevoir des offres promotionnelles de la part de
                  Teach'r
                </Typography>
              }
            />

          </div>
          <div className="-mt-6 font-sans">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "10px",
                // marginLeft: "4rem",
                fontFamily: "nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "nunito",
                      fontSize: "10px",
                    },
                  }}
                  onClick={() => setRemember(!remember)}
                />
              }
              label={
                <Typography
                  style={{ fontFamily: "nunito" }}
                  className="text-sm lg:text-lg md:text-base"
                >
                  Rester connecté
                </Typography>
              }
            />
          </div>
        </div>

        <div className="flex justify-around w-4/5 mx-auto space-x-4 -translate-y-4 md:hidden md:translate-y-44 md:w-full">
          <div className="flex justify-center w-1/2 font-bold bg-white border-2 rounded-full text-first border-first ">
            <div
              onClick={handleBack}
              className="w-full pt-2 text-sm text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <button
              disabled={isLoading}
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="w-full px-3 py-2 text-sm font-bold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-bold border-first text-first active:bg-first active:text-white "
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "gray",
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />
              ) : (
                "Envoyer"
              )}{" "}
            </button>
          </div>
        </div>

        <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleBack()}
              className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "#4d4d4d",
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />
              ) : (
                "Envoyer"
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Five;
