import StepDeclare from "./StepDeclare/StepDeclare";
import StepLocation from "./StepDeclare/StepLocation";
import StepPrice from "./StepDeclare/StepPrice";
import StepConfirmation from "./StepDeclare/StepConfirmation";
import { useEffect, useState } from "react";
import StepperStore from "../../../../LocalForage/StepperStore";
import StepEnd from "./StepDeclare/StepEnd";
import Navbar from "../../../../Components/Admin/Navbar";
import StepPunctual from "./StepDeclare/CourseTypeDeclare/StepPunctual";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../Services/FirebaseService";
import DatesOfWeek from "./AccueilDashboard/ChartDashboard/DatesOfWeek";
import DatesOfWeekForPrice from "./AccueilDashboard/ChartDashboard/DateOfWeeksPrice";
import StepPunctualPastFirst from "./StepDeclare/CourseTypeDeclare/StepPunctualPastFirst";
// import { Step } from "@mui/material";
import StepSubscription from "./StepDeclare/CourseTypeDeclare/StepSubscription";
import React from "react";

const DeclareCourse = () => {
    const [user] = useAuthState(auth)
    const [currentStep, setCurrentStep] = useState<any>(1);
    const handleChangeStep = (val: number) => {
        setCurrentStep(val);
    };
    const renderStepScreen = (step: number) => {
        switch (step) {
            case 1:
                return <StepDeclare handleChangeStep={handleChangeStep} />;
            case 2:
                return <StepLocation handleChangeStep={handleChangeStep} />;
            case 3:
                return <StepSubscription handleChangeStep={handleChangeStep} />;
            case 4:
                return <StepPunctual handleChangeStep={handleChangeStep} />;
            case 5:
                return <StepPunctualPastFirst handleChangeStep={handleChangeStep} />;
            case 6:
                return <StepPrice handleChangeStep={handleChangeStep} />;
            case 7:
                return <StepConfirmation handleChangeStep={handleChangeStep} />;
            case 8:
                return <StepEnd handleChangeStep={handleChangeStep} />;
            default:
                return <StepDeclare handleChangeStep={handleChangeStep} />;
        }
    };
    useEffect(() => {
        StepperStore.setItem("activeStep" + user?.uid, currentStep);
        StepperStore.removeItem("activeStepundefined")
    }, [currentStep, user?.uid]);
    useEffect(() => {
        (async () => {
            try {
                const value = await StepperStore.getItem("activeStep" + user?.uid);
                switch (value) {
                    case null:
                        setCurrentStep(1);
                        break;
                    case 8:
                        setCurrentStep(1)
                        break;
                    default:
                        setCurrentStep(value);
                        break;
                }
            } catch (err) {
                setCurrentStep(1);
            }
        })();
    }, [user?.uid]);

    return (
        <div className='h-screen md:overflow-hidden bg-white'>
            <Navbar index={1} />
            <div className='mt-5 py-6 md:px-10 px-4 grid md:grid-cols-3 gap-4 grid-cols-1 md:h-[80%] h-[90%]'>
                <div className="md:col-span-2 shadow-dashboard-setup-intro-form border-1  rounded-[11px] h-[100%] bg-gradient-to-b from-[#4B6AD830] via-transparent to-[#4B6AD800]">
                    {renderStepScreen(currentStep)}
                </div>
                <div className="hidden md:col-span-1 space-y-4 md:block">
                    <div className="rounded-xl shadow-md col-span-1 bg-white md:block hidden h-[40%] hadow-dashboard-setup-intro-form border-1 bg-gradient-to-b from-[#4B6AD808] via-transparent to-[#4B6AD800]">
                        <DatesOfWeek />
                    </div>
                    <div className="rounded-xl shadow-md  h-[57%] bg-white md:block hidden hadow-dashboard-setup-intro-form border-1 bg-gradient-to-b from-[#4B6AD808] via-transparent to-[#4B6AD800]">
                        <DatesOfWeekForPrice />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DeclareCourse;
