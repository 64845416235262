import React from 'react';
import updateponc from '../../../../../../../Assets/Images/modifier_cours.gif'
import StepperStore from '../../../../../../../LocalForage/StepperStore';

const EditConfirme = (props:any) => {
    
    const handleNext = () => {
        StepperStore.clear()
        props.backStep(1)
    }
    return (
        <div className=' p-6 shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent h-[80%] mt-4 //h-[0%] px-4 py-4 bg-gradient-to-b from-[#4B6AD830] via-transparent to-[#4B6AD800] rounded-xl '>
            <div className='flex'>
                <div className='md:w-2/3 md:p-10  //border-2 md:pr-56 '>
                    <div className="mt-3">
                        <h1 className="lg:text-2xl pl-10 //text-center md:text-lg text-sm font-bold text-first">
                            Modification d'une prestation ponctuelle reussi !
                        </h1>
                    </div>
                    <div className='mt-8 pl-10'>
                        <p className='lg:text-xl md:text-base text-sm font-regular text-first'>
                            Votre prestation ponctuelle a bien été modifiée. Vous pourrez retrouver toutes les modifications de celui-ci dans votre tableau.
                        </p>
                    </div>
                </div>
                <div className='w-1/2 hidden md:block -translate-x-16  px-4 pt-4'>
                    <img src={updateponc} alt="" />
                </div>
            </div>
            <div className='md:pl-20 md:-translate-y-16 md:mt-0 mt-10'>
                <div className="bg-first p-1 rounded-lg md:w-1/4 cursor-pointer" onClick={()=> { handleNext() }}>
                    <p className="text-white md:text-base text-sm text-center font-bold">Mes prestations</p>
                </div>
            </div>
        </div>
    );
};

export default EditConfirme;