import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import transition from "react-element-popper/animations/transition";
import StepperStore from "../../../../LocalForage/StepperStore";
import errorIcon from "../../../../Assets/Images/error.png";
import Functions from "../../../../Helpers/Functions";
import { Badge } from "@mui/material";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimePicker } from "antd";

const format = "HH:mm"
const months = [
  ["jan", "j"], 
  ["fev", "f"],
  ["mar", "m"],
  ["avr", "a"],
  ["mai", "m"],
  ["juin", "j"],
  ["juil", "j"],
  ["août", "a"],
  ["sept", "s"],
  ["oct", "o"],
  ["nov", "n"],
  ["déc", "d"],
];
const weekDays = [
  ["Lundi", "L"],
  ["Mardi", "M"],
  ["Mercredi", "M"],
  ["Jeudi", "J"],
  ["Vendredi", "V"],
  ["Samedi", "S"],
  ["Dimanche", "D"], 
];

const StageRevision = (props) => {

  const end = moment().set({ hour: 17, minute: 0 }).add(1.5, "hours");
  const start = moment().set({ hour: 17, minute: 0 });
  const minDate = new Date();
  const yestarday = minDate.setDate(minDate.getDate() - 1);
  
  const [user] = useAuthState(auth);
  const [duration, setDuration] = useState(1.5);
  const [dates, setDates] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);
  const [error, setError] = useState({
    val: "null",
    val2: "null",
  });
  
  const val = dates.length * duration;
  const pickDates = () => {
    const result = [];
    // eslint-disable-next-line array-callback-return
    dates.map((date) => {
      result.push(
        moment(date?.toDate?.().toString()).add(1, "d").format("DD/MM/YYYY")
      );
    });
    return result.join(", ");
  };
  const handleTaggle = () => {
    setToggle(!toggle);
  };
  const handleDurationChange = (event, newValue) => {
    setDuration(newValue);
    setEndTime(moment(startTime).add(newValue, "hours"));
  };
  const calculateTotalHours = () => {
    let total = duration * dates.length;
    return total;
  };
  const validationHours = (value) => {
    if (value < 5) {
      return {
        isValid: false,
        message: "Il vous faut reserver 5 heures au minimum",
        icon: <img src={errorIcon} alt="" className="w-5 h-5 mt-1" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };

  const validate = () => {
    let errRange = true;
    if (!validationHours(val).isValid) {
      setError({
        val: validationHours(val).message,
        val2: validationHours(val).icon,
      });
      errRange = true;
    } else {
      setError({
        val: validationHours(val).message,
        val2: validationHours(val).icon,
      });
      errRange = false;
    }
    if (errRange) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = (event) => {
    if (validate()) {
      StepperStore.setItem(
        user ? "secondPrestation" + user.uid : "secondPrestation",
        {
          type: "Stage",
          data: {
            duration: duration,
            dates: dates.map((date) =>
              moment(date?.toDate?.().toString())
                .add(1, "d")
                .format("YYYY/MM/DD")
            ),
            startTime: startTime.toDate().valueOf(),
            endTime: endTime.toDate().valueOf(),
          },
        },

        function (err) {
          console.warn(err);
        }
      );

      props.handleNext();
    }

    event.preventDefault();
  };
  
  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h5 className="lg:text-3xl md:text-2xl text-lg text-first font-regular">Regroupement des prestations</h5>
      <div className="mt-6 /md:w-2/3 w-full ">
        <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[2px] px-3 w-full lg:text-lg md:text-base text-sm">
          <label
            htmlFor=""
            className="lg:text-lg md:text-base text-sm font-medium"
          >
            {" "}
            Nombre d'heure
          </label>
          <div className=" h-9 md:-translate-y-1 md:mt-0 -mt-2">
            <Functions.PrettoSlider
              defaultValue={1}
              step={1}
              min={1}
              max={4}
              marks
              value={duration}
              onChange={handleDurationChange}
            />
          </div>
        </div>
        <span className="font-regular bg-transparent text-first">
          <input
            className="bg-transparent"
            type="text"
            value={Functions.renderDuration(duration * 2)}
            onChange={(e) => setDuration(e.target.value)}
          />
        </span>
      </div>
        <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md /py-2 py-[2px] px-3 w-full lg:text-lg md:text-base text-sm">
          <label
            htmlFor=""
            className="lg:text-lg md:text-base text-sm font-medium"
          >
            {" "}
            Dates du regroupement
          </label>
          <div className="mt-1">
            <Badge
              color="primary"
              className="w-full hidden md:flex"
              badgeContent={dates.length > 6 ? "+ " + (dates.length - 6) : null}
            >
              <DatePicker
                formattingIgnoreList={["week", "day", "name", "month", "of"]}
                months={months}
                weekDays={weekDays}
                minDate={new Date()}
                mapDays={({ date }) => {
                  let props = moment(date?.toDate?.().toString()).add(1, "d");
                  let prop = {};
                  if ([5, 10, 15, 20, 25].includes(props.day)) {
                    prop.hidden = true;
                  }
                  return {
                    children: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          //padding: "0 10px",
                          fontSize: "12px",
                        }}
                      >
                        <div style={{ textAlign: "" }}>{props.format("D")}</div>
                      </div>
                    ),
                  };
                }}
                format={"DD/MM/YYYY"}
                style={{
                  backgroundColor: "#F2F5FB",
                  height: "30px",
                  border: "none",
                  fontSize: "18px",
                  padding: "10",
                  width: "100%",
                }}
                containerStyle={{
                  width: "100%",
                }}
                animations={[transition({ duration: 800, from: 35 })]}
                value={dates}
                onChange={(e) => {
                  setDates(e);
                  validate();
                }}
                multiple
                numberOfMonths={2}
                render={(val, openCalendar) => {
                  return (
                    <input
                      onClick={openCalendar}
                      className="w-full bg-transparent text-[18px] truncate"
                      value={pickDates()}
                      readOnly
                      placeholder="Ex : 23/10/2022, 24/10/2022,..."
                    />
                  );
                }}
              />
            </Badge>
            <Badge
              color="primary"
              className="w-full md:hidden flex"
              badgeContent={dates.length > 4 ? "+ " + (dates.length - 4) : null}
            >
              <DatePicker
                formattingIgnoreList={["week", "day", "name", "month", "of"]}
                months={months}
                weekDays={weekDays}
                minDate={yestarday}
                mapDays={({ date }) => {
                  let props = moment(date?.toDate?.().toString()).add(1, "d");
                  let prop = {};
                  if ([5, 10, 15, 20, 25].includes(props.day)) {
                    prop.hidden = true;
                  }
                  return {
                    children: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          //padding: "0 10px",
                          fontSize: "11px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {props.format("D")}
                        </div>
                      </div>
                    ),
                  };
                }}
                format={"DD/MM/YYYY"}
                style={{
                  backgroundColor: "#F2F5FB",
                  height: "30px",
                  border: "none",
                  fontSize: "18px",
                  padding: "10",
                  width: "100%",
                  textAlign: "center",
                }}
                containerStyle={{
                  width: "100%",
                }}
                animations={[transition({ duration: 800, from: 35 })]}
                value={dates}
                onChange={(e) => {
                  setDates(e);
                  validate();
                }}
                multiple
                numberOfMonths={2}
                render={(val, openCalendar) => {
                  return (
                    <input
                      onClick={openCalendar}
                      className="w-full bg-transparent"
                      readOnly
                      value={pickDates()}
                      placeholder="Ex : 23/10/2022, 24/10/2022,..."
                    />
                  );
                }}
              />
            </Badge>
          </div>
        </div>
        <div
          onClick={handleTaggle}
          className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color border-gray-300 text-gray-900 text-sm rounded-md py-[2px] md:px-2 px-3 focus:outline-none focus:ring-0 focus:border-gray-200 focus:border-1"
        >
          <label className="block mb-2 text-base font-medium lg:text-xl">
            Créneaux de disponibilité par jour :
          </label>
          <Badge
            color="primary"
            className="w-full hidden md:flex"
            badgeContent={dates.length > 3 ? "+ " + (dates.length - 3) : null}
          >
            <input
              name="day"
              id="day"
              placeholder="Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi et Dimanche"
              className="bg-blue-50  w-full  lg:text-xl text-base focus:outline-none focus:ring-0 focus:border-1 truncate"
              value={dates
                .map((date) =>
                  moment(date?.toDate?.().toString())
                    .add(1, "d")
                    .format(
                      "dddd " +
                      Functions.renderTime(startTime) +
                      " - " +
                      Functions.renderTime(endTime)
                    )
                )
                .join(", ")}
            />
          </Badge>
          <Badge
            color="primary"
            className="w-full md:hidden flex"
            badgeContent={dates.length > 2 ? "+ " + (dates.length - 2) : null}
          >
            <input
              name="day"
              id="day"
              placeholder="Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi et Dimanche"
              className="bg-blue-50  w-full  lg:text-xl text-base focus:outline-none focus:ring-0 focus:border-1 "
              value={dates
                .map((date) =>
                  moment(date?.toDate?.().toString()).format(
                    "dddd " +
                    Functions.renderTime(startTime) +
                    " - " +
                    Functions.renderTime(endTime)
                  )
                )
                .join(", ")}
            />
          </Badge>
        </div>
        {toggle && (
          <div className="grid gap- -translate-y-6 z-50 -space-y-2 -p-2 md:grid-cols-2 md:space-y-0 lg:p- md:p-4 md:mt-3 mt-2 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color max-h-20  md:max-h-32 list-teacher overflow-y-scroll">
            {dates.map((day, index) => (
              <div
                key={index}
                className="flex pt-1 md:pt-0 justify-between space-x-1"
              >
                <span className="mt-2 lg:text-md w-1/2 hidden md:flex ">
                  {moment(day?.toDate?.().toString())
                    .add(1, "d")
                    .format("dddd DD MMMM")}
                </span>
                <span className="mt-2 lg:text-md w-1/2 md:hidden flex  ">
                  {moment(day?.toDate?.().toString())
                    .add(1, "d")
                    .format("ddd DD MMM")}
                </span>
                <div className="flex justify-between w-3/4">
                  <div
                    className="p-1 font-regular text-center rounded-lg border-1 md:p-2 border-first"
                    value={day}
                  >
                    <TimePicker
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                        setEndTime(moment(newValue).add(duration, "hours"));
                      }}
                      minuteStep={5}
                      format={format}
                      showNow={false}
                      clearIcon=""
                      size="large"
                      suffixIcon=""
                    />
                  </div>
                  <span className="mt-4 px-2"> à </span>
                  <div className="md:-mt-2 md:p-4 p-1 text-center font-regular border-first rounded-lg">
                    <TimePicker
                      value={endTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                      }}
                      minuteStep={5}
                      format={format}
                      showNow={false}
                      clearIcon=""
                      size="large"
                      suffixIcon=""
                      inputReadOnly={true}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <span className="font-regular bg-transparent text-first">
          <input
            type="text"
            value={
              "Total : " + Functions.renderDuration(calculateTotalHours() * 2)
            }
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            className="bg-transparent"
          />
        </span>
      {/* </div> */}
      <div className=" /translate-y-4 flex space-x-2 md:text-lg  w-4/5 mx-auto">
        {error.val === "null" || error.val === ""
          ? " "
          : validationHours(val).icon}
        <p className="text-[#FF724F] h-6 w-full ">
          {error.val === "null" || error.val === ""
            ? null
            : validationHours(val).message}
        </p>
      </div>
      <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-20 md:transl md:w-full">
        <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
          <div
            onClick={props.handleBack}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={(e) => handleSubmit(e)}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full /-z-50">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => props.handleBack()}
              className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(z) => handleSubmit(z)}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              Suivant
            </button>
          </div>
        </div>
    </div>
  );
};

export default StageRevision;
