import React, { useEffect, useRef, useState } from 'react';
import Legend from './Legend';
import Chart from './Chart';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import Simulator from '../../../../../Objects/models/Simulator';
import fuseilu from '../../../../../Assets/Icons/fusée-mac.svg'
import 'animate.css';
import { useNavigate } from 'react-router-dom';
interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
}
const Index = (props: any) => {
    const navigate = useNavigate()
    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    const [hourlyPrice, setHourlyPrice] = useState(60)
    const [show, setShow] = useState<boolean | undefined>()
    const [marker, setMarker] = useState(0)
    const [open, setOpen] = useState(false)
    const [stop, setStop] = useState(false)
    const getEligibble = (value: boolean) => {
        setShow(value)
    }
    useEffect(() => {
        if (simulatorData) {
            setHourlyPrice(simulatorData.hourlyPrice)
        }
    }, [simulatorData])
    useEffect(() => {
        if (props.response && props.response.hourlyPay.liberatoire) {
            if (props.response.hourlyPay.liberatoire > props.response.hourlyPay.classic) {
                setMarker(0)
            } else {
                setMarker(1)
            }
        }else{
            setMarker(1)
        }
    }, [props])
    const animationRef = useRef<number | null>(null);

    useEffect(() => {
        animationRef.current = window.setInterval(() => {
            const element = document.getElementById('change-chart');

            if (element && show) {
                // Ajoutez ou supprimez des classes CSS d'animation selon vos besoins
                if (!stop) {
                    element.classList.toggle('animate__heartBeat')
                } else {
                    element.classList.remove('animate__heartBeat')
                }
            }
        }, 1000);

        return () => {
            if (animationRef.current) {
                clearInterval(animationRef.current);
            }
        };
    }, [show, stop]);
    const renderSlide = () => {
        if (marker === 1) {
            return (
                <Swiper
                    className='h-full'
                    slidesPerView={1}
                    loop
                    speed={700}
                    navigation={{
                        nextEl: ".next-slide"
                    }}
                    modules={[Navigation]}
                    effect="slide">

                    <SwiperSlide>
                        {show !== undefined ? <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Rémunération horaire net d’impôt avec <span className='text-[#8097EC] whitespace-pre'>le régime classique</span></h3> :
                            <Skeleton className='mb-10 rounded-md' variant="text" sx={{ fontSize: '1rem' }} />
                        }
                        <Chart hourlyPrice={hourlyPrice} type='classique' callBack={getEligibble} />
                    </SwiperSlide>
                    {
                        show === true &&
                        <SwiperSlide>
                            <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Rémunération horaire net d’impôt avec <span className='text-radio'>le régime libératoire</span></h3>
                            <Chart type='libératoire' hourlyPrice={hourlyPrice} callBack={getEligibble} />
                        </SwiperSlide>
                    }
                    {
                        show === true &&
                        <SwiperSlide>
                            <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Synthèse des régimes d’imposition :</h3>
                            <Chart type='synthèse' hourlyPrice={hourlyPrice} callBack={getEligibble} />
                        </SwiperSlide>
                    }
                </Swiper>
            )
        }
        return (
            <Swiper
                className='h-full'
                slidesPerView={1}
                loop
                speed={700}
                navigation={{
                    nextEl: ".next-slide"
                }}
                modules={[Navigation]}
                effect="slide">
                {
                    show === true &&
                    <SwiperSlide>
                        <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Rémunération horaire net d’impôt avec <span className='text-radio'>le régime libératoire</span></h3>
                        <Chart type='libératoire' hourlyPrice={hourlyPrice} callBack={getEligibble} />
                    </SwiperSlide>
                }
                <SwiperSlide>
                    {show !== undefined ? <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Rémunération horaire net d’impôt avec <span className='text-[#8097EC] whitespace-pre'>le régime classique</span></h3> :
                        <Skeleton className='mb-10 rounded-md' variant="text" sx={{ fontSize: '1rem' }} />
                    }
                    <Chart hourlyPrice={hourlyPrice} type='classique' callBack={getEligibble} />
                </SwiperSlide>

                {
                    show === true &&
                    <SwiperSlide>
                        <h3 className='px-4 pb-4 text-sm font-regular lg:text-xl text-normal lg:px-0' >Synthèse des régimes d’imposition :</h3>
                        <Chart type='synthèse' hourlyPrice={hourlyPrice} callBack={getEligibble} />
                    </SwiperSlide>
                }
            </Swiper>
        )

    }
    return (
        <div className="flex flex-col gap-y-4">
            <div className='flex flex-col justify-between py-8 gap-x-14 md:flex-row gap-y-8'>
                {
                    simulatorData && props.response ?
                        <div className='lg:hidden rounded-[17px] bg-white p-4 md:p-4'>
                            <h3 className='text-normal text-[15px] font-regular'>En facturant {simulatorData.weekHours}h de cours/semaine à {simulatorData.hourlyPrice}€/h après CI <sup className='text-third sup'>(1)</sup></h3>
                            <ul className='m-0'>
                                <li className='text-sm font-regular text-normal'>Vous gagnerez : <span className={marker === 0 ? 'text-radio' : 'text-[#8097EC]'}>{(marker === 0 ? props.response.hourlyPay.liberatoire * simulatorData.weekHours : props.response.hourlyPay.classic * simulatorData.weekHours).toFixed(2)}€/semaine</span></li>
                            </ul>
                        </div> :
                        <div className='lg:hidden'>
                            <Skeleton className='lg:rounded-[30px] rounded-[17px]' variant="rectangular" width="100%" height="73px" />
                        </div>
                }
                <div className="order-last w-full lg:order-first lg:w-2/3">
                    <Legend response={props.response} callBack={props.callBack} />
                </div>
                <div className={`${show !== undefined ? 'bg-white' : 'bg-transparent'} flex relative rounded-[17px] lg:rounded-[30px] lg:border-[3px] border-radio lg:shadow-simulator-dashboard md:p-8 lg:w-3/5 items-center gap-x-4 justify-between py-8 p-1 min-h-[523px]`}>
                   {renderSlide()}
                    <div className="hidden w-max lg:block">
                        <Button id='change-chart' onClick={() => setStop(true)} className={`animate__animated w-10 h-10 min-w-0 p-2 text-center rounded-full bg-radio next-slide disabled:opacity-40`} disabled={show ? false : true}>
                            <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L12.5618 11.1216C12.7928 11.321 12.7928 11.679 12.5618 11.8784L2 21" stroke="white" stroke-width="3" stroke-linecap="round" />
                            </svg>
                        </Button>
                    </div>
                    <div className="w-full lg:hidden absolute -bottom-[18px] flex justify-center">
                        <Button id='change-chart' onClick={() => setStop(true)} className={`animate__animated w-10 h-10 min-w-0 p-2 text-center rounded-full bg-radio next-slide disabled:opacity-40 `} disabled={show ? false : true}>
                            <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L12.5618 11.1216C12.7928 11.321 12.7928 11.679 12.5618 11.8784L2 21" stroke="white" stroke-width="3" stroke-linecap="round" />
                            </svg>
                        </Button>
                    </div>
                    <div className="absolute lg:-bottom-[20px] w-full left-0 -bottom-[60px]">
                        <Button onClick={() => setOpen(true)} className='rounded-[15px] text-white mx-auto lg:w-1/3 block' style={{ background: 'linear-gradient(180deg, #219CFF 0%, #0B2775 100%)' }}>ÇA M’INTÉRESSE !</Button>
                    </div>
                </div>
            </div>
            <div className='items-center hidden gap-x-2 lg:flex'>
                <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9628 16.3491C13.178 16.5563 13.3008 16.8749 13.3008 17.1936C13.3008 17.4963 13.178 17.8149 12.9628 18.0381C12.7476 18.2612 12.4557 18.3886 12.1485 18.3886C11.8412 18.3886 11.5493 18.2612 11.3341 18.0381C11.1189 17.8149 10.9961 17.4963 10.9961 17.1936C10.9961 16.8749 11.1189 16.5563 11.3341 16.3491C11.5493 16.1259 11.8412 15.9985 12.1485 15.9985C12.4557 15.9985 12.7476 16.1259 12.9628 16.3491Z" fill="#4D4D4D" />
                    <path d="M13.3008 9.35373V13.8791C13.3008 14.5323 12.7784 15.0741 12.1485 15.0741C11.5185 15.0741 10.9961 14.5323 10.9961 13.8791V9.35373C10.9961 8.68453 11.5185 8.15869 12.1485 8.15869C12.7784 8.15869 13.3008 8.68453 13.3008 9.35373Z" fill="#4D4D4D" />
                    <path d="M21.154 19.7588L12.15 3.5854L3.14604 19.7588H21.154ZM24.1501 20.3641C24.3499 20.7306 24.3499 21.1926 24.1501 21.5592C23.9503 21.9257 23.5664 22.1488 23.1514 22.1488H1.14858C0.73359 22.1488 0.34965 21.9257 0.14985 21.5592C-0.04995 21.1926 -0.04995 20.7306 0.14985 20.3641L11.1513 0.60564C11.3511 0.22316 11.7353 0 12.15 0C12.5647 0 12.9489 0.22316 13.1487 0.60564L24.1501 20.3641Z" fill="#4D4D4D" />
                </svg>
                <p className='font-regular lg:text-sm text-normal '>Ce simulateur ne tient pas compte des invalidités ou autres droits spéciaux auxquels vous pourriez avoir droit en fonction de votre situation, ne tient pas compte des crédits d'impôts éventuels auxquels vous avez déjà droit, dans la limite de 6000€ de crédit d'impôt par famille</p>
            </div>
            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "42px",
                        position: "relative",
                        overflow: "visible",
                        boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)"

                    }
                }}
            >
                <div className="relative flex flex-col p-4 md:p-8 gap-y-4">
                    <div className="absolute z-[1302] right-6 top-6">
                        <span onClick={() => setOpen(false)} className='block p-0 cursor-pointer w-max'>
                            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0251 0C26.451 0 34.0989 7.50236 34.0989 16.7011C34.0989 25.9476 26.451 33.45 17.0251 33.45C7.64791 33.45 0 25.9476 0 16.7011C0 7.50236 7.64791 0 17.0251 0ZM17.0251 13.9295L21.117 9.96332C22.9681 8.12357 25.7934 10.8951 23.9667 12.7588L19.8748 16.7011L23.9667 20.7151C25.8178 22.531 22.9681 25.3025 21.117 23.5106L17.0251 19.4966L12.982 23.5106C11.1065 25.3264 8.30553 22.531 10.1323 20.7151L14.1754 16.7011L10.1323 12.7349C8.25681 10.8951 11.0822 8.09968 12.982 9.93943L17.0251 13.9295Z" fill="#FF724F" />
                            </svg>
                        </span>
                    </div>
                    <div className='absolute left-0 z-[1301] w-full -top-20'>
                        <div className="p-10 pt-0 mx-auto w-max" style={{ background: "radial-gradient(50% 50% at 50% 50%, rgba(0,0,0,0.4) 0%, rgba(217,217,217,0) 100%)" }}>
                            <img src={fuseilu} alt="" />
                        </div>
                    </div>
                    <DialogTitle id="alert-dialog-title" >
                        <h2 className='mt-10 font-bold text-center uppercase text-first lg:text-[22px]'>ÇA VOUS INTERESSE ?</h2>
                    </DialogTitle>
                    <DialogContent className='flex flex-col gap-y-8'>
                        <DialogContentText id="alert-dialog-description">
                            <p className='font-regular text-center text-normal lg:text-lg'> Pour pouvoir profiter de votre nouveau revenu net par heure de cours et faire profiter du crédit d’impôt à vos élèves vous pouvez suivre les étapes en <span className='font-regular'>cliquant si dessous. (3min)</span></p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='flex-col gap-y-3'>
                        <Button className='block w-1/2 py-3 mx-auto font-bold text-white rounded-lg bg-first' onClick={() => navigate('/declarons/avance-immediate/en-profiter')}>C'EST PARTI</Button>
                    </DialogActions>
                </div>
            </Dialog>

        </div>
    );
};

export default Index;