import { styled } from '@mui/material/styles';
import stepIcon from '../../Assets/Images/step_icon.svg'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  color: "#0B2775",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: 5,

    background: theme.palette.mode === '#ECECEC',
  }
}));
function Stepper(props) {
  const formatStep = () => {
    return props.currentStep * 100 / props.step
  }
  return (
    <div className='//px-5 py-5 //lg:px-12 //border-4 //border-red-400'>
      <div className='relative'>
        <BorderLinearProgress sx={{
          color: "#0B2775",
          overflow: 'visible',
          '& .MuiLinearProgress-bar': {
            transform: 'translateX(0) !important',
            width: `${formatStep()}%`,
            transition: 'width 1s'
          },
          '& .MuiLinearProgress-bar::after': {
            // content:`url(${stepIcon})`,
            position: 'absolute',
            right: '-5px',
            top: '-29px'
          }
        }} variant="determinate" value={formatStep()}></BorderLinearProgress>
        {/* <div className={`absolute w-full -left-3 translate-x-[${formatStep()}%] -bottom-4`}>
          <img src={stepIcon}  alt="" />
        </div> */}
      </div>
      <div className='pt-10 flex justify-between'>
        <div className='w-1/2 hidden md:block '>
          <div className=" w-2/3 mx-auto">
            <p className='text-first xl:text-3xl  lg:text-2xl md:text-xl text-lg font-bold md:leading-[41.51px]'>DEVENEZ PRESTATAIRE, </p>
            <br />
            <br />
            {/* <br /> */}
            <p className='text-first font-regular xl:text-2xl lg:text-xl md:text-lg md:leading-[41.51px]' >Postulez aux offres de missions en adéquation avec vos compétences et communiquez directement avec plus de
              <br /> 10 000 clients potentiels. </p>
            <br />
            {/* <br /> */}
            <p className='text-first font-regular xl:text-2xl lg:text-xl md:text-lg md:leading-[41.51px]'>
              Concentrez-vous sur votre prestations nous réalisons toutes les démarches administratives à votre place, gratuitement.
            </p>
          </div>
        </div>
        <div className='md:w-1/2 w-full mx-auto' >
          {props.children[props.currentStep - 1]}
        </div>
      </div>
    </div>
  )
}

export default Stepper