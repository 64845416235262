import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoNova from '../../../../../../Assets/Images/nova.png'
const First = (props:any) => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col gap-y-10'>
            <div className='w-3/5 lg:w-3/4'>
                <div className="flex items-end pb-8 gap-x-2">
                    <h3 className='text-lg font-bold text-first lg:text-2xl'>Déclaration</h3>
                    <img  className='w-[86px] h-[32px]'  src={logoNova} alt="" />
                </div>
                <p className='font-regular text-normal lg:text-lg'>Nova est le service de démarches en ligne des organismes de services à la personne.
                    <br />La déclaration est obligatoire pour exercer en tant qu'auto-entrepreneur et faire bénéficier à vos élèves du crédit d'impôt instantané.</p>
                <p className='mt-4 font-regular text-normal lg:text-lg'>Comment ça marche ?</p>
            </div>
            <div className='flex flex-col pb-4 md:gap-y-8 gap-y-4'>
                <Button onClick={()=> navigate('/declarons/auto-entrepreneur/nova/guide')} className='px-8 text-white rounded-full md:w-1/2 bg-first'>accéder au guide </Button>
                <Button className='px-8 text-white rounded-full md:w-1/2 bg-first' onClick={()=> props.nextScreen(1)}>ajouter ma déclaration NOVA</Button>
            </div>
        </div>
    );
};

export default First;