import React, { useEffect, useState } from 'react'
import iconGoogle from '../../../Assets/Images/google_icon.svg'
import { Button, Checkbox } from '@mui/material'
import validateEmail from '../../../Validation/Email';
import validatePassword from '../../../Validation/Password'
import { useNavigate } from "react-router-dom"
import { auth, FirebaseAuthServiceErrorCode, getJWTToken, mailSendMailVerification, registerWithEmailAndPassword, signInWithGoogle } from '../../../Services/FirebaseService'
import { useAuthState } from 'react-firebase-hooks/auth'
import ToolTip from '../../../Components/utils/ToolTip'
import { validatePhoneNumber } from '../../../Validation/Phone'
import NavBar from '../../../Components/ComponentsUtils/NavBar'
import StepperStore from '../../../LocalForage/StepperStore';
import Loader from '../../../Components/utils/Loader';
import TeachrRepository from '../../../Repositories/TeachrRepository';
import ApiService from '../../../Services/ApiService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Functions from '../../../Helpers/Functions';
import { inMemoryPersistence } from 'firebase/auth';
import Teachr from '../../../Objects/models/Teachr';
import { useDispatch } from 'react-redux';

export default function Register(props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [teachr, setTeachr] = useState<Teachr>()
  //check if user exist //
  useEffect(() => {
    if (user) {
      (async () => {
        const jwtToken = await getJWTToken(user)
        ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
        TeachrRepository.getCurrentTeachr().then((response) => {
          dispatch({
            type: 'SET_INFO',
            payload: {
              teachr: response,
              file: undefined,
              authorization: ""
            }
          })
          navigate('/declarons/prestations')
        }).catch((error) => {
          ApiService.disableAuth()
          let displayName = [""]
          if (user.providerData[0].providerId === "google.com") {
            if (user.providerData[0].displayName) {
              displayName = user.providerData[0].displayName.split(" ")
            }
            const teachrObject = {
              birthday: "",
              description: "",
              firebaseAuthToken: "",
              firebaseMessagingToken: "firebaseMessagingToken",
              firstName: displayName.length > 0 ? displayName[0] : "",
              lastName: displayName.length >= 1 ? displayName[1] : "",
              mail: user.providerData[0].email,
              phone: "",
              sex: "",
              whereStudies: "",
              yearInStudies: 0
            }
            dispatch({
              type: 'SET_INFO',
              payload: {
                teachr: teachrObject,
                file: undefined,
                authorization: ""
              }
            })
          } else {
            dispatch({
              type: 'SET_INFO',
              payload: {
                teachr: teachr,
                file: undefined,
                authorization: ""
              }
            })
          }
          if (!user.emailVerified) {
            mailSendMailVerification(user!).then(() => props.nextStep(2))
          } else {
            props.nextStep(2)
          }
        })
      })()
      // navigate('/register/next')
    }
  }, [teachr, user]);

  //end Check if user exist

  // definitions//


  const Swal = require('sweetalert2')
  let timerInterval: any
  const [showPass, setShowPass] = useState(false)
  const [showCPass, setShowCPass] = useState(false)
  const [remember, setRemember] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: '',
    cpassword: '',
    phone: '',
    terms: false,
    ccg: false
  })
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    phone: '',
    terms: '',
    ccg: ''
  })

  // end Definitions //

  //end get //

  //validation //

  //end Validation //
  const validateTerms = () => {
    if (!form.terms) {
      return {
        isValid: false,
        message: 'Veuillez accepter les conditions d’utilisation et la politique des données'
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }
  const validateCcg = () => {
    if (!form.ccg) {
      return {
        isValid: false,
        message: 'Veuillez accepter les  CGUs et CGVs'
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }
  const validate = () => {
    let erremail = true
    let errpass = true
    let errterms = true
    let errccg = true
    if (!validateEmail(form.email).isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      erremail = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      erremail = false
    }
    if (!validatePassword(form.password, form.cpassword).isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errpass = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errpass = false
    }
    if (!validateTerms().isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errterms = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errterms = false
    }
    if (!validateCcg().isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errccg = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errccg = false
    }


    if (errpass || erremail || errterms || errccg || validatePhoneNumber(form.phone) !== '')
      return false
    else return true
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (validate()) {
      const teachrObject = {
        birthday: "",
        description: "",
        firebaseAuthToken: "",
        firebaseMessagingToken: "firebaseMessagingToken",
        firstName: "",
        lastName: "",
        mail: "",
        phone: "",
        sex: "",
        whereStudies: "",
        yearInStudies: 0
      }
      teachrObject.mail = form.email
      teachrObject.phone = Functions.formatPhoneNumber(form.phone)
      setTeachr(teachrObject)
      document.getElementById('teachr-loader')?.classList.remove('hidden')
      const request = registerWithEmailAndPassword(form.email, form.password)
      if (remember) {
        request.then((response: any) => {
          StepperStore.setItem('register', {
            email: form.email,
            phone: Functions.formatPhoneNumber(form.phone)
          })
        }).catch((error) => {
          ApiService.disableAuth()
          document.getElementById('teachr-loader')?.classList.add('hidden')
          switch (error.code) {
            case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: "Cet email est déjà utilisé !",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              })
              break;

            default:
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: "quelque chose s'est mal passé!",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              })
              break;
          }
        })
      } else {
        auth.setPersistence(inMemoryPersistence).then(() => {
          request.then((response: any) => {
            StepperStore.setItem('register', {
              email: form.email,
              phone: Functions.formatPhoneNumber(form.phone)
            })

          }).catch((error) => {
            ApiService.disableAuth()
            document.getElementById('teachr-loader')?.classList.add('hidden')
            switch (error.code) {
              case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: "Cet email est déjà utilisé !",
                  timer: 2000,
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
                break;

              default:
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: "quelque chose s'est mal passé!",
                  timer: 2000,
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
                break;
            }
          })
        })
      }


    }



  }
  return (
    <div className='w-screen overflow-x-hidden bg-center bg-no-repeat bg-cover md:h-screen md:overflow-y-auto bg-register'>
      <div className="overflow-x-hidden md:h-screen md:overflow-y-auto :">
        <Loader />
        <div className="md:w-1/2">
          <div className='md:w-4/5  md:mx-0 mx-4 relative flex flex-col gap-4 xl:gap-8 p-6 bg-[#FFFFFF] border-[#758FDA78] border-[1px] rounded-lg'>
            <h1 className='text-base font-bold text-first xl:text-3xl lg:text-2xl md:text-lg' >Nous rejoindre : </h1>
            <Button onClick={signInWithGoogle} className='flex items-center font-nunito //shadow-[0px 4px 4px 0px #0000000F] border-[1px] border-[#80808050] font-bold justify-center xl:px-4 md:px-2 py-2 text-[#2A2A2A] no-underline bg-[#F4F7F8] rounded-md gap-x-3 w-max'><img src={iconGoogle} alt="" /> S’inscrire avec Google</Button>
            <div>
              <p className='xl:text-lg lg:text-base md:text-sm text-[#4d4d4d]'>Ou remplir les champs suivants :</p>
            </div>
            <div className="flex flex-col gap-4 lg:gap-4 md:gap-8 pb-14">
              <div className="flex flex-col md:flex-row gap-y-6 gap-x-4">
                <div className="w-full md:w-1/2">
                  <ToolTip text={errors.email}>
                    <div className={errors.email === '' ? 'bg-[#F4F7F8] rounded-md py-2 px-3 border-[1px] border-[#80808050]' : 'bg-[#F4F7F8] rounded-md py-2 px-3 border-2 border-red-600 '}>
                      <label htmlFor="">Email</label>
                      <input type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: e.target.value, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: form.ccg })} placeholder='mail@mail.com' />
                    </div>
                  </ToolTip>
                </div>
                <div className="w-full md:w-1/2">
                  <ToolTip text={errors.phone}>
                    <div
                      className={
                        errors.phone === ''
                          ? "bg-[#F4F7F8] rounded-md py-2 px-3 border-[1px] border-[#80808050] w-full"
                          : "bg-[#F4F7F8] rounded-md py-2 px-3 w-full border-2 border-red-600"
                      }
                    >
                      <label htmlFor="">Numéro de téléphone</label>
                      <input
                        type="tel"
                        onChange={(e) => {
                          setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: e.target.value, terms: form.terms, ccg: form.ccg });
                          setErrors({
                            email: errors.email,
                            password: errors.password,
                            terms: errors.terms,
                            phone: validatePhoneNumber(e.target.value),
                            ccg: errors.ccg
                          })
                        }}
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="06 XX XX XX XX"
                      />
                    </div>
                  </ToolTip>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-6 gap-x-4">
                <div className='w-full md:w-1/2'>
                  <ToolTip text={errors.password}>
                    <div className={errors.password === '' ? 'bg-[#F4F7F8] rounded-md py-2 px-3 border-[1px] border-[#80808050] w-full relative' : 'bg-[#F4F7F8] rounded-md relative py-2 px-3 w-full border-2 border-red-600'}>
                      <label htmlFor="">Mot de passe</label>
                      <input type={showPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: form.email, password: e.target.value, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: form.ccg })} placeholder='********' />
                      <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowPass(!showPass)}>{showPass ? <VisibilityIcon className='text-normal' /> : <VisibilityOffIcon className='text-normal' />}</span>

                    </div>
                  </ToolTip>
                </div>
                <div className='relative w-full px-3 py-2 bg-[#F4F7F8] border-[1px] border-[#80808050] rounded-md md:w-1/2'>
                  <label htmlFor="">Confirmation de mot de passe</label>
                  <input type={showCPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: form.email, password: form.password, cpassword: e.target.value, phone: form.phone, terms: form.terms, ccg: form.ccg })} placeholder='********' />
                  <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowCPass(!showCPass)}>{showCPass ? <VisibilityIcon className='text-normal' /> : <VisibilityOffIcon className='text-normal' />}</span>
                </div>
              </div>
              <div className='flex flex-col justify-between gap-4 //md:flex-row'>
                <ToolTip text={errors.terms}>
                  <div className='flex items-center w-max'>
                    <Checkbox sx={errors.terms === '' ? {
                      color: '#808080',
                      '&.Mui-checked': {
                        color: '#808080',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    } : {
                      color: 'rgb(220 38 38)',
                      '&.Mui-checked': {
                        color: '#808080',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    }} onClick={() => setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: !form.terms, ccg: form.ccg })} />
                    <a target={"_blank"} href='https://www.teachr.fr/policy' className="text-[#808080] underline" rel="noreferrer">J’accepte la politique des données.</a>
                  </div>
                </ToolTip>
                <ToolTip text={errors.ccg}>
                  <div className='flex items-center w-max'>
                    <Checkbox sx={errors.ccg === '' ? {
                      color: '#808080',
                      '&.Mui-checked': {
                        color: '#808080',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    } : {
                      color: 'rgb(220 38 38)',
                      '&.Mui-checked': {
                        color: '#808080',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    }} onClick={() => setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: !form.ccg })} />
                    <a target={"_blank"} href='https://www.teachr.fr/cgu_cgv' className="text-[#808080] underline" rel="noreferrer">J'accepte les CGUs et CGVs.</a>
                  </div>
                </ToolTip>
              </div>
              <div className='flex items-center gap-2 text-[#808080]'>
                  <Checkbox onChange={() => setRemember(!remember)} className='p-0 text-[#808080]' />
                  <a className="text-[#808080]" rel="noreferrer">Se souvenir de moi</a>
                </div>
            </div>
            <Button type="submit" onClick={handleSubmit} className='absolute flex items-center justify-center px-4 py-2 font-bold text-white no-underline rounded-lg bottom-4 right-4 lg:w-1/2 font-nunito bg-first gap-x-3'>JE M’INSCRIS !</Button>
          </div>
        </div>
      </div>
    </div >
  )
}
