import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import gif from "../../../Assets/Icons/load.gif";
import ArrowRightCircle from "../../../Assets/Icons/arrow-right-circle.svg";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import ClientRepository from "../../../Repositories/ClientRepository";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
// import Client from "../../../Objects/models/Client";
import { useEffect, useState } from "react";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
import { CoursePack } from "../../../Objects/models/CoursePack";
import NoLessonRequest from "./Components/NoLessonRequest";
import HistoricPunctual from "./HistoriqueCourse/HistoricPunctual";
import HistoricCoursePacks from "./HistoriqueCourse/HistoricCoursePacks";
import HistoricSubscription from "./HistoriqueCourse/HistoricSubscription";
import HistoricInTheHourCourse from "./HistoriqueCourse/HistoricInTheHourCourse";
import { Link } from "react-router-dom";

const HistoricCourse = () => {
  const [pastPunctual, setPastPunctual] = useState<PunctualCourse[]>();
  const [pastUrgent, setPastUrgent] = useState<PunctualCourse[]>();
  const [pastSubscription, setPastSubscription] =
    useState<SubscriptionCourse[]>();
  const [pastPacks, setPastPacks] = useState<CoursePack[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ClientRepository.getCurrentClient().then((client) => {

      if (client.id) {
        PunctualCourseRepository.getPastPunctualCourse(client.id).then(
          (pastCourse) => {
            if (pastCourse) {
              const urgentCourse = pastCourse.filter(
                (course: PunctualCourse) => course.isCourseInTheHour
              );
              const PunctualCourses = pastCourse.filter(
                (course: PunctualCourse) => course.isCourseInTheHour === false
              );
              setPastUrgent(urgentCourse);
              setPastPunctual(PunctualCourses);
            }
          }
        );
        SubscriptionCourseRepository.getPastSubscriptionCourse(
          client.id
        ).then((pastCourse) => {
          if (pastCourse) {
            setPastSubscription(pastCourse);
          }
        });
        CoursePackRepository.getPastCoursePacksClient(client.id).then(
          (pastCourse: any) => {
            setPastPacks(pastCourse.members);
          }
        );
      }
      setLoading(true);
    });
  }, []);

  const scrollCoursesToRight = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(screen.width, 0);
  };
  const scrollCoursesToLeft = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(-screen.width, 0);
  };

  return (
    <>
      {
        //pastPacks || pastSubscription || pastPunctual ?
        pastPunctual?.length === 0 &&
          pastUrgent?.length === 0 &&
          pastSubscription?.length === 0 &&
          pastPacks?.length === 0 ? (
          <NoLessonRequest />
        ) : (
          <div>
            {loading ? (
              <div>
                <div className="block font-sans">
                  <div className="mt-10 md:mt-4 font-sans">
                    {pastUrgent?.length === 0 ? (
                      ""
                    ) : (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="text-sm font-regular lg:text-xl md:text-lg text-first">
                            Prestation dans l'heure
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="urgent-course" className="space-y-6  list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex md:space-y-4 //gap-x-10">
                            {pastUrgent?.map(
                              (course: PunctualCourse, key) => (
                                <HistoricInTheHourCourse {...course} key={key} />
                              )
                            )}
                          </div>
                          {pastUrgent !== undefined &&
                            pastUrgent?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("urgent-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("urgent-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {pastPunctual?.length === 0 ? "" :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="text-sm font-regular lg:text-xl md:text-lg text-first">
                            Prestation Pontuelle
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="punctual-course" className="space-y-6  list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex md:space-y-4 //gap-x-10">
                            {pastPunctual?.map(
                              (course: PunctualCourse, key) => (
                                <HistoricPunctual {...course} key={key} />
                              )
                            )}
                          </div>
                          {pastPunctual !== undefined &&
                            pastPunctual?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("punctual-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("punctual-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    }
                    {pastSubscription?.length === 0 ? '' :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="text-sm font-regular lg:text-xl md:text-lg text-first">
                            Prestation Régulière
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="regular-course" className="space-y-6  list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex md:space-y-4 //gap-x-10">
                            {pastSubscription?.map(
                              (course: SubscriptionCourse, key: any) => (
                                <HistoricSubscription
                                  {...course}
                                  key={key}
                                />
                              )
                            )}
                          </div>
                          {pastSubscription !== undefined &&
                            pastSubscription?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("regular-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("regular-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    }
                    {pastPacks?.length === 0 ? "" :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="text-sm font-regular lg:text-xl md:text-lg text-first">
                            Regroupement des prestations
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="pack-course" className="space-y-6  list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex md:space-y-4 //gap-x-10">
                            {pastPacks?.map((course: CoursePack, key) => (
                              <HistoricCoursePacks {...course} key={key} />
                            ))}
                          </div>
                          {pastPacks !== undefined &&
                            pastPacks?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("pack-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("pack-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>}
                  </div>
                </div>
              </div>
            ) : (
              <div className="mx-auto my-auto mt-40">
                <img src={gif} alt="gif" className="mx-auto " />
              </div>
            )}
          </div>
        )
      }
    </>
  );
};

export default HistoricCourse;
