import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import illustration from '../../../Assets/videos/aici_finish.gif'
import TeachrContactRepository from '../../../Repositories/TeachrContactRepository';
const Complete = (props: any) => {
    const dispatch = useDispatch()
    dispatch({
        type: 'HIDE_LOADER'
    })
    const params = useParams()
    const navigate = useNavigate()
    const [name,setName] = useState('')
    useEffect(() => {
        if (params.key) {
            TeachrContactRepository.fetchTeachrContactByKey(params.key).then((response) => {
                if (response) {
                    setName(response.teachr.firstName)
                }
            }).catch(() => navigate('/notfound'))

        } else {
            navigate('/notfound')
        }
    }, [])
    return (
        <div className="md:p-8">
            <div className='p-4 md:p-8 bg-[#fff] border-[rgba(0,0,0,0.1)]  border-[1px] shadow-dashboard-setup-intro-form mt-10 rounded-xl flex flex-col gap-y-10'>
                <h1 className='text-2xl font-bold lg:text-3xl text-first'>Vous avez besoin de relire les informations précédentes ?</h1>
                <div className='flex flex-col items-center justify-between md:flex-row gap-y-10'>
                    <div className='flex flex-col font-regular leading-6 lg:w-3/4 lg:text-lg text-first gap-y-8'>
                        <p>Cliquez sur "Bon à savoir" afin de relancer le tutoriel d'activation.
                        <br />N’oubliez pas d’activer votre compte CESU+ comme mentionné précédement, nous restons à votre disposition si besoin.
                        </p>
                    </div>
                    <div className="flex justify-center order-first w-full md:justify-end md:order-last">
                        <img src={illustration} alt="" />
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button onClick={() => props.callBack(false)} className='w-full py-2 text-lg font-bold text-white uppercase rounded-lg px-14 bg-first next-slide md:w-max'>Bon à savoir</Button>
                </div>
            </div>
        </div>
    );
};





export default Complete;