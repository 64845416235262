import React from 'react'
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 25,
  color:'#0B2775',
  height: 25,
  backgroundColor: theme.palette.mode === 'dark' ? '#0B2775' : 'transparent',
  'input ~ &' : {
  border: '1px solid #0B2775'
  },
  'input:checked ~ &' : {
    border: 'none',
    },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#0B2775',
    border: 'none',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    border: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#0B2775',
  '&:before': {
    display: 'block',
    width:  '100%',
    height: '100%',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0B2775',
  },
});

function RadioButton(props:any) {
  return (
    <div><Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  /></div>
  )
}

export default RadioButton