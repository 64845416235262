import { useEffect, useRef, useState } from "react";
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import ComponentBodyTablePunctualCourse from "./ComponentBodyTablePunctualCourse";
import ponct_dash from "../../../../../../../Assets/Images/ponct_dash.svg"
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
import { RadioGroup } from "@mui/material";
import React from "react";

const ComponentPoncual = (props: any) => {
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const { handleLoad, isHere, isEdit, isDelete, handleEdit, handleDelete } = props
    const [value, setValue] = useState('');
    const radioGroupRef = useRef<HTMLElement>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value)
    }


    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
                    (course) => {
                        setCourse(course);
                        handleLoad()
                    }
                );
            }
        });
    }, []);


    return (
        <>
            {course === undefined ?
                <div className="overflow-y-auto scroll w-full relative  h-[85%] md:mt-6 px-2 //py-2 //bg-white  rounded-[11px] shadow-dashboard-setup-intro-form">
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
                : course.length === 0 ?
                    <div className="  md:w-full relative list-teacher h-[83%] mt-6 px-2 py-2 shadow-md border-[1px] border-[#4B6AD86B]  rounded-xl md:rounded-transparent">
                        <div className="mx-auto md:flex">
                            <div className="md:w-2/4 mx-auto ">
                                <p className="text-[#4d4d4d] lg:text-2xl md:text-lg text-base font-regular mx-auto  text-center md:pt-10" >
                                    Afin de recevoir un paiement via notre plateforme, Veuillez déclarer votre première prestation, ainsi que tout autre prestation que vous avez déjà dispensé.
                                </p>
                            </div>
                            <div className="mx-auto //mt-16 md:w-1/4">
                                <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="overflow-y-auto md:mt-4 scroll w-full relative  h-[85%] bg-white //border-2 shadow-md border-[1px] border-[#4B6AD86B]  rounded-xl md:rounded-transparent">
                        <RadioGroup
                            ref={radioGroupRef}
                            aria-label="ringtone"
                            name="ringtone"
                            value={value}
                            onChange={handleChange}
                        >

                            <table className="w-full text-sm text-left   ">
                                <thead className="text-xs md:text-lg font-bold text-first bg-[#EDF0FB]">
                                    <tr className=" w-full   ">
                                        {isHere && <th scope="col " className="py-2 w-[5%]  //px-3 /text-center   "></th>}
                                        <th scope="col " className="py-4 px-3 /text-center w-[16%]  ">
                                            Date
                                        </th>
                                        {/* <th scope="col " className="py-4 px-3 /text-center w-[16%]  ">
                                            Heure
                                        </th> */}
                                        <th scope="col " className="py-4 px-3 /text-center w-[13%] ">
                                            Client
                                        </th>
                                        <th scope="col " className="py-4 px-3 /text-center w-[16%] ">
                                            Prestations
                                        </th>
                                        <th
                                            scope="col "
                                            className="py-4 px-3 text-center"
                                        >
                                            <div className="/text-center">
                                                <p>Paiement </p>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            <div className="border-b-2 w-[20px]"></div>
                                        </th>
                                        <th
                                            scope="col "
                                            className="py-4 px-3 text-center"
                                        >
                                            <div className="/text-center">
                                                <p>Rémunération</p>
                                            </div>
                                        </th>
                                        <th scope="col " className="py-4 px-3 text-center /text-center w-[12%] ">
                                            Durée
                                        </th>
                                        <th scope="col " className="py-4 px-3 /text-center /text-center w-[12%] ">
                                            Statut
                                        </th>
                                    </tr>
                                </thead>
                                {course?.sort(function (a: any, b: any) {
                                    const date1: any = moment(a.startDatetime).toDate()
                                    const date2: any = moment(b.startDatetime).toDate()
                                    return (date1 - date2);

                                })?.map((punctual: PunctualCourseDashboard, key) => (
                                    <ComponentBodyTablePunctualCourse punctual={punctual} handleLoad={handleLoad} isHere={isHere} handleDelete={handleDelete} isEdit={isEdit} isDelete={isDelete} handleEdit={handleEdit} key={key} />
                                ))}
                            </table>
                        </RadioGroup>
                    </div>
            }
        </>
    );
};

export default ComponentPoncual;
