import  { useEffect, useState } from 'react';
import { Address } from '../../../../../Objects/models/Address';
import Child from '../../../../../Objects/models/Child';
import Client from '../../../../../Objects/models/Client';
import { PunctualCourse } from '../../../../../Objects/models/PunctualCourse';
import { Subject } from '../../../../../Objects/models/Subject';
import checkCircle from '../../../../../Assets/Icons/check-circle.svg'
import ProfileImg from '../../../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../../..//Assets/Icons/hours.svg'
import CalendarIcon from '../../../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../../../Assets/Icons/address.svg'
import LocationIcon from '../../../../../Assets/Icons/address.svg'
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import Functions from '../../../../../Helpers/Functions';
import moment, { duration, Moment } from 'moment';
import CoursePrice from '../../../../../Components/utils/CoursePrice';
import MessageCourse from '../../../../../Components/Dashboard/MessageCourse';
import { Button } from '@mui/material';
import CourseProposal from '../../../../../Objects/models/CourseProposal';
import CourseProposalRepository from '../../../../../Repositories/CourseProposal';
import Teachr from '../../../../../Objects/models/Teachr';
import editIcon from '../../../../../Assets/Icons/edit.svg'
import { TimePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const format = "HH:mm";

const PunctualDetails = (
    punctual: {
        teachr: Teachr,
        type: string,
        course: PunctualCourse,
        subject: Subject,
        child: Child | Client,
        address: Address
    }
) => {
    const navigate = useNavigate()
    const [openTimepicker, setOpenTimePicker] = useState(false)
    const [startTime, setStartTime] = useState<Moment>()
    const [endTime, setEndTime] = useState('')
    const [editMode, setEditMode] = useState(false)
    // const [startTimeError, setStartTimeError] = useState(false)
    useEffect(() => {
        // setStartTime(moment(punctual.course.startDatetime))
        setEndTime(moment(punctual.course.endDatetime).format('LT'))
        // setLimitTime(moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours'))
        if (moment(punctual.course.startDatetime).format('LT') === moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours').format('LT')) {
            setEditMode(false)
        }else{
            setEditMode(true)
        }
        
    }, [punctual])
    useEffect(()=> {
        
        
       if (editMode) {
        setEndTime(getEndDateTime().format('LT'))
       } 
    },[editMode,startTime])
    const formatTime = (time: string | undefined) => {
        let format = ''
        if (time) {
            const regex = "(.*):(.*)"
            const match = time.match(regex)
            if (match) {
                format = `${match[1]}h${match[2]}`
            }
        }
        return format
    }
    const [description, setDrescription] = useState('')
    const getDescrition = (value: string) => {
        setDrescription(value)
    }
    const [price, setPrice] = useState('')
    const getPrice = (value: string) => {
        setPrice(value)
    }
    const getEndDateTime = () => {
        const result = moment(startTime).add(punctual.course.duration / 2, 'hours')
        return result
    }
    const createdProposal = () => {
        document.getElementById('teachr-loader')?.classList.remove('hidden')
        const courseProposal = new CourseProposal(
            punctual.teachr['@id']!,
            `/punctual_courses/${punctual.course.id}`,
            moment(punctual.course.startDatetime!).second( moment(punctual.course.endDatetime!).second()).toDate(),
            moment(punctual.course.endDatetime).second( moment(punctual.course.endDatetime!).second()).toDate(),
            price,
            description
        )
        if (editMode) {
            courseProposal.startDatetime = new Date(moment(startTime).second( moment(punctual.course.endDatetime!).second()).toDate())
            courseProposal.endDatetime = new Date(moment(getEndDateTime()).second( moment(punctual.course.endDatetime!).second()).toDate())
        }
        CourseProposalRepository.createCourseProposal(courseProposal).
            then(() => {
                navigate('/declarons/prestations/postuler/confirm')
                document.getElementById('teachr-loader')?.classList.add('hidden')
            }).catch((error) => {
                console.warn(error);

            })

           
    }
    // useEffect(() => {
    //     validateStartDateTime()
    //     setEndTime(moment(getEndDateTime()).format('LT'))
    // }, [startTime])

    // const validateStartDateTime = () => {
    //     const limiteStartDate = moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours').format('LT')
    //     if (moment(punctual.course.startDatetime).format('LT') === limiteStartDate) {
    //         setEditMode(false)
    //     }else{
    //         setEditMode(true)
    //     }
    //     if (moment(startTime).format('LT') < limiteStartDate && moment(startTime) >= moment(punctual.course.startDatetime)) {
    //         setStartTimeError(false)
    //     } else {
    //         setStartTimeError(true)
    //     }

    // }
    const disabledHours = () => {
        const hours = []
        const maxHour = moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours').hour();
        const minHour = moment(punctual.course.startDatetime).hour();

        for (let i = maxHour + 1; i <= 24; i++) {
            hours.push(i)
        }
        for (let i = minHour - 1; i >= 0; i--) {
            hours.push(i)
        }

        return hours
    };
    const disabledMinutes = () => {
        const hours: any = []
        const maxMinutes = moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours').minute();
        const minMinutes = moment(punctual.course.startDatetime).minute();
        switch (moment(startTime).hour()) {
            case moment(punctual.course.startDatetime).hour():
                for (let i = minMinutes - 1; i >= 0; i--) {
                    hours.push(i)
                }
                return hours
            case moment(punctual.course.endDatetime).subtract(punctual.course.duration / 2, 'hours').hour():
                for (let i = maxMinutes + 1; i <= 45; i++) {
                    hours.push(i)
                }
                return hours

            default:
                return hours
        }

    }
    const MySwal = withReactContent(Swal)
    const showConfirmSubscriptionWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-third'>
                        <p className=''>Envoyer sans personnalisation ?</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p>Êtes-vous sûr de ne pas vouloir ajouter une description à votre candidature ?
                            Nous avons remarqué qu'avoir une description personnalisée augmente de 42% vos chances d'être sélectionné !
                        </p>
                        <div className="flex justify-between py-4">
                            <Button onClick={() => Swal.clickCancel()} className='font-regular bg-transparent rounded-full py-2 text-first lg:w-1/4 w-full border-solid border-[1px] border-first px-8 uppercase ' >Annuler</Button>
                            <Button onClick={() => { Swal.clickConfirm(); createdProposal() }} className='font-regular bg-transparent rounded-full py-2 text-third lg:w-1/4 w-full border-solid border-[1px] border-third px-8 uppercase ' >postuler</Button>
                        </div>
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    const validate = () => {
        if (description.length === 0) {
            showConfirmSubscriptionWidget()
        } else if (description.length >= 20) {
            createdProposal()
        }
    }
    useEffect(()=> {
       if (editMode) {
        if (moment(punctual.course.startDatetime) > moment()) {
            setStartTime(moment(punctual.course.startDatetime))
        }else{
            getDefaultTime()
        }
        
       }
    },[editMode])
    const getDefaultTime = () => {
        const min = moment().minute()
        switch (min) {
            case 0:
                setStartTime(moment().minute(0))
                break
            case 15:
                setStartTime(moment().minute(15))

                break
            case 30:
                setStartTime(moment().minute(30))

                break
            case 45:
                setStartTime(moment().minute(45))

                break

            default:
                if (min > 30 && min < 45) {
                    setStartTime(moment().minute(45))
                    break
                } else if (min > 15 && min < 30) {
                    setStartTime(moment().minute(30))
                   break
                } else{
                    setStartTime(moment().minute(15))
                    break
                }

        }
    }

    return (
        <div className='mx-auto lg:w-2/3'>
            <div className="flex flex-col justify-center gap-8 py-8 lg:flex-row">
                <div className='shadow-course-select  w-full p-6 rounded-[20px] mx-auto'>
                    <div className="mx-auto w-max">
                        <div className='flex items-center  gap-4 text-[22px] font-bold uppercase text-first'>
                            <p>RéCAPITULATIF </p>
                            <img className='h-[25px]' src={checkCircle} alt="" />
                        </div>
                        <div className="flex flex-col gap-5 py-8">
                            <div className="flex items-center gap-x-4">
                                <img src={ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                                <div>
                                    <p className='overflow-hidden text-xl font-regular text-first'>Ponctuelle</p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px]'>pour {punctual.child?.firstName} / {Functions.getLevel(punctual.child?.class)}</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div className='w-full'>
                                    <p className='text-[16px] font-regular text-first whitespace-nowrap'>Créneau de disponibilité</p>
                                    <div className="flex items-center justify-between">
                                        <div className='text-[#4D4D4D] text-sm flex items-center whitespace-nowrap'>
                                            <span>{`${Functions.renderDuration(punctual.course.duration)} entre `}</span>
                                            {
                                                editMode ?
                                                    <TimePicker
                                                        hideDisabledOptions={true}

                                                        disabledHours={disabledHours}
                                                        disabledMinutes={disabledMinutes}
                                                        inputReadOnly={true}
                                                        // status={startTimeError ? 'error' : ''}
                                                        defaultValue={startTime}
                                                        value = {startTime}
                                                        onSelect={(value) => {
                                                            if (value) {
                                                                setStartTime(value)
                                                            }
                                                        }}
                                                        onChange={(newValue) => {
                                                            setOpenTimePicker(!openTimepicker)
                                                        }}
                                                        open={openTimepicker}
                                                        onClick={() => setOpenTimePicker(!openTimepicker)}
                                                        minuteStep={15}
                                                        bordered={false}
                                                        format={format}
                                                        showNow={false}
                                                        clearIcon=""
                                                        size={'small'}
                                                        className={'p-0 pl-2 w-[52px] font-regular underline'}
                                                        suffixIcon=""
                                                    />
                                                    : <span className='px-1'>{formatTime(moment(punctual.course.startDatetime).format('LT'))}</span>
                                            }

                                            <span>
                                                {`et ${formatTime(endTime)}`}
                                            </span>
                                        </div>
                                        {editMode && <img src={editIcon} onClick={() => setOpenTimePicker(!openTimepicker)} width="18" className='cursor-pointer' alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div>
                                    <p className='text-xl font-regular text-first'>{moment(punctual.course?.startDatetime).format('dddd DD MMMM YYYY')}</p>
                                </div>
                            </div>

                            {punctual.course?.isRemote
                                ? <div className="flex items-center gap-4">
                                    <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <p className='text-xl font-regular text-first'>Cours à distance</p>
                                </div>
                                : <div className="flex items-center gap-4">
                                    <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <p className='text-xl font-regular text-first'>{`${punctual.address?.streetName}, ${punctual.address?.city}`}</p>
                                </div>}
                        </div>
                    </div>
                </div>
                <CoursePrice teachr={punctual.teachr} courseType='punctual' duration={punctual.course.duration} getPrice={getPrice} days={1} />
                <MessageCourse child={punctual.child} getDescrition={getDescrition} />
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-8 py-4">
                <Button href='/declarons/prestations' className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-third lg:w-1/4 border-third' >précédent</Button>
                <Button onClick={() => validate()} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-first lg:w-1/4 border-first' >postuler</Button>
            </div>
        </div>
    );
};

export default PunctualDetails;