import React from "react";

export default function InputDate(props) {
  return (
    <div>
      {props.inset ? null : (
        <label htmlFor="" className="block mb-3 text- white">
          {props.label}
        </label>
      )}
      <div className={
        "  relative rounded-lg px-4 " +
        props.customClass
      }>
        <div className="flex items-center justify-between">
          <div className="w-full">
            {props.inset ? <label htmlFor="">{props.label}</label> : null}
            <input onChange={(e) => props.callback(e.target.value)} onClick={props.click} type={props.type} value={props.value} className={' border-none lg:text-xl  -mt-6 focus:outline-none block bg-transparent w-full '} placeholder={props.placeholder}
            />
          </div>
          {props.icon}
        </div>
      </div>
    </div>
  );
}
