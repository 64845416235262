import React from 'react';

const EmptyComment = () => {
    return (
        <div className='h-full bg-bottom bg-no-repeat bg-empty-comment'>
            <p className='text-2xl font-regular text-center text-first'>Aucun teach’r n’a laissé de commentaires pour l’instant</p>
        </div>
    );
};

export default EmptyComment;