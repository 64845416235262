import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InterestedTeachrRepository from '../../../../../Repositories/InterestedTeachrRepository';
import { Skeleton } from '@mui/material';
const ReactApexChart = React.lazy(() => import('react-apexcharts'));

interface Simulator {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
}

interface SimulatorRequest {
    isAcre: boolean,
    hourlyPrice: number,
    weekHours: number,
    external_income: number,
    childs: number,
    last_income: number,
    adult: number,
    education: number
}
const Chart = (props: {
    hourlyPrice: number,
    type: string,
    callBack: any
}) => {
    const simulatorData: Simulator | null = useSelector((state: any) => state.simulateur)
    const [yaxisMin, setYaxisMin] = useState(1)
    const [yaxisMax, setYaxisMax] = useState(45)
    const [marker, setMarker] = useState({
        postion: 0,
        serie: 0
    })
    const [classicData, setClassicData] = useState<number[][]>([])
    const [liberatoireData, setLiberatoireData] = useState<number[][]>([])
    const [count, setCount] = useState(1)
    const [series, setSeries] = useState<ApexAxisChartSeries>()
    const [colors, setColors] = useState([''])
    const [legend, setLegend] = useState([''])
    const [strokeColors, setStrokeColors] = useState([''])
    useEffect(() => {
        if (simulatorData) {
            const classicResult: number[][] = classicData
            const liberatoireResult: number[][] = liberatoireData
            if (simulatorData && count < 46) {
                let object: SimulatorRequest = {
                    adult: simulatorData.adults,
                    childs: simulatorData.childs,
                    external_income: simulatorData.external_income,
                    last_income: simulatorData.last_income,
                    hourlyPrice: simulatorData.hourlyPrice,
                    weekHours: count,
                    isAcre: simulatorData.isAcre,
                    education: simulatorData.education
                }
                InterestedTeachrRepository.simulate(object).then((response) => {
                    if (response) {
                        classicResult.push([count, response.hourlyPay.classic])
                        if (response.hourlyPay.liberatoire) {
                            props.callBack(true)
                            if (response.hourlyPay.liberatoire) {
                                liberatoireResult.push([count, response.hourlyPay.liberatoire])
                            }
                        } else {
                            props.callBack(false)
                        }
                        setClassicData(classicResult)
                        setLiberatoireData(liberatoireResult)
                        if (count === simulatorData.weekHours) {
                            let serie = 0
                            if (props.type === 'classique' || props.type === 'libératoire') {
                                serie = 0
                            } else {
                                if (response.hourlyPay.liberatoire < response.hourlyPay.classic) {
                                    serie = 2
                                } else {
                                    serie = 0
                                }
                            }

                            if (count === 1) {
                                setMarker({
                                    postion: 0,
                                    serie: serie
                                })
                            } else {

                                setMarker({
                                    postion: (simulatorData.weekHours) / 5,
                                    serie: serie
                                })
                            }
                        }
                        if (count === 1) {
                            setCount(count + 4)
                        } else {
                            setCount(count + 5)
                        }
                    }
                })
            }
        }
    }, [simulatorData, count, classicData, liberatoireData])

    useEffect(() => {
        switch (props.type) {
            case 'libératoire':
                setColors(['#219CFF', '#606060'])
                setLegend([
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.25001L17 1.25" stroke="#219CFF" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <p>Régime ${props.type}</p>
                        </div>
                        `,
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="1" x2="15" y2="1" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>
                            </svg>
                            <p>Montant payé par l’élève</p>
                        </div>
                    `
                ])
                setStrokeColors(["#92D3FF", "transparent"])

                setSeries(
                    [{
                        name: 'Avec le régime ' + props.type,
                        data: liberatoireData,
                        color: '#219CFF'
                    },
                    {
                        name: 'Montant payé par l’élève',
                        data: [[1, props.hourlyPrice], [45, props.hourlyPrice]],
                        color: "#4D4D4D",
                        type: "numeric",
                    }])

                break;
            case 'classique':
                setColors(['#8097EC', '#606060'])
                setLegend([
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.25001L17 1.25" stroke="#8097EC" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <p>Régime ${props.type}</p>
                        </div>
                        `,
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="1" x2="15" y2="1" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>
                            </svg>
                            <p>Montant payé par l’élève</p>
                        </div>
                    `
                ])
                setStrokeColors(["rgba(214, 229, 255, 0.60)", "transparent", "rgba(214, 229, 255, 0.60)"])
                setSeries(
                    [
                        {
                            name: 'Avec le régime ' + props.type,
                            data: classicData,
                            color: '#8097EC'
                        },
                        {
                            name: 'Montant payé par l’élève',
                            data: [[1, props.hourlyPrice], [45, props.hourlyPrice]],
                            color: "#4D4D4D",
                            type: "numeric",
                        }])
                break;

            default:
                setColors(['#219CFF', '#606060', '#8097EC'])
                setLegend([
                    `
                    <div style="display: flex; gap: 0 15px; align-items: center;">
                        <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.25001L17 1.25" stroke="#219CFF" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        <p>Régime libératoire</p>
                    </div>
                    `
                    ,
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.25001L17 1.25" stroke="#8097EC" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <p>Régime classique</p>
                        </div>
                        `,
                    `
                        <div style="display: flex; gap: 0 15px; align-items: center;">
                            <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="1" x2="15" y2="1" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>
                            </svg>
                            <p>Montant payé par l’élève</p>
                        </div>
                    `
                ])
                setStrokeColors(["#92D3FF", "transparent", "rgba(214, 229, 255, 0.60)"])
                setSeries(
                    [{
                        name: 'Avec le régime classique',
                        data: liberatoireData,
                        color: '#219CFF'
                    },

                    {
                        name: 'Montant payé par l’élève',
                        data: [[1, props.hourlyPrice], [45, props.hourlyPrice]],
                        color: "#4D4D4D",
                        type: "numeric",
                    },
                    {
                        name: 'Avec le régime libératoire',
                        data: classicData,
                        color: '#8097EC',
                    },
                    ])
                break;
        }
    }, [classicData, liberatoireData, props])
    useEffect(() => {
        let max = 0
        let min = 0
        let diff = 0
        if (count > 45 && props.hourlyPrice && liberatoireData) {
            liberatoireData.map((el) => {
                diff = props.hourlyPrice - el[1]

                if (diff > 0) {
                    if (max !== 0) {
                        if (max < Math.abs(diff)) {
                            max = props.hourlyPrice
                        }
                    }else{
                        max = props.hourlyPrice
                    }
                    if (min !== 0) {
                        if (min > Math.abs(diff)) {
                            min = el[1]
                        }
                    }else{
                        min = el[1]
                    }
                }else{
                    if (max !== 0) {
                        if (max < Math.abs(diff)) {
                            max = el[1]
                        }
                    }else{
                        max = el[1]
                    }
                    if (min !== 0) {
                        if (min > Math.abs(diff)) {
                            min = props.hourlyPrice
                        }
                    }else{
                        min = props.hourlyPrice
                    }
                }
                // console.log('el', el, 'max', max, 'min', min)
                
            })
            diff = (max - min) / 6
            max = max + diff
            min = Math.abs(min - diff)

            setYaxisMax(parseInt(max.toFixed(0)))
            setYaxisMin(parseInt(min.toFixed(0)))
        }

    }, [liberatoireData, count])
    useEffect(() => {
        let max = 0
        let min = 0
        let diff = 0
        if (count > 45 && props.hourlyPrice && classicData) {
            classicData.map((el) => {
                diff = props.hourlyPrice - el[1]

                if (diff > 0) {
                    if (max !== 0) {
                        if (max < diff) {
                            max = props.hourlyPrice
                        }
                    }else{
                        max = props.hourlyPrice
                    }
                    if (min !== 0) {
                        if (min > diff) {
                            min = el[1]
                        }
                    }else{
                        min = el[1]
                    }
                }else{
                    if (max !== 0) {
                        if (max < diff) {
                            max = el[1]
                        }
                    }else{
                        max = el[1]
                    }
                    if (min !== 0) {
                        if (min > diff) {
                            min = props.hourlyPrice
                        }
                    }else{
                        min = props.hourlyPrice
                    }
                }
                
            })
            diff = (max - min) / 6
            max = max + diff
            min = Math.abs(min - diff)
            if (max > yaxisMax) {
                setYaxisMax(parseInt(max.toFixed(0)))
            }
            if (min < yaxisMin ) {
                setYaxisMin(parseInt(min.toFixed(0)))
            }
            
           
        }

    }, [classicData, count, yaxisMax, yaxisMin])
    return (
        <div className='flex flex-col w-full lg:h-[90%] h-[400px] gap-y-8'>
            {
                count > 45 ?

                    <ReactApexChart options={{

                        chart: {

                            height: '100%',

                            fontFamily: "nunito",
                            zoom: {
                                enabled: false
                            },
                            type: "area"
                        },
                        dataLabels: {
                            enabled: false,
                            background: {
                                borderRadius: 999,
                            },
                            style: {
                                colors: colors,
                                fontSize: "0px"
                            }
                        },
                        legend: {
                            show: true,
                            horizontalAlign: 'left',
                            position: 'top',
                            labels: {
                                useSeriesColors: false,
                                colors: "#4D4D4D",
                            },
                            itemMargin: {
                                horizontal: 30
                            },
                            fontFamily: 'nunito',
                            fontWeight: 600,
                            fontSize: '16px',
                            customLegendItems: legend,
                            markers: {
                                width: 0,
                                height: 0
                            }


                        },

                        markers: {

                            discrete: [
                                {
                                    seriesIndex: marker.serie,
                                    size: 15,
                                    dataPointIndex: marker.postion + 1,
                                    fillColor: props.type === 'libératoire' ? "#219CFF" : props.type === 'classique' ? "#8097EC" : marker.serie === 0 ? '#219CFF' : '#8097EC',
                                    strokeColor: '#fff',
                                    shape: "circle",
                                }
                            ],
                            size: 8,
                            colors: ["transparent", "transparent", "transparent"],
                            strokeColors: strokeColors,
                            strokeWidth: 2,
                            hover: {
                                size: undefined,
                                sizeOffset: 1
                            }
                        },
                        stroke: {
                            curve: ["smooth", "stepline", "smooth"],
                            colors: colors,
                            dashArray: [0, 6, 0],
                            width: [3, 2, 3]

                        },
                        yaxis: {
                            title: {
                                text: "Rémunération horaire NET d'impôts",
                                style: {
                                    color: "#4D4D4D",
                                    fontFamily: "nunito",
                                    fontSize: "14px",
                                    fontWeight: 600
                                },
                                offsetX: -11,
                            },
                            min: yaxisMin,
                            max: yaxisMax,
                            tickAmount: 9,
                            labels: {
                                show: true,
                                formatter: function (value:any) {
                                    return value.toFixed(2) + '€'; // Formatage avec des séparateurs de milliers
                                },
                                style: {
                                    colors: "#808080",
                                    fontSize: '12px',
                                    fontFamily: 'nunito',
                                    fontWeight: 400,
                                },
                            },
                        },
                        grid: {
                            position: 'back',
                            borderColor: 'rgba(0,0,0,0.22)',
                            strokeDashArray: 5,
                            xaxis: {
                                lines: {
                                    show: true,
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: true,
                                }
                            },
                        },
                        xaxis: {
                            title: {
                                text: "Nombre d’heures de cours par semaine",
                                style: {
                                    color: "#4D4D4D",
                                    fontFamily: "nunito",
                                    fontSize: "14px",
                                    fontWeight: 600
                                },
                                offsetY: 11,

                            },
                            type: 'numeric',
                            // min: 1,
                            max: 45,
                            // range: 0,
                            tickAmount: 9,
                            // decimalsInFloat: 1,
                            sorted: true,
                            tooltip: {
                                enabled: false
                            },
                            labels: {
                                formatter: function (value:any) {
                                    return parseInt(value).toFixed(0) + 'h'; // Formatage avec des séparateurs de milliers
                                },
                                show: true,
                                style: {
                                    colors: "#808080",
                                    fontSize: '12px',
                                    fontFamily: 'nunito',
                                    fontWeight: 400,
                                },
                            },
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: true,
                                color: 'rgba(0,0,0,0.33)',
                                strokeWidth: 1,
                            },
                        },
                        tooltip: {
                            x: {
                                format: 'dd/MM/yy HH:mm'
                            },
                        },
                        fill: {
                            colors: colors,
                            opacity: [1, 0],
                            type: ['gradient', 'color', "gradient"],
                            gradient: {
                                type: "vertical",
                                stops: [0, 100],
                                inverseColors: false,
                                gradientToColors: ["rgba(146,211,255,0.4)", "rgba(233,238,251,0)"]

                            }
                        },
                        responsive: [{
                            breakpoint: 768,
                            options: {
                                legend: {

                                    show: true,
                                    position: 'top',
                                    labels: {
                                        useSeriesColors: false,
                                        colors: "#4D4D4D",
                                    },
                                    itemMargin: {
                                        horizontal: 30
                                    },
                                    fontFamily: 'nunito',
                                    fontWeight: 600,
                                    fontSize: '11px',

                                    customLegendItems: legend,
                                    markers: {
                                        width: 0,
                                        height: 0
                                    }


                                },
                                xaxis: {
                                    title: {
                                        text: "Nombre d’heures de cours par semaine",
                                        style: {
                                            color: "#4D4D4D",
                                            fontFamily: "nunito",
                                            fontSize: "10px",
                                            fontWeight: 600
                                        },
                                        offsetY: 2,

                                    },
                                    type: 'numeric',
                                    // min: 1,
                                    max: 45,
                                    // range: 0,
                                    tickAmount: 9,
                                    // decimalsInFloat: 1,
                                    sorted: true,
                                    tooltip: {
                                        enabled: false
                                    },
                                    labels: {
                                        formatter: function (value: string) {
                                            return parseInt(value).toFixed(0) + 'h'; // Formatage avec des séparateurs de milliers
                                        },
                                        show: true,
                                        style: {
                                            colors: "#808080",
                                            fontSize: '10px',
                                            fontFamily: 'nunito',
                                            fontWeight: 400,
                                        },
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    axisBorder: {
                                        show: true,
                                        color: 'rgba(0,0,0,0.33)',
                                        strokeWidth: 1,
                                    },
                                },
                                // yaxis: {
                                //     title: {
                                //         text: "Rémunération horaire NET d'impôts",
                                //         style: {
                                //             color: "#4D4D4D",
                                //             fontFamily: "nunito",
                                //             fontSize: "10px",
                                //             fontWeight: 600
                                //         },
                                //     },
                                //     min: yaxisMin,
                                //     max: yaxisMax,
                                //     tickAmount: 9,
                                //     labels: {
                                //         show: true,
                                //         formatter: function (value: number) {
                                //             return value.toFixed(0) + '€'; // Formatage avec des séparateurs de milliers
                                //         },
                                //         style: {
                                //             colors: "#808080",
                                //             fontSize: '10px',
                                //             fontFamily: 'nunito',
                                //             fontWeight: 400,
                                //         },
                                //     },
                                // },
                            },
                        }]
                    }} series={series} type='area' height="100%" />
                    : <Skeleton className='lg:rounded-[30px] rounded-[17px]' variant="rectangular" width="100%"  height="100%" />

            }
        </div>
    );
};

export default Chart;