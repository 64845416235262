import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import { validatePhoneNumber } from '../../../../Validation/Phone';
import Functions from '../../../../Helpers/Functions';

const First = (props: any) => {
    const [gender, setGender] = useState(0)
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [error, setError] = useState({
        phone: false,
        country: false
    })
    const validate = ()=>{
        let error_phone = true
        let error_country = true
        if (validatePhoneNumber(Functions.formatPhoneNumber(phone)) !== '') {
            error_phone = true
        }else{
            error_phone = false
        }
        if (country === "") {
            error_country = true
        }else{
            error_country = false
        }
        setError({
            phone: error_phone,
            country: error_country
        })
        if (!error_phone && !error_country) {
            return true
        }
        return false

    }
    useEffect(() => {
        props.callBack({
            gender: gender,
            phone: Functions.formatPhoneNumber(phone),
            country: country
        })
        if (gender >= 0 && validate()) {
            props.nextStep(2)
        }
    }, [phone, gender, country])
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(parseInt((event.target as HTMLInputElement).value));
    };
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex gap-4 items-end">
                <div className='w-full'>
                    {error.country ? <p className='text-third pb-1'>Veuillez renseigner un pays</p> : null}
                    <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                        <label htmlFor="" className='block text-base' >Pays de naissance</label>
                        <input type="text" placeholder='France' onBlur={(e) => setCountry(e.target.value)} className='w-full' />
                    </div>
                </div>
                <div className='w-full'>
                    {error.phone ? <p className='text-third pb-1'>Veuillez renseigner un numéro de téléphone</p> : null}
                    <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                        <label htmlFor="" className='block text-base' >Numéro de téléphone </label>
                        <input type="text" placeholder='0X XX XX XX' onBlur={(e) => setPhone(e.target.value)} className='w-full' />
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-x-10">
                <label className='text-xl font-regular text-normal' htmlFor="">Genre :</label>
                <RadioGroup row
                    value={gender}
                    name="customized-radios"
                    onChange={handleChange}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Femme" />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Homme" />
                    {/* <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="2" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non précisé" /> */}
                </RadioGroup>
            </div>


        </div>
    );
};

export default First;