import React, { useEffect, useState } from "react";
// import imageFond from "../../../Assets/Images/Group (2).png";
import StepperStore from "../../../LocalForage/StepperStore";
import { useNavigate } from "react-router-dom";


const Last = (props:any) => {
  const navigate = useNavigate();
const date = Date.now()
  const handleP = () => {
    navigate('/user/timer', {
      state: date 
    })
  }
  
  const handleNext = () => {
    handleP()
    StepperStore.clear();
  };
  const [password, setPassword] = useState();

  const getPassword = async () => {
    const value: any = await StepperStore.getItem("setPassword");
    if (value !== null) {
      setPassword(value.password);
    }
  };

  useEffect(() => {
    getPassword();
  }, []);

  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative md:pt-20">
        <h5 className="lg:text-3xl  md:text-2xl text-lg text-first font-regular">
          Félicitations !
        </h5>
      <div className="flex justify-between /w-4/5 /mx-auto">
        <div className=" /md:w-3/4  lg:text-xl xl:text-2xl md:text-lg text-[4d4d4d] text-sm">
          <p className="">
            
            Votre demande de prestation a bien été créée et les prestateurs inscrits
            sur Déclarons viennent d'être notifiés. Nous vous avons créé un mot de
            passe temporaire :
            <strong className="text-xl text-first font-regular">
              {" "}
              {password}
            </strong>
          </p>
          <br />
          <p>
            Pensez à le modifier via le mail de réinitialisation de mot de passe
            que nous venons de vous envoyer 😉
            </p>
        </div>
      </div>
      <div className="md:w-1/2 cursor-pointer mx-auto w-3/5  lg:text-base  md:translate-y-8 translate-y-44  bg-first text-center rounded-lg py-2 uppercase text-sm">
        <div
          onClick={handleNext}
          className=" text-white mx-auto cursor-pointer font-bold"
        >
          Menu principal
        </div>
      </div>
    </div>
  );
};

export default Last;
