import React from 'react';
import eytanimage from "../../../../Assets/Images/eytan.png"
import { Button } from '@mui/material';
const First = (props:any) => {
    return (
        <div>
            <div className='bg-white rounded-[20px] p-4'>
                <div className="flex items-center gap-x-4">
                    <img src={eytanimage} alt="" />
                    <p className='text-lg font-bold text-normal lg:text-[22px]'>Eytan</p>
                </div>
                <div className='font-regular text-normal lg:text-lg lg:leading-[30.01px] py-4'>
                    <p>Bienvenue dans l’espace de création de votre procuration pour obtenir votre déclaration SAP.
                        <br /> En nous délivrant celle-ci, vous nous permettez de faire toutes les démarches administrative afférentes à votre place. </p>
                </div>
                
            </div>
            <Button onClick={()=> props.callBack(1)} className='text-white text-xl rounded-full px-10 py-3 mx-auto block mt-14' style={{ background: 'linear-gradient(180deg, #0B2775 0%, #219CFF 100%)' }}>C’EST PARTI</Button>
        </div>
    );
};

export default First;