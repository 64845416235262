import { Button } from '@mui/material';
import React from 'react';
import NovaLoader from '../../../../../../Components/utils/NovaLoader';

const Third = (props: any) => {
    return (
        <div className='flex flex-col w-2/3 py-8 gap-y-14'>
            <h3 className='text-2xl font-bold text-first'>Vérification de la déclaration :</h3>
            <div className='flex items-center justify-between ml-10 w-max gap-x-28'>
                <NovaLoader />
                <div className='text-lg font-regular text-first'>
                    Authentification validée ! <span>✅</span>
                </div>
            </div>
            <div className='py-14'>
                <Button onClick={() => props.nextScreen(3)} className='w-full px-8 font-bold text-white rounded-lg bg-first'>SUIVANT </Button>
            </div>
        </div>
    );
};

export default Third;