import { Button } from '@mui/material';
import React from 'react';

const Cae = (props:any) => {
    return (
        <div>
        <h2 className='text-base font-bold text-first lg:text-2xl'>CAE (condition d’activité exclusive)</h2>
        <div className="flex flex-col pt-4 gap-y-2">
            <p className='text-sm font-regular text-first lg:text-lg lg:leading-[24.55px]'>
            et engagez vous à respecter la CAE. En tant que professeur particulier <span className="font-regular">vous devez respecter cette condition d’activité exclusive</span>, vous n’êtes pas éligible à la dispense.  
            </p>
        </div>
        <div className="absolute bottom-0 left-0 flex justify-between w-full pb-4 md:px-8">
            
        <Button onClick={() => props.getStep(4)} className='px-8 font-bold uppercase rounded-full text-[#B7B7B7] lg:text-xl'>PRÉCEDENT</Button>
            <Button onClick={()=> props.getStep(6)} className='px-8 font-bold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
        </div>
    </div>
    );
};

export default Cae;