import React, { useEffect, useState } from 'react';
import First from './First';
import Fith from './Fith';
import Fourth from './Fourth';
import NoApe from './NoApe';
import Second from './Second';
import Third from './Third';
import Teachr from '../../../../../../Objects/models/Teachr';
import TeachrRepository from '../../../../../../Repositories/TeachrRepository';
import NoOcr from './NoOcr';
import { log } from 'console';

const Index = (props: any) => {
    const [isCodeApe, setIsCodeApe] = useState(false)
    const [screen, setScreen] = useState(0)
    const [teachr, setTeachr] = useState<Teachr>()
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            setTeachr(response)
        })
    }, [screen])
    useEffect(() => {
        if (props.isCode) {
            setIsCodeApe(props.isCode)
        }
    }, [])
    const nextScreen = (value: number) => {
        setScreen(value)
    }
    const getIsCodeAe = (value: boolean) => {
        setIsCodeApe(value)
    }
    const renderSreen = () => {
        if (teachr) {
            if (teachr.proofIsAE) {
                if (teachr.proofSignSap) {
                    props.nextStep(5)
                    return <NoOcr />
                }
                props.nextStep(3)
                return <Fourth nextScreen={nextScreen} />
            } else {
                switch (screen) {
                    case 0:
                        props.nextStep(2)
                        if (isCodeApe) {
                            return <First nextScreen={nextScreen} />
                        }
                        return <NoApe getIsCodeAe={getIsCodeAe} />
                    case 1:
                        props.nextStep(2)
                        return <Second nextScreen={nextScreen} />
                    case 2:
                        props.nextStep(3)
                        return <Third nextScreen={nextScreen} />
                    case 3:
                        props.nextStep(3)
                        return <Fourth nextScreen={nextScreen} />
                    case 4:
                        props.nextStep(4)
                        // return <Fith nextScreen={nextScreen} />
                        return <NoOcr />
                    default:
                        props.nextStep(2)
                        if (isCodeApe) {
                            return <First />
                        }
                        return <NoApe getIsCodeAe={getIsCodeAe} />
                }
            }
        }
        return (<div></div>)

    }
    return (
        <div>
            {renderSreen()}
        </div>
    );
};

export default Index;