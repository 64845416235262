
import * as React from 'react';
// import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import clipSettings from '../../../../../Assets/Icons/clip-settings.svg'
import msg from '../../../../../Assets/Images/msg.svg'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RadioButton from '../../../../../Components/Utilitis/RadioButton';
import Teachr_Repository from '../../../../../Repositories/Teachr_Repository';
import TeachrValidateClientAici from '../../../../../Objects/models/TeachrValidateClientAici';

export default function PopUpVerification(props: any) {
    const { client, teachrId, handleLoad } = props
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleValidateClient = () => {
        const teachrValidateClient = new TeachrValidateClientAici(true)
        Teachr_Repository.teachValidateClient(teachrId?.id, client?.id, teachrValidateClient).then((res) => {
            handleLoad()
            handleClose()
        })
    }


    return (
        <React.Fragment>
            <div className='mt-1' onClick={handleClickOpen}>
                <RadioButton />
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <div className='flex w-full  gap-x-10  relative text-base font-bold uppercase text-first'>
                        <div className="pt-1 pl-4 gap-x-3 flex">
                            <div>
                                <p className="text-left lg:text-xl md:text-base text-sm">VÉRIFICATION</p>
                            </div>
                            <div>
                                <img onClick={() => handleClose()} className='cursor-pointer w-7' src={clipSettings} alt="" />
                            </div>
                        </div>
                        {/* <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div> */}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className=" flex text-left  gap-x-6 ">
                            <div className="w-1/2 px-4 pt-6 ">
                                <p className='text-lg text-normal '>
                                    Pour pouvoir déclarer un cours avec <strong className='font-regular'>“nom de l’élève”</strong>, vous devez être certain que votre client a bien confirmé son compte URSSAF.
                                    <br />
                                    <br />
                                    Sans la confirmation de son compte, il vous sera <strong>impossible de déclarer un cours</strong>.
                                </p>
                            </div>
                            <div className='w-1/2'>
                                <img src={msg} alt="" className='w-full' />
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full px-4 justify-between //gap-x-8 py-3 //md:pt-8'>
                        <Button onClick={() => handleValidateClient()} className='block bg-first font-bold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >MON CLIENT A CONFIRMÉ SON COMPTE</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}