import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import picture from "../../../../../Assets/Images/Validation_1.svg"
import StepperStore from "../../../../../LocalForage/StepperStore";
import { auth } from "../../../../../Services/FirebaseService";
import React from "react";
// import Course from "../Course";

const StepEnd = (props: any) => {
    // const {handleBack} = props
    const [user] = useAuthState(auth)
    const [type, setType] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const value: any = await StepperStore.getItem("type" + user?.uid);
            if (value !== null) {
                setType(value.cours);
            }
        })();
    }, []);

    const handleNext = async () => {
        // props.nextStep()
        navigate("/declarons/dashboard/prestations")
        await StepperStore.clear()
        // window.location.reload()
    }

    return (
        <div className='overflow-y-auto list-teacher relative bg-transparent md:py-8 py-4 xl:px-14 px-4 md:h-[100%] h-[100%] rounded-[11px]'>
            <div className="mt-10">
                <h1 className="lg:text-2xl xl:pl-8 md:text-xl text-lg text-first font-bold ">Félicitations, déclaration de prestation{type === "regulier" ? ' régulière' : ' ponctuelle'} validée ! 🥳</h1>
            </div>
            <div className="flex mt-6  //space-x-4 ">
                <div className=' hidden  md:block w-1/2 '>
                    <img src={picture} alt="" />
                </div>
                <div className=" md:w-1/2  py-10  font-regular  //xl:px-8 lg:text-xl md:text-lg text-base">
                    <div className="pr-6 ">
                        <p>
                        Votre demande de déclaration vient d’être validée !
                        </p>
                    </div>
                    <div className="md:hidden   w-full flex justify-around space-x-4 pb-4 translate-y-6">
                        {/* <div className="flex justify-center  w-full mt-20 mb-4 "> */}
                            <div
                                onClick={() => {
                                    handleNext();
                                }}
                                className="md:px-4 md:py-2 py-2 px-3  bg-white w-full text-center font-bold hover:font-bold text-xs border-first text-first uppercase rounded-xl active:bg-first active:text-white  "
                            >
                                Tableau de bord
                            </div>
                        {/* </div> */}
                    </div>

                    <div className=" hidden w-4/5 mx-auto  mt-28 md:flex justify-between">
                            <div
                                onClick={() => {
                                    handleNext()
                                }}
                                className="py-2 font-bold text-white w-full uppercase rounded-lg text-center text-sm px-16 bg-first"
                            >
                                Tableau de bord
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepEnd;