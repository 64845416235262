import { Button } from '@mui/material';
import React from 'react';
import monaClassic from "../../Assets/Images/mona-classic.png"
import monaPremier from "../../Assets/Images/mona-premier.png"
import monaPlat from "../../Assets/Images/mona-plat.png"
const Offers = () => {
    return (
        <section className='py-8 font-sans'>
            <div className='mx-auto lg:w-[90%] px-4 md:px-8'>
                <h2 className='font-regular text-[#424242] lg:text-[26px] text-lg font-sans'>NOS OFFRES :</h2>
                <div className="flex flex-col justify-around py-4 lg:py-8 gap-y-8 lg:flex-row">
                    <div className='border-[2.5px] border-[#DCEA82] shadow-monabanq-offer-left lg:rounded-[5px] rounded-[21px] p-8 flex flex-col lg:gap-y-6 gap-y-4 lg:w-[554px]'>
                        <h3 className='font-regular text-[#424242] lg:text-[26px] text-center text-[16px] font-sans'>COMPTE COURANT :</h3>
                        <h4 className='font-regular text-[#424242] lg:text-[18px] text-center text-sm font-sans'>JUSQU’A <span className='lg:text-[22px] text-[#BAD606]'>120€</span> OFFERT POUR L’OUVERTURE D’UN <br />PREMIER COMPTE (1) : </h4>
                        <div className='flex items-center justify-between'>
                            <div>
                                <p className='lg:text-[26px] text-xs font-regular text-[#424242] font-sans'>Pratiq+</p>
                                <p className='lg:text-[16px] text-[11px] font-regular text-[#424242] font-sans'>3€/mois</p>
                            </div>
                            <div>
                                <div>
                                    <svg width="41" height="1" viewBox="0 0 41 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="0.5" x2="40.5" y2="0.5" stroke="black" stroke-linecap="round" />
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <p className='lg:text-[26px] text-xs font-regular text-[#424242] font-sans'>Uniq</p>
                                <p className='lg:text-[16px] text-[11px] font-regular text-[#424242] font-sans'>6€/mois</p>
                            </div>
                            <div>
                                <div>
                                    <svg width="41" height="1" viewBox="0 0 41 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="0.5" x2="40.5" y2="0.5" stroke="black" stroke-linecap="round" />
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <p className='lg:text-[26px] text-xs font-regular text-[#424242] font-sans'>Uniq+</p>
                                <p className='lg:text-[16px] text-[11px] font-regular text-[#424242] font-sans'>9€/mois</p>
                            </div>
                        </div>
                        <ul className='list-disc list-inside'>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'> Une carte Visa Classic incluse.</li>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'>Un découvert autorisé(2) garanti à l’ouverture du compte.</li>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'>Changez de compte courant quand vous voulez et sans frais...</li>
                        </ul>
                        <a target="_blank" href='https://clk.tradedoubler.com/click?p=200547&a=3304846&g=23407414' className='font-sans bg-[#BAD606] text-white rounded-full w-2/3 py-2 shadow-dashboard-setup-input mx-auto  block underline font-regular lg:text-[22px] text-lg text-center'>CHOISIR</a>
                    </div>
                    <div className='lg:w-[554px] border-[2.5px] border-[rgba(77,77,77,0.51)] shadow-monabanq-offer-right lg:rounded-[5px] rounded-[21px] p-8 flex flex-col lg:gap-y-6 gap-y-2'>
                        <h3 className='font-regular text-[#424242] lg:text-[26px] text-[16px] text-center font-sans'>AUTO ENTREPRENEUR (2) : </h3>
                        <div className="flex flex-col lg:gap-y-4 gap-y-2">
                            <div className='flex items-center justify-between'>
                                <div>
                                    <p className="lg:text-[26px] text-xs font-regular text-[#424242]  text-center lg:leading-[29.9px] leading-[13.5px] font-sans"><span className='lg:text-lg text-[10px]'>Visa <br /></span> Classic </p>
                                </div>
                                <div className='hidden lg:block'>
                                    <svg width="41" height="1" viewBox="0 0 41 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="0.5" x2="40.5" y2="0.5" stroke="black" stroke-linecap="round" />
                                    </svg>
                                </div>
                                <div className=''>
                                    <p className='lg:text-[26px] text-xs font-regular text-[#424242]  text-center lg:leading-[29.9px] leading-[13.5px] font-sans'>  <span className='lg:text-lg text-[10px]'>Visa<br /></span>Premier  </p>
                                </div>
                                <div className='hidden lg:block'>
                                    <svg width="41" height="1" viewBox="0 0 41 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="0.5" x2="40.5" y2="0.5" stroke="black" stroke-linecap="round" />
                                    </svg>
                                </div>
                                <div className=''>
                                    <p className='lg:text-[26px] text-xs font-regular text-[#424242]  text-center lg:leading-[29.9px] leading-[13.5px] font-sans'><span className='lg:text-lg text-[10px]'>Visa<br /></span>Platinium</p>
                                </div>
                            </div>
                            <div className='flex justify-between'>
                                <div className='flex flex-col items-center'>
                                    <img src={monaClassic} alt="" />
                                    <p className='text-[#424242] lg:text-[16px] text-[12px] font-regular font-sans'>9€ TTC/mois</p>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <img src={monaPremier} alt="" />
                                    <p className='text-[#424242] lg:text-[16px] text-[12px] font-regular font-sans'>12€/TTC/mois</p>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <img src={monaPlat} alt="" />
                                    <p className='text-[#424242] lg:text-[16px] text-[12px] font-regular font-sans'>18€/TTC/mois </p>
                                </div>
                            </div>
                        </div>
                        <ul className='list-disc list-inside'>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'> Dépôt de chèques et d’espèces</li>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'>Protection juridique</li>
                            <li className='lg:text-[16px] text-[11px] text-black font-sans'>Terminal de paiement Smile&Pay ...</li>
                        </ul>
                        <a href='https://www.monabanq.com/fr/produits/compte-auto-entrepreneur.html?SITEPROV=INTERNET&REFPRINCIPALE=60600001&CODEPROMO=3304846&AFFID=3304846&CAMTYPE=AFFILIATION&SUPPORT=DISPLAY&EVENT=301178&idaffilie=&tduid=5b44b487ba7d4af08316e895932a7970&affId=3304846' target="_blank" className='bg-[#424242] text-white rounded-full w-2/3 py-2 shadow-dashboard-setup-input mx-auto  block underline font-regular lg:text-[22px] text-lg text-center font-sans'>CHOISIR</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Offers;