import React from "react";
import moment from 'moment';
import Functions from '../../../../../Helpers/Functions';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


const ComponentChildStudent = (props: any) => {
    const child = props
  
    return (
        <>
            <tr className="">
            <th scope="row" className="md:flex hidden items-center  //pl-6 py-3 text-gray-900 whitespace-nowrap ">
                <div className="border-2 w-[32px] h-[32px] rounded-full text-white bg-first text-center pt-1 uppercase">
                {child.child.studentName === "" || child.child.studentName === undefined ? child.child.mail[0] : child.child.studentName[0]}
                </div>
                <div className="pl-3">
                    <div className="text-base font-regular">{child.child.studentName === "" || child.child.studentName === undefined ? child.child.mail : child.child.studentName}</div>
                </div>
            </th>
            <td className="px-2  py-4 md:hidden">
                <div className="//pl-3">
                    <div className="text-base font-regular">{child.child.studentName === "" || child.child.studentName === undefined ? child.child.mail : child.child.studentName}</div>
                </div>
            </td>
            <td className="px-2  py-4">
                 {child.child.level === "" || child.child.level === undefined || child.child.level === null ? "classe" : Functions.renderClass(child.child.level)}
            </td>
            <td className="px-2  py-4">
                {child.child?.createdAt?.date !== null ? moment(child.child?.createdAt?.date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
            </td>
            <td className="pr-3 w-[35%] py-1 invisible md:visible ">
                <div className='flex //border //justify-between w-[100%]'>
                    <div className='border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'>
                        <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1 '>Envoyé</p>
                        <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p>
                    </div>
                    <div className={'border-b-4 border-first w-[30%] -translate-y-3' }></div>
                    <div className={!child.child.status ? 'border-2 border-gray text-first font-regular rounded-2xl w-8 h-8 text-center pt-1' : 'border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'}>
                       
                        {child.child.status ?
                             <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1'>Lu</p> :
                             <p className='text-xs text-gray font-regular -translate-y-6 w-14 -translate-x-4 '> En cours</p>
                        }
                        {child.child.status ?
                            <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p> :
                            <p className='-translate-y-4 text-gray'>2</p>
                        }
                    </div>
                    <div className={child.child.status ? 'border-b-4 border-first w-[30%] -translate-y-3' : 'border-b-4 border-gray w-[30%] -translate-y-3'}></div>
                    <div className={!child.child.status ? 'border-2 border-gray text-first font-regular rounded-2xl w-8 h-8 text-center pt-1' : 'border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'}>
                        
                        {child.child.status ?
                             <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1 '>Validé</p> :
                             <p className='text-xs text-gray font-regular -translate-y-6 -translate-x-1 '>Validé</p>
                        }
                        {child.child.status ?
                            <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p> :
                            <p className='-translate-y-4 text-gray'>3</p>
                        }
                    </div>
                </div>
            </td>
        </tr>
        </>
    );
};

export default ComponentChildStudent;