import { useState } from "react";
import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../Helpers/Functions";
import moment from "moment";
import errorIcon from "../../../../Assets/Images/error.png";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimePicker } from "antd";
import Index from "./Index";
const format = "HH:mm";

const ThirdType = (props) => {

  const { currentComponent, handleNext, handleBack } = props;
  const end = moment().add(3, "hours");
  const start = moment;

  const [user] = useAuthState(auth);
  const [duration, setDuration] = useState(1.5);
  const [endTime, setEndTime] = useState(end);
 
  const handleChange = (value) => {
    if (moment(value) < endTime) {
      setEndTime(end);
    } else {
      setEndTime(value);
    }
  };
  const validateHour = () => {
    if (moment(endTime).diff(start, "minutes") < duration * 60) {
      return {
        isValid: false,
        message: "Merci de laisser une heure de fin de cours plus tardive.",
        icon: <img src={errorIcon} alt="" className="w-4 mt-.5 h-4" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  };
  const handleDurationhange = (event, newValue) => {
    setDuration(newValue);
    setEndTime(moment().add(newValue + 1.5, "hours"));
  };
  const handleSubmit = (event) => {
    if (validateHour()) {
      StepperStore.setItem(
        user ? "secondPrestation" + user.uid : "secondPrestation",
        {
          type: "Urgence",
          data: {
            duration: duration,
            endTime: endTime.toDate().valueOf(),
          },
        },

        function (err) {
          console.warn(err);
        }
      );
      props.handleNext();
    }

    event.preventDefault();
  };

  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h5 className="text-lg font-regular text-first lg:text-3xl md:text-2xl"> Prestation dans l’urgence</h5>
      <div className="">
        <div className="mt-6 /md:w-2/3 w-full ">
          <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[2px] px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="lg:text-lg md:text-base text-sm font-medium"
            >
              {" "}
              Nombre d'heure
            </label>
            <div className=" h-9 md:-translate-y-1 md:mt-0 -mt-2">
              <Functions.PrettoSlider
                defaultValue={1}
                step={1}
                min={1}
                max={4}
                marks
                value={duration}
                onChange={handleDurationhange}
              />
            </div>
          </div>
          <span className="font-regular bg-transparent text-first">
            <input
              className="bg-transparent"
              type="text"
              value={Functions.renderDuration(duration * 2)}
              onChange={(e) => setDuration(e.target.value)}
            />
          </span>
        </div>
        <div className="grid w-full gap-8 mx-auto mt-4 -space-y-3 md:grid-cols-2 md:space-y-0">
          <div>
            <div
              className={
                "shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md relative flex pl-12 px-4 py-4 focus:outline-none focus:ring-0 "
              }
            >
              <label className="w-full -ml-6 text-base font-medium lg:text-xl">
                Aujourd'hui
              </label>
              <div className=" ">
                <Index
                  currentComponent={currentComponent}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              </div>
            </div>
          </div>
          <div className="translate-y-4 md:translate-y-0">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans  /py-[1px] -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Fin de disponibilité :
              </label>
              <div className="-ml-3">
                <div
                  className={
                    "flex items-center md:hidden justify-between w-full ml-1 pt-2 rounded-lg "
                  }
                >
                  <TimePicker
                    value={endTime}
                    onChange={(newValue) => {
                      handleChange(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="small"
                    suffixIcon=""
                  />
                </div>
                <div
                  className={
                    "md:flex items-center hidden justify-between w-full rounded-lg "
                  }
                >
                  <TimePicker
                    value={endTime}
                    onChange={(newValue) => {
                      handleChange(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon=""
                  />
                </div>
              </div>
            </div>
            {/* <div className="bg-input-color block border-gray-300 text-gray-900 text-sm rounded-xl pl-4 py-2  md:p-2.5 focus:outline-none focus:ring-0 focus:border-gray-200 focus:border-1">
              <label className="block text-base font-medium md:mb-2 lg:text-xl">
                Fin de disponibilité :
              </label>
              <div className="lg:text-lg md:text-base text-sm -mt-2">
              <TimePicker
                     value={endTime}
                     onChange={(newValue) => {
                      handleChange(newValue)
                     }}
                    minuteStep={5}
                    bordered={false}
                    format={format} 
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon={
                      <img src={hours} alt="calendar icon" className="w-8" />
                    }
                  />
              </div>
            </div> */}
            {/* <div className="flex w-full mx-auto space-x-2 translate-y-4 ">
              {error.hour === "null" || error.hour === ""
                ? " "
                : validateHour().icon}
              <p className="text-[#FF724F] h-6 w-full ">
                {error.hour === "null" || error.hour === ""
                  ? null
                  : validateHour().message}
              </p>
            </div> */}
            <div></div>
          </div>
        </div>
        <div className="md:hidden  flex justify-around mx-auto space-x-4 translate-y-28 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
            <div
              onClick={handleBack}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => props.handleBack()}
              className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(z) => handleSubmit(z)}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdType;
