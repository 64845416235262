import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";

const ComponentWeekChart = (props) => {
  const { durationByDay } = props
  const chartRef = useRef(null);

  useEffect(() => {
    const chartData = {
      labels: ["L", "M", "M", "J", "V", "S", "D",],
      datasets: [{
        label: '',
        data: Object.values(durationByDay),
        borderWidth: 0,
        backgroundColor: '#0B2775',
        borderColor: '#0B2775',
        borderRadius: 10,
      }]
    };

    const chartConfig = {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          y: {
            display: false
          },
          x: {
            display: true,
            // stacked: false, 
            // offset: true,
            grid: {
              offset: false
            }
          }
        },
        barPercentage: 0.3,
        responsive: true,
      }
    };
    const canvas = chartRef.current;
    canvas.style.width = '50px'; // Set custom width
    canvas.style.height = '120px'; // Set custom height

    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, chartConfig)


    return () => {
      myChart.destroy();
    };
  }, [durationByDay]);

  return (
    <div className=''>
      <canvas className="" ref={chartRef} />
    </div>
  );
};

export default ComponentWeekChart;