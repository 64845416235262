import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import aiciImage from '../../../Assets/Images/aici.png'
import Navbar from '../../../Components/Admin/Navbar';
import StepperStore from '../../../LocalForage/StepperStore';
import Teachr from '../../../Objects/models/Teachr';
import TeachrRepository from '../../../Repositories/TeachrRepository';
import { auth } from '../../../Services/FirebaseService';
import Layout from './Dashboard/AccueilDashboard/Layout';
import loader from '../../../Assets/Icons/loader-bleu.gif'
import { useDispatch } from 'react-redux';
import IndexLayoutCourse from './Dashboard/Index';

const Index = () => {
    const dispatch = useDispatch()
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [authorization, setAuthorization] = useState<number | undefined>()
    useEffect(() => {
        if (!loading) {
            if (user) {
                TeachrRepository.getCurrentTeachr().then((response) => {
                    setTeachr(response)
                })

            } else {
                navigate('/declarons/login')
            }
        }
    }, [user, loading])
    useEffect(() => {
        if (teachr) {
            if (teachr.isValidated) {
                if (teachr.isAE && teachr.RCS) {
                    TeachrRepository.get_client_by_teacher_favorite(teachr.id!).then((response) => {
                        if (response.clients.length > 0) {
                            setAuthorization(0)
                        } else {
                            setAuthorization(1)
                        }
                    })
                } else {
                    setAuthorization(2)
                }
            } else {
                setAuthorization(3)
            }
        }
    }, [teachr])
    const goToDashboard = () => {


        switch (authorization) {
            case 0:
                navigate('/declarons/dashboard')
                break;
            case 1:
                navigate('/declarons/dashboard/setup')
                break;
            case 2:
                dispatch({
                    type: 'SHOW_WARNING', payload: "Pour bénéficier du portail de déclaration des cours avec l'Avance Immédiate Crédit d'Impôt vous devez être auto-entrepreneur. Veuillez uploader votre certificat d'inscription au répertoire SIREN sur cette page puis revenir sur l'URL : https://app.teachr.fr/declarons/dashboard"
                })
                navigate('/declarons/profile')
                break;
            case 3:
                StepperStore.setItem("currentStep" + user?.uid, 4).then(() => navigate('/declarons/register'))

                break;

            default:
                navigate('/declarons/dashboard')
                break;
        }
    }
    const renderScreen = () => {
        if (authorization === 0) {
            return (
                <IndexLayoutCourse/>
            )
        } else if (authorization && authorization >= 1) {
            return (<div className='relative h-screen p-4 overflow-hidden bg-right-bottom bg-no-repeat bg-contain md:bg-[length:80%_50%] lg:bg-contain lg:p-14 bg-dashboard md:p-8'>
                <div >
                    <img className='w-1/4 lg:w-max' src={aiciImage} alt="" />
                </div>
                <div className='w-full h-full pt-10 lg:absolute'>
                    <div className='lg:w-1/2'>
                        <h1 className='text-xl font-bold lg:text-4xl text-first'>Avance immédiate crédit d’impôt : <br />votre tableau de bord</h1>
                        <div className="lg:w-5/6 ">
                            <p className='lg:text-2xl lg:leading-10 text-[#4D4D4D] font-regular my-8 text-base '>Ajoutez vos élèves, déclarez vos cours et accédez à toutes vos actions depuis votre tableau de bord. </p>
                            <Button onClick={() => goToDashboard()} className='w-full py-2 mt-10 text-base font-bold text-white rounded-full lg:text-2xl bg-first'>C'EST PARTI</Button>
                        </div>
                    </div>
                </div>

            </div>)
        }
        return (
            <div className='flex items-center justify-center h-screen'>
                <div className='w-1/3 lg:w-max'>
                    <img src={loader} alt="chargement" />
                    <p className='py-8 text-center text-first'>Chargement ...</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            {renderScreen()}
        </div>
    );
};

export default Index;