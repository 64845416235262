import React from 'react';
import illu1 from '../../../../Assets/Icons/why1.svg'
import illu2 from '../../../../Assets/Icons/why2.svg'
import illu3 from '../../../../Assets/Icons/why3.svg'
const Why = () => {
    return (
        <div className='px-4 mx-auto lg:w-[95%] md:px-8'>
            <h2 className='font-bold text-first lg:text-[34px] uppercase text-lg'>un dispositif MIS EN PLACE par l’état et RE-DISTRIBUÉ teach’r :</h2>
            <h3 className='font-regular text-first lg:text-3xl lg:leading-[40.92px] md:py-14 text-base py-8'>L’avance immédiate crédit d’impôt :</h3>
            <div className="flex flex-col lg:gap-y-14 gap-y-10">
                <div className='flex flex-col items-center gap-8 md:flex-row'>
                    <div>
                        <img className='w-3/5 mx-auto md:w-full' src={illu1} alt="" />
                    </div>
                    <div className='text-center md:text-left'>
                        <h4 className='font-bold lg:text-[26px] '>Le crédit d’impôt classique :</h4>
                        <p className='text-sm font-bold lg:text-lg'>Il s’applique globalement 1 fois par an au moment de la déclaration d’impôsition annuel, le parent peut alors bénéficier d’une remise de 50% sur le montant de ses cours particuliers</p>
                    </div>
                </div>
                <div className='flex flex-col items-center gap-8 md:flex-row'>
                    <div>
                        <img className='w-3/5 mx-auto md:w-full' src={illu2} alt="" />
                    </div>
                    <div className='text-center md:text-left'>
                        <h4 className='font-bold lg:text-[26px]'>Le crédit d’impôt instantané (juin 2022)</h4>
                        <p className='font-bold lg:text-lg'>En Juin 2022, l’État créer le crédit d’impôt instantané. Le parent paie alors immédiatement 50% de ses cours particuliers au moment du paiement. Plus besoin d’attendre l’année suivante.</p>
                    </div>
                </div>
                <div>
                    <h4 className='font-bold lg:text-3xl text-first'>Qu’est-ce que ça change pour vous professeur ? </h4>
                    <div className='flex flex-col items-center gap-8 pt-8 md:flex-row md:pt-0'>
                        <div>
                            <img className='w-3/5 mx-auto md:w-full' src={illu3} alt="" />
                        </div>
                        <div className='text-center md:text-left'>
                            <p className='font-bold lg:text-lg'>Concrétement vous allez pouvoir augmenter vos prix sans que cela n’impacte le parent.
                                En proposant un cours à 30€/h c’est seulement 15€ que le parent devra verser sur notre plateforme.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Why;