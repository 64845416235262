import { Box, LinearProgress } from '@mui/material';
import React from 'react';

const LinearStep = (props: any) => {
    return (
        <Box className='relative w-full'>
            <div className='hidden lg:block absolute w-[80px] rotate-90 top-[31px] -z-0 -left-[20px]'>
                <LinearProgress sx={{
                    'backgroundColor': "#D2D2D2",
                    '& .MuiLinearProgress-bar': {
                        'backgroundColor': props.complete ? '#0B2775' : '#D2D2D2'
                    }
                }} variant="determinate" value={props.value} />
            </div>
            <div className='block w-full lg:hidden'>
                <LinearProgress sx={{
                    'backgroundColor': "#D2D2D2",
                    '& .MuiLinearProgress-bar': {
                        'backgroundColor': props.complete ? '#0B2775' : '#D2D2D2'
                    }
                }} variant="determinate" value={props.value} />
            </div>

        </Box>
    );
};

export default LinearStep;