import { Button } from '@mui/material';
import React from 'react';

const NoApe = (props:any) => {
    return (
        <div className='flex flex-col md:w-3/5 gap-y-8'>
            <div className='flex flex-col pb-14 md:gap-y-8 gap-y-4'>
                <div>
                    <h3 className='py-4 text-lg font-bold md:pb-8 text-first lg:text-2xl'>Poursuivre avec un mauvais code APE :</h3>
                    <p className='font-regular text-normal lg:text-lg'>Vous pouvez choisir de continuer votre inscription avec le mauvais code APE, cependant vous risquez d’attendre 2 semaines de plus en cas de refus
                        <br />
                        Vous souhaitez poursuivre avec le mauvais code ? &nbsp;<a href="#" onClick={()=> props.getIsCodeAe(true)} className='font-regular text-radio'> Cliquez ici</a>
                    </p>
                </div>
                <div>
                    <h3 className='pb-4 text-lg font-bold text-first lg:text-2xl'>Changement de code APE :</h3>
                    <p className='font-regular text-normal lg:text-lg'> Vous pouvez aussi changer facilement votre code APE depuis le site officiel de l'URSSAF en choisissant "Formateur indépendant en Mathématiques ou en Français" &nbsp;<a href="https://www.autoentrepreneur.urssaf.fr/portail/accueil/gerer-mon-auto-entreprise.html" target='_blank' className='font-regular text-radio'> En cliquant ici.</a></p>
                </div>
            </div>
            <Button onClick={()=> props.getIsCodeAe(true)} className='px-8 text-white rounded-lg bg-first md:w-max'>C’EST BON Je l’ai</Button>
        </div>
    );
};

export default NoApe;