import React, { useEffect, useState } from 'react'
import StepperAe from '../../../../../Components/Dashboard/Account/Stepper'
import backgroundImage from '../../../../../Assets/Images/bg-ae.svg'
import Account from './Account'
import NovaIndex from './Nova/Index'
import Teachr from '../../../../../Objects/models/Teachr'
import TeachrRepository from '../../../../../Repositories/TeachrRepository'
import IsAeComponent from '../IsAe/Index'
const Index = () => {
    const [step, setStep] = useState(1)
    const [isCodeApe, setIsCodeApe] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            setTeachr(response)
        })
    }, [])

    const getIsCodeAe = (value: boolean) => {
        setIsCodeApe(value)
    }
    const nextStep = (value: number) => {
        setStep(value)
    }
    const RenderStepScreen = () => {
        if (teachr) {
            if (teachr.proofIsAE) {
                return <NovaIndex isCode={isCodeApe} nextStep={nextStep} />
                // if (teachr.proofSignSap) {
                //     setStep(2)
                // }
            }
        }
        switch (step) {
            case 1:
                return <Account getIsCodeAe={getIsCodeAe} nextStep={nextStep} />
            case 2:
                return <NovaIndex isCode={isCodeApe} nextStep={nextStep} />

            default:
                return <NovaIndex isCode={isCodeApe} nextStep={nextStep} />
        }
    }
    if (teachr) {
        if (teachr.isAE) {
            return (
                <IsAeComponent teachr={teachr}/>
            )
        }
        return (

            <div className='m-4 md:m-8 relative max-w-6xl md:p-8 p-4 md:bg-right-bottom bg-bottom bg-no-repeat rounded-lg shadow-account md:h-[581px] flex flex-col gap-y-4' style={{ backgroundImage: `url(${backgroundImage})` }}>
                <StepperAe step={step} />
                {RenderStepScreen()}
            </div>
        )
    }
    return (
        <div></div>
    )
};

export default Index;