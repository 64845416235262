import { Address } from "./Address";
import Image from "./Image";

export default class Teachr  {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

        address?: Address
        
        birthday: Date | string
        whereStudies:	string
        yearInStudies:	number
        description:	string
        siren?:	number
        iban?:	string
        subjectToTeach?:{
            id: string,
            level: number
        }[]
        otherSubjects?:	string
        otherSueperiorSubjects?:	string
        NNI?:	string
        secondFirstName?:	string
        bornPlace?:	string
        proofIsAE?:	any
        marriedName?:	string
        isBornFrance?:	boolean
        RCS?:	string
        firebaseAuthToken:	string
        firstName:	string
        lastName:	string
        phone:	string | null
        mail:	string | null
        profilePicture?:	any
        sex :	string
        firebaseMessagingToken:	string
        geoPoint?:	string
        geoDistance?:	number
        acceptMarketingEmail?: boolean
        isValidated?: boolean
        isAE?: boolean
        businessType?: string
        signSap?: boolean
        isShowInfos?: boolean
        proofSignSap?: any
        proofIdentity?: any
        
    readonly id?: number;

    readonly lastModificationDatetime?: Date;

    readonly creationDatetime?: Date;

    constructor(
        firebaseAuthToken: string,
        firstName: string,
        lastName: string,
        mail: string | null,
        phone: string | null,
        firebaseMessagingToken: string,
        sex : string,
        whereStudies: string,
        yearInStudies: number,
        description: string,
        // iban: string,
        birthday: Date | string,
    ) {
        this.firebaseAuthToken = firebaseAuthToken;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.mail = mail;
        this.firebaseMessagingToken = firebaseMessagingToken;
        this.whereStudies = whereStudies;
        this.yearInStudies = yearInStudies;
        this.description = description;
        this.sex = sex;
        this.birthday = birthday;
    }

}
