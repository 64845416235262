import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import ValidateNni from '../../../../Validation/Nni';

const Third = (props: any) => {
    const [isActivity, setIsActivity] = useState<number>()
    const [socialTax, setSocialTax] = useState<number>()
    const [nni, setNni] = useState('')
    const [error, setError] = useState({
        nni: true,
        isActivity: true,
        socialTax: true
    })
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActivity(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSocialTax = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSocialTax(parseInt((event.target as HTMLInputElement).value));
    };
    const validate = () => {
        let error_nni = true
        let error_social_tax = true
        let error_is_activity = true
        error_nni = !ValidateNni(nni).isValid
        if (isActivity !== undefined && isActivity >= 0) {
            error_is_activity = false
        } else {
            error_is_activity = true
        }
        if (socialTax !== undefined && socialTax >= 0) {
            error_social_tax = false
        } else {
            error_social_tax = true
        }
        setError({
            nni: error_nni,
            isActivity: error_is_activity,
            socialTax: error_social_tax
        })
        if (!error_social_tax && !error_is_activity && !error_nni) {
            return true
        }
        return false

    }
    useEffect(() => {
        console.log(error);

    }, [error])
    useEffect(() => {
        props.callBack({
            isActivity: isActivity,
            socialTax: socialTax,
            nni: nni
        })
        if (validate()) {
            props.nextStep(4)
        }
    }, [socialTax, isActivity, nni])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
                {error.isActivity ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Avez-vous déjà exercé une activité non salarié en France ?</label>
                <RadioGroup row
                    value={isActivity}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex  flex-col">
                {error.socialTax ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Pour quel mode de versement de vos cotisations sociales souhaitez-vous opter ? </label>
                <RadioGroup row
                    value={socialTax}
                    name="customized-radios"
                    onChange={handleChangeSocialTax}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Mensuel" />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Trismestriel" />
                </RadioGroup>
            </div>
            <div className="w-full">
                {error.nni ? <p className='text-third pb-1'>Veuillez entrer une valeur</p> : null}
                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                    <label htmlFor="" className='text-base block'>Quel est votre numéro de sécurité sociale : </label>
                    <input onBlur={(e) => setNni(e.target.value)} type="text" placeholder='Ex: 190097521412904' className='w-full' />
                </div>
            </div>
        </div>
    );
};

export default Third;