import { SubscriptionCourseProposal } from '../../../../../Objects/models/SubscriptionCourseProposal';
import { PackProposal } from '../../../../../Objects/models/PackProposal';
import CourseProposal from '../../../../../Objects/models/CourseProposal';
import RegularCandidacy from './Regular';
import PackCandidacy from './Pack';
import PunctualCandidacy from './Punctual';
const Pending = (state: { regular: SubscriptionCourseProposal[] | undefined, punctual: CourseProposal[] | undefined, pack: PackProposal[] | undefined}) => {

    return (
        <div>
            {
                state.regular && state.punctual && state.pack ?
                    <div className="flex flex-wrap justify-center gap-x-8 md:justify-start xl:gap-x-[1.3%]">
                         {state.punctual.map((proposal, key) =>
                            <PunctualCandidacy  {...proposal} />
                        )}
                        {state.regular.map((proposal, key) =>
                            <RegularCandidacy {...proposal} />
                        )}
                        {state.pack.map((proposal, key) =>
                            <PackCandidacy  {...proposal} />
                        )}
                       
                    </div>
                    : null
            }


        </div>
    );
};

export default Pending;