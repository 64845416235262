import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import StepperItem from './StepperItem';
import logoNova from '../../../Assets/Images/nova.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#D2D2D2',
        borderRadius: '999px',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: '999px',
        backgroundColor: '#0B2775',
    },
}));

const StepperAe = (props: {step: number}) => {
    const animateStepper = (step: number) => {
        if (step < 4) {
            return (
                step / 4 * 100
            )
        }
        return 100
    }
    return (
        <div className='relative'>
            <div className="flex justify-between">
                <div id='step-item' className='flex justify-end w-full gap-x-2'>
                    <h3 className={`text-[12px] text-first font-bold hidden md:block ${props.step >= 1 ? 'text-first' : 'text-input-gray'}`}>Création du statut</h3>
                    <div className="self-end">
                        <StepperItem current={props.step === 1 ? true : false} step={1} complete={props.step > 1 ? true : false} />
                    </div>
                </div>
                <div className='flex justify-end w-full gap-x-2'>
                    <h3 className={`text-[12px] text-first font-bold hidden md:block ${props.step >= 2 ? 'text-first' : 'text-input-gray'}`}>
                        <img className='w-[43px] h-[16px]' src={logoNova} alt=" " />
                    </h3>
                    <div className="self-end">
                        <StepperItem step={2} current={props.step === 2 ? true : false} complete={props.step > 2 ? true : false} /></div>
                </div>
                <div className='flex justify-end w-full gap-x-2'>
                    <h3 className={`text-[12px] text-first font-bold hidden md:block ${props.step >= 3 ? 'text-first' : 'text-input-gray'}`}>Mandat de facturation</h3>
                    <div className="self-end">
                        <StepperItem step={3} current={props.step === 3 ? true : false} complete={props.step > 3 ? true : false} /></div>
                </div>
                <div className='flex justify-end w-full gap-x-2'>
                    <h3 className={`text-[12px] text-first font-bold hidden md:block ${props.step >= 4 ? 'text-first' : 'text-input-gray'}`}>Terminé !</h3>
                    <div className="self-end">
                        <StepperItem step={4} current={props.step === 4 ? true : false} complete={props.step > 4 ? true : false} last /></div>
                </div>
            </div>
            <div className="absolute w-[99%] top-[34%] -z-10 left-1 " id='stepper-bar'>
                <BorderLinearProgress variant="determinate" value={animateStepper(props.step)} />
            </div>

        </div>
    );
};

export default StepperAe;