import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";

const ComponentMonthChart = (props) => {
    const { durationByMonth } = props
    const chartRef = useRef(null);

    useEffect(() => {
        const chartData = {
            labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jui", "Aôut", "Sept", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "Heure de cours",
                    data: Object.values(durationByMonth),
                    borderWidth: 0,
                    fill: true,
                    backgroundColor: "#0B2775",
                    borderColor: "#0B2775",
                    borderRadius: 10,
                },
            ],
        };

        const chartConfig = {
            type: "bar",
            data: chartData,
            options: {
                scales: {
                    y: {
                        display: false,
                    },
                },
                barPercentage: 0.3,
                responsive: true,
            },
        };

        const canvas = chartRef.current;
        canvas.style.width = '50px'; // Set custom width
        canvas.style.height = '120px'; // Set custom height

        const ctx = canvas.getContext('2d');
        const myChart = new Chart(ctx, chartConfig)

        return () => {
            myChart.destroy();
        };
    }, [durationByMonth]);

    return (
        <div className=''>
            <canvas className="" ref={chartRef} />
        </div>
    );
};

export default ComponentMonthChart;