
import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import userReducer from "./Reducers/userReducers";
import generalReducer from "./Reducers/generalReducers";
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import messageReducer from "./Reducers/MessageReducers";
import loaderReducer from "./Reducers/LoaderReducers";
import teachrReducer from "./Reducers/TeachrReducers";
import simulatorReducer from "./Reducers/SimulatorReducers";
import simulateurReducer from "./Reducers/SimulateurReducers";
import procurationReducers from "./Reducers/ProcurationReducers";
const createNoopStorage = () =>{
    return {
        getItem(_key: any) {
            return Promise.resolve(null);
        },
        setItem(_key: any, value: any) {
            return Promise.resolve(value);
        },
        removeItem(_key: any){
            return Promise.resolve();
        },
    };
};
const storage = typeof window !== 'undefined' ? createWebStorage("local") : createNoopStorage();
const rootPersistConfig = {
    key: "root_redux_persist",
    storage: storage,
    whitelist: ["user", "general", "teachr", "simulator", "simulateur", "procuration"],
};

const generalPersistConfig = {
    key: "general_redux_persist",
    storage: storage,
    whitelist: ["authorizations", "alreadyLaunched"],
};

const rootReducer = combineReducers({
    user: userReducer,
    general: persistReducer(generalPersistConfig, generalReducer),
    message: messageReducer,
    loader: loaderReducer,
    teachr: teachrReducer,
    simulator: simulatorReducer,
    procuration: procurationReducers,
    simulateur: simulateurReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = createStore(persistedReducer);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export type AppState = ReturnType<typeof rootReducer>;
