export function validatePhoneNumber(phone) {
  phone = phone.replace(/ +/g, "")
  const search = phone.match(/^[+]?[0-9]+$/)
  if (!search) {
    return "Téléphone invalide";
  }
  const character = phone.split("");
  let message = "";
  switch (character[0]) {
    case "+":
      if (character.length !== 12) {
        message = "Téléphone invalide";
      } else {
        if (character[2] === '3' && character[1] === "3") {
          if (character[3] === "7" || character[3] === "6") {
            message = "";
          }else{
            message = "Téléphone invalide";
          }
        }else{
          message = "Téléphone invalide"
        }
      }
      
      break;
    case "0":
      if (character.length !== 10) {
        message = "Téléphone invalide";
      } else {
        if (character[1] === "7" || character[1] === "6") {
          message = "";
        }else{
          message = "Téléphone invalide";
        }
       
      }

      break;
    case "7":
      if (character.length !== 9) {
        message = "Téléphone invalide";
      } else {
        message = "";
      }

      break;
    case "6":
      if (character.length !== 9) {
        message = "Téléphone invalide";
      } else {
        message = "";
      }
      break;

    default:
      message = "Téléphone invalide";
      break;
  }
  return message;
}
