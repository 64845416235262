import { Button,Skeleton } from '@mui/material';
import  { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Teachr from '../../../../Objects/models/Teachr';
import SchoolIcon from '@mui/icons-material/School';
import SimpleSelect from '../../../../Components/utils/SimpleSelect';
import SubjectsSelect from '../../../../Components/Dashboard/Account/SubjectSelect';
import { Subject } from '../../../../Objects/models/Subject';
import SubjectRepository from '../../../../Repositories/SubjectRepository';
import ValidateIban from '../../../../Validation/Iban';
import { auth, RefreshAuthentification, UpdateUserEmail } from '../../../../Services/FirebaseService';
import validateEmail from '../../../../Validation/Email';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserInfo } from 'firebase/auth';
const AccountInformation = (props: any) => {
    const [email, setEmail] = useState('')
    const [editModeEmail, setEditModeEmail] = useState(true)
    const [phone, setPhone] = useState('')
    const [editModePhone, setEditModePhone] = useState(true)
    const [iban, setIban] = useState('')
    const [editModeIban, setEditModeIban] = useState(true)
    const [teachr, setTeachr] = useState<Teachr>()
    const [school, setSchool] = useState<string>()
    const [editMode, setEditMode] = useState(true)
    const [level, setLevel] = useState<number>()
    const [subjects, setSubjects] = useState<Subject[]>()
    const [subjectsId, setSubjectId] = useState<string[]>([])
    const [userProviders, setUserProviders] = useState<UserInfo[]>()
    const [tagHeight, setTagHeight] = useState(20)
    const [user] = useAuthState(auth)
    useEffect(()=>{
        if (user) {
            setUserProviders(user.providerData)
        }
    },[user])
    
    const getLevel = (value: number) => {
        setLevel(value)
    }
    const getTagHeight = (value: number) => {
        setTagHeight(value)
    }
    const getSubjects = (value: Subject[]) => {
        let array: string[] = []
        value.map((subject) => (array.push(subject['@id']!)))
        setSubjectId(array)
    };
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response)=>{
            if (response) {
                setTeachr(response)
                setEmail(response.mail!)
                setPhone(response.phone!)
                setIban(response.iban!)
                setSchool(response.whereStudies)
                getSubject(response)
            }
        })
    }, [])
    
    const updateParcours = () => {
        if (teachr) {
            teachr.yearInStudies = level!
            teachr.whereStudies = school!
            // teachr.subjectToTeach = subjectsId
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if ( teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            TeachrRepository.updateTeachr(teachr).then((succes) => {
            }).catch((error) => { console.warn(error) })
        }

    }
    const updateContact = () => {
        if (teachr) {
            let update = false
          if(!editModeIban && ValidateIban(iban).isValid){
            teachr.iban = iban.replace(/ /g, "")
            update = true
          }
          if (!editModeEmail && validateEmail(email).isValid) {
            RefreshAuthentification('azerty').then((response)=> 
            {
                UpdateUserEmail(email).then((response)=> console.warn(response)  ).catch((error)=> console.warn(error) )
            }
            ).catch((error)=> console.warn(error)
            )
            
          }
          if (update)  {
            // TeachrRepository.updateTeachr(teachr)
            
        }
          
        }

    }
    const getSubject = (teachr: Teachr) => {
        const regex = /\/subjects\/(.*)/
        const array: Subject[] = []
        if (teachr) {
            teachr.subjectToTeach!.map((value) => {
                // const result = value.match(regex)
                // if (result) {
                //     SubjectRepository.fetchSubjectById(parseInt(result[1])).then((subject) => {
                //         array.push(subject)
                //     })
                // }
            })
            setSubjects(array)
        }
    }
    return (
        <div>
            <div className="flex flex-col gap-8 md:p-4 md:flex-row lg:w-4/5 lg:gap-24 ">
               {teachr && subjects && school ? 
               <div className='relative p-4 rounded-lg lg:w-2/3 shadow-account text-normal'>
                    <h1 className='py-2 font-bold uppercase text-first'>Mon parcours <SchoolIcon /></h1>
                    <div className="flex flex-col gap-4 py-4">
                        {teachr.yearInStudies && <SimpleSelect icon={true} callback={getLevel} defaultValue={teachr.yearInStudies} label="Niveau d’études" placeholder='Ex : Bac +1' data={['Bac + 1', 'Bac + 2', 'Bac + 3', 'Bac + 4', 'Bac + 5', 'Bac + 6', 'Bac + 7', 'Bac + 8']} customClass='bg-[#F2F5FB] ' />}
                        <div className='bg-[#F2F5FB] rounded-md py-2 px-3 relative'>
                            {school !== '' && <ClearIcon onClick={() => { setEditMode(false); setSchool('') }} className=' absolute top-1/3 right-6  p-[1px] text-lg text-white font-regular cursor-pointer rounded-full bg-[#808080]' />}
                            <label htmlFor="">Nom de l’établissment</label>
                            <input readOnly={editMode} type="text" value={school} onChange={(e) => setSchool(e.target.value)} className='block w-full bg-transparent border-none focus:outline-none' />
                        </div>
                        <div style={{height:tagHeight +100 + "px"}}>
                            <label htmlFor="" className='font-regular'>Vos matières </label>
                            <SubjectsSelect callBack = {getTagHeight} type={1} placeholder="SVT, piano, anglais..." default={subjects} getSubjects={getSubjects} customClass='bg-[#F2F5FB] py-3' />

                        </div>
                        <Button onClick={() => updateParcours()} className="bg-first rounded-xl py-1 text-[12px] text-white border-solid border-[2px] font-regular border-first no-underline uppercase px-8" >
                            Valider
                        </Button>
                    </div>
                </div>
                :
                <Skeleton variant="rounded" className='lg:w-1/3' height={500} />}
                
                {teachr ? <div className='px-4 py-8 rounded-lg lg:p-8 h-max shadow-account text-normal'>
                    <h1 className='py-2 font-bold uppercase text-first'>Contact</h1>
                    <div className='flex flex-col gap-6 pt-6'>
                        <div className='bg-[#F2F5FB] rounded-md py-2 px-3 relative'>
                            {email !== '' && <ClearIcon onClick={() => setEditModeEmail(false)} className=' absolute top-1/3 right-6  p-[1px] text-lg text-white font-regular cursor-pointer rounded-full bg-[#808080]' />}
                            <label htmlFor="">E-mail</label>
                            <input readOnly={editModeEmail} type="text" value={email} onChange={(e) => setEmail(e.target.value)} className='block w-full bg-transparent border-none focus:outline-none' />
                        </div>
                        <div className='bg-[#F2F5FB] rounded-md py-2 px-3 relative'>
                            {phone !== '' && <ClearIcon onClick={() => setEditModePhone(false)} className=' absolute top-1/3 right-6  p-[1px] text-lg text-white font-regular cursor-pointer rounded-full bg-[#808080]' />}
                            <label htmlFor="">No. Téléphone portable</label>
                            <input readOnly={editModePhone} type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className='block w-full bg-transparent border-none focus:outline-none' />
                        </div>
                        <div>
                            <label className='font-regular uppercase '>Iban</label>
                            <div className="relative flex gap-4 py-3 border-none focus:ring-0 focus:border-none focus:outline-none">
                                {iban !== '' && <ClearIcon onClick={() =>  setEditModeIban(false)} className=' absolute top-1/3 right-6  p-[1px] text-lg text-white font-regular cursor-pointer rounded-full bg-[#808080]' />}
                                <AccountBalanceIcon />
                                <input type="text" readOnly={editModeIban} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setIban(e.target.value)} value={iban} />
                            </div>
                            <p className='font-regular'>Votre IBAN est conservé de manière entièrement sécurisée. Il nous sert uniquement à verser sur votre compte l’argent que vous gagnez en donnant des cours avec Teach’r.
                            </p>
                            <Button onClick={updateContact} className="font-bold md:px-4 mt-5 bg-first mx-auto w-max block rounded-xl py-1  text-white border-solid border-[1px] border-first no-underline uppercase px-2" >
                                valider les modifications
                            </Button>
                        </div>
                    </div>
                </div>:
                <Skeleton variant="rounded" width={"100%"} height={500} />}
            </div>
        </div >
    );
};

export default AccountInformation;