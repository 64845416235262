import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import countries from '../../Assets/file/code_pays.json'
import Functions from '../../Helpers/Functions';
interface Country {
    label: string,
    value: number
}
const SelectCountry = (props: any) => {

    const [value, setValue] = useState<Country | null>()
    useEffect(() => {
        if (props.value) {
            setValue({
                label: props.value,
                value: Functions.getCodePays(props.value)
            })
            props.callBack(props.value)
        }
    }, [])
    return (
        <div className='px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
            <label htmlFor="" className='block text-sm lg:text-lg md:text-base'>Pays de naissance</label>
            <Autocomplete
                value={value}
                defaultValue={props.value ? {
                    label: props.value,
                    value: Functions.getCodePays(props.value)
                } : null}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        props.callBack(newValue.label)
                    } else {
                        props.callBack("")
                    }
                }}
                id="country-select"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        sx={{
                            '&': {
                                fontFamily: "nunito",
                                fontSize: "1rem",
                                padding: 0
                            },
                            '& fieldset': {
                                border: "none",
                                fontFamily: 'nunito',
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot ': {
                                padding: 0,
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                padding: "2px 0",
                                fontSize: "1rem",
                            },
                        }}
                        {...params}
                        placeholder="Sélectionnez votre pays"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    );
};

export default SelectCountry;