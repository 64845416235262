import React from "react";
import AddStudent from "../AddStudent/AddStudent";


const StepDeclare = (props: any) => {
  const {handleChangeStep} = props

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%] md:py-8 py-4 xl:px-14 px-4">
      <div className="space-y-4">
        <div className="xl:w-1/2 lg:w-2/3">
          <p className="//underline //xl:underline-offset-[16px] underline-offset-[8px] text-first font-bold lg:text-2xl md:text-lg text-lg">
          Déclaration d’une prestation :
          </p>
        </div>
        <div className="mt-6">
          <p className="text-[#4D4D4D] lg:text-lg md:text-base text-sm font-regular">
          Vous pouvez déclarer ici toutes vos prestations avec vos clients y compris ceux que vous avez déjà effectué. 
          </p>
        </div>
        <div className="xl:mt-4 mt-2">
          <p className="text-first lg:text-xl md:text-base text-sm font-bold">
            Pour quel client souhaitez-vous déclarer une prestation ?{" "}
          </p>
        </div>
          <AddStudent handleChangeStep={handleChangeStep}  />
      </div>
    </div>
  );
};

export default StepDeclare;
