import { Button } from '@mui/material';
import React, { useState } from 'react';
import StepperStore from '../../../../LocalForage/StepperStore';
import AiciSetupInterface from '../../../../Objects/interfaces/AiciSetup';
import Layout from './Layout';

const Intro = (props: any) => {
    const handleClick = () => {
        let screenState:AiciSetupInterface = {
            currentStep: 1,
            key: '',
            paiment: {
                bic: null,
                iban: null
            },
            Parent: {
                first: {
                    firstName: null,
                    name: null,
                    mail: '',
                    telephone: null,
                },
                second: {
                    address: null,
                    birthDay: null,
                    country: null,
                    gender: null,
                    isBornFrance: null,
                    zipCode: null,
                    city: null,
                    birthName: null
                }
            },
            student: {
                firstName: null,
                level: null
            },
        }
        StepperStore.setItem('dashboard_setup',screenState)
        props.nextStep(1)
    }
    return (
        <div>
            <Layout next={<Button onClick={() => handleClick()} className='w-full py-2 font-bold text-white uppercase rounded-lg md:text-base px-14 bg-first md:w-max'>C’EST PARTI !</Button>}>
                <h2 className='text-lg font-bold //underline md:text-xl text-first //underline-offset-8'>Ajout d’un client :</h2>
                <p className='text-base font-regular leading-2 text-[#4D4D4D] py-4'>Pour ajouter un client vous aurez besoin des informations ci-dessous. <br />
                Si vous ne pouvez pas toutes les compléter, votre client recevra à son tour ce formulaire et pourra finaliser son inscription. <br />
                Votre client paie lui-même ses prestations ? Dans ce cas, Veuillez remplir les mêmes informations dans les champs "Client".</p>
                <div className="flex flex-col gap-8 pt-2 md:flex-row">
                    <ul className='list-disc list-inside'>
                        <h3 className='mb-2 text-base font-regular text-first'>Client :</h3>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Prénom & Nom de famille</li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>E-mail</li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Téléphone </li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Civilité </li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Date de naissance & Commune de naissance & Pays de naissance</li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Adresse postale  </li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>IBAN</li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>BIC</li>
                    </ul>
                    {/* <ul className='list-disc list-inside'>
                        <h3 className='mb-2 text-base font-regular text-first'>Élève :</h3>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Prénom</li>
                        <li className='font-medium text-[#4D4D4D] text-sm'>Niveau scolaire</li>
                    </ul> */}
                </div>
            </Layout>
        </div>
    );
};

export default Intro;