import React from 'react';
import Functions from '../../../../../../Helpers/Functions';
import prifile from "../../../../../../Assets/Icons/profile.svg"
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import { Avatar } from '@mui/material';
import moment from 'moment';
// import Stack from '@mui/material/Stack';
// import CircularProgress from '@mui/material/CircularProgress';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const CompChild = (props: any) => {
    const { child } = props
    // const steps = [
    //     'Envoyé',
    //     'Lu',
    //     'Validé',
    // ];
    // console.log(child.status);


    return (
        <tr className="font-regular">
            <th scope="row" className="md:flex hidden items-center  pl-6 py-3 text-gray-900 whitespace-nowrap ">
                <Avatar
                    alt='azert'
                    src={prifile}
                    sx={{
                        background: "#92D3FF"
                    }}
                />
                 <div className="pl-3">
                    <div className="text-base ">{child?.studentName === "" || child?.studentName === undefined ? child?.mail : child?.studentName}</div>
                    <div className="font-normal text-gray-500">{child?.level === "" || child?.level === undefined || child?.level === null ? "Pas de classe" : Functions.renderClass(child?.level)}</div>
                </div>
            </th>
            <td className="px-2  py-4 md:hidden">
                <div className="//pl-3">
                    <div className="text-base ">{child?.studentName === "" || child?.studentName === undefined ? child?.mail : child?.studentName}</div>
                    <div className="font-normal text-gray-500">{child?.level === "" || child?.level === undefined || child?.level === null ? "classe" : Functions.renderClass(child?.level)}</div>
                </div>
            </td>
            <td className="px-2  py-4">
                {child.firstName !== null && child.name !== null ? child.firstName + " " + child.name : child.mail}
            </td>
            <td className="px-2  py-4">
                {child?.createdAt?.date !== null ? moment(child?.createdAt?.date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
            </td>
            <td className="pr-3 w-[35%] py-1 invisible md:visible  ">
                {/* <Stepper activeStep={child.status ? 3 : 1} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>
                                <p className="//-translate-y-16 text-xs font-sans font-regular text-first">{label}</p>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper> */}
                <div className='flex //border //justify-between w-[100%]'>
                    <div className='border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'>
                        <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1 '>Envoyé</p>
                        <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p>
                    </div>
                    <div className={'border-b-4 border-first w-[30%] -translate-y-3' }></div>
                    <div className={!child.status ? 'border-2 border-gray text-first font-regular rounded-2xl w-8 h-8 text-center pt-1' : 'border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'}>
                       
                        {child.status ?
                             <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1'>Lu</p> :
                             <p className='text-xs text-gray font-regular -translate-y-6 w-14 -translate-x-4 '> En cours</p>
                        }
                        {child.status ?
                            <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p> :
                            <p className='-translate-y-4 text-gray'>2</p>
                        }
                    </div>
                    <div className={child.status ? 'border-b-4 border-first w-[30%] -translate-y-3' : 'border-b-4 border-gray w-[30%] -translate-y-3'}></div>
                    <div className={!child.status ? 'border-2 border-gray text-first font-regular rounded-2xl w-8 h-8 text-center pt-1' : 'border-2 bg-first text-first font-regular rounded-2xl w-8 h-8 text-center pt-1'}>
                        
                        {child.status ?
                             <p className='text-xs text-first font-regular -translate-y-6 -translate-x-1 '>Validé</p> :
                             <p className='text-xs text-gray font-regular -translate-y-6 -translate-x-1 '>Validé</p>
                        }
                        {child.status ?
                            <p className='-translate-y-4 text-white'><CheckOutlinedIcon/></p> :
                            <p className='-translate-y-4 text-gray'>3</p>
                        }
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default CompChild;