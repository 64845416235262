import validator from "validator";
import errorIcon from "../Assets/Images/error.png";

const validateSelects = (value) => {
  if ( (value === undefined)) {
    return {
      isValid: false,
      message: "Sélectionnez une prestation",
      icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />
    };
  } else
    return {
      isValid: true,
      message: "", 
      icon:""
    };
};

export default validateSelects;
