import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";

const ComponentWeekPriceChart = (props) => {
  const { durationByDay } = props
  const chartRef = useRef(null);

  useEffect(() => {
    const chartData = {
      labels: ["L", "M", "M", "J", "V", "S", "D",],
      datasets: [{
        label: '',
        data: Object.values(durationByDay),
        fill: true,
        backgroundColor: "#E8F5FF",
        borderColor: "#0B2775",
        borderRadius: 10,
      }]
    };

    const chartConfig = {
      type: 'line',
      data: chartData,
      options: {
        scales: {
          y: {
            display: true,
            ticks: {
              // Définir les valeurs personnalisées que vous souhaitez afficher sur l'axe Y
              // stepSize: 20, // Pas entre les valeurs
              min: 0, // Valeur minimale affichée sur l'axe Y
              // max: 1000 // Valeur maximale affichée sur l'axe Y
          }
          },
          x: {
            display: true,
            // stacked: false, 
            // offset: true,
            grid: {
              offset: false
            }
          }
        },
        barPercentage: 0.3,
        responsive: true,
      }
    };
    const canvas = chartRef.current;
    canvas.style.width = '50px'; // Set custom width
    canvas.style.height = '120px'; // Set custom height

    const ctx = canvas.getContext('2d');
    const myChart = new Chart(ctx, chartConfig)


    return () => {
      myChart.destroy();
    };
  }, [durationByDay]);

  return (
    <div className=''>
      <canvas className="" ref={chartRef} />
    </div>
  );
};

export default ComponentWeekPriceChart;