import React, { useEffect, useState } from 'react';

const Breadcrumb = (props: any) => {
    return (
        <div className='w-full'>
            <div className='justify-center hidden md:flex md:gap-x-3 gap-x-4'>
                <div className='flex items-center gap-x-3'>
                    <span className={`${props.active >= 1 ? "bg-first" : "before:border-b-[#D2D2D2]"} flex items-center justify-center w-[39px] h-[39px] p-0 leading-none text-center  rounded-full  lg:text-[22px] font-regular text-white`}>1</span>
                    <span className={`${props.active >= 1 ? "text-first " : " text-[#D2D2D2]"}  text-center lg:text-[22px] text-xs  flex items-center font-regular`}>Vos informations</span>
                </div>
                <div className={`${props.active >= 2 ? "before:border-b-first before:border-solid" : "before:border-b-[#D2D2D2] before:border-dashed"} flex items-center gap-x-3 before:content-[' '] before:w-[53px] before:h-1  before:border-b-2 `}>
                    <span className={`${props.active >= 2 ? "bg-first" : "bg-[#D2D2D2]"} flex items-center justify-center w-[39px] h-[39px] p-0 leading-none text-center  rounded-full  lg:text-[22px] font-regular text-white`}>2</span>
                    <span className={`${props.active >= 2 ? "text-first " : " text-[#D2D2D2]"}  text-center lg:text-[22px] text-xs  flex items-center font-regular`}>Démarches</span>
                </div>
                <div className={`${props.active >= 3 ? "before:border-b-first before:border-solid" : "before:border-b-[#D2D2D2] before:border-dashed"} flex items-center gap-x-3 before:content-[' '] before:w-[53px] before:h-1  before:border-b-2`}>
                    <span className={`${props.active >= 3 ? "bg-first" : "bg-[#D2D2D2]"} flex items-center justify-center w-[39px] h-[39px] p-0 leading-none text-center  rounded-full  lg:text-[22px] font-regular text-white`}>3</span>
                    <span className={`${props.active >= 3 ? "text-first " : " text-[#D2D2D2]"}  text-center lg:text-[22px] text-xs  flex items-center font-regular`}>Finalisation</span>
                </div>
            </div>
            <div className='flex justify-between w-full md:hidden gap-x-4'>
                <div className='flex flex-col w-full gap-y-2'>
                    <div className={`${props.active >= 1 ? "text-first " : " text-[#D2D2D2]"} flex items-center text-sm font-regular gap-x-2`}>
                        <span className='mx-auto'>Informations</span>
                        <span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 0C2.69143 0 0 2.69143 0 6C0 9.30857 2.69143 12 6 12C9.30857 12 12 9.30857 12 6C12 2.69143 9.30857 0 6 0ZM10.1374 4.38686L5.37086 9.15343C5.226 9.29829 5.02457 9.38143 4.81971 9.38143V9.70457L4.81286 9.38143C4.60543 9.37971 4.40314 9.29314 4.25914 9.14314L1.85314 6.65143C1.55486 6.342 1.56343 5.84743 1.87286 5.54914C2.01857 5.40771 2.21143 5.33057 2.41371 5.33057C2.62714 5.33057 2.82686 5.41543 2.97514 5.56886L4.82914 7.49057L9.03514 3.28457C9.18257 3.13714 9.378 3.05657 9.58629 3.05657C9.79457 3.05657 9.99 3.138 10.1374 3.28457C10.4409 3.58886 10.4409 4.08343 10.1374 4.38686Z" fill={props.active >= 1 ? '#0B2775' :'#D2D2D2'} />
                            </svg>
                        </span>
                    </div>
                    <div className={`${props.active >= 1 ? "bg-first" : "bg-[#D2D2D2]"} w-full h-1 rounded-full`}></div>
                </div>
                <div className='flex flex-col w-full gap-y-2'>
                    <div className={`${props.active >= 2 ? "text-first " : " text-[#D2D2D2]"} flex items-center text-sm font-regular gap-x-2`}>
                        <span className='mx-auto'>Démarches</span>
                        <span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 0C2.69143 0 0 2.69143 0 6C0 9.30857 2.69143 12 6 12C9.30857 12 12 9.30857 12 6C12 2.69143 9.30857 0 6 0ZM10.1374 4.38686L5.37086 9.15343C5.226 9.29829 5.02457 9.38143 4.81971 9.38143V9.70457L4.81286 9.38143C4.60543 9.37971 4.40314 9.29314 4.25914 9.14314L1.85314 6.65143C1.55486 6.342 1.56343 5.84743 1.87286 5.54914C2.01857 5.40771 2.21143 5.33057 2.41371 5.33057C2.62714 5.33057 2.82686 5.41543 2.97514 5.56886L4.82914 7.49057L9.03514 3.28457C9.18257 3.13714 9.378 3.05657 9.58629 3.05657C9.79457 3.05657 9.99 3.138 10.1374 3.28457C10.4409 3.58886 10.4409 4.08343 10.1374 4.38686Z" fill={props.active >= 2 ? '#0B2775' :'#D2D2D2'} />
                            </svg>
                        </span>
                    </div>
                    <div className={`${props.active >= 2 ? "bg-first" : "bg-[#D2D2D2]"} w-full h-1 rounded-full`}></div>
                </div>
                <div className='flex flex-col w-full gap-y-2'>
                    <div className={`${props.active >= 3 ? "text-first " : " text-[#D2D2D2]"} flex items-center text-sm font-regular gap-x-2`}>
                        <span className='mx-auto'>Finalisation</span>
                        <span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 0C2.69143 0 0 2.69143 0 6C0 9.30857 2.69143 12 6 12C9.30857 12 12 9.30857 12 6C12 2.69143 9.30857 0 6 0ZM10.1374 4.38686L5.37086 9.15343C5.226 9.29829 5.02457 9.38143 4.81971 9.38143V9.70457L4.81286 9.38143C4.60543 9.37971 4.40314 9.29314 4.25914 9.14314L1.85314 6.65143C1.55486 6.342 1.56343 5.84743 1.87286 5.54914C2.01857 5.40771 2.21143 5.33057 2.41371 5.33057C2.62714 5.33057 2.82686 5.41543 2.97514 5.56886L4.82914 7.49057L9.03514 3.28457C9.18257 3.13714 9.378 3.05657 9.58629 3.05657C9.79457 3.05657 9.99 3.138 10.1374 3.28457C10.4409 3.58886 10.4409 4.08343 10.1374 4.38686Z" fill={props.active >= 3 ? '#0B2775' :'#D2D2D2'} />
                            </svg>
                        </span>
                    </div>
                    <div className={`${props.active >= 3 ? "bg-first" : "bg-[#D2D2D2]"} w-full h-1 rounded-full`}></div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;