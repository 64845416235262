import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';

export default function InfoAcceuil(props: any) {
    let timerInterval: any;
    const [open, setOpen] = React.useState(false);
    const [code, setCode] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        if (!validate().isValidate) {
            setOpen(false);
            Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Le code de vérification que vous avez entré est incorrect.",
                timer: 5000,
                willClose: () => {
                    clearInterval(timerInterval);
                    setOpen(false);
                },
            });
        }
    };

    const validate = () => {
        if (code === "") {
            return {
                isValidate: true,
            }
        } else {
            return {
                isValidate: false,
            }
        }
    }


    return (
        <div>
            <p onClick={handleClickOpen}>
                {props.title}
            </p>
            <Dialog
                open={open}
                onClose={handleClose}
            // aria-labelledby="alert-dialog-title"
            // aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* {"Use Google's location service?"} */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Le site déclarons.fr est pour l’instant accessible uniquement aux bêtas testeurs qui ont reçu un code de validation de la part de l’équipe.
                        Il sera accessible prochainement au grand public.
                    </DialogContentText>
                    <div className='mt-4'>
                        <label className='text-[#4d4d4d]'>Code de validation </label>
                        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} className='border w-full rounded-lg py-1 px-2 text-[#4d4d4d] ' />
                        <p className='text-red-400'>{validate().isValidate ? "Veuillez remplir le champs" : ""}</p>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleClose2} autoFocus>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}