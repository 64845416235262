import StepperStore from '../../../../LocalForage/StepperStore'
import { useEffect, useState } from 'react'
import NavBar from '../../../../Components/ComponentsUtils/NavBar'
import Stepper from '../../../../Components/utils/Stepper'
import FirstRegister from './First'
import SecondRegister from './Second'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../../Services/FirebaseService'
import Loader from '../../../../Components/utils/Loader'
import Last from './Last'
import Register from '../Register'
import { useDispatch } from 'react-redux'

function Index() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState<number>()
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (!loading) {
      if (user) {
        StepperStore.getItem("currentStep" + user.uid).then((response: any) => {
          if (response) {
            setCurrentStep(response)
          } else {
            setCurrentStep(1)
          }
        })
      } else {
         setCurrentStep(1)
      }
    }
  }, [loading, user]);

  const nextStep = (step: number) => {
    if (user) {
      setCurrentStep(step)
      StepperStore.setItem("currentStep" + user.uid, step, function (err) { })
    }

  };
  const prevStep = (step: number) => {
    if (user) {
      if (step >= 2) {
        setCurrentStep(step);
      }
    } else {
      setCurrentStep(step);
    }

  };


  return (
    <div className='w-screen pb-10 overflow-x-hidden bg-center bg-no-repeat bg-cover 2xl:overflow-y-hidden md:h-screen md:overflow-y-auto md:pb-0 bg-register'>
      <Loader />
      <NavBar theme='' />
      <div className='//border-2'>
        <Stepper step={4} currentStep={currentStep} >
          <Register nextStep={nextStep} />
          <FirstRegister nextStep={nextStep} prevStep={prevStep} />
          <SecondRegister nextStep={nextStep} prevStep={prevStep} />
          <Last />
        </Stepper>
      </div>
    </div>
  )
}

export default Index