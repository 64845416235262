import moment from 'moment';
import React from 'react';
import AvatarH from '../../../Assets/Images/avatar-h.svg'
import ReviewClient from '../../../Objects/models/ReviewClient';
const Reviews = (props: { review: ReviewClient }) => {
    const getNote = (note: number | undefined) => {
        if (note) {
            switch (note) {
                case 5:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                        </div>
                    )
                case 4:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                        </div>
                    )
                case 3:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                        </div>
                    )
                case 2:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                        </div>
                    )
                case 1:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                        </div>
                    )

                default:
                    return (
                        <div className='flex gap-1'>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.42086 10.9444L5.50224 9.3225L2.55764 10.8935C2.22276 11.073 1.83303 10.7738 1.90232 10.3908L2.48835 7.01238L0.135558 4.59453C-0.13292 4.31923 0.0200834 3.84644 0.395376 3.79257L3.67774 3.32875L5.16736 0.261562C5.33769 -0.0885473 5.81979 -0.0825625 5.98434 0.267546L7.42489 3.35868L10.6986 3.87935C11.071 3.9392 11.2153 4.41499 10.944 4.6873L8.55365 7.06325L9.08483 10.4506C9.14546 10.8396 8.75284 11.1299 8.42086 10.9444Z" fill="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.83801 7.14071L9.35867 10.461L6.52343 8.88544L6.28543 8.75319L6.0452 8.88135L3.18526 10.4072L3.75931 7.09783L3.80291 6.8465L3.62501 6.66368L1.30454 4.27905L4.52602 3.82384L4.78955 3.7866L4.90582 3.54719L6.35186 0.569734L7.75 3.56988L7.86225 3.81073L8.12467 3.85247L11.3387 4.36365L8.97949 6.70863L8.79847 6.88856L8.83801 7.14071Z" stroke="#FFCE31" />
                            </svg>
                        </div>
                    )
            }
        }
        return

    }
    return (
        <div className='flex flex-col p-4 rounded-lg bg-bg-color-tuile gap-y-4'>
            <div className='flex flex-col justify-between md:flex-row gap-x-3'>
                <img className='w-[36px] rounded-full' src={props.review.owner?.profilePicture ? props.review.owner?.profilePicture: AvatarH} alt="" />
                <div className='w-full'>
                    <p className='text-base font-regular text-first'>{props.review.owner?.firstName} &nbsp;{props.review.owner?.lastName}</p>
                    <p className='text-sm text-normal'> <span className="font-regular">Matière</span> : {props.review.punctualCourse?.subjectAsked.fr_FR} </p>
                </div>
                <div className='w-max'>
                    <p className='text-xs font-regular text-first'>{moment(props.review.lastModificationDatetime!).format('DD/MM/YYYY')}</p>
                    {getNote(props.review.note)}
                </div>
            </div>
            <div>
                {props.review.comment}
            </div>
        </div>
    );
};

export default Reviews;