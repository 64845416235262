import { Button } from '@mui/material';
import React from 'react';
import illustration from '../../../../Assets/videos/presentation1.gif'
const Overview = () => {

    return (
        <div className='h-full px-4 mx-auto lg:w-[95%] md:px-8' >
            <h3 className='text-xs font-medium text-normal lg:text-lg'>JUSQU'À 40% À 70% EN PLUS SUR VOS COURS PARTICULIERS</h3>
            <h1 className='text-first lg:text-[40px] font-bold uppercase pt-8 text-xl'>Professeurs, gagnez plus, travaillez autant</h1>
            <div className="flex flex-col items-center justify-between md:flex-row lg:gap-x-14 gap-x-8">
                <div>
                    <div className='flex flex-col pb-8 lg:py-10 lg:gap-y-14 gap-y-8'>
                        <p className='font-regular text-first lg:text-3xl lg:leading-[40.92px] text-base'>Comme 99% des professeurs particuliers, <br /> vous ne savez sûrement pas que vous pouvez gagner plus d’argent en déclarant vos cours. </p>
                        <p className='font-regular text-normal lg:text-2xl'>Eh oui, en passant par Teach’r c’est jusqu'à 70% en plus que vous pourrez gagnez sur vos cours particuliers.</p>
                    </div>
                    <Button className='w-full py-2 font-bold text-white rounded-lg bg-first lg:text-2xl'>DÉCOUVRIR 🚀</Button>
                </div>
                <div className=' h-[calc(100vh_-_21.5rem)] lg:w-4/6 bg-center bg-no-repeat bg-contain order-first md:order-last w-full' style={{ backgroundImage: `url(${illustration})` }}>
                </div>
            </div>
        </div>
    );
};

export default Overview;

