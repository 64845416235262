import React, { useEffect, useState } from 'react';
import ProfileImg from '../../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../../Assets/Icons/hours.svg'
import CalendarIcon from '../../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../../Assets/Icons/address.svg'
import LocationIcon from '../../../../Assets/Icons/address.svg'
import euroIcon from '../../../../Assets/Icons/euro-circle.svg'
import { Skeleton } from '@mui/material';
import { Subject } from '../../../../Objects/models/Subject';
import SubjectRepository from '../../../../Repositories/SubjectRepository';
import { Address } from '../../../../Objects/models/Address';
import AddressRepository from '../../../../Repositories/AddressRepository';
import Child from '../../../../Objects/models/Child';
import Client from '../../../../Objects/models/Client';
import ChildRepository from '../../../../Repositories/ChildRepository';
import ClientRepository from '../../../../Repositories/ClientRepository';
import Functions from '../../../../Helpers/Functions';
import moment from 'moment';
import { PackProposal } from '../../../../Objects/models/PackProposal';
import { CoursePack } from '../../../../Objects/models/CoursePack';
import CoursePackRepository from '../../../../Repositories/CoursePackRepository';
const PackCandidacyComponent = (proposal: PackProposal) => {
    interface CoursePackDate {
        month: string;
        days: string[]
    }
    const [course, setCourse] = useState<CoursePack>()
    const [subject, setSubject] = useState<Subject>()
    const [address, setAddress] = useState<Address>()
    const [child, setChild] = useState<Child | Client>()
    useEffect(() => {
        CoursePackRepository.fetchCoursePackById(proposal.coursePack_id).then((response) => {
            setCourse(response)
            if (response) {
                SubjectRepository.fetchSubjectById(response.subjectAsked_id!).then((response) => {
                    setSubject(response)
                })
                if (response.address_id) {
                    AddressRepository.fetchAddressById(response.address_id).then((response) => {
                        setAddress(response)
                    })
                }

                if (response.forChild_id) {
                    ChildRepository.fetchChildByIdForTeachr(response.forChild_id, proposal.teachr_id).then((response) => {
                        setChild(response)

                    })
                } else if (response.forClient_id) {
                    ClientRepository.fetchClientByIdForTeachr(proposal.teachr_id, response.forClient_id).then((response) => {
                        setChild(response)
                    })
                }
            }
        }).catch((error) => console.warn(error)
        )
    }, [])

    const displayCoursePackDates = (dates: Date[]) => {

        let groupDate: CoursePackDate[] = []
        let lastMont: string = ''
        for (let i = 0; i < dates.length; i++) {
            const date = dates[i]
            let sameDays: string[] = []
            let lastIndex: number = 0
            if (lastMont !== moment(date).format('MMMM')) {
                lastMont = moment(date).format('MMMM')
                for (let j = 0; j < dates.length; j++) {
                    if (moment(date).format('MMMM') === moment(dates[j]).format('MMMM')) {
                        sameDays.push(moment(dates[j]).format('dddd DD'))
                    }
                }
                groupDate.push({
                    month: lastMont,
                    days: sameDays
                })
                lastIndex = i
            } else {
                for (let j = 0; j < dates.length; j++) {
                    if (moment(date).format('MM') === moment(dates[j]).format('MMMM')) {
                        groupDate[lastIndex].days.push(moment(dates[j]).format('dddd DD'))
                    }
                }
            }
        }

        return groupDate
    }
    return (
        <div className=" p-6 mx-auto rounded-lg border-[#758FDA] border-[1px] w-[340px]">
            <div className="flex flex-col">
                <div className="flex flex-col gap-4">
                    <div>
                        <p className='text-xl font-bold /whitespace-nowrap text-first'>Récapitulatif du de la prestation</p>
                    </div>
                    <div className="flex items-center gap-x-4">
                        <img src={child?.profilePicture ? child?.profilePicture : ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                        <div>
                            {!subject ?
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="200px"
                                />
                                : <p className='overflow-hidden whitespace-nowrap w-[210px] text-ellipsis text-lg font-semibold text-first' title={subject.fr_FR}>{subject.fr_FR}</p>
                            }
                            
                        </div>
                    </div>
                    {!course?.isRemote
                        ? <div className="flex items-center gap-4">
                            <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            {address ? <a href={`https://www.google.com/maps/search/?api=1&query=${address?.streetName}%2C+${address?.city}`} target='_blanck' className='text-lg font-semibold underline text-first'>{address?.streetName + ', ' + address?.city}</a> :
                                <Skeleton
                                    animation="wave"
                                    height={16}
                                    width="200px"
                                />}

                        </div> :
                        <div className="flex items-center gap-4">
                            <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            <p className='text-lg font-semibold text-first'>Cours à distance</p>
                        </div>}
                    <div className="flex items-center gap-4">
                        <img src={euroIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <p className='text-lg font-regular text-first'>{Functions.renderPaymentMean(course?.paymentMean!, false)}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 py-4">
                    <div>
                        <p className='text-xl font-bold /whitespace-nowrap text-first'>Dates du regroupement des prestations</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <div>
                            {course?.dates && displayCoursePackDates(course.dates).map((date: CoursePackDate, key) => (
                                <ul key={key}>
                                    <li className='items-center text-[16px] capitalize font-semibold text-first'>
                                        {date.month} :
                                    </li>
                                    <li className='text-[#4D4D4D] text-sm lg:text-sm flex flex-wrap'>
                                        {date.days.map((day: string, key) => (<span key={key} >{day}<span className={`${key === date.days.length - 1 && 'hidden'}`}>,&nbsp;</span></span>))}
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <div>
                            <p className='text-[16px] font-semibold text-first'><span className='uppercase'>à</span> partir de {moment(course?.dates[0]).format("h") + "h" + moment(course?.dates[0]).format("mm")}</p>
                            <p className='text-[#4D4D4D] text-sm lg:text-sm'>{Functions.renderDuration(course?.durationPerDay!)} / jour</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default PackCandidacyComponent;