import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import cours_regulier from "../../../../../Assets/Icons/cours_regulier.svg";
import stage_revision from "../../../../../Assets/Icons/stage_d.svg";
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
// import pastcourse from '../../../../../Assets/Icons/pastcourse.svg'
import SearchBar from '../../../../../Components/Admin/SearchBar';
import StepperStore from '../../../../../LocalForage/StepperStore';
// import ClientRepository from '../../../../../Repositories/ClientRepository';
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
import ComponentChildDeclareCourse from './ComponentChildDeclareCourse';
import ChildRepository from '../../../../../Repositories/ChildRepository';
import { auth } from '../../../../../Services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';
// import ChoiceCourseDeclare from '../StepDeclare/ChoiceCourseDeclare';
// import TeachrContactRepository from '../../../../../Repositories/TeachrContactRepository';

export interface ConfirmationDialogRawProps {
    id: string;
    keepMounted: boolean;
    value: string;
    open: boolean;
    onClose: (value?: string) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const [clientTiersPrest, setClientTiersPrest] = React.useState<any>();
    const [teachrId, setTeachrId] = React.useState<any>();
    const radioGroupRef = React.useRef<HTMLElement>(null);
    const [load, setLoad] = React.useState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoad = () => {
        setLoad(!load)
    }
    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    React.useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            setTeachrId(teachr)
        })
    }, [])
    React.useEffect(() => {
        handleLoad()
        TeachrRepository.get_client_by_teacher_favorite(teachrId?.id).then((client) => {
            setClientTiersPrest(client.clients)
        })
    }, [handleLoad, teachrId?.id])

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '60%', height: 500 }, boxShadow: '0px 4px 7px 7px #80808087' }}

            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}

        >
            <div>
                <div className='flex justify-between mt-4'>
                    <div className='//border-2' >
                        <DialogTitle sx={{ color: "#0B2775", fontWeight: "900", textTransform: "uppercase" }}>Mes clients : </DialogTitle>
                    </div>
                    <div className="right-4 //w-1/4 // top-3 absolute">
                        <img onClick={() => handleOk()} className='cursor-pointer' src={closeCircle} alt="" />
                    </div>
                </div>
                <div className='px-6 pb-2'>
                    <SearchBar />
                </div>
            </div>
            <DialogContent>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {clientTiersPrest?.map((child: any, key: any) => (
                        <div className='flex  '>
                            <ComponentChildDeclareCourse child={child} teachrId={teachrId} handleLoad={handleLoad} key={key} />
                        </div>
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <div
                    onClick={handleOk}
                    className="md:py-2 py-1 w-full border-2 text-center cursor-pointer font-bold text-white uppercase rounded-xl md:text-base text-xs border-first bg-first mb-4 mx-4"
                >
                    AJOUTER
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default function ConfirmationDialog(props: any) {
    const { handleChangeStep } = props
    const [user] = useAuthState(auth)
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [child, setChild] = React.useState<any>();
    const [error, setError] = React.useState({
        value: 'null'
    })
    const validate = (val: any) => {
        if (val === "") {
            return {
                isValid: false,
                message: "Veuillez ajouter un élève",
            };
        } else
            return {
                isValid: true,
                message: "",
            };
    }
    const validateA = () => {
        let errvalue = true;
        if (!validate(value).isValid) {
            setError({ value: validate(value).message });
            errvalue = true;
        } else {
            setError({ value: validate(value).message });
            errvalue = false;
        }

        if (errvalue) {
            return false;
        } else {
            return true;
        }
    };

    // const handleNextSubscription = () => {
    //     if (validateA()) {
    //         handleChangeStep(2);
    //         StepperStore.setItem("cours" + user?.uid, {
    //             cours: 'regulier'
    //         });
    //     }
    // }
    const handleNextPunctual = () => {
        if (validateA()) {
            handleChangeStep(2);
            StepperStore.setItem("cours" + user?.uid, {
                cours: 'punctual'
            });
        }
    }
    // const handleNextPunctualPast = () => {
    //     if (validateA()) {
    //         handleChangeStep(5);
    //         StepperStore.setItem("cours" + user?.uid, {
    //             cours: 'punctualPast',
    //             parentId: child
    //         });
    //     }
    // }

    const handleClickListItem = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        (async () => {
            const value: any = await StepperStore.getItem("student" + user?.uid);
            StepperStore.removeItem("activeStepundefined")
            if (value !== null) {
                setValue(value.value);
            }
            StepperStore.setItem("client" + user?.uid, {
                client: child?.parent
            })
        })();
    }, [child, user?.uid]);
    React.useEffect(() => {
        if (value !== "") {
            const id = parseInt(value)
            ChildRepository.fetchChildById(id).then((res) => {
                setChild(res)
            }).catch((re) => console.warn(re))
        }
    }, [value, child])
    const handleClose = (newValue?: string) => {
        if (newValue) {
            setValue(newValue);
        }
        StepperStore.setItem("student" + user?.uid, {
            value: newValue,
            child: child
        })
        setOpen(false)
    };
    return (
        <div>
            <div className="mt-2 //border-2">
                <Box >
                    <List component="div" role="group">
                        <div onClick={handleClickListItem} className="bg-first text-white p-1 lg:text-lg md:text-base text-sm font-bold text-center md:w-1/2 px-auto border-2 border-first rounded-xl cursor-pointer">
                            {child === undefined ? "Ajouter" : child.firstName}
                        </div>
                        <ConfirmationDialogRaw
                            id="ringtone-menu"
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            value={value}
                        />
                    </List>
                </Box>
            </div>
            <div className="//lg:right-2 //hidden md:flex  //right-4 //top-56 //lg:top-24 //absolute">
                <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
                    {error.value === "null" || error.value === ""
                        ? null
                        : validate(value).message}
                </p>
            </div>
            <div>
                <div className="xl:mt-4 mt-2">
                    <p className="text-first lg:text-xl md:text-base text-sm font-bold">
                        De quel type de prestation s’agit-il ?{" "}
                    </p>
                </div>

                <div className="w-full relative  mx-auto md:translate-y-6 grid gap-6 //xl:mt-4 //lg:-mt-3 mt-4 md:mt-0 md:space-y-0 lg:grid-cols-3 lg:px-0 md:px-10">
                    <div className="bg-[#2d2d2d] bg-opacity-[10%] //hover:bg-[#4B6AC8] //hover:bg-opacity-[28%] //hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D]  pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
                        <div className=" pl-3 w-1/4">
                            <img
                                src={stage_revision}
                                alt=""
                                className=" w-10 //mt-[4px]"
                            />
                        </div>
                        <div className="w-3/4 cursor-pointer">
                            <p className=" //text-center md:pl-10 pt-2 md:text-lg text-sm text-[#4d4d4d] font-bold cursor-pointer">
                                Stage
                            </p>
                        </div>
                    </div>
                    <div className="bg-[#2d2d2d] bg-opacity-[10%] //hover:bg-[#4B6AC8] //hover:bg-opacity-[28%] //hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
                        <div className="   pl-3 w-1/4 z-40 ">
                            <img
                                src={stage_revision}
                                alt=""
                                className=" w-10 //mt-[4px] "
                            />
                        </div>
                        <div className="w-3/4 cursor-pointer">
                            <div>
                                <p className="md:text-center pt-2 md:text-lg text-sm text-[#4d4d4d] font-bold cursor-pointer">
                                    Prestation Regulière
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            handleNextPunctual()
                        }}
                        className="bg-[#4B6AD8] bg-opacity-[10%] hover:bg-[#4B6AC8] hover:bg-opacity-[28%] hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
                        <div className="  pl-3 w-1/4">
                            <img
                                src={cours_regulier}
                                alt=""
                                className="rounded-full w-10 mt-[4px]"
                            />
                        </div>
                        <div className="  w-3/4 cursor-pointer">
                            <p className="md:text-center pt-2 md:text-lg text-sm  font-bold cursor-pointer">
                                Prestation Ponctuelle
                            </p>
                        </div>
                    </div>
                </div>
                <div className="translate-y-6 md:flex hidden //border-2 //xl:translate-y-3 //lg:translate-y-1 lg:px-0 md:px-10" >
                    <p className="text-[#4d4d4d] text-xs pl-2">Bientôt disponible </p>
                </div>
                <div className='mt-14'>
                    <p className='text-first lg:text-lg md:text-base text-sm font-regular'>Vous souhaitez déclarer tous vos cours du mois passé ?</p>
                </div>
                <div className=' grid lg:grid-cols-3 mt-4'>
                    <div
                        // onClick={() => {
                        //     handleNextPunctualPast()
                        // }}
                        className="bg-[#2d2d2d] bg-opacity-[10%] //hover:bg-[#4B6AC8] //hover:bg-opacity-[28%] //hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
                        <div className=" pl-3 w-1/4">
                            <img
                                src={stage_revision}
                                alt=""
                                className=" w-10 //mt-[4px]"
                            />
                        </div>
                        <div className="  w-3/4 cursor-pointer">
                            <p className="md:text-center pt-1 md:text-lg text-sm text-[#4d4d4d] font-bold cursor-pointer">
                                Cours déjà effectués
                            </p>
                        </div>
                    </div>
                </div>
                <div className="//translate-y-10 md:flex hidden //border-2 //xl:translate-y-3 //lg:translate-y-1 lg:px-0 md:px-10" >
                    <p className="text-[#4d4d4d] text-xs pl-2">Bientôt disponible </p>
                </div>
            </div>

        </div>
    );
}
