import hour from "../../../../Assets/Icons/heure.svg";
import calendar from "../../../../Assets/Icons/calendrier.svg";
import lieu from "../../../../Assets/Icons/lieu.svg";
import money from "../../../../Assets/Icons/euro.svg";
// import toch from "../../../../Assets/Images/toch.png";
// import Update from "../../Paiement/Update";
// import Delete from "../../Paiement/Delete";
import { useNavigate } from "react-router-dom";
import { PunctualCourse } from "../../../../Objects/models/PunctualCourse";
import ClientRepository from "../../../../Repositories/ClientRepository";
import ChildRepository from "../../../../Repositories/ChildRepository";
import AddressRepository from "../../../../Repositories/AddressRepository";
import SubjectRepository from "../../../../Repositories/SubjectRepository";
import CourseProposalRepository from "../../../../Repositories/CourseProposalRepository";
import { useEffect, useState } from "react";
import { Address } from "../../../../Objects/models/Address";
import { Subject } from "../../../../Objects/models/Subject";
import Child from "../../../../Objects/models/Child";
import Functions from "../../../../Helpers/Functions";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import CourseProposal from "../../../../Objects/models/CourseProposal";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";

const HistoricPunctual = (course: PunctualCourse) => {
  const navigate = useNavigate()
  const [punctualCoursePropsal, setPunctualCoursePropsal] =
    useState<CourseProposal[]>();
  const [address, setAddress] = useState<Address>();
  // const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState<Subject>();
  const [child, setChild] = useState<Child>();
  const [teachr, setTeachr] = useState<any>();
  const [teachrHours, setTeachrHours] = useState<any>();
  const [proposal, setProposal] = useState<CourseProposal>();
  const number = 1


  useEffect(() => {
    ClientRepository.getCurrentClient().then((client) => {
      if (client.id) {
        if (course.forChild_id) {
          ChildRepository.fetchChildById(course.forChild_id).then((child) => {
            if (child) {
              setChild(child);
            }
          });
        }
        if (course.address_id) {
          AddressRepository.fetchAddressById(course.address_id).then(
            (address) => {
              if (address) {
                setAddress(address);
              }
            }
          );
        }
        if (course.subjectAsked_id) {
          SubjectRepository.fetchSubjectById(course.subjectAsked_id).then(
            (subject) => {
              if (subject) {
                setSubject(subject);
              }
            }
          );
        }
        if (client.id && course.id) {
          CourseProposalRepository.getCourseProposals(course.id).then(
            (punctualCourseProposalCollection) => {
              if (punctualCourseProposalCollection) {
                setPunctualCoursePropsal(punctualCourseProposalCollection);
              }
            }
          );
        }

      }
    });
  }, [course]);

  useEffect(() => {
    punctualCoursePropsal?.map((proposal: CourseProposal) => (
      setProposal(proposal)
    )

    );
    punctualCoursePropsal?.map((proposal: CourseProposal) =>
      Teachr_Repository.fetchTeachrByIdClient(proposal?.teachr_id!).then(
        (teachrData) => {
          setTeachr(teachrData);

        }
      )
    );
    punctualCoursePropsal?.map((proposal: CourseProposal) =>
      Teachr_Repository.getTeachrHours(proposal?.teachr_id!).then(
        (teachrData) => {
          setTeachrHours(teachrData);
        }
      )
    );
  }, [punctualCoursePropsal])

  const handleNext = () => {
    navigate("/user/profile", {
      state: { teachr, teachrHours, proposal, number },
    });
  }

  const handleClick = () => {
    (async () => {
      // setLoading(true);
      const client = await ClientRepository.getCurrentClient();
      window.open(
        "mailto:support@teachr.fr?subject=User " +
        client.id +
        " - Modification de la demande de Prestation ponctuelle " +
        course.id
      );
      // handleCloseUpd();
    })();
  };

  const handleDelete = () => {
    (async () => {
      // setLoading(true);
      const client = await ClientRepository.getCurrentClient();
      window.open(
        "mailto:support@teachr.fr?subject=User " +
        client.id +
        " - Suppression de la demande de Prestation ponctuelle " +
        course.id
      );
      // handleCloseUpdate();
    })();
  };

  return (
    <>
      <div className="md:flex md:w-1/2 md:m-4 shrink-0">
        <div className="md:w-full ">
          <div className="p-4 rounded-lg border-gray md:w-full shadow-res">
            <div className="mt-4 md:flex md:mt-2 //gap-x-8">
              <div className="space-y-2 md:w-1/2 ">
                <div className="flex space-x-4 md:space-x-0 ">
                  <div className="w-1/5 pt-1 pl-3">
                    <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#001E73] to-[#4B6AD8]">
                    </div>
                  </div>
                  <div className="w-4/5">
                    {subject?.fr_FR ? (
                      <p className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                        Prestation ponctuelle :
                      </p>
                    ) : (
                      <Skeleton width="100%" />
                    )}
                    <div className="text-xs lg:text-base md:text-sm text-[#0054A3] font-bold">
                      {subject?.fr_FR ? (
                        <span>{subject?.fr_FR} </span>
                      ) : (
                        <Skeleton width="50%" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 md:space-x-0">
                  <div className="w-1/5 pt-1 pl-3">
                    <img src={hour} alt="" />
                  </div>
                  <div className="w-4/5">
                    {course.startDatetime && course.endDatetime ? (
                      <span className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                        Entre{" "}
                        {moment(course.startDatetime).format("HH:mm") +
                          " et " +
                          moment(course.endDatetime).format("HH:mm")}
                      </span>
                    ) : (
                      <Skeleton width="75%" />
                    )}

                    <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-gray">
                      {course.duration ? (
                        <span>{Functions.renderDuration(course.duration)}</span>
                      ) : (
                        <Skeleton width="75%" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 md:space-x-0">
                  <div className="w-1/5 pt-1 pl-3">
                    <img src={calendar} alt="" />
                  </div>
                  <div className="pt-2">
                    {course.startDatetime ? (
                      <p className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                        {moment(course.startDatetime).format("dddd DD MMMM YYYY")}
                      </p>
                    ) : (
                      <Skeleton width="75%" />
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2 space-y-2 md:w-1/2 ">
                <div className="flex space-x-4 md:space-x-0">
                  <div className="w-1/5 pt-1 pl-4">
                    <img src={lieu} alt="" />
                  </div>
                  <div className="w-4/5">
                    {/* <p className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                    Rue d’Anthoine, Marseille
                  </p> */}
                    {course.isRemote ? (
                      <p className="text-sm font-regular translate-y-2 lg:text-lg md:text-base text-[#0054A3]">
                        {course.isRemote ? (
                          "Cours à distance"
                        ) : (
                          <Skeleton width="75%" />
                        )}
                      </p>
                    ) : (
                      <p className="text-sm font-regular lg:text-base md:text-base //translate-y-1 text-[#0054A3] pt-2">
                        {address ? (
                          address?.streetName + ", " + address?.city
                        ) : (
                          <Skeleton width="75%" />
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {punctualCoursePropsal?.map((proposal: CourseProposal, key) => (
                  <div className="flex space-x-4 md:space-x-0">
                    <div className="w-1/5 pt-1 pl-3">
                      <img src={money} alt="" />
                    </div>
                    <div className="w-4/5 ">
                      {proposal.hourlyPrice ? (
                        <p className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                          Prix par heure :{" "}
                          {(JSON.parse(proposal.hourlyPrice!) / 100).toFixed(2) +
                            "€"}
                        </p>
                      ) : (
                        <Skeleton width="100%" />
                      )}

                      {course.isRemote ? null :

                        <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-gray">
                          {proposal.hourlyPrice ? (
                            <span>
                              Soit après crédit d’impôt :{" "}
                              {(JSON.parse(proposal.hourlyPrice!) / 200).toFixed(
                                2
                              ) + "€"}
                            </span>
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </div>
                      }
                    </div>
                  </div>
                ))}
                <div className="flex space-x-4 md:space-x-0">
                  <div className="w-1/5 pt-1 pl-3">
                    {teachr?.firstName &&
                      <div className="h-10 w-10 rounded-full bg-gradient-to-b from-[#001E73] to-[#4B6AD8] text-white lg:text-xl md:text-lg font-bold text-center lg:pt-1 pt-2">
                        {teachr?.firstName[0]}
                      </div>}
                  </div>
                  <div className="w-4/5 ">
                    {teachr?.firstName ? (
                      <p className="text-sm font-regular lg:text-lg md:text-base text-[#0054A3]">
                        Avec : {teachr?.firstName}
                      </p>
                    ) : (
                      <Skeleton width="50%" />
                      // 'pour'
                    )}
                    <div className="text-xs cursor-pointer lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-gray">
                      <span onClick={() => handleNext()} >Voir Profil</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-around py-4 space-x-4 translate-y-4 md:space-x-md:space-x-0 md:translate-y-0 ">
              <div className="w-full cursor-pointer ">
                <span className="text-[#0054A3] " onClick={handleClick}>
                  <div className="border-[1px] md:p-2 p-1 md:text-[16px] text-xs text-center text-[#0054A3] px-3 border-first uppercase active:bg-first active:border-first active:text-white font-regular  rounded-lg">
                    Modifier
                  </div>
                </span>
              </div>
              <div className="w-full cursor-pointer ">
                <span className="text-[#0054A3] " onClick={handleDelete}>
                  <div className="border-[1px] text-center md:p-2 p-1 md:text-[16px] text-xs md:px-3 text-[#0054A3]  border-first uppercase active:bg-first active:border-first active:text-white font-regular  rounded-lg">
                    Supprimer
                  </div>
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoricPunctual;
