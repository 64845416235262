const SET_INFO = 'SET_INFO';

const initialState : Blob | File | string | null = null

export default  function procurationReducers(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      default:
        return state
    }
  }