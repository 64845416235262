import lieu from "../../../../Assets/Images/place.png";
// import picture from "../../../../Assets/Images/picture.png";
// import profile from "../../../../Assets/Images/profilehomme.png";
import starOnly from "../../../../Assets/Images/staronly.png";
// import toch from "../../../../Assets/Images/tochh.png";
import money from "../../../../Assets/Images/money.svg";
import msg from "../../../../Assets/Images/msg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgress, Rating } from "@mui/material";
import Functions from "../../../../Helpers/Functions";
import ReviewClient from "../../../../Objects/models/ReviewClient";
import ReviewClientRepository from "../../../../Objects/models/ReviewClientRepository";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/checkclose.png";
import Tabs from "@mui/material/Tabs";
// import vecteur from "../../../../Assets/Images/vecteur 2.png"
// import img from "../../../../Assets/Images/hommeCard.svg";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import NavBarCominsoon from "../../../../Components/Dashboard/NavBar";
// import Paiement from "../../Paiement/Payment";
import { Elements } from "@stripe/react-stripe-js";
import PunctualCoursePayment from "../../Paiement/ConfirmationCourse/AcceptCourse/PunctualCoursePayment";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionCoursePayment from "../../Paiement/ConfirmationCourse/AcceptCourse/SubscriptionCoursePayment";
import CoursePackPayment from "../../Paiement/ConfirmationCourse/AcceptCourse/CoursePackPayment";
import { PackProposal } from "../../../../Objects/models/PackProposal";
import ClientRepository from "../../../../Repositories/ClientRepository";
import PackProposalRepository from "../../../../Repositories/PackProposalRepository";
import SubscriptionCourseProposalRepository from "../../../../Repositories/SubscriptionCourseProposalRepositorys";
// import Skeleton from "@mui/material/Skeleton";
const stripePromise = loadStripe("pk_test_aTkKFKmFR3oCTGEppE2nxxA7");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#4B6AD8",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #4B6AD8",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0B2775",
  "&.Mui-selected": {
    color: "#4B6AD8",
  },
  "&.Mui-focusVisible": {},
}));

const ProfileTeacher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useState<any>(location.state);
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState<ReviewClient[]>();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [packProposals, setPackProposals] = useState<PackProposal[]>();
console.log(state);

  useEffect(() => {
    (
      async () => {
        const client = await ClientRepository.getCurrentClient();
        if (client.id && state.proposal.coursePack_id) {
          const coursePack = PackProposalRepository.fetchAllCoursePackProposals(
            state.proposal.coursePack_id,
            true
          );
          coursePack.then((res) => {
            setPackProposals(res.members)
          })

        }
      }
    )()
  },)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleNext = () => {
    setValue(1);
    setIsLoading(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpens = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const onRefuseConfirm = async () => {
    if (state.proposal?.totalDailyPrice !== undefined) {
      setLoading(true);
      const client = await ClientRepository.getCurrentClient();
      if (client.id) {
        await SubscriptionCourseProposalRepository.refuseSubCourseProposal(
          client.id,
          state.proposal.id
        );
        handleCloses();
        navigate("/user/prestations");
      }
    } else {
      setLoading(true);
      handleCloses();
      navigate("/user/prestations");
    }
  };
  const loadData = async (page: number) => {
    if (state.proposal?.hourlyPrice !== undefined) {
      const reviewsCollection = await ReviewClientRepository.getReviewsClient(
        page,
        ["punctualCourse.acceptedProposal.teachr.id=" + state.teachr.id]
      );
      setReview(reviewsCollection.members);
    } else if (state.proposal?.totalDailyPrice !== undefined) {
      const reviewsCollection = await ReviewClientRepository.getReviewsClient(
        page,
        ["subscriptionCourse.acceptedProposal.teachr.id=" + state.teachr.id]
      );
      setReview(reviewsCollection.members);
    } else if (state.proposal.fees !== undefined) {
      const reviewsCollection = await ReviewClientRepository.getReviewsClient(
        page,
        ["coursePack.acceptedProposal.teachr.id=" + state.teachr.id]
      );
      setReview(reviewsCollection.members);
    }
  };
  useEffect(() => {
    loadData(1);
  }, []);
  const handleChanges = () => {
    handleNext();
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-screen h-screen xl:overflow-hidden overflow-y-auto font-sans">
      <div className="fixed z-50 w-full bg-white">
        <NavBarCominsoon active={5} />
        <div className="mx-4 md:mx-10 md:mt-10 mt-4">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-lg md:text-base">
                    Profil de {state.teachr.firstName}
                  </p>
                }
              />
              {isLoading ? (
                <StyledTab
                  label={
                    <p className="text-sm font-regular lg:text-lg md:text-base">
                      Paiement
                    </p>
                  }
                />
              ) : (
                ""
              )}
            </StyledTabs>
          </Box>
        </div>
      </div>
      <div className="md:mx-2  md:mt-44 mt-36">
        <Box sx={{ width: "100%", }}>
          {/* <div className="border-2 overflow-y-auto h-[70%]"> */}
          <Box sx={{ px: 3, pt: 3, background: "white", }}>
            <TabPanel value={value} index={0}>
              <div className="md:mt-[50px] mt-10 md:mx-0 /border-2 h-[500px] overflow-y-auto overflow-x-hidden list-teacher">
                <div className="hidden md:flex lg:gap-x-0 gap-x-4">
                  <div className="w-2/3 lg:w-1/2">
                    <div className="w-full p-4 bg-gradient-to-br from-[#7383B140] via-transparent to-[#ffffff] rounded-lg shadow-profile">
                      <div className="flex">
                        <div className="w-1/4 ">
                          <div className="mx-auto translate-y-1 rounded-full shadow-profile xl:w-24 xl:h-24 bg-gradient-to-b from-[#001E73] to-[#4B6AD8]">
                            <p className="xl:text-3xl lg:text-2xl md:text-xl text-white font-bold mx-auto pt-7 h-full text-center ">{state.teachr?.firstName[0] + "." + state.teachr?.lastName[0]}</p>
                          </div>
                        </div>
                        <div className="block w-1/3 my-auto space-y-1">
                          <div className="">
                            <p className="text-sm font-regular text-first lg:text-xl md:text-lg //translate-y-4">
                              {state.teachr.firstName + "." + state.teachr?.lastName}
                            </p>
                          </div>
                          <div className="">
                            {state.teachr?.averageNote === 0 ? (
                              <Rating
                                name="half-rating-read"
                                defaultValue={0}
                                sx={{
                                  color: " #7383B1"
                                }}
                                precision={0.1}
                                readOnly
                              />
                            ) : (
                              <Rating
                                name="half-rating-read"
                                // defaultValue={4}
                                sx={{
                                  color: " #7383B1"
                                }}
                                defaultValue={state.teachr?.averageNote}
                                precision={0.1}
                                readOnly
                              />
                            )}
                          </div>
                          <div className="text-xs lg:text-base md:text-sm">
                            <p>
                              {Functions.renderTeachrHours(
                                state.teachrHours?.totalHours
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="w-5/12 my-auto space-y-2 ">
                          <div className="flex ">
                            <div className="w-1/4 pl-4 ">
                              <img src={lieu} alt="" />
                            </div>
                            <div className="w-3/4 ">
                              <p className="text-sm font-regular truncate lg:text-lg md:text-base text-first">
                                Boulogne
                              </p>
                            </div>
                          </div>
                          <div className="flex " onClick={() => navigate("/user/coming-soon")}>
                            <div className="w-1/4 pl-3 ">
                              <img src={msg} alt="" />
                            </div>
                            <div className="w-3/4 ">
                              <p className="text-sm font-regular truncate lg:text-lg md:text-base text-first">
                                Discuter avec {state.teachr.firstName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full md:px-8 px-4 md:mt-8 mt-2 gap-x-4">
                        <div className="pt-1">
                          <img src={money} alt="" />
                        </div>
                        <div>
                          <p className="text-sm font-regular lg:text-lg md:text-base text-first">
                            Prix par heure après crédit d’impôt :{" "}
                            {state.proposal?.hourlyPrice !== undefined ?
                              (JSON.parse(state.proposal.hourlyPrice!) / 200).toFixed(2) + "€" :
                              state.proposal?.totalDailyPrice !== undefined ?
                                (state.proposal?.totalDailyPrice! / 2).toFixed(2) + "€" :
                                state.proposal.fees !== undefined &&
                                Functions.renderPackProposalPrice
                                  (parseFloat(state.proposal.teachrPrice),
                                    state.course.durationPerDay,
                                    state.course.dates.length,
                                    state.proposal.fees / 100,
                                    state.proposal.URSSAFPrice / 100 / 2).hour.toFixed(2) + " €"
                            }
                          </p>
                          <p className="text-xs lg:text-base md:text-sm text-gray">
                            <span>
                              Prix par heure avant crédit d’impôt :{" "}
                              {state.proposal?.hourlyPrice !== undefined ?
                                (JSON.parse(state.proposal?.hourlyPrice!) / 100).toFixed(2) + "€" :
                                state.proposal?.totalDailyPrice !== undefined ?
                                  JSON.parse(state.proposal?.totalDailyPrice!).toFixed(2) + "€" :
                                  state.proposal?.fees !== undefined &&
                                  Functions.renderPackProposalPrice
                                    (parseFloat(state.proposal?.teachrPrice),
                                      state.course?.durationPerDay,
                                      state.course?.dates.length,
                                      state.proposal?.fees / 100,
                                      state.proposal?.URSSAFPrice / 100).hour.toFixed(2) + " €"
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="w-full mt-4 space-y-2 lg:px-10 ">
                        <div className="w-full ">
                          <p className="text-base font-regular text-first lg:text-xl md:text-lg ">
                            Description :
                          </p>
                          <div className="">
                            <p className="w-full overflow-hidden text-sm text-gray lg:text-lg md:text-base">
                              {state.teachr.description}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 ">
                          {state.proposal.description ? (
                            <p className="w-full overflow-hidden text-base font-regular text-first lg:text-xl md:text-lg">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="text-sm text-gray lg:text-lg md:text-base ">
                            {state.proposal.description}
                          </p>
                        </div>
                      </div>
                      {state?.number === 1 ? 
                      <div className="flex justify-between mx-auto mt-4 space-x-3">
                        <div className="w-2/3 mx-auto pt-2 border-2 rounded-lg cursor-pointer border-[#4d4d4d] active:text-white active:bg-[#4d4d4d] ">
                          <div
                            onClick={handleBack}
                            className="w-full text-xs font-bold text-center uppercase lg:text-base md:text-sm text-[#4d4d4d] active:text-white "
                          >
                            Retour
                          </div>
                        </div>
                      </div>
                        : <div className="flex justify-around w-full md:px-8 mt-10 gap-x-8 text-sm lg:text-lg md:text-base">
                          <div className="w-1/2 pt-1 border-2 rounded-lg cursor-pointer border-[#4d4d4d] active:text-white active:bg-[#4d4d4d]">
                            <div
                              onClick={handleClickOpens}
                              className="w-full text-xs font-bold text-center uppercase lg:text-base md:text-sm text-[#4d4d4d] active:text-white "
                            >
                              Non, Merci
                            </div>
                            <Dialog open={opens} onClose={handleCloses}>
                              <div className="p-3 border-first border-1">
                                <DialogTitle>
                                  <div className="flex justify-between mb-3">
                                    <span className="text-first font-bold uppercase font-sans lg:text-[22px] md:text-lg text-base">
                                      REFUSER CE TEACH'R
                                    </span>
                                    <img
                                      src={close}
                                      alt=""
                                      className="w-6 h-6 md:w-6 md:h-6 //translate-y-2"
                                      onClick={() => handleCloses()}
                                    />
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <p className="text-[#4d4d4d] font-sans lg:text-lg md:text-base text-sm">
                                    Une fois ce teach'r refusé, vous ne pourrez
                                    plus revenir en arrière. Voulez-vous
                                    vraiment continuer ?
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <div className="flex w-full mx-auto mb-4 space-x-6 ">
                                    <button
                                      onClick={handleCloses}
                                      className="w-1/2 px-3 py-1 text-xs font-bold uppercase border-2 rounded-lg lg:text-base md:text-sm text-[#4d4d4d] active:text-white active:bg-first border-[#4d4d4d]"
                                    >
                                      RETOUR
                                    </button>
                                    <button
                                      onClick={() => {
                                        onRefuseConfirm();
                                      }}
                                      className="w-1/2 px-3 py-1 text-xs font-bold uppercase border-2 rounded-lg lg:text-base md:text-sm text-first border-first active:bg-first active:border-first active:text-white"
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          sx={{
                                            color: "blue",
                                          }}
                                          size={20}
                                          thickness={4}
                                          value={100}
                                        />
                                      ) : (
                                        "continuer"
                                      )}
                                    </button>
                                  </div>
                                </DialogActions>
                              </div>
                            </Dialog>
                          </div>
                          <div
                            onClick={() => {
                              handleChanges();
                            }}
                            className="w-1/2 p-1 text-xs font-bold text-center uppercase border-2 rounded-lg cursor-pointer text-first lg:text-base md:text-sm border-first active:bg-first active:border-first active:text-white"
                          >
                            Accepter
                          </div>
                        </div>}
                    </div>
                  </div>
                  <div className="w-1/3 lg:w-1/2  mt-2">
                    {state.teachr?.averageNote ?
                      <div className="flex justify-around pt-1 mx-auto mt-4 space-x-4 font-regular bg-white border-2 xl:w-2/5 lg:w-1/2 -translate-y-14 shadow-avis rounded-2xl text-first ">
                        <div className="w-1/4 text-base text-center xl:text-xl md:text-lg">
                          <p>AVIS</p>
                        </div>
                        <div className="w-1/2 text-base text-center xl:text-xl md:text-lg ">
                          <Rating
                            name="half-rating-read"
                            sx={{
                              color: " #7383B1"
                            }}
                            defaultValue={state.teachr?.averageNote}
                            precision={0.1}
                            readOnly
                          />
                        </div>
                        <div className="w-1/4 text-base text-center lg:text-xl md:text-lg">
                          <p>({review?.length}) </p>
                        </div>
                      </div>
                      : ""}
                    <div className="h-screen -mt-8 space-y-8 overflow-x-hidden lg:-translate-y-16 lg:mt-8 list-teacher">
                      {review?.map((comment: ReviewClient) => (
                        <div className="">
                          <div className="p-2 mx-auto lg:w-1/2 rounded-lg shadow-lg ">
                            <div className="flex justify-between">
                              <div className="w-1/4 ">
                                <div className="mx-auto /translate-y-1 rounded-full shadow-profile xl:w-16 xl:h-16 lg:h-12 lg:w-12 bg-gradient-to-b from-[#001E73] to-[#4B6AD8]">
                                  <p className="xl:text-2xl lg:text-xl md:text-lg text-white font-bold mx-auto pt-4 h-full text-center ">{comment.owner?.firstName[0]}</p>
                                </div>
                              </div>
                              <div className="w-1/2 pl-2 lg:space-y-0">
                                <div className="">
                                  <p className="text-xs font-regular text-first lg:text-base md:text-sm">
                                    {comment.owner?.firstName}
                                  </p>
                                </div>
                                <div className="w-1/2 //mx-auto">
                                  <Rating
                                    name="half-rating-read"
                                    sx={{
                                      color: " #7383B1"
                                    }}
                                    defaultValue={comment.note}
                                    // defaultValue={5}
                                    precision={0.1}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-2 lg:mx-4">
                              <p className="text-sm font-regular text-first lg:text-lg md:text-base">
                                {comment.comment ? "Commentaire :" : ""}
                              </p>
                              <p className="text-xs text-gray lg:text-base md:text-sm">
                                {comment.comment}                           </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className="w-full lg:w-1/2">
                    <div className="w-full p-4 bg-bg-color-tuile rounded-xl shadow-profile">
                      <div className="justify-around ">
                        <div className="flex justify-around  ">
                          <div className="w-20 h-20  translate-y-1 rounded-full shadow-profile bg-gradient-to-b from-[#001E73] to-[#4B6AD8]">
                            <p className="xl:text-3xl lg:text-2xl md:text-xl text-white font-bold mx-auto pt-7 h-full text-center ">{state.teachr?.firstName[0] + "." + state.teachr?.lastName[0]}</p>
                          </div>
                          <div className="block">
                            <p className="text-lg font-regular text-first ">
                              {state.teachr.firstName + " " + state.teachr?.lastName}
                            </p>
                            <div className="">
                              <div className="text-lg">
                                {state.teachr?.averageNote === 0 ? (
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={0}
                                    sx={{
                                      color: " #7383B1"
                                    }}
                                    precision={0.1}
                                    readOnly
                                  />
                                ) : (
                                  <Rating
                                    name="half-rating-read"
                                    // defaultValue={4}
                                    sx={{
                                      color: " #7383B1"
                                    }}
                                    defaultValue={state.teachr?.averageNote}
                                    precision={0.1}
                                    readOnly
                                  />
                                )}
                              </div>
                              <p>
                                {Functions.renderTeachrHours(
                                  state.teachrHours?.totalHours
                                )}
                              </p>
                            </div>
                            {/* <div className="flex ">
                                <div className="pr-2 w-5">
                                  <img src={lieu} alt="" className="" />
                                </div>
                                <div className="">
                                  <p className="text-sm font-regular translate-y-1 text-first">
                                    Boulogne
                                  </p>
                                </div>
                              </div> */}
                          </div>
                        </div>
                        <div className="mt-3 ">
                          <div className="flex w-full ">
                            <div className="/w-1/3 pr-4 /pl-8  ">
                              <img src={money} alt="" className="w-8" />
                            </div>
                            <div className="/w-2/3  ">
                              <div>
                                <p className="text-sm font-regular lg:text-lg md:text-base text-first">
                                  Prix par heure après crédit d’impôt :{" "}
                                  {state.proposal?.hourlyPrice !== undefined ?
                                    (JSON.parse(state.proposal.hourlyPrice!) / 200).toFixed(2) + "€" :
                                    (state.proposal?.totalDailyPrice! / 2).toFixed(2) + "€"
                                  }
                                </p>
                                <p className="text-xs lg:text-base md:text-sm text-gray">
                                  <span>
                                    Prix par heure avant crédit d’impôt :{" "}
                                    {state.proposal?.hourlyPrice !== undefined ?
                                      (JSON.parse(state.proposal?.hourlyPrice!) / 100).toFixed(2) + "€" :
                                      state.proposal?.totalDailyPrice !== undefined ?
                                        JSON.parse(state.proposal?.totalDailyPrice!).toFixed(2) + "€" :
                                        state.proposal.fees !== undefined &&
                                        Functions.renderPackProposalPrice
                                          (parseFloat(state.proposal.teachrPrice),
                                            state.course.durationPerDay,
                                            state.course.dates.length,
                                            state.proposal.fees / 100,
                                            state.proposal.URSSAFPrice / 100).hour.toFixed(2) + " €"
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-3">
                        <div onClick={() => navigate("/user/coming-soon")} className="w-1/2 pt-1 text-sm rounded-lg text-first shadow-shadow-button">
                          <p className="w-full text-center">Discuter</p>
                          <div className="pt-1 mx-auto w-8">
                            <img src={msg} alt="" className="" />
                          </div>
                        </div>
                        <div className="w-1/2 pt-1 text-sm rounded-lg text-first shadow-shadow-button">
                          <div className="flex font-sans md:hidden">
                            <span
                              className="w-full text-first"
                              onClick={handleClickOpen}
                            >
                              <p className="text-center">Avis</p>
                              <div className="w-8 mx-auto">
                                <img
                                  src={starOnly}
                                  alt=""
                                  className="w-10 px-auto"
                                />
                              </div>
                            </span>
                            {state.teachr?.averageNote ?
                              <Dialog open={open} onClose={handleClose}>
                                <div className="w-[295px] overflow-x-hidden">
                                  <DialogTitle>
                                    <div className="h-8 z-40  flex fixed bg-white w-[258px]">
                                      <div className="w-4/5">
                                        <span className="text-first font-regular md:text-[22px] text-sm uppercase font-sans">
                                          Avis
                                        </span>
                                      </div>
                                      <div className="w-1/5 pt-1 pl-4 ">
                                        <img
                                          src={close}
                                          alt=""
                                          className="w-5"
                                          onClick={handleClose}
                                        />
                                      </div>
                                    </div>
                                  </DialogTitle>
                                  <DialogContent>
                                    <div className="">
                                      <div className="flex justify-around w-full py-2 mx-auto mt-6 font-regular shadow-avis rounded-2xl text-first">
                                        <div className="text-base translate-y-1 ">
                                          <p>AVIS</p>
                                        </div>
                                        <div className="translate-y-  text-[15px] ">
                                          <Rating
                                            name="half-rating-read"
                                            sx={{
                                              color: " #7383B1"
                                            }}
                                            defaultValue={state.teachr?.averageNote}
                                            precision={0.1}
                                            readOnly
                                          />
                                        </div>
                                        <div className="text-base translate-y-1 ">
                                          <p>({review?.length})</p>
                                        </div>
                                      </div>
                                      <div className="mt-4 space-y-4 overflow-x-hidden list-teacher">
                                        {review?.map(
                                          (comment: ReviewClient) => (
                                            <div className="">
                                              <div className="w-full p-2 mx-auto rounded-xl shadow-avis ">
                                                <div className="flex justify-between ">
                                                  <div className="w-1/2 ">
                                                    <div className="mx-auto /translate-y-1 rounded-full shadow-profile w-16 h-16 bg-gradient-to-b from-[#001E73] to-[#4B6AD8]">
                                                      <p className="xl:text-2xl lg:text-xl md:text-lg text-white font-bold mx-auto pt-4 h-full text-center ">{comment.owner?.firstName[0] + "." + state.teachr?.lastName[0]}</p>
                                                    </div>
                                                  </div>
                                                  <div className="w-1/2 //mx-auto">
                                                    <div>
                                                      <Rating
                                                        name="half-rating-read"
                                                        defaultValue={
                                                          comment.owner
                                                            ?.averageNote
                                                        }
                                                        sx={{
                                                          color: " #7383B1"
                                                        }}
                                                        precision={0.1}
                                                        readOnly
                                                      />
                                                    </div>
                                                    <div className="">
                                                      <p className="text-base font-regular text-first">
                                                        {
                                                          comment.owner
                                                            ?.firstName
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="">
                                                  <p className="text-base font-regular text-first">
                                                    {comment.comment
                                                      ? "Commentaire :"
                                                      : ""}{" "}
                                                  </p>
                                                  <p className="text-sm text-gray">
                                                    {comment.comment}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </DialogContent>
                                </div>
                              </Dialog>
                              : ''}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <p className="text-base font-regular text-first">
                          Description :
                        </p>
                        <p className="w-full overflow-hidden text-sm text-gray">
                          {state.teachr.description}
                        </p>
                      </div>
                      {state.proposal.description ? (
                        <div className="mt-2">
                          {state.proposal.description ? (
                            <p className="text-base font-regular text-first">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="w-full overflow-hidden text-sm text-gray">
                            {state.proposal.description}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {state?.number === 1 ?
                        <div className="flex justify-between mx-auto mt-4 space-x-3">
                          <div className="w-2/3 mx-auto pt-2 border-2 rounded-lg cursor-pointer border-[#4d4d4d] active:text-white active:bg-[#4d4d4d] ">
                            <div
                              onClick={handleBack}
                              className="w-full text-xs font-bold text-center uppercase lg:text-base md:text-sm text-[#4d4d4d] active:text-white "
                            >
                              Retour
                            </div>
                          </div>
                        </div>
                        : <div className="flex justify-between mx-auto mt-4 space-x-3">
                          <div className="w-1/2 pt-2 border-2 rounded-lg cursor-pointer border-[#4d4d4d] active:text-white active:bg-[#4d4d4d] ">
                            <div
                              onClick={handleClickOpens}
                              className="w-full text-xs font-bold text-center uppercase lg:text-base md:text-sm text-[#4d4d4d] active:text-white "
                            >
                              Non, Merci
                            </div>
                            <Dialog open={opens} onClose={handleCloses}>
                              <div className="p-3 border-first border-1">
                                <DialogTitle>
                                  <div className="flex justify-between mb-3">
                                    <span className="text-first font-bold uppercase font-sans lg:text-[22px] md:text-lg text-base">
                                      REFUSER CE TEACH'R
                                    </span>
                                    <img
                                      src={close}
                                      alt=""
                                      className="w-6 h-6 md:w-6 md:h-6 //translate-y-2"
                                      onClick={() => handleCloses()}
                                    />
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <p className="text-[#4d4d4d] font-sans lg:text-lg md:text-base text-sm">
                                    Une fois ce teach'r refusé, vous ne pourrez
                                    plus revenir en arrière. Voulez-vous
                                    vraiment continuer ?
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <div className="flex w-full mx-auto mb-4 space-x-6 ">
                                    <button
                                      onClick={handleCloses}
                                      className="w-1/2 px-3 py-1 text-xs font-bold uppercase border-2 rounded-full lg:text-base md:text-sm text-[#4d4d4d] active:text-white active:bg-first border-[#4d4d4d]"
                                    >
                                      RETOUR
                                    </button>
                                    <button
                                      onClick={() => {
                                        onRefuseConfirm();
                                      }}
                                      className="w-1/2 px-3 py-1 text-xs font-bold uppercase border-2 rounded-full lg:text-base md:text-sm text-first border-first active:bg-first active:border-first active:text-white"
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          sx={{
                                            color: "blue",
                                          }}
                                          size={20}
                                          thickness={4}
                                          value={100}
                                        />
                                      ) : (
                                        "continuer"
                                      )}
                                    </button>
                                  </div>
                                </DialogActions>
                              </div>
                            </Dialog>
                          </div>
                          <button
                            onClick={() => {
                              handleChanges();
                              // handleChangeProfile();
                            }}
                            className="w-1/2 p-2 px-3 text-xs font-bold uppercase border-2 rounded-lg cursor-pointer lg:text-base md:text-sm text-first border-first active:bg-first active:border-first active:text-white"
                          >
                            Accepter
                          </button>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Box>
          {/* </div> */}
          <TabPanel value={value} index={1}>
            {state.proposal?.hourlyPrice !== undefined ?
              <Box sx={{ pt: 3 }}>
                <Elements stripe={stripePromise}>
                  <PunctualCoursePayment state={state} />
                </Elements>
              </Box>
              :
              state.proposal?.totalDailyPrice !== undefined ?
                <Box sx={{ pt: 3 }}>
                  <Elements stripe={stripePromise}>
                    <SubscriptionCoursePayment state={state} />
                  </Elements>
                </Box>
                :
                state.proposal.fees !== undefined &&
                packProposals?.map((packProposals, key) => (
                  <Box sx={{ pt: 3 }}>
                    <Elements stripe={stripePromise}>
                      <CoursePackPayment state={state} packProposals={packProposals} />
                    </Elements>
                  </Box>
                ))
            }
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default ProfileTeacher;
