import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppPropsInterface from "../Objects/interfaces/AppPropsInterface";
import FormLayout from "../Components/Prestations/Index";
import SignUpClient from "../Screens/Client/Auth/SignUp";
import Welcome from "../Screens/Client/Auth/Welcome";
import InputSearch from "../Components/Utilitis/InputDate";
import LastStep from "../Components/Prestations/PrestationStep/Last";
import Register from "../Screens/Teacher/Auth/Register/Index";
import Login from "../Screens/Teacher/Auth/Login";
import ComingSoon from "../Screens/Teacher/ComingSoon";
import DahboardIndex from "../Screens/Teacher/Dashboard/Index";
import Erreur from "../Components/ComponentsUtils/Erreur404";
import ComingSoons from "../Screens/Client/ComingSoon";
import NewHome from "../Components/Home";
import TabPanel from "../Screens/Client/Courses/Index";
import ProfileTeacherPunctualCourse from "../Screens/Client/Courses/ProfileTeachr/ProfileTeachr";
import CompleteProfile from "../Screens/Client/Auth/CompleteProfile";
import CourseSelect from "../Screens/Teacher/Dashboard/Course/details/Index";
import ProfileIndex from "../Screens/Teacher/Dashboard/Account/Index";
import Confirm from "../Screens/Teacher/Dashboard/Course/details/Confirm";
import CandidacyIndex from "../Screens/Teacher/Dashboard/Candidacy/Index";
import StudentIndex from "../Screens/Teacher/Dashboard/Candidacy/Child/Index";
import ConfirmationStep from "../Screens/Client/Paiement/ConfirmationCourse/AcceptCourse/ConfirmationStep";
import TimerClient from "../Components/ComponentsUtils/TimerClient";
import DashboardIndex from "../Screens/Teacher/Admin/Setup/Index"
import AiciHome from "../Screens/Teacher/Admin/Index"
import Index from "../Screens/Client/Aici/Index";
import AddStudentPage from "../Screens/Teacher/Admin/Dashboard/AddStudentPage";
import DashboardIndexCourse from "../Screens/Teacher/Admin/Dashboard/Index"
import IndexCourseType from "../Screens/Teacher/Admin/Dashboard/Courses";
import DeclareCourse from "../Screens/Teacher/Admin/Dashboard/DeclareCourse";
import PresentationIndex from "../Screens/Teacher/Admin/Presentation/Index";
import Home from "../Screens/Teacher/Guide/Home";
import MonaBanq from "../Screens/MonaBanq";
import NovaGuide from "../Screens/Teacher/Dashboard/Account/StatusAE/Nova/tuto/Index";
import Simulateur from '../Screens/Teacher/AfterVideo/Index'
import SimulateurV2 from '../Screens/Teacher/Simulateur/Index'
import SimulateurV2Simulate from '../Screens/Teacher/Simulateur/simulate/Index'
import SimulateurV2Fonctionnement from '../Screens/Teacher/Simulateur/Fonction/Index'
import MeetIndex from '../Screens/Teacher/AfterVideo/Meet/Index'
import ProcurationIndex from '../Screens/Teacher/Procuration/Index'
import ProcurationSapIndex from '../Screens/Teacher/Procuration/Sap/index'


export default class RootNavigator extends React.Component<AppPropsInterface> {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NewHome />} />
          <Route path="*" element={<Erreur />} />
          <Route path="/client/complete-infos/:key" element={<Index />} />
          <Route path="/client/complete-infos" element={<Index />} />
          <Route path="/coming-soon" element={<ComingSoon />} />

          <Route path="user">
            <Route path="/user/signup" element={<SignUpClient {...this.props} />} />
            <Route path="/user/welcome" element={<Welcome />} />
            <Route path="/user/completeprofile" element={<CompleteProfile {...this.props} />} />
            <Route path="/user/inputsearch" element={<InputSearch />} />
            <Route path="/user/laststep" element={<LastStep />} />
            <Route path="/user/prestations" element={<TabPanel />} />
            <Route path="/user/profile" element={<ProfileTeacherPunctualCourse />} />
            <Route path="/user/declaration" element={<FormLayout />} />
            <Route path="/user/coming-soon" element={<ComingSoons />} />
            <Route path="/user/confirm" element={<ConfirmationStep />} />
            <Route path="/user/timer" element={<TimerClient />} />
          </Route>
          <Route path="declarons">
            <Route path="/declarons/tuto-ae" element={<Home />} />
            <Route path="/declarons/auto-entrepreneur/nova/guide" element={<NovaGuide />} />
            <Route path="/declarons/login" element={<Login />} />
            <Route path="/declarons/register" element={<Register />} />
            <Route path="/declarons/prestations" element={<DahboardIndex />} />
            <Route path="/declarons/prestations/postuler/" element={<CourseSelect />} />
            <Route path="/declarons/profile" element={<ProfileIndex />} />
            <Route path="/declarons/prestations/postuler/confirm" element={<Confirm />} />
            <Route path="/declarons/candidatures" element={<CandidacyIndex />} />
            <Route path="/declarons/candidatures/client" element={<StudentIndex />} />
            <Route path="/declarons/avance-immediate" element={<Simulateur />} />
            <Route path="/declarons/simulateur" element={<SimulateurV2 />} />
            <Route path="/declarons/simulate" element={<SimulateurV2Simulate />} />
            <Route path="/declarons/fonctionnement" element={<SimulateurV2Fonctionnement />} />
            <Route path="/declarons/avance-immediate/en-profiter" element={<MeetIndex />} />
            <Route path="/declarons/procuration/ae" element={<ProcurationIndex />} />
            <Route path="/declarons/procuration/sap" element={<ProcurationSapIndex />} />
            <Route path="dashboard">
              <Route path="/declarons/dashboard" element={<AiciHome />} />
              <Route path="/declarons/dashboard/presentation" element={<PresentationIndex />} />
              <Route path="/declarons/dashboard/setup" element={<DashboardIndex />} />
              <Route path="/declarons/dashboard" element={<DashboardIndexCourse />} />
              <Route path="/declarons/dashboard/declare" element={<DeclareCourse />} />
              <Route path="/declarons/dashboard/prestations" element={<IndexCourseType />} />
              <Route path="/declarons/dashboard/client" element={<AddStudentPage />} />
            </Route>
          </Route>
          <Route path="Partenaires/monabanq" element={<MonaBanq />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
