import moment from 'moment';
import { useEffect, useState } from 'react';
import SubjectRepository from '../../../../../../../Repositories/SubjectRepository';
import { Subject } from '../../../../../../../Objects/models/Subject';
import CourseProposalRepository from '../../../../../../../Repositories/CourseProposalRepository';
import ChildRepository from '../../../../../../../Repositories/ChildRepository';
import Child from '../../../../../../../Objects/models/Child';
import Functions from '../../../../../../../Helpers/Functions';
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import UsrssafStatusService from "../../../../../../../Objects/models/UrssafStatusService";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';

const LightTooltipNoError = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: '#4d4d4d',
        boxShadow: '7px 5px 20px #FFE0D9',
        border: "1px solid #FFE0D9",
        fontSize: 14,
        fontFamily: "Nunito",
    },
}));
const LightTooltipPaye = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: '#4d4d4d',
        boxShadow: '7px 5px 20px  #E8FFEF',
        border: "1px solid  #E8FFEF",
        fontSize: 14,
        fontFamily: "Nunito",
    },
}));
const LightTooltipCours = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: '#4d4d4d',
        boxShadow: '7px 5px 20px  #F4F4F8',
        border: "1px solid  #F4F4F8",
        fontSize: 14,
        fontFamily: "Nunito",
    },
}));
const ComponentBodyTablePunctualCoursePast = (props: any) => {
    const { punctual } = props
    const [subject, setSubject] = useState<Subject>()
    const [status, setStatus] = useState<UsrssafStatusService>()
    const [proposal, setProposal] = useState<any>()
    const [child, setChild] = useState<Child>()
    const [priceT, setPriceT] = useState<any>()
    const formattedTime = moment(punctual.startDatetime).format('HH:mm')
    // const formattedEndTime = moment(punctual.endDatetime).format('HH:mm')
    const modifiedTime = moment(formattedTime, 'HH:mm').subtract(2, 'hours');
    // const modifiedEndTime = moment(formattedEndTime, 'HH:mm').subtract(2, 'hours');
    useEffect(() => {
        SubjectRepository.fetchSubjectById(punctual.subjectAsked_id).then((subject) => {
            setSubject(subject)
        })
        CourseProposalRepository.fetchCourseProposalById(punctual.acceptedProposal_id).then((proposals) => {
            setProposal(proposals)

        })
        ChildRepository.fetchChildByIdForTeachr(punctual.forChild_id, punctual.directAskedTeachr_id).then((child) => {
            setChild(child)
        })
        PunctualCourseRepository.fetchChargePunctualCourseById(punctual.charge_id).then((res) => {
            setPriceT(res.amount);
        })
        if (punctual?.urssafServiceStatus_id !== null) {
            PunctualCourseRepository.getUsrssafStatusServiceIdById(punctual?.urssafServiceStatus_id).then((status) => {

                setStatus(status)
            }).catch((err) => {
                console.warn(err);

            })
        }
    }, [punctual.acceptedProposal_id, punctual.charge_id, punctual.directAskedTeachr_id, punctual.forChild_id, punctual.subjectAsked_id, punctual?.urssafServiceStatus_id])

    return (
        <>
            <tbody className="text-[#4D4D4D] font-regular">
                {punctual?.urssafServiceStatus_id === null ? "" :
                    <tr className="border-b-[0.5px] border-[#4B6AD86B] ">
                        <td className="py-2 px-3 /text-center hidden lg:block">{moment(punctual.startDatetime).format('DD-MM-YYYY')}</td>
                        <td className="py-2 px-3 /text-center lg:hidden">{moment(punctual.startDatetime).format('DD-MM-YY')}</td>
                        {/* <td className="py-2 px-3 /text-center">{modifiedTime.format('HH:mm')} */}
                        {/* </td> */}
                        <td className="py-2 px-3 /text-center">{child?.firstName}</td>
                        <td className="py-2 px-3 /text-center">{subject?.fr_FR}</td>
                        <td className="py-2 px- text-center //flex justify-around">
                            <div>
                                {(((proposal?.teachrPrice + (proposal?.teachrPrice * 10 / 90) + 0) * (punctual.duration / 2)) / 2).toFixed(2) + "€"}
                            </div>
                        </td>
                        <td className="py-2 px-3 text-center md:block hidden"><div className="border-b-2 w-5 mx-auto translate-y-3" ></div></td>
                        {/* <td className="py-2 text-center md:block hidden"> <div className="border-b-2 w-[10px]"></div></td> */}
                        <td className="py-2 px- text-center //flex justify-around">
                            <div>
                                {priceT === undefined ? "chargement..." : (proposal?.teachrPrice * (punctual.duration / 2)).toFixed(2) + "€"}
                            </div>
                        </td>
                        <td className="py-2 px-3 /text-center text-center //text-center">{Functions.renderDuration(punctual.duration)}</td>
                        <td className="py-2 px-2 text-center">
                            {punctual?.urssafServiceStatus_id === null ?
                                <div className="text-center rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                    <LightTooltipCours title="Votre cours est prévu">
                                        <div className="text-center w-full">  Prestation prévue</div>
                                    </LightTooltipCours>
                                </div>
                                : status?.status === "INTEGRATED" || status?.status === "WAITING_FOR_VALIDATION" || status?.status === "10" ?
                                    <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                        <LightTooltipCours title={status?.status === "INTEGRATED" ? "La demande de paiement a été reçue et a passé les différents contrôle de cohérence" : "Le particulier a été prévenu que la demande de paiement est à valider/rejeter par lui"}>
                                            <div className="text-center w-full ">En cours</div>
                                        </LightTooltipCours>
                                    </div>
                                    : status?.status === 'VALIDATED' ?
                                        <div className="text-center w-full rounded-full cursor-pointer bg-[#E8FFEF] text-[#3D8E67] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                            <LightTooltipPaye title={status?.statusDescription}>
                                                <div className="text-center w-full">Validé</div>
                                            </LightTooltipPaye>
                                        </div>
                                        : status?.status === 'PAID' ?
                                            <div className="text-center w-full rounded-full cursor-pointer bg-[#E8FFEF] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                <LightTooltipPaye title="Le virement de paiement de la demande de paiement au prestataire est lancé ">
                                                    <div className="text-center w-full">Payé</div>
                                                </LightTooltipPaye>
                                            </div>
                                            : status?.status === 'COLLECTED' ?
                                                <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                    <LightTooltipPaye title="La demande de paiement a été payée avec succès par le particulier">
                                                        <div className="text-center w-full">Payé</div>
                                                    </LightTooltipPaye>
                                                </div>
                                                : status?.status === 'COVERED' ?
                                                    <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                        <LightTooltipPaye title="La demande de paiement en impayé a été recouvrée par le prestataire">
                                                            <div className="text-center w-full">Récouvré</div>
                                                        </LightTooltipPaye>
                                                    </div>
                                                    : status?.status === 'CANCELED' || status?.status === 'CANCELED 111' || status?.status === 'CANCELED 112' ?
                                                        <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                            <LightTooltipNoError title={status?.status === 'CANCELED' ? "La demande de paiement a été annulée par le particulier" : status?.status === 'CANCELED 111' ? "La demande de paiement a été annulée après impayé du particulier" : 'La demande de paiement a été annulée après recouvrement par le prestataire'}>
                                                                <div className="text-center w-full">Annulé</div>
                                                            </LightTooltipNoError>
                                                        </div>
                                                        : status?.status === 'REFUSED' || status?.status === 'REFUSAL_OF_DIRECT_DEBIT' ?
                                                            <div className="text-center w-full rounded-full cursor-pointer bg-[#FFE0D9] text-[#FF724F] ] font-regular  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                                <LightTooltipNoError title={status?.status === 'REFUSED' ? "La demande de paiement est refusée par le particulier : il a renseigné son rejet et un code motif associé" : "Le prélèvement associé à la demande de paiement est revenu en erreur . La demande de paiement est en impayée"}>

                                                                    <div className="text-center w-full">Refusé</div>
                                                                </LightTooltipNoError>
                                                            </div>
                                                            :
                                                            <div className=" /text-center w-full rounded-full cursor-pointer bg-[#FFE0D9] text-[#FF724F] font-regular  //mx-auto px-[6px] py-[6px] //w-1/2 flex space-x-2">
                                                                <LightTooltipNoError title={status?.error[0].message}>
                                                                    <div className="text-center w-full">
                                                                        Erreur
                                                                    </div>
                                                                </LightTooltipNoError>
                                                            </div>}
                        </td>
                    </tr>
                }
            </tbody>
        </>
    );
};

export default ComponentBodyTablePunctualCoursePast;