import NavBar from "./ComponentsUtils/NavBar";
import help1 from "../Assets/Images/help1.svg";
import help2 from "../Assets/Images/help2.svg";
import help3 from "../Assets/Images/help3.svg";
import help4 from "../Assets/Images/help4.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getJWTToken } from "../Services/FirebaseService";
import ApiService from "../Services/ApiService";
import TeachrRepository from "../Repositories/TeachrRepository";
import ClientRepository from "../Repositories/ClientRepository";
import InfoAcceuil from "./ComponentsUtils/InfoAcceuil";

const NewHome = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (user) {
      (async () => {
        const jwtToken = await getJWTToken(user)
        ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
        TeachrRepository.getCurrentTeachr().then(() => navigate("/declarons/prestations")).catch(() => {
          ApiService.disableAuth()
          ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
          ClientRepository.getCurrentClient().then((response) => {
            navigate("/user/prestations")
          }).catch(() => {
            ApiService.disableAuth()
            navigate("/")
          })

        })
      }
      )()
    }
  }, [navigate, user])

  return (
    <div className="md:overflow-hidden overflow-scroll h-screen  bg-[#F9FAFF]">
      <NavBar />
      <div className="justify-around  mx-auto overflow-hidden md:flex md:w-full ">
        <div className=" md:w-1/2  font-sans">
          <div className=" w-4/5 mx-auto xl:mt-16 lg:mt-10 md:mt-14 mt-10">
            <p className="text-first xl:text-4xl  lg:text-2xl md:text-xl text-lg font-regular md:leading-[41.51px]">
              Besoin d'une aide à domicile ? <br></br>
              D'un service de bricolage ou de jardinage ?
            </p>
            <br />
            <br />
            <p className="text-first font-medium xl:text-3xl lg:text-xl md:text-lg md:leading-[41.51px]">
              Faites votre demande et trouvez votre prestataire parfait en seulement 3 min ⏱
            </p>
          </div>
          <div
            // onClick={() => navigate("/user/declaration")}
            className="w-4/5 py-2 mx-auto xl:mt-20 mt-10  text-sm font-regular text-center text-white uppercase rounded-xl cursor-pointer lg:text-lg md:text-md bg-first"
          >
            <InfoAcceuil title={" C'est parti !"}/>
          </div>
          <div className="w-4/5 mx-auto mt-10 flex gap-x-2 ">
            <p className="text-sm lg:text-lg md:text-md text-[#4d4d4d] font-regular">Vous avez déjà un compte ?
             {/* <Link to='/declarons/login' className="text-first font-regular">Connectez-vous </Link>  */}
             </p>
             <p className="text-first font-regular text-sm lg:text-lg md:text-md font-regular cursor-pointer"><InfoAcceuil title={"Connectez-vous "}/> </p> 
          </div>
        </div>
        <div className=" md:w-1/2 hidden md:block ">
          <div className="grid grid-cols-2 mt-3 ">
            <div className=" xl:w-[351px] lg:w-[250px] lg:h-[250px] xl:h-[351px] //mt-[124px]">
              <img src={help1} alt="" className="" />
            </div>
            <div className="xl:w-[214px] lg:w-[150px] lg:h-[150px] xl:h-[214px] ">
              <img src={help2} alt="" className="" />
            </div>
            <div className="xl:w-[260px] lg:w-[150px] lg:h-[150px] xl:h-[260px]  ">
              <img src={help4} alt="" className="" />
            </div>
            <div className=" xl:w-[363px] lg:w-[250px] lg:h-[250px] xl:h-[363px] lg:-translate-y-20 lg:-translate-x-20 //mt-[378px] ">
              <img src={help3} alt="" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHome;
