import { useEffect, useState } from "react";
import StepperStore from "../../../../../../../LocalForage/StepperStore";
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import UpdateChoicePunctual from "./EditComponentPunctual/EditChoicePunctual";
import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';

const EditPunctualChoice = (props: any) => {
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const [value, setValue] = useState<any>();
    const [load, setLoad] = useState(false);
    const { backStep } = props


    useEffect(() => {
        setLoad(true)
        StepperStore.getItem("course_id").then((value) => {
            // setLoad(true)
            if (value !== null) {
                setValue(value);
                setLoad(false)
            }
        })
    }, [load])

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
                    (course) => {
                        setCourse(course);
                    }
                );
            }
        });
    }, []);

    return (
        <>
            {
                course === undefined ?
                    <div className="h-[80%] mt-4">
                        <Skeleton variant="rectangular" width="100%" height="100%"  />
                    </div>
                    :
                    <div className="shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent md:mt-4 overflow-y-auto list-teacher md:px-20 py-2  h-[80%] px-4 py-4 bg-white rounded-xl">
                        <div className="mb-6">
                            <h1 className="lg:text-2xl md:text-lg text-lg font-bold text-first">
                                Que souhaitez-vous modifier ?
                            </h1>
                        </div>
                        {
                            course?.map((option: PunctualCourseDashboard) => (
                                <div>
                                    {parseInt(value) === option.id && (
                                        <div className="relative">
                                            <UpdateChoicePunctual backStep={backStep} option={option} key={option.id} id={option.id} />
                                        </div>
                                    )}
                                </div>
                            ))
                        }
                    </div>
            }
        </>
    );
};

export default EditPunctualChoice;
