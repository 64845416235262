import React, { useEffect, useState } from 'react';
import NavBar from '../../../../Components/ComponentsUtils/NavBar';
import { Button } from '@mui/material';
import simulateurBg from '../../../../Assets/Images/simulateur_bg.svg'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import First from './First';
import Dashboard from './Dashboard/Index'
import Second from './Second';
import { useNavigate } from 'react-router-dom';
import InterestedTeachrRepository from '../../../../Repositories/InterestedTeachrRepository';
import { useSelector } from 'react-redux';
import Simulator from '../../../../Objects/models/Simulator';

interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
}
interface SimulatorRequest {
    isAcre: boolean,
    hourlyPrice: number,
    weekHours: number,
    external_income: number,
    childs: number,
    last_income: number,
    adult: number,
    education: number
}
const Index = () => {
    const navigate = useNavigate()
    const [response, setResponse] = useState<Simulator>()
    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    useEffect(() => {
       if (!simulatorData) {
         navigate('/declarons/avance-immediate')
       }

    }, [simulatorData])
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#F2F5FB',
            borderRadius: '999px',
            // boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.13)'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '999px',
            backgroundColor: '#219CFF',
        },
    }));
    const [step, setStep] = useState(1)
    const [validate, setValidate] = useState(false)
    const nexStepper = () => {
        return step / 2 * 100
    }
    const nextStep = () => {
        if (validate) {
            if (step === 2 && simulatorData) {
                const object: SimulatorRequest = {
                    adult: simulatorData.adults,
                    childs: simulatorData.childs,
                    external_income: simulatorData.external_income,
                    last_income: simulatorData.last_income,
                    hourlyPrice: simulatorData.hourlyPrice,
                    weekHours: simulatorData.weekHours,
                    isAcre: simulatorData.isAcre,
                    education: simulatorData.education
                }
                InterestedTeachrRepository.simulate(object).then((response) => {
                    setResponse(response)
                })
            }
            setStep(step + 1)
        }
    }
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1)
        } else if (step === 1) {
            navigate('/declarons/avance-immediate')
        }
    }
    const getValidate = (value: boolean) => {
        setValidate(value)
    }
    const renderTitle = (): string => {
        let title = `${step}/2 Votre foyer fiscal :`
        switch (step) {
            case 1:
                title = `${step}/2 Votre foyer fiscal :`
                break;
            case 2:
                title = `${step}/2 Votre situation financière :`
                break;

            default:
                title = `${step}/2 Votre foyer fisca :`
                break;
        }
        return title
    }
    const renderScreen = () => {
        switch (step) {
            case 1:

                return (
                    <First callBack={getValidate} />
                )
            case 2:
                return (
                    <Second callBack={getValidate} />
                )
            default:
                return (
                    <First callBack={getValidate} />
                )
        }
    }
    return (
        <div className='h-screen lg:pb-20  bg-white'>
            <header className='lg:bg-white bg-[rgba(239,246,255,1)]'>
                <NavBar />
            </header>
            <main className='bg-bottom bg-no-repeat bg-cover 2xl:h-full' style={{ backgroundImage: `url(${simulateurBg})` }}>
                <div className='flex flex-col lg:justify-around gap-y-4 h-full  lg:px-14 px-4 md:px-8 py-8  bg-white lg:bg-transparent' >
                    <div className='flex flex-col gap-y-2'>
                        <h1 className='font-[900] text-first lg:text-[32px] uppercase text-xl'>Quel serait votre nouveau revenu net par heure de cours ?</h1>
                    </div>
                    {step <= 2 ?
                        <div className='flex flex-col justify-center h-full'>
                            <div className='flex flex-col lg:px-10 py-8 bg-white rounded-[23px] md:w-1/3 border-radio border-[3px] gap-y-8 px-4 md:px-8'>
                                <div className="w-full">
                                    <BorderLinearProgress variant="determinate" value={nexStepper()} />
                                    <h4 className='pt-4 lg:text-xl font-regular text-normal text-base'>{renderTitle()}</h4>
                                </div>
                                <div>
                                    {renderScreen()}
                                </div>
                                <div className='flex justify-between'>
                                    <Button className='text-sm lg:text-lg font-regular bg-transparent rounded-full text-normal py-3' onClick={prevStep}>PRÉCÉDENT</Button>
                                    <Button className='px-8 text-sm lg:text-lg font-bold text-white rounded-full bg-radio py-3' onClick={nextStep}>SUIVANT</Button>
                                </div>
                            </div>
                        </div>

                        :
                        <Dashboard response={response} callBack={prevStep} />
                    }



                </div>
            </main>
        </div>
    );
};

export default Index;