import NavBar from '../../Components/Dashboard/NavBar'
import appStoreIcon from '../../Assets/button/appstore.png'
import playStoreIcon from '../../Assets/button/googleplay.png'
import qrCodeAppleBtn from '../../Assets/Images/qr_code_apple.svg'
import qrCodePlaystoreBtn from '../../Assets/Images/qr_code_androide.svg'
import appleIcon from '../../Assets/Icons/apple-blue.svg'
import playIcon from '../../Assets/Icons/play-blue.svg'

function ComingSoon() {
  return (
    <div className='h-screen overflow-hidden'>
      <NavBar />
      <div className='flex flex-col h-screen gap-8 px-4 pt-8 bg-bottom bg-no-repeat bg-contain bg-coming-soon md:px-8'>
        <h1 className='text-first lg:text-[32px] text-[20px] md:text-[28px] font-regular lg:w-1/2 md:w-2/3'>Cette version n’est pas encore disponible !
          Veuillez télécharger l’application pour retrouver l’avancée de vos demandes de cours.
        </h1>
        <div className="items-end justify-between hidden w-[40%] lg:flex">
          <a href='https://apps.apple.com/fr/app/declarons/id1473591330' target="_blank" rel="noreferrer">
            <img src={qrCodeAppleBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-bold text-first '>Télécharger via l’App Store</p>
              <img src={appleIcon} className="w-5" alt="" />
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=fr.teachr.app" target="_blank" rel="noreferrer">
            <img src={qrCodePlaystoreBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-bold text-first '>Télécharger via Google play</p>
              <img src={playIcon} className="w-5" alt="" />
            </div>
          </a>
        </div>
        <div className="flex items-center justify-between gap-4 py-8 text-center md:w-2/4 lg:hidden">
          <div className=''>
            <img className='' src={appStoreIcon} alt="" />
          </div>
          <div className=''>
            <img className='' src={playStoreIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon