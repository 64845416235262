import React from 'react';
import callCenterImage from '../../Assets/Images/callcenter-man.png'
import crediMutuelLogo from "../../Assets/Images/creditmutuel.png"
const Second = () => {
    return (
        <section className='py-4 font-sans lg:py-10'>
            <div className="mx-auto lg:w-[90%] md:px-8 flex flex-col lg:flex-row items-center justify-between lg:gap-x-10 gap-x-4 px-4">
                <div className='flex-col items-center hidden w-full lg:flex gap-y-8'>
                    <div>
                        <img src={callCenterImage} alt="" />
                    </div>
                    <div>
                        <img src={crediMutuelLogo} alt="" />
                    </div>
                </div>
                <div className='flex flex-col lg:gap-y-8 gap-y-4'>
                    <h2 className='lg:text-[26px] font-regular text-[#424242] text-lg font-sans'>UNE BANQUE AU SERVICE DE L’HUMAIN :</h2>
                    <div className='lg:hidden h-[150px] rounded-[16px] overflow-y-hidden'>
                        <img className='-mt-5 rounded-[16px]' src={callCenterImage} alt="" />
                    </div>
                    <p className='lg:text-[22px] text-[13px] text-[#424242] font-sans'>
                        <span className='font-regular text-[#BAD606] lg:text-[24px]'>Des conseillers non commissionnés et accessibles : <br /></span>
                        Chez Monabanq, les conseillers ne sont pas commissionnés quand ils accompagnent un client et lui proposent un produit. Banque à la fois humaine et digitale, Monabanq met un point d’honneur à chouchouter ses clients
                    </p>
                    <p className='lg:text-[22px] text-[13px] text-[#424242] font-sans'>
                        <span className='font-regular text-[#BAD606] lg:text-[24px]'>Un service client primé et reconnu : <br /></span>
                        Lauréate du concours « Elu Service Client de l’Année » en 2023 pour la 6 ème année consécutive, la banque en ligne Monabanq répond présente au niveau de l’excellence relationnelle et de sa qualité de service irréprochable.
                    </p>
                    <p className='lg:text-[22px] text-[13px] text-[#424242] font-sans'>
                        <span className='font-regular text-[#BAD606] lg:text-[24px]'>Une banque en ligne du Crédit Mutuel Alliance Fédérale : <br /></span>
                        Les clients de Monabanq peuvent utiliser les distributeurs automatiques de billets du Crédit Mutuel et du CIC pour déposer leurs espèces et chèques.
                    </p>
                </div>
            </div>
        </section>
    );
};


export default Second;


