import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import Child from "../Objects/models/Child";
import { plainToClass } from "class-transformer";
import HydraCollection from "../Objects/models/HydraCollection";

export default abstract class ChildRepository {
    /**
     * Fetch a child stored into the API by his id.
     * @param id
     */
    public static fetchChildById(id: number) {
        return ApiService.getSingleInstance<Child>(
            Route.CHILDREN_BASE + "/" + id,
            Child
        );
    }

    // public static getChildByTeachr(page: number = 1) {
    //     return ApiService.getCollection<HydraCollection<Child>>(
    //         Route.CHILDREN_BASE,
    //         HydraCollection,
    //         true,
    //         page,
    //         null
    //     );
    // }


    /**
     * Fetch a child stored into the API by his id.
     * @param id
     */
    public static fetchChildByIdForTeachr(id: number, teachrId: number) {
        return ApiService.getSingleInstance<Child>(
            `/teachrs/v2/teachrs/${teachrId}/children/${id}`,
            Child
        );
    }

    /**
     * Creates an instance of child into the API.
     * @param child
     */
    public static createChild(child: Child) {
        return ApiService.postInstance<Child>(
            Route.CHILDREN_BASE,
            Child,
            child
        );
    }

    /**
     * Updates the instance of the child passed as parameter.
     * @param child
     */
    // public static updateChild(child: Child) {
    //     return ApiService.putInstance<Child>(
    //         Route.CHILDREN_BASE + "/" + child.id,
    //         Child,
    //         child
    //     );
    // }


    /**
     * Returns all children of the connected user.
     */
    public static getChildren(client_id:number) {
        return ApiService.get(`/clients/v2/clients/${client_id}/children`)
            .then(
                (body: any) => plainToClass(
                    Child,
                    body
                )
            );

    }

    public static fetchParentByChildIdForTeachr(
        childId: number,
        teachrId: number
    ) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/children/${childId}/parent`
        );
    }

    /**
     * Returns contact infos of the coursepack's client.
     */
    public static fetchChildOrClientInfoForTeachrCoursePack(
        coursePackId: number,
        teachrId: number
    ) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/course_packs/${coursePackId}/student_contact_infos`
        );
    }


    /**
     * Returns contact infos of the subscription course's client.
     */
    public static fetchChildOrClientInfoForTeachrSubscriptionCourse(
        subscriptionCourseId: number,
        teachrId: number
    ) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses/${subscriptionCourseId}/student_contact_infos`
        );
    }

    public static deleteChild=(client_id: number, child_id:number)=>{
        return ApiService.put(`/clients/v2/clients/${client_id}/children/${child_id}/soft_delete`);
    }


}


