import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import gif from "../../../Assets/Icons/load.gif";
import ArrowRightCircle from "../../../Assets/Icons/arrow-right-circle.svg";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import ClientRepository from "../../../Repositories/ClientRepository";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
// import Client from "../../../Objects/models/Client";
import { useEffect, useState } from "react";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
import { CoursePack } from "../../../Objects/models/CoursePack";
// import NoLessonRequest from "./Components/NoLessonRequest";
import PunctualCoursePlanned from "./ActiveCourse/PunctualCoursePlanned";
import CoursePackPlanned from "./ActiveCourse/CoursePackPlanned";
import SubscriptionCoursePlanned from "./ActiveCourse/SubscriptionCoursePlanned";
import InTheHourCoursePlanned from "./ActiveCourse/InTheHourCoursePlanned";
import { Link } from "react-router-dom";
import NoActiveCourse from './Components/NoActiveCourse';

const ActiveCourse = () => {
  const [activePunctual, setActivePunctual] = useState<PunctualCourse[]>();
  const [activeUrgent, setActiveUrgent] = useState<PunctualCourse[]>();
  const [activeSubscription, setActiveSubscription] =
    useState<SubscriptionCourse[]>();
  const [activePacks, setActivePacks] = useState<CoursePack[]>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    ClientRepository.getCurrentClient().then((client) => {

      if (client.id) {
        PunctualCourseRepository.getActivePunctualCourse(client.id).then(
          (activeCourse) => {
            if (activeCourse) {
              const urgentCourse = activeCourse.filter(
                (course: PunctualCourse) => course.isCourseInTheHour
              );
              const PunctualCourses = activeCourse.filter(
                (course: PunctualCourse) => course.isCourseInTheHour === false
              );
              setActiveUrgent(urgentCourse);
              setActivePunctual(PunctualCourses);
            }
          }
        );
        SubscriptionCourseRepository.getActiveSubscriptionCourse(
          client.id
        ).then((activeCourse) => {
          if (activeCourse) {
            setActiveSubscription(activeCourse);
          }
        });
        CoursePackRepository.getCoursePacks(client.id).then(
          (activeCourse: any) => {
            setActivePacks(activeCourse.members);
          }
        );
      }
      setLoading(true);
    });
  }, []);

  const scrollCoursesToRight = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(screen.width, 0);
  };
  const scrollCoursesToLeft = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(-screen.width, 0);
  };

  return (
    <>
      {
        //activePacks || activeSubscription || activePunctual ?
        activePunctual?.length === 0 &&
          activeUrgent?.length === 0 &&
          activeSubscription?.length === 0 &&
          activePacks?.length === 0 ? (
          <NoActiveCourse />
        ) : (
          <div>
            {loading ? (
              <div>
                <div className="block  font-sans">
                  <div className="md:mt-4 mt-10 font-sans">
                    {activeUrgent?.length === 0 ? (
                      ""
                    ) : (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="lg:text-xl md:text-lg text-sm font-regular text-first">
                            Prestation dans l'heure
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="urgent-course" className=" list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex space-y-6 md:space-y-4 //gap-x-10">
                            {activeUrgent?.sort((a: any, b: any) => {
                              return b.creationDatetime - a.creationDatetime
                            }).map(
                              (course: PunctualCourse, key) => (
                                <InTheHourCoursePlanned {...course} key={key} />
                              )
                            )}
                          </div>
                          {activeUrgent !== undefined &&
                            activeUrgent?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("urgent-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("urgent-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {activePunctual?.length === 0 ? "" :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="lg:text-xl md:text-lg text-sm font-regular text-first">
                            Prestation Pontuelle
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="punctual-course" className=" list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex space-y-6 md:space-y-4 //gap-x-10">
                            {activePunctual?.sort((a: any, b: any) => {
                              return b.creationDatetime - a.creationDatetime
                            }).map(
                              (course: PunctualCourse, key) => (
                                <PunctualCoursePlanned {...course} key={key} />
                              )
                            )}
                          </div>
                          {activePunctual !== undefined &&
                            activePunctual?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("punctual-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("punctual-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    }
                    {activeSubscription?.length === 0 ? '' :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="lg:text-xl md:text-lg text-sm font-regular text-first">
                            Prestation Régulière
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="regular-course" className=" list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex space-y-6 md:space-y-4 //gap-x-10">
                            {activeSubscription?.sort((a: any, b: any) => {
                              return b.creationDatetime - a.creationDatetime
                            }).map(
                              (course: SubscriptionCourse, key: any) => (
                                <SubscriptionCoursePlanned
                                  {...course}
                                  key={key}
                                />
                              )
                            )}
                          </div>
                          {activeSubscription !== undefined &&
                            activeSubscription?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("regular-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("regular-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>
                    }
                    {activePacks?.length === 0 ? "" :
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="lg:text-xl md:text-lg text-sm font-regular text-first">
                          Regroupement des prestations
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div id="pack-course" className=" list-teacher md:overflow-x-scroll md:scroll-smooth md:overflow-y-hidden md:w-full md:flex space-y-6 md:space-y-4 //gap-x-10">
                            {activePacks?.sort((a: any, b: any) => {
                              return b.creationDatetime - a.creationDatetime
                            }).map((course: CoursePack, key) => (
                              <CoursePackPlanned {...course} key={key} />
                            ))}
                          </div>
                          {activePacks !== undefined &&
                            activePacks?.length >= 2 ? (
                            <Link
                              to=""
                              className="hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center"
                            >
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToLeft("pack-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                                className="-rotate-180"
                              />
                              Toutes les prestations prévues{" "}
                              <img
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  scrollCoursesToRight("pack-course");
                                }}
                                src={ArrowRightCircle}
                                alt="arrow icon"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </AccordionDetails>
                      </Accordion>}
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-40   my-auto mx-auto">
                <img src={gif} alt="gif" className=" mx-auto" />
              </div>
            )}
          </div>
        )
      }
    </>
  );
};

export default ActiveCourse;
