import React from 'react';

const Third = () => {
    return (
        <section className='py-8 bg-[#424242] font-sans'>
            <div className='mx-auto lg:w-[90%] flex flex-col gap-y-8 px-4 md:px-8'>
                <h2 className='text-white lg:text-[26px] font-regular text-[12px] font-sans'>Un service client accessible de 8h à 21h du lundi au vendredi et le samedi jusqu’à 16h</h2>
                <div className="flex justify-between lg:w-[90%] mx-auto w-full">
                    <div className='flex flex-col items-center w-full lg:gap-y-4 gap-y-2'>
                        <span className='text-white bg-black lg:text-[46px] text-[12px] font-sans font-regular w-[60px] h-[22px] lg:w-[177px] lg:h-[68px] rounded-lg lg:rounded-[16px] lg:px-8 text-center'>100%</span>
                        <span className='font-regular text-white text-[6px] lg:text-[15px] text-center font-sans'>Des appels sont décrochés<br /> en moins de 5 sonnerie</span>
                    </div>
                    <div className='flex flex-col items-center w-full lg:gap-y-4 gap-y-2'>
                        <span className='text-white bg-black lg:text-[46px] text-[12px] font-regular w-[60px] h-[22px] lg:w-[177px] lg:h-[68px] rounded-lg lg:rounded-[16px] lg:px-8 text-center'>24h</span>
                        <span className='font-regular text-white text-[6px] lg:text-[15px] text-center font-sans'>Délai garanti pour obtenir un <br /> rendez-vous avec un expert</span>
                    </div>
                    <div className='flex flex-col items-center w-full lg:gap-y-4 gap-y-2'>
                        <span className='text-white bg-black lg:text-[46px] text-[12px] font-sans font-regular w-[60px] h-[22px] lg:w-[177px] lg:h-[68px] rounded-lg lg:rounded-[16px] lg:px-8 text-center'>37s</span>
                        <span className='font-regular text-white text-[6px] lg:text-[15px] text-center font-sans'>Temps moyen de réponse par <br />
                            chat</span>
                    </div>
                    <div className='flex flex-col items-center w-full lg:gap-y-4 gap-y-2'>
                        <span className='text-white bg-black lg:text-[46px] text-[12px] font-sans font-regular w-[60px] h-[22px] lg:w-[177px] lg:h-[68px] rounded-lg lg:rounded-[16px] lg:px-8 text-center'>1h</span>
                        <span className='font-regular text-white text-[6px] lg:text-[15px] text-center font-sans'>Délai de réponse par email</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Third;