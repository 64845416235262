import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';

const Ninth = (props: any) => {
    const [firstResidence, setFirstResidence] = useState<number>(0)
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstResidence(parseInt((event.target as HTMLInputElement).value));
    };
    useEffect(() => {
        props.callBack({
            firstResidence: firstResidence
        })
        if (firstResidence !== undefined && firstResidence >= 0 ) {
            props.nextStep(10) 
         }
    }, [firstResidence])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
                <label className='text-normal text-xl font-regular' htmlFor="">Par défaut votre résidence principale est insaisissable, souhaitez vous <span className='font-regular' >renoncer à cette insaisissabilité ?</span> </label>
                <RadioGroup row
                    value={firstResidence}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
        </div>
    );
};

export default Ninth;