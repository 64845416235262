import React, {  useState } from 'react'
import logo_degrade from "../../Assets/Images/logo_dec_white.svg";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileButton from './ProfileButton';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, } from '@mui/material';
import Messages from '../utils/Messages';
// import ReorderIcon from '@mui/icons-material/Reorder';
// import TeachrRepository from '../../Repositories/TeachrRepository';
// import Teachr from '../../Objects/models/Teachr';
// import Client from '../../Objects/models/Client';
// import ClientRepository from '../../Repositories/ClientRepository';
// import { auth, logout } from '../../Services/FirebaseService';
// import { useAuthState } from 'react-firebase-hooks/auth';

function NavBarClient(props: any) {
//   const [user, loading] = useAuthState(auth)
  const [toggle, setToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(props.active);
//   const [teachr, setTeachr] = useState<Teachr>()
//   const [client, setClient] = useState<Client>()
  const navigate = useNavigate()
  const handleListItemButtonClick = (
    index: number,
  ) => {
    setSelectedIndex(index);
    navigate('/coming-soon')
  };

//   useEffect(() => {
//     document.body.classList.remove('bg-bg-home');
//     document.body.classList.add('w-screen');
//     document.body.classList.add('overflow-x-hidden');
//     document.body.style.backgroundRepeat = 'no-repeat';
//     document.body.style.backgroundSize = 'cover';
//     document.body.style.height = '100%';
//   }, []);
//   useEffect(() => {
//     if (loading) {
//       document.getElementById('teachr-loader')?.classList.remove('hidden')
//     } else {
//       if (!user) {
//         logout()
//       } else {
//         TeachrRepository.getCurrentTeachr().then((response) => {
//           if (response) {
//             setTeachr(response)
//           }
//         }).catch((err) => {
//           if (err) {
//             ClientRepository.getCurrentClient().then((response) => {
//               if (response) {
//                 setClient(response)
//               }
//             }).catch(() => {
//               // logout()
//             })
//           } else {
//             ClientRepository.getCurrentClient().then((response) => {
//               if (response) {
//                 setClient(response)
//               }
//             }).catch(() => {
//               // logout()
//             })
//           }
//         })
//       }
//     }
//   }, [loading, user]);

  return (
    <div>
      <div className="//bg-[#fff] bg-gradient-to-r from-[#001E73] to-[#6F80AE] lg:pb-0 pb-8">
        <div className="flex items-center justify-between px-8 py-6 font-bold">
          <div className=''>
            <img className="cursor-pointer lg:w-[150px] w-[84px]" onClick={() => navigate('/')} src={logo_degrade} alt="" />
          </div>
          <div className="lg:hidden">
            <MenuIcon
              onClick={() => {
                setToggle(!toggle);
              }}
            ></MenuIcon>
          </div>
          <div className="hidden lg:block">
            <List sx={{
              display: 'flex',
              gap: "0 20px",
              '& .Mui-selected': {

                // boxShadow: '4px 4px 6px 1px rgba(0, 0, 0, 0.25)',
                color: '#fff !important',
                // backgroundColor: '#fff !important',
                borderBottom: "4px solid "
              },
              '& .MuiListItem-root': {
                padding: '0',
                // borderRadius: ' 9999px',
                
                fontFamily: 'Nunito'
              }
            }}>
              {/* {teachr ?
                <>
                  <ListItem
                    selected={selectedIndex === 0}
                    onClick={(event) => { handleListItemButtonClick(0); navigate('/coming-soon') }} className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' >
                    Mes presentations
                  </ListItem>
                  <ListItem
                    selected={selectedIndex === 1}
                    onClick={(event) => { handleListItemButtonClick(1); navigate('/declarons/candidatures') }}
                    className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' >
                    MES CANDIDATURES
                  </ListItem>
                  <ListItem
                    selected={selectedIndex === 2}
                    onClick={(event) => { handleListItemButtonClick(2); navigate('/declarons/prestations') }}
                    className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' >  PRESTATIONS DISPONIBLES
                  </ListItem>
                  <ListItem
                    selected={selectedIndex === 3}
                    onClick={(event) => { handleListItemButtonClick(3); navigate("/declarons/dashboard") }}
                    className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' > DASHBOARD
                  </ListItem>
                </>
                : */}
                {/* <> */}
                  <ListItem
                    selected={selectedIndex === 4}
                    onClick={(event) => { handleListItemButtonClick(0); navigate('/user/declaration') }} className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' >
                    Nouvelle prestation
                  </ListItem>
                  <ListItem
                    selected={selectedIndex === 5}
                    onClick={(event) => { handleListItemButtonClick(1); navigate('/user/prestations') }}
                    className='px-4 py-2 uppercase cursor-pointer whitespace-nowrap //hover:text-[#6F80AE] text-white md:text-base ' >
                    MES demandes de prestations
                  </ListItem>
                {/* </>
              } */}
              <ListItem
                className='uppercase w-max whitespace-nowrap //border-2 //border-white //hover:text-[#6F80AE] text-white md:text-base ' >
                <ProfileButton />
              </ListItem>
            </List>
          </div>
        </div>
        {
          toggle ? (
            // <>
            //   {teachr ?
            //     <div className="flex flex-col items-center gap-2 lg:hidden lg:flex-row">
            //       <Link
            //         to="/coming-soon"
            //         className="font-bold uppercase text-white md:text-base "
            //       >
            //         Mes presentations
            //       </Link>
            //       <Link
            //         to="/declarons/candidatures"
            //         className="font-bold uppercase text-white md:text-base "
            //       >
            //         mes candidatures
            //       </Link>
            //       <Link to="/declarons/prestations" className='px-4 uppercase text-[#6F80AE]'>
            //         PRESTATIONS DISPONIBLES
            //       </Link>
            //       <Link
            //         to="/declarons/dashboard"
            //         className="font-bold uppercase text-white md:text-base "
            //       >
            //         DASHBOARD
            //       </Link>
            //       <ProfileButton />
            //     </div> :
                <div className="flex flex-col items-center gap-2 lg:hidden lg:flex-row">
                  <Link
                    to="/user/declaration"
                    className="font-bold uppercase text-white md:text-base "
                  >
                    Nouvelle prestation
                  </Link>
                  <Link
                    to="/user/prestations"
                    className="font-bold uppercase text-white md:text-base "
                  >
                    MES demandes de prestations
                  </Link>
                  <ProfileButton />
                </div>

        //       }
        //     </>
          ) : null
        }
      </div >
      <Messages />
    </div>
  )
}

export default NavBarClient