import React, { useState } from "react";
import prestation1 from "../../../Assets/Icons/stage_revision.svg";
import prestation2 from "../../../Assets/Icons/cours_regulier.svg";
import prestation3 from "../../../Assets/Icons/cours_en urgence.svg";
import FirstType from "./PrestationType/FirstType";
import FourthType from "./PrestationType/FourthType";
import SecondType from "./PrestationType/SecondType";
import ThirdType from "./PrestationType/ThirdType";

const Second = (props: { currentComponent?: any; handleNext?: any; handleBack?: any; }) => {
  const [choice, setChoice] = useState(false)
  const { handleNext, handleBack} = props;

  const handleSubmit = () => {
    setChoice(true)
  };

  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h2 className="lg:text-3xl  md:text-2xl text-lg text-first font-regular">Quel type de prestations souhaitez-vous ?</h2>
      <div className="grid grid-cols-1 gap-6  md:space-y-0 md:grid-cols-2">
        <div
          onClick={(event) => props.currentComponent(2, <FirstType handleNext={handleNext} handleBack={handleBack} />)}
          className="bg-[#4B6AD8] py-2 bg-opacity-[10%] hover:bg-[#4B6AC8] hover:bg-opacity-[28%] hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
          <div className="  pl-6 w-1/4">
            <img
              src={prestation1}
              alt=""
              className="rounded-full w-10 mt-[4px]"
            />
          </div>
          <div className="  w-3/4 cursor-pointer">
            <p className="//md:text-center /pt-2 md:text-lg text-sm  font-bold cursor-pointer">
              Prestation régulière
            </p>
            <p className="//md:text-center /pt-2 md:text-sm text-xs text-[#4d4d4d] font-regular cursor-pointer">
              Une prestation, plusieurs fois
            </p>
          </div>
        </div>
        <div
          onClick={(event) => props.currentComponent(2, <FourthType handleNext={handleNext} handleBack={handleBack} />)}
          className="bg-[#4B6AD8] py-2 bg-opacity-[10%] hover:bg-[#4B6AC8] hover:bg-opacity-[28%] hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
          <div className="  pl-6 w-1/4">
            <img
              src={prestation1}
              alt=""
              className="rounded-full w-10 mt-[4px]"
            />
          </div>
          <div className="  w-3/4 cursor-pointer">
            <p className="//md:text-center /pt-2 md:text-lg text-sm  font-bold cursor-pointer">
              Regroupement des prestations
            </p>
            <p className="//md:text-center /pt-2 md:text-sm text-xs text-[#4d4d4d] font-regular cursor-pointer">
              Plusieurs prestations
            </p>
          </div>
        </div>
        <div
          onClick={(event) => props.currentComponent(2, <ThirdType handleNext={handleNext} handleBack={handleBack} />)}
          className="bg-[#4B6AD8] py-2 bg-opacity-[10%] hover:bg-[#4B6AC8] hover:bg-opacity-[28%] hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
          <div className="  pl-6 w-1/4">
            <img
              src={prestation3}
              alt=""
              className="rounded-full w-10 mt-[4px]"
            />
          </div>
          <div className="  w-3/4 cursor-pointer">
            <p className="//md:text-center /pt-2 md:text-lg text-sm  font-bold cursor-pointer">
              Prestation En Urgence
            </p>
            <p className="//md:text-center /pt-2 md:text-sm text-xs text-[#4d4d4d] font-regular cursor-pointer">
              Une prestation, tout de suite
            </p>
          </div>
        </div>
        <div
          onClick={(event) => props.currentComponent(2, <SecondType handleNext={handleNext} handleBack={handleBack} />)}
          className="bg-[#4B6AD8] py-2 bg-opacity-[10%] hover:bg-[#4B6AC8] hover:bg-opacity-[28%] hover:border-[#758FDA]  border-2 border-solid border-[#758FDA3D] pt-2 cursor-pointer text-first   rounded-xl flex justify-around p-1">
          <div className="  pl-6 w-1/4">
            <img
              src={prestation2}
              alt=""
              className="rounded-full w-10 mt-[4px]"
            />
          </div>
          <div className="  w-3/4 cursor-pointer">
            <p className="//md:text-center /pt-2 md:text-lg text-sm  font-bold cursor-pointer">
              Prestation ponctuelle
            </p>
            <p className="//md:text-center /pt-2 md:text-sm text-xs text-[#4d4d4d] font-regular cursor-pointer">
              Une prestation, une seule fois
            </p>
          </div>
        </div>
      </div>
      <div className="text-third lg:text-lg md:text-base text-sm md:translate-y-10 translate-y-4 -mb-4 text-center">
        <p>{choice ? "Veuillez choisir un type de cours" : null}</p>
      </div>
      <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-10 md:translate-y-44 md:w-full">
        <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
          <div
            onClick={handleBack}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent

          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={(e) => handleSubmit()}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => handleBack()}
            className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent

          </button>
        </div>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={() => handleSubmit()}
            className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

          >
            Suivant
          </button>
        </div>
      </div>
    </div>

  );
};

export default Second;
