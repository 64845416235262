import { Type } from "class-transformer";
import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import CourseProposal from "./CourseProposal";
import AvailablePromotionalCode from "./AvailablePromotionalCode";
import { Subject } from "./Subject";
import Child from "./Child";
import Client from "./Client";

declare class ReviewClient {}

export class PunctualCourseDashboardUpdate extends HydraEntity implements DisplayableCourse {
    duration?: number;

   
    // @Type(() => Date)
    startDateTime?: string;
    /**
     * The end datetime of the course.
     */
    // @Type(() => Date)
    endDateTime?: string;
    
    
    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    isNewDate?: boolean; // only used to display PunctualCourse in front, doesn't exist in db

    constructor(
        startDateTime: string,
        endDateTime:string,
        duration?: number,
    ) {
        super();
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.duration = duration;
    }
}
