import  { useEffect, useState } from 'react';
import Profile from './Profile';
import Comment from './Comment';
import NavBar from '../../../../../Components/Dashboard/NavBar';
import starIconAlt from '../../../../../Assets/Icons/star-alt.svg'
import chevron from '../../../../../Assets/Icons/chevron.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewTeachr from '../../../../../Objects/models/ReviewTeachr';
import ReviewTeachrRepository from '../../../../../Repositories/ReviewRepository';
import EmptyComment from './Empty';
const StudentIndex = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [reviews, setReviews] = useState<ReviewTeachr[]>()

    useEffect(() => {
        const match = location.state.course.forChild['@id'].match(/\/children\/(.*)/)
        let childId = null
        if (match) {
            childId = match[1]
        } else {
            childId = location.state.course.forChild.forClient.id
        }
        ReviewTeachrRepository.getReviewsTeachrs(1, ["punctualCourse.forChild=" + childId]).then((response) => {
            if (response) {
                setReviews(response.members)
            }
        })
    }, [])

    return (
        <div>
            <NavBar active={1} />
            <div className='pt-4 px-4 lg:pl-14 md:h-[calc(100vh_-_8rem)] overflow-hidden'>
                <div className='flex gap-6 w-max'>
                    <h1 onClick={() => navigate(-1)} className='cursor-pointer font-bold text-first text-[20px]  underline-offset-8  decoration-third decoration-4 uppercase'>candidature</h1>
                    <img src={chevron} alt="" />
                    <h1 className='cursor-pointer font-bold text-first text-[20px] underline underline-offset-8  decoration-third decoration-4'>FICHE ELEVE</h1>
                </div>
                <div className="flex flex-col py-8 gap-y-8 gap-x-20 md:flex-row">
                    <div className=' md:w-1/4'>
                        <Profile {...location.state.course.forChild ? location.state.course.forChild : location.state.course.forClient} />
                    </div>
                    <div className='md:w-3/4'>
                        {reviews ? reviews.length > 0 && <p className='flex items-center text-[24px] text-first font-regular gap-3'><img src={starIconAlt} alt="" /> {reviews?.length} commentaires</p> : null

                        }
                        { }
                        {/* <p className='text-first font-regular text-[24px]'>Aucun teach’r n’a laissé de commentaires pour l’instant</p>
                        <img className='mx-auto' src={illustration} alt="" /> */}
                        {!reviews ? null : reviews.length > 0 ?
                            <div className='mt-4 flex flex-col gap-3 md:overflow-y-auto md:h-[calc(100vh_-_17rem)]'>
                                {reviews.map((review) => <Comment {...review} />)}
                            </div>
                            :
                            <div className={`mt-4 flex flex-col gap-3 h-[calc(100vh_-_17rem)]`}>
                                <EmptyComment />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentIndex;