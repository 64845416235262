import validator from 'validator';
const validateEmail = (value) => {
    if (validator.isEmpty(value)) {
        return {
            isValid: false,
            message: 'Veuillez remplir ce champ'
        }
    }
    if (!validator.isEmail(value)) {
        return {
            isValid: false,
            message: 'Veuillez entrer un mail valide'
        }
    } else
        return {
            isValid: true,
            message: ''
        }
};

export default validateEmail