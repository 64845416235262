import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { persistor, store } from './Redux/store';
import RootOverlay from './Components/ComponentsUtils/RootOverlay';
import { initialState as userReducerInitialState } from './Redux/Reducers/userReducers';
import { initialState as generalReducerInitialState } from './Redux/Reducers/generalReducers';
import RootNavigator from './Navigation/RootNavigator';
import { PersistGate } from 'redux-persist/integration/react';

export default class App extends React.Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RootNavigator  {...{ user: userReducerInitialState, general: generalReducerInitialState, dispatch: store.dispatch }} />
            <RootOverlay {...{ user: userReducerInitialState, general: generalReducerInitialState, dispatch: store.dispatch }} />
          </PersistGate>
        </Provider>
      </>
    );
  }
}
