import moment from 'moment';
import { useEffect, useState } from 'react';
import SubjectRepository from '../../../../../../../Repositories/SubjectRepository';
import { Subject } from '../../../../../../../Objects/models/Subject';
// import CourseProposalRepository from '../../../../../../../Repositories/CourseProposalRepository';
import ChildRepository from '../../../../../../../Repositories/ChildRepository';
import Child from '../../../../../../../Objects/models/Child';
import Functions from '../../../../../../../Helpers/Functions';
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
// import UsrssafStatusService from "../../../../../../../Objects/models/UrssafStatusService";
import SubscriptionCourseProposalRepository from '../../../../../../../Repositories/SubscriptionCourseProposalRepositorys';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import { FormControlLabel } from "@mui/material";
import RadioButtonDashboard from '../../../UtilsDashboard/RadioButton';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// import pause from "../../../../../../../Assets/Icons/pause2.svg";
// import edit from "../../../../../../../Assets/Icons/edit2.svg";
// import delete2 from "../../../../../../../Assets/Icons/delete2.svg";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import * as React from 'react';

const validateValue = (value: any) => {
    if (value === '') {
        return {
            isValid: false,
            message: "Veuillez séléctionner un cours",
        };
    }
    else
        return {
            isValid: true,
            message: "",
        };
};
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#F2F9FF",
        color: 'red',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontFamily: "Nunito"
        //   fontFamily:
    },
}));
const LightTooltipNoError = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#F2F9FF",
        color: '#4d4d4d',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontFamily: "Nunito"
    },
}));

const days = [
    {
        fr: 'Lundi',
        en: 'monday'
    },
    {
        fr: 'Mardi',
        en: 'tuesday'
    },
    {
        fr: 'Mercredi',
        en: 'wednesday'
    },
    {
        fr: 'Jeudi',
        en: 'thursday'
    },
    {
        fr: 'Vendredi',
        en: 'friday'
    },
    {
        fr: 'Samedi',
        en: 'saturday'
    },
    {
        fr: 'Dimanche',
        en: 'sunday'
    }
]
const translateDay = (day: string) => {
    const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
    if (translate.length >= 0) {
        return translate[0].fr
    }
    return ''

}

const ComponentBodyTableSubscription = (props: any) => {
    const { punctual, isHere, isEdit, isPause, isDelete, handleEdit, handleClickOpen, val } = props
    const [subject, setSubject] = useState<Subject>()
    // const [status, setStatus] = useState<UsrssafStatusService>()
    const [proposal, setProposal] = useState<any>()
    const [child, setChild] = useState<Child>()
    const [error, setError] = useState({
        value: "null"
    })


    const validate = () => {
        let errvalue = true;
        if (!validateValue(punctual?.id).isValid) {
            setError({ value: validateValue(punctual?.id).message });
            errvalue = true;
        } else {
            setError({ value: validateValue(punctual?.id).message });
            errvalue = false;
        }

        if (errvalue) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubjectRepository.fetchSubjectById(punctual.subjectAsked_id).then((subject) => {
                    setSubject(subject)
                })
                SubscriptionCourseProposalRepository.fetchSubscriptionCourseProposalByIdTeachr(teachr.id, punctual.acceptedProposal_id).then((res) => {
                    setProposal(res)
                })
                ChildRepository.fetchChildByIdForTeachr(punctual.forChild_id, punctual.directAskedTeachrId).then((child) => {
                    setChild(child)
                })
                if (punctual?.urssafServiceStatus !== null) {
                    PunctualCourseRepository.getUsrssafStatusServiceIdById(punctual?.urssafServiceStatus).then((status) => {
                        // setStatus(status)
                    }).catch((err) => {
                        console.warn(err);
                    })
                }

            }

        })
    }, [punctual.acceptedProposal_id, punctual.directAskedTeachrId, punctual.forChild_id, punctual.subjectAsked_id, punctual?.urssafServiceStatus])


    const handleEditGo = () => {
        if (validate()) {
            handleEdit()
        }
    }



    // const handleEditGo = () => {
    //     if (validate()) {
    //         handleEdit()
    //     }
    // }


    return (
        <>
            <tbody className="text-[#4D4D4D] font-regular overflow-y-auto scroll">
                {punctual.resumesAt == null &&
                    <tr className="//bg-[#808080] bg-gray-50">
                        {isHere &&
                            <>
                                {isEdit ?
                                    <>
                                        {
                                            error.value === "null" || error.value === "" ?
                                                <LightTooltipNoError title={error.value === "null" || error.value === "" ? "Modifier " : validateValue(val).message}>
                                                    <td className=" //py-2 px-1">
                                                        <FormControlLabel
                                                            value={punctual?.id}
                                                            key={punctual?.id}
                                                            onClick={handleEditGo}
                                                            control={<RadioButtonDashboard image={<ModeEditOutlineIcon sx={{ color: "#0B2775" }} />} />}
                                                            labelPlacement="start"
                                                            label=""
                                                        />
                                                    </td>
                                                </LightTooltipNoError>
                                                :
                                                <LightTooltip title={error.value === "null" || error.value === "" ? "Modifier " : validateValue(val).message}>
                                                    <td className=" //py-2 px-1">
                                                        <FormControlLabel
                                                            value={punctual?.id}
                                                            key={punctual?.id}
                                                            onClick={handleEditGo}
                                                            control={<RadioButtonDashboard image={<ModeEditOutlineIcon sx={{ color: "#0B2775" }} />} />}
                                                            labelPlacement="start"
                                                            label=""
                                                        />
                                                    </td>
                                                </LightTooltip>
                                        }
                                    </>
                                    :
                                    isPause ?
                                        <>
                                            {
                                                error.value === "null" || error.value === "" ?
                                                    <LightTooltipNoError title={error.value === "null" || error.value === "" ? "Mettre en pause " : validateValue(val).message}>
                                                        <td className=" //py-2 px-1">
                                                            <FormControlLabel
                                                                value={punctual?.id}
                                                                key={punctual?.id}
                                                                onClick={handleClickOpen}
                                                                control={<RadioButtonDashboard image={<PauseCircleFilledIcon sx={{ color: "#0B2775" }} />} />}
                                                                labelPlacement="start"
                                                                label=""
                                                            />
                                                        </td>
                                                    </LightTooltipNoError>
                                                    :
                                                    <LightTooltip title={error.value === "null" || error.value === "" ? "Mettre en pause " : validateValue(val).message}>
                                                        <td className=" //py-2 px-1">
                                                            <FormControlLabel
                                                                value={punctual?.id}
                                                                key={punctual?.id}
                                                                onClick={handleClickOpen}
                                                                control={<RadioButtonDashboard image={<PauseCircleFilledIcon sx={{ color: "#0B2775" }} />} />}
                                                                labelPlacement="start"
                                                                label=""
                                                            />
                                                        </td>
                                                    </LightTooltip>
                                            }
                                        </>
                                        :
                                        isDelete ?
                                            <>
                                                {
                                                    error.value === "null" || error.value === "" ?
                                                        <LightTooltipNoError title={error.value === "null" || error.value === "" ? "Supprimer" : validateValue(val).message}>
                                                            <td className=" //py-2 px-1">
                                                                <FormControlLabel
                                                                    value={punctual?.id}
                                                                    key={punctual?.id}
                                                                    onClick={handleEdit}
                                                                    control={<RadioButtonDashboard image={<DeleteIcon sx={{ color: "#0B2775" }} />} />}
                                                                    labelPlacement="start"
                                                                    label=""
                                                                />
                                                            </td>
                                                        </LightTooltipNoError>
                                                        :
                                                        <LightTooltip title={error.value === "null" || error.value === "" ? "Supprimer" : validateValue(val).message}>
                                                            <td className=" //py-2 px-1">
                                                                <FormControlLabel
                                                                    value={punctual?.id}
                                                                    key={punctual?.id}
                                                                    onClick={handleEdit}
                                                                    control={<RadioButtonDashboard image={<DeleteIcon sx={{ color: "#0B2775" }} />} />}
                                                                    labelPlacement="start"
                                                                    label=""
                                                                />
                                                            </td>
                                                        </LightTooltip>
                                                }
                                            </>
                                            : ""
                                }
                            </>

                        }
                        <td className="py-2 px-1 hidden lg:flex">{moment(punctual.startDate).format('dddd DD/MM/YYYY')}</td>
                        <td className="py-2 px-1 lg:hidden">{moment(punctual.startDate).format('DD/MM/YY')}</td>
                        <td className="py-2 px-1">{child?.firstName}</td>
                        <td className="py-2 px-1">{subject?.fr_FR}</td>
                        <td className="py-2 px-1 //w-6 //border-2">{punctual?.daysOfTheWeek.map((day: string, key: any) => <span key={key} className="after:content-[','] last:after:content-['']">&#160;{translateDay(day)}</span>)}</td>
                        <td className="py-2 px- text-center //flex justify-around">
                            <div>{proposal?.totalDailyPrice}€
                            </div>
                        </td>
                        <td className="px-1 py-2 text-center //text-center">{Functions.renderDuration(punctual.durationPerDay)}</td>
                        <td className="px-1 py-2 text-center //text-center">{Functions.renderDuration(punctual.durationPerDay * punctual?.daysOfTheWeek.length)}</td>
                    </tr>
                }
            </tbody>
        </>
    );
};

export default ComponentBodyTableSubscription;