import React, { useState } from "react";
import validateCustom from "../../../../Validations/CustomList";
import "antd/dist/antd.css";
import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../Helpers/Functions";
import { styled, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { InputBase } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DatePickers from "../../../Utilitis/DatePickers";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimePicker } from "antd";
require('../../../../Styles/multiselect.css')

const format = "HH:mm";
const days = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
};
const TeachrInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 14,
  },
}));
const TeachrInputs = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 17,
  },
}));
const Menu = styled(MenuItem)(({ theme }) => ({
  "&": {
    border: "2px solid #0B2775",
    borderRadius: "5px",
    color: "#0B2775",
    padding: ".5rem",
    paddingLeft: "4rem",
    margin: ".2rem",
    textAlign: "center !important",
  },
  "&.Mui-selected": {
    border: "2px solid #0B2775",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#0B2775",
  },
}));
function getStyles() {
  return {
    fontWeight: "bold",
    fontFamily: "Nunito",
  };
}
const useStyles = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "grid !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3.5rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 15,
    },
  },
});
const useStylesMobile = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "block !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3rem ",
      paddingRight: "2rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 12,
    },
  },
});

const FirstType = (props) => {

  moment.locale("fr");
  const classes = useStyles();
  const classe = useStylesMobile();
  const mostLikelyTime = moment();
  const theme = useTheme();
  const today = new Date();
  const start = moment().set({ hour: 17, minute: 0 });
  mostLikelyTime.set({ hour: 17, minute: 0 });

  const [user] = useAuthState(auth);
  const [duration, setDuration] = useState(1.5);
  const [date, setDate] = useState(today);
  const [startTime, setStartTime] = useState(start);
  const [day, setDay] = React.useState([]);
  const [error, setError] = useState({
    day: "null",
  });

  const getDate = (value) => {
    setDate(value);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDay(typeof value === "string" ? value.split(",") : value);
  };
  const handleDurationChange = (event, newValue) => {
    setDuration(newValue);
  };

  const validate = () => {
    let errday = true;
    if (!validateCustom(day).isValid) {
      setError({ day: validateCustom(day).message });
      errday = true;
    } else {
      setError({ day: validateCustom(day).message });
      errday = false;
    }

    if (errday) {
      return false;
    } else {
      return true;
    }
  };



  const handleSubmit = (event) => {
    if (validate()) {
      const time = startTime.toDate().valueOf()
    StepperStore.setItem(user ? "secondPrestation" + user.uid : "secondPrestation",
      {
        type: "Régulier",
        data: {
          date,
          time,
          day,
          duration,
        },
      }
    );
    props.handleNext();
    }
    event.preventDefault();
  };

  const calculateTotalHours = () => {
    let total = duration * day.length;
    return total;
  };

  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h5 className="text-lg font-regular lg:text-3xl md:text-2xl text-first">Prestation régulière</h5>
      <div className="">
         <div className="mt-6 /md:w-2/3 w-full ">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[2px] px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Nombre d'heure
              </label>
              <div className=" h-9 md:-translate-y-1 md:mt-0 -mt-2">
                <Functions.PrettoSlider
                  defaultValue={1}
                  step={1}
                  min={1}
                  max={4}
                  marks
                  value={duration}
                  onChange={handleDurationChange}
                />
              </div>
            </div>
            <span className="font-regular bg-transparent text-first">
              <input
                className="bg-transparent"
                type="text"
                value={Functions.renderDuration(duration * 2)}
                onChange={(e) => setDuration(e.target.value)}
              />
            </span>
          </div>

        <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans py-1 md:mt-8 mt-3 px-3 w-full lg:text-lg md:text-base text-sm">
          <label className="text-sm font-medium lg:text-lg md:text-base" >  Choisissez vos jours :  </label>
          <div className="relative hidden  font-sans md:block">
            <Select
              sx={{
                width: "100%",
                paddingLeft: "",
                fontFamily: "nunito",
                background: "#F2F5FB !important",
              }}
              multiple
              MenuProps={{ classes: { paper: classes.select } }}
              label={"Text"}
              value={day}
              onChange={handleChange}
              input={<TeachrInputs />}
              placeholder="Lundi, Mardi, Mercredi..."
              className="text-xs"
            >
              {Object.entries(days).map(([key, day]) => (
                <Menu
                  key={day}
                  value={key}
                  style={getStyles(day, day, theme)}
                  placeholder="Lundi, Mardi, Mercredi..."
                  className="text-sm text-center border lg:text-lg md:text-base"
                >
                  {day}
                </Menu>
              ))}
            </Select>
          </div>
          <div className="relative mt-1  font-sans md:hidden">
            <Select
              sx={{
                width: "100%",
                paddingLeft: "",
                fontFamily: "nunito",
                background: "#F2F5FB",
              }}
              multiple
              label={"Text"}
              value={day}
              onChange={handleChange}
              input={<TeachrInput />}
              MenuProps={{ classes: { paper: classe.select } }}
              placeholder="Lundi, Mardi, Mercredi..."
              className="text-xs displayDay"
            >
              {Object.entries(days).map(([key, day]) => (
                <Menu
                  key={day}
                  value={key}
                  style={getStyles(day, day, theme)}
                  placeholder="Lundi, Mardi, Mercredi..."
                  className="text-sm text-center border lg:text-lg md:text-base "
                >
                  {day}
                </Menu>
              ))}
            </Select>
          </div>
        </div>
        <span className="font-regular bg-transparent text-first">
          <input
            type="text"
            value={
              "Total : " +
              Functions.renderDuration(calculateTotalHours() * 2) +
              "/semaine"
            }
            className="bg-transparent"
            onChange={(e) => setDuration(e.target.value)}
          />
        </span>
        <div className="flex w-full mx-auto space-x-2  md:translate-y-1 md:mb-4">
          {error.day === "null" || error.day === ""
            ? " "
            : validateCustom(day).icon}
          <p className="text-[#FF724F] h-6 w-full lg:text-base  md:text-sm text-xs md:-mt-1  ">
            {error.day === "null" || error.day === ""
              ? null
              : validateCustom(day).message}
          </p>
        </div>

        <div className="block w-full gap-8 mx-auto space-y-6 md:space-y-0 md:flex mt-">
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans py-[1px] h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="text-sm font-medium lg:text-lg md:text-base"
              >
                {" "}
                À partir du :
              </label>
              <div className="-mt-6 translate-y-2 md:-mt-3 md:translate-y-0 ">
                <div className="relative -ml-4 ">
                  <DatePickers value={date} callBack={getDate} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans  py-[1px] -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="text-sm font-medium lg:text-lg md:text-base"
              >
                {" "}
                À partir de :
              </label>
              <div className="-ml-3">
                <div
                  className={
                    "flex items-center md:hidden justify-between w-full ml-1 pt-2 rounded-lg "
                  }
                >
                  <TimePicker
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    TimePicker
                    showNow={false}
                    clearIcon=""
                    size="small"
                    suffixIcon=""
                  />
                </div>
                <div
                  className={
                    "md:flex items-center hidden justify-between w-full rounded-lg "
                  }
                >
                  <TimePicker
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-around mx-auto space-x-4 translate-y-16 md:hidden md:translate-y-44 md:w-full">
          <div className="flex justify-center w-1/2 font-bold bg-white border-2 rounded-full text-first border-first ">
            <div
              onClick={props.handleBack}
              className="w-full pt-2 text-sm text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="w-full px-3 py-2 text-sm font-bold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-bold border-first text-first active:bg-first active:text-white "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => props.handleBack()}
              className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(z) => handleSubmit(z)}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstType;
