import React from 'react';

import { styled } from '@mui/material/styles';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';




const StepperItem = (prop: {
    step: number,
    current?: boolean,
    complete?: boolean,
    last?: boolean
}) => {
    const CurrentCircularProgressWithLabel = (
        props: CircularProgressProps & { value: number },
    ) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress className='bg-white rounded-full' sx={{
                    color: "#0B2775",
                    zIndex: 1,
                }} size={42} thickness={4} variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="#0B2775"
                        sx={{
                            fontFamily: "nunito",
                            fontSize: "20px",
                            fontWeight: "bold"
                        }}
                    >{prop.last ?
                        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0022 13.3241V8.47507H18.9297L16.2754 5.53046L18.9297 2.58669H11.0022V0H9.99609V17.5403H11.0022V13.3241Z" fill="#0B2775" />
                            <path d="M12.009 12.3301V13.372C16.4715 13.7 19.9935 15.4751 19.9935 17.5304C19.9935 19.8171 15.6519 21.7561 10.5002 21.7561C5.34806 21.7561 1.00639 19.8168 1.00639 17.5304C1.00639 15.4754 4.53731 13.6999 8.99093 13.372L8.99072 12.3301C3.83878 12.6775 0 14.8483 0 17.5304C0 20.4827 4.61168 22.7886 10.5 22.7886C16.3883 22.7886 21 20.4827 21 17.5304C21 14.8481 17.1615 12.6772 12.0093 12.3301H12.009Z" fill="#0B2775" />
                        </svg>

                        :
                        prop.step} </Typography>
                </Box>
            </Box>
        );
    }
    const CompleteCircularProgressWithLabel = (
        props: CircularProgressProps & { value: number },
    ) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress className='rounded-lg bg-first' sx={{
                    color: "#0B2775",
                    zIndex: 1,
                }} size={42} thickness={4} variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="#fff"
                        sx={{
                            fontFamily: "nunito",
                            fontSize: "20px",
                            fontWeight: "bold"
                        }}
                    >{prop.last ?
                        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0022 13.3241V8.47507H18.9297L16.2754 5.53046L18.9297 2.58669H11.0022V0H9.99609V17.5403H11.0022V13.3241Z" fill="white" />
                            <path d="M12.009 12.3301V13.372C16.4715 13.7 19.9935 15.4751 19.9935 17.5304C19.9935 19.8171 15.6519 21.7561 10.5002 21.7561C5.34806 21.7561 1.00639 19.8168 1.00639 17.5304C1.00639 15.4754 4.53731 13.6999 8.99093 13.372L8.99072 12.3301C3.83878 12.6775 0 14.8483 0 17.5304C0 20.4827 4.61168 22.7886 10.5 22.7886C16.3883 22.7886 21 20.4827 21 17.5304C21 14.8481 17.1615 12.6772 12.0093 12.3301H12.009Z" fill="white" />
                        </svg>
                        :
                        prop.step}</Typography>
                </Box>
            </Box>
        );
    }

    const UncompleteCircularProgressWithLabel = (
        props: CircularProgressProps & { value: number },
    ) => {

        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress className='bg-white rounded-full' sx={{
                    color: "#D2D2D2",
                    zIndex: 1,
                }} size={42} thickness={4} variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="#D2D2D2"
                        sx={{
                            fontFamily: "nunito",
                            fontSize: "20px",
                            fontWeight: "bold"
                        }}
                    >{prop.last ?
                        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0022 13.3241V8.47507H18.9297L16.2754 5.53046L18.9297 2.58669H11.0022V0H9.99609V17.5403H11.0022V13.3241Z" fill="#C4C4C4" />
                            <path d="M12.009 12.3301V13.372C16.4715 13.7 19.9935 15.4751 19.9935 17.5304C19.9935 19.8171 15.6519 21.7561 10.5002 21.7561C5.34806 21.7561 1.00639 19.8168 1.00639 17.5304C1.00639 15.4754 4.53731 13.6999 8.99093 13.372L8.99072 12.3301C3.83878 12.6775 0 14.8483 0 17.5304C0 20.4827 4.61168 22.7886 10.5 22.7886C16.3883 22.7886 21 20.4827 21 17.5304C21 14.8481 17.1615 12.6772 12.0093 12.3301H12.009Z" fill="#C4C4C4" />
                        </svg>
                        :
                        prop.step}</Typography>
                </Box>
            </Box>
        );
    }
    if (prop.current) {
        return (
            <CurrentCircularProgressWithLabel value={100} />
        );
    } else if (prop.complete) {
        return (
            <CompleteCircularProgressWithLabel value={100} />
        );
    }
    return (
        <UncompleteCircularProgressWithLabel value={100} />
    );
};

export default StepperItem;