import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';

const Tenth = (props: any) => {
    const [commercialName, setCommercialName] = useState<number>()
    const [salaries, setSalaries] = useState<number>()
    const [salaryNumber, setSalaryNumber] = useState('')
    const [domainName, setDomainName] = useState<number>()
    const [domainNameValue, setDomainNameValue] = useState('')
    const [error, setError] = useState({
        commercialName: false,
        salaries: false,
        salaryNumber: false,
        domainName: false,
        domainNameValue:  false
    })
    const handleChangeCommercialName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommercialName(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSalaries = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSalaries(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeDomainName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomainName(parseInt((event.target as HTMLInputElement).value));
    };
    const getOptions = (value: string) => {
        if (value !== undefined) {
            setSalaryNumber(value)
        }
    }
    const validate = ()=>{
        let error_commercial_name = true
        let error_salaries = true
        let error_salary_number = true
        let error_domain_name = true
        let error_domain_name_value = true

        if(commercialName !== undefined && commercialName >= 0 ){
            error_commercial_name = false
        }else{
            error_commercial_name = true
        }
        if(salaries !== undefined){
            error_salaries = false
            if (salaries === 1) {
                if (salaryNumber !== '') {
                    error_salary_number = false
                }else{
                    error_salary_number = true
                }
            }else{
                error_salary_number = false
            }
            
        }else{
            error_salaries = true
            error_salary_number = true
        }
        if(domainName !== undefined && domainName >= 0 ){
            error_domain_name = false
            if (domainName === 1) {
                if (domainNameValue !== '') {
                    error_domain_name_value = false
                }else{
                    error_domain_name_value = true
                }
            }else{
                error_domain_name_value = false
            }
            
        }else{
            error_domain_name_value = true
            error_domain_name = true
        }
        setError({
            commercialName: error_commercial_name,
            salaries: error_salaries,
            salaryNumber: error_salary_number,
            domainName: error_domain_name,
            domainNameValue:  error_domain_name_value
        })
        if (!error_commercial_name && !error_salaries && !error_salary_number && !error_domain_name && !error_domain_name_value) {
            return true
        }
        return false
    }
    useEffect(() => {
        props.callBack({
            commercialName: commercialName,
            salaries: salaries,
            salaryNumber: salaryNumber,
            domainName: domainName,
            domainNameValue:  domainNameValue
        })
        if (validate ()) {
            props.nextStep(11)
        }
        // if (commercialName !== undefined && commercialName >= 0 && salaries !== undefined && salaries >= 0 ) {
        //     if (salaries !== undefined) {
        //         if (salaries === 1) {
        //             if (salaryNumber !== '') {
        //                 if (domainName !== undefined) {
        //                     if (domainName === 1) {
        //                         if (domainNameValue !== '') {
        //                             props.nextStep(11)
        //                         }
                               
        //                     }else{
        //                         props.nextStep(11)
        //                     }
        //                 }
        //             }
                   
        //         }else{
        //             if (domainName !== undefined) {
        //                 if (domainName === 1) {
        //                     if (domainNameValue !== '') {
        //                         props.nextStep(11)
        //                     }
                           
        //                 }else{
        //                     props.nextStep(11)
        //                 }
        //             }
        //         }
        //     }
            
        //  }
    }, [domainNameValue, commercialName, salaries, salaryNumber, domainName])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
            {error.commercialName ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Souhaitez-vous ajouter un nom commercial ?</label>
                <RadioGroup row
                    value={commercialName}
                    name="customized-radios"
                    onChange={handleChangeCommercialName}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex flex-col">
            {error.salaries ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Employez-vous des salariés ?</label>
                <RadioGroup row
                    value={salaries}
                    name="customized-radios"
                    onChange={handleChangeSalaries}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
           {
            salaries ?  <div className='gap-x-4 flex items-center'>
                {error.salaryNumber ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
            <label htmlFor="" className='text-normal text-lg font-regular'>Combien ? </label>
            <div className='w-max'>
                <SimplOptions defaultValue={salaryNumber} callback={getOptions}  data={[
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10'
                ]} customClass='bg-white  text-lg border-first bg-[rgba(146,211,255,0.17)] gap-x-2 px-1 py-1' />
            </div>
        </div>: null
           }
            <div className="flex flex-col">
            {error.domainName ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Souhaitez-vous ajouter un nom de domaine ? </label>
                <RadioGroup row
                    value={domainName}
                    name="customized-radios"
                    onChange={handleChangeDomainName}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            {domainName ? 
            <div>
                {error.domainNameValue ? <p className='text-third pb-1'>Veuillez renseigner un nom</p> : null}
            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-3 px-5 bg-white w-full'>
                <input type="text" placeholder="Nom de domaine activité" onBlur={(e) => setDomainNameValue(e.target.value)} className='w-full' />
            </div> 
            </div>: null}
        </div>
    );
};

export default Tenth;