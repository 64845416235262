import React, { useState } from 'react';
import NavBar from '../../../../../../../Components/Dashboard/NavBar';
import accountView from '../../../../../../../Assets/Images/tuto-nova/account.svg'
import activityView from '../../../../../../../Assets/Images/tuto-nova/activity.svg'
import interventionView from '../../../../../../../Assets/Images/tuto-nova/intervention.svg'
import reglementationView from '../../../../../../../Assets/Images/tuto-nova/reglementation.svg'
import caeView from '../../../../../../../Assets/Images/tuto-nova/cae.svg'
import renseignementView from '../../../../../../../Assets/Images/tuto-nova/renseignement-1.svg'
import sendView from '../../../../../../../Assets/Images/tuto-nova/send.svg'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Account from './Account';
import Activity from './Activity';
import Intervention from './Intervention';
import Reglementation from './Reglementation';
import Cae from './Cae';
import Renseignement from './Renseignement';
import Send from './Send';
const Index = () => {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#fff',
            borderRadius: '999px',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.13)'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '999px',
            backgroundColor: '#0B2775',
        },
    }));


    const [step, setStep] = useState(1)
    const nexStepper = ()=>{
        return step / 7 * 100
    }
    const getStep = (value: number) => {
        setStep(value)
    }
    const renderPcView = () => {
        switch (step) {
            case 1:
                return accountView
            case 2:
                return activityView
            case 3:
                return interventionView
            case 4:
                return reglementationView
            case 5:
                return caeView
            case 6:
                return renseignementView
            case 7:
                return sendView

            default:
                return accountView
        }
    }
    const renderStepScreen = () => {
        switch (step) {
            case 1:
                return <Account getStep={getStep} />
            case 2:
                return <Activity getStep={getStep} />
            case 3:
                return <Intervention getStep={getStep} />
            case 4:
                return <Reglementation getStep={getStep} />
            case 5:
                return <Cae getStep={getStep} />
            case 6:
                return <Renseignement getStep={getStep} />
            case 7:
                return <Send getStep={getStep} />

            default:
                return <Account getStep={getStep} />
        }
    }
    return (
        <div>
            <NavBar active={2} />
            <div className='p-4 lg:px-14 md:p-8'>
                <div className='flex flex-col gap-y-8'>
                    <h1 className='font-bold text-first lg:text-[32px] text-lg '>DÉCLARATION NOVA, LE TUTO !</h1>
                    <p className=' font-regular text-normal lg:text-2xl lg:leading-[32.74px] text-base'>Pour que vos élèves aient accès au crédit d'impôt <br /> instantané, il est indispensable que vous ayez reçu un <br />arrêté préfectoral NOVA vous donnant droit. </p>
                    <p className=' text-base font-regular text-normal lg:text-2xl lg:leading-[32.74px]'>Grâce a nôtre guide, faites votre déclaration en 5min top chrono ⏱ </p>
                </div>

                <div className='max-w-[1225px] border border-solid border-[rgba(33,156,255,0.2)] shadow-tuto-nova  rounded-[30px] md:my-8 relative md:pb-8 my-4 pb-14 h-full'>

                    <div className="flex justify-end py-2 pb-0 md:p-8">
                        <Button onClick={()=> setStep(7)}  className='block px-4 font-regular text-right capitalize rounded-full md:px-8 w-max text-third lg:text-base'>passer</Button>
                    </div>
                    <div className="flex flex-col justify-between p-4 pt-4 lg:p-10 gap-x-8 md:p-8 md:flex-row gap-y-8">
                        <div>
                            <img src={renderPcView()} alt="" />
                        </div>
                        <div className='flex flex-col w-full gap-y-4'>
                            <div className='flex items-center w-full gap-x-4'>
                                <div className="w-max">
                                    <h3 className='text-base font-bold text-first lg:text-2xl whitespace-nowrap'>ÉTAPE {step}/7</h3>
                                </div>
                                <div className="w-full">
                                    <BorderLinearProgress variant="determinate" value={nexStepper()} />
                                </div>
                            </div>
                            <div>
                                {renderStepScreen()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Index;