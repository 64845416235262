import { Button } from '@mui/material';
import React from 'react';
import NavBar from '../../../Components/ComponentsUtils/NavBar';

const Home = () => {
    return (
        <div>
            <NavBar />
           <div className='pt-14'>
           <div className='flex flex-col w-1/2 gap-10 ml-14'>
                <h1 className='text-4xl font-bold text-first drop-shadow-title-tuto-ae'>DEVENEZ <br />
                    AUTO-ENTREPRENEUR ! </h1>
                <p className='text-3xl font-regular text-first'>Qui aurait envie de passer à côté de <br />
                 50% de plus par heure de cours ?</p>
                <p className='text-3xl font-regular text-first'>Entièrement gratuit, Teach’r vous <br />
                 explique la marche à suivre.</p>
                <div className="flex justify-between gap-8 uppercase">
                    <Button className='w-full py-2 text-2xl font-bold text-white rounded-lg bg-first'>SE CREER LE STATUT</Button>
                    <Button className='w-full py-2 text-2xl font-bold text-white rounded-lg bg-first'>OBTENIR PLUS D’INFOS</Button>
                </div>
            </div>
           </div>
        </div>
    );
};

export default Home;