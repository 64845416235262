import React, { useEffect, useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import mandataireSign from '../../../../Assets/Images/sign.png'
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router';
import Teachr from '../../../../Objects/models/Teachr';
import NavBar from '../../../../Components/ComponentsUtils/NavBar';
import Stepper from './Stepper';
import First from './First';
import Second from './Second';
import Fourth from './Fourth';
import Third from './Third';
import Last from './Last';
import ImageRepository from '../../../../Repositories/ImageRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Fifth from './Fifth';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [file, setFile] = useState<any>();
    const [proofIentity, setProofIdentity] = useState<File>();
    const [pdfFile, setPdfFile] = useState<any>(process.env.PUBLIC_URL + '/Pouvoir_creation_SAP_VDEF_2607.pdf')
    const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null)
    const [numPages, setNumPages] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const generalTeachr = useSelector((state: any) => state.teachr)
    const [signUrl, setSignUrl] = useState<string>()
    const [signText, setSignText] = useState<string>()
    const [isSign, setIsSign] = useState(false)
    const [isSignText, setIsSignText] = useState(false)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
        setLoading(false)
    }
    useEffect(() => {
        if (generalTeachr) {
            if (generalTeachr.user) {
                if (generalTeachr.user.proofSignSap !== null) {
                    // setPdfFile(generalTeachr.user.proofIsAE.contentUrl)
                    setCurrentStep(6)
                }
                setTeachr(generalTeachr.user)
            }else{
                navigate('/declarons/login')
            }
           
        } else {
            navigate('/declarons/login')
        }
    }, [generalTeachr])
    useEffect(() => {
        const loadPDF = async () => {
            const response = await fetch(pdfFile);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            setPdfDoc(pdfDoc)
        };
        loadPDF();
    }, [pdfFile]);
    const changePdf = () => {
        (async () => {
            if (pdfDoc) {
                const pdfBytes = await pdfDoc.save();
                const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
                setFile(pdfBytes)
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfFile(pdfUrl)
            };
        }
        )()

    }

    const getInfos = (value: any) => {
        if (pdfDoc && value) {
            const fullNameField = pdfDoc.getForm().getTextField('text_1rgez');
            const birthDateField = pdfDoc.getForm().getTextField('text_2azmj');
            const cityBirthField = pdfDoc.getForm().getTextField('text_3bvew');
            const siretField = pdfDoc.getForm().getTextField('text_4kpir');
            const addressField = pdfDoc.getForm().getTextField('text_16unmp');
            const signCityField = pdfDoc.getForm().getTextField('text_5nlom');
            const codeApeField = pdfDoc.getForm().getTextField('text_7cfjc');
            const createdAtField = pdfDoc.getForm().getTextField('text_8fxmo');
            const enterprisePhoneField = pdfDoc.getForm().getTextField('text_9psux');
            const EnterpriseEmailField = pdfDoc.getForm().getTextField('text_10xfe');
            const ChiefFirstNameField = pdfDoc.getForm().getTextField('text_12nvnu');
            const ChiefLastNameField = pdfDoc.getForm().getTextField('text_11jqek');
            const ChiefPhoneField = pdfDoc.getForm().getTextField('text_13jxjc');
            const genderField = pdfDoc.getForm().getRadioGroup('radio_group_1ghva');
            if (value.firstName && value.firstName !== '' && value.birthName && value.birthName !== '') {
                fullNameField.setText(value.firstName + ' ' + value.birthName);
            }
            if (value.siret && value.siret !== '') {
                siretField.setText(value.siret);
            }
            if (value.birthDate && value.birthDate !== '') {
                birthDateField.setText(value.birthDate);
            }
            if (value.city && value.city !== '') {
                cityBirthField.setText(value.city);
            }
            if (value.address && value.address !== '') {
                addressField.setText(value.address);
            }
            if (value.signCity && value.signCity !== '') {
                signCityField.setText(value.signCity);
            }
            if (value.apeCode && value.apeCode !== '') {
                codeApeField.setText(value.apeCode);
            }
            if (value.createdAt && value.createdAt !== '') {
                createdAtField.setText(value.createdAt);
            }
            if (value.enterprisePhone && value.enterprisePhone !== '') {
                enterprisePhoneField.setText(value.enterprisePhone);
            }
            if (value.enterpriseEmail && value.enterpriseEmail !== '') {
                EnterpriseEmailField.setText(value.enterpriseEmail);
            }
            if (value.chiefFirstName && value.chiefFirstName !== '') {
                ChiefFirstNameField.setText(value.chiefFirstName);
            }
            if (value.chiefLastName && value.chiefLastName !== '') {
                ChiefLastNameField.setText(value.chiefLastName);
            }
            if (value.chiefPhone && value.chiefPhone !== '') {
                ChiefPhoneField.setText(value.chiefPhone);
            }
            if (value.chiefGender !== undefined) {
                switch (value.chiefGender) {
                    case 0:
                        genderField.select('Value_njst')
                        break;
                    case 1:
                        genderField.select('Value_ymsg')
                        break;
                    default:
                        genderField.select('Value_njst')
                        break;
                }
            }
            changePdf()
        }
    }
    const nextStep = (value: number) => {
        if (value === 5) {
            setPage(1)
            if (file && teachr && isSign && isSignText) {
                setLoading(true)
                ImageRepository.createImage(new File([file], 'proof-is-sign-sap', { type: 'application/pdf' })).then((response) => {
                    
                    teachr.proofIdentity = teachr.proofIdentity['@id']
                    if (teachr.proofIsAE) {
                        teachr.proofIsAE = teachr.proofIsAE['@id']
                    }
                    teachr.proofSignSap = response['@id']
                    TeachrRepository.updateTeachr(teachr).then((responseTeachr) => {
                        dispatch({
                            type: 'SET_INFO',
                            payload: {
                                teachr: responseTeachr
                            }
                        })
                        setCurrentStep(6)
                        setLoading(false)
                    })
                }).catch((error) => {
                    setLoading(false)
                })
            }else{
                setCurrentStep(value)
            }
        } else {
            setCurrentStep(value)
            setPage(value)
        }
    }
    const getSignature = (value: any) => {
        setSignUrl(value)
    }
    const getSignatureText = (value: any) => {
        setSignText(value)
    }
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                const { width, height } = currentPage.getSize();
                if (!isSign) {
                    if (signUrl) {
                        const dateField = pdfDoc.getForm().getTextField('text_6zzwq');
                        dateField.setText(moment().format('L'))
                        const imageBytes1 = await fetch(signUrl).then((res) => res.arrayBuffer());
                        const image1 = await pdfDoc.embedPng(imageBytes1);
                        const imageBytes2 = await fetch(mandataireSign).then((res) => res.arrayBuffer());
                        const image2 = await pdfDoc.embedPng(imageBytes2);
                        let imageWidth = 100;
                        let imageHeight = 30;
                        currentPage.drawImage(image1, {
                            x: 80,
                            y: imageWidth - imageHeight - 10,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        imageWidth = 230;
                        imageHeight = 40;
                        currentPage.drawImage(image2, {
                            x: width - imageWidth - 10,
                            y: 70,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setIsSign(true)
                        changePdf()
                    }

                }

            }
        }
        )()
    }, [signUrl])
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                if (!isSignText) {
                    if (signText) {
                        const imageBytes = await fetch(signText).then((res) => res.arrayBuffer());
                        const image = await pdfDoc.embedPng(imageBytes);
                        const imageWidth = 100;
                        const imageHeight = 20;
                        currentPage.drawImage(image, {
                            x: 80,
                            y: imageWidth - 10,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setIsSignText(true)
                        changePdf()
                    }
                }

            }
        }
        )()
    }, [signText, pdfFile])
    const renderScreen = () => {
        if (teachr && teachr.proofSignSap) {
            return <Last callBack={nextStep} /> 
        }else{
            switch (currentStep) {
                case 0:
                    return <First callBack={nextStep} />
                case 1:
                    return <Second sendData={getInfos} callBack={nextStep} />
                case 2:
                    return <Third sendData={getInfos} callBack={nextStep} />
                case 3:
                    return <Fourth index = {4} callBack={nextStep} />
                case 4:
                    return <Fourth index = {5} callBack={nextStep} />
                case 5:
                    return <Fifth loading={loading} getSignatureText={getSignatureText} getSignature={getSignature} callBack={nextStep} />
                case 6:
                    return <Last callBack={nextStep} />
                default:
                    return <First callBack={nextStep} />
            }
        }
        
    }
    return (
        <div className='h-screen overflow-hidden '>
            <header className='lg:bg-white bg-[rgba(239,246,255,1)]'>
                <NavBar />
            </header>
            <div className='flex h-full pb-14 '>
                <div className='h-full px-4 pt-10 pb-40 lg:w-1/4 md:px-8 lg:px-10'>
                    <h1 className='text-first font-bold lg:text-[22px] mb-8'>ESPACE PROCURATION SAP 📄</h1>
                    <Stepper current={currentStep} />
                </div>
                <div className='w-full overflow-y-scroll bg-[#F5FAFF] px-4 md:px-8 lg:px-10 pt-10'>
                    <div className='flex justify-between'>
                        <div className="flex items-center cursor-pointer gap-x-4" onClick={() => currentStep > 0 &&  setCurrentStep(currentStep - 2)}>
                            <span>
                                <svg width="39" height="34" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="39" height="34" rx="8" fill="#219CFF" fill-opacity="0.14" />
                                    <path d="M31 17.85C31.4694 17.85 31.85 17.4694 31.85 17C31.85 16.5306 31.4694 16.15 31 16.15L31 17.85ZM10.399 16.399C10.067 16.7309 10.067 17.2691 10.399 17.601L15.8083 23.0104C16.1403 23.3424 16.6785 23.3424 17.0104 23.0104C17.3424 22.6785 17.3424 22.1403 17.0104 21.8083L12.2021 17L17.0104 12.1917C17.3424 11.8597 17.3424 11.3215 17.0104 10.9896C16.6785 10.6576 16.1403 10.6576 15.8083 10.9896L10.399 16.399ZM31 16.15L11 16.15L11 17.85L31 17.85L31 16.15Z" fill="#0B2775" />
                                </svg>
                            </span>
                            <span className='text-first font-regular lg:text-[22px]'>Précédent</span>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className='py-10'>
                        <div className='flex justify-between gap-10'>
                            {currentStep !== 0 &&
                                <div>
                                    <Document
                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}

                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            }
                            <div className={currentStep !== 0 ? 'w-1/2' : 'w-full'}>
                                {renderScreen()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Index;