// import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import ResponseCourse from "./ResponseCourse";
import { styled } from "@mui/material/styles";
import ActiveCourse from "./ActiveCourse";
import { auth, getJWTToken } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import NoLessonRequest from "./Components/NoLessonRequest";
import NoActiveCourse from "./Components/NoActiveCourse";
import HistoricCourse from "./HistoricCourse";
import NoHistoriqueCourse from "./Components/NoHistoriqueCourse";
import NavBarClient from "../../../Components/Dashboard/NavbarClient";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#4B6AD8",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #4B6AD8",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0B2775",
  "&.Mui-selected": {
    color: "#4B6AD8",
  },
}));

export default function BasicTabs() {
  const [user] = useAuthState(auth);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }; 

  return (
    <div className="h-screen overflow-x-hidden list-teacher font-sans">
      <div className="fixed z-40 w-full bg-white">
        <NavBarClient active={5} />
        <div className="mx-4 md:mx-10 mt-10">
          <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
            <div className="hidden md:block">
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    Demande de attente
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    Prestation Prévue
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    Historique
                  </p>
                }
              />
            </StyledTabs>
            </div>
            <div className="-mt-10 md:hidden //border">
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    En attente
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    Prestation Prévue
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-regular lg:text-xl md:text-lg">
                    Historique
                  </p>
                }
              />
            </StyledTabs>
            </div>
            
          </Box>
        </div>
      </div>
      <div className="md:mx-2  md:mt-44 mt-32  ">
        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            {user ? <ResponseCourse /> : <NoLessonRequest />}   
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <ActiveCourse/> */}
            {user ?  <ActiveCourse/> : <NoActiveCourse />}  
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <HistoriqueComingsoon/> */}
            {user ?  <HistoricCourse/> : <NoHistoriqueCourse />}  
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
