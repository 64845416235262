import React from 'react';
const SearchBar = () => {
    return (
        <div>
            <div className='bg-[#F2F5FB] flex gap-3 items-center justify-around rounded-lg px-4  py-3'>
                <input className='w-full h-full bg-transparent text-[#808080] text-lg font-normal' type="text" placeholder='un élève, un cours, ... (BIENTÔT DISPONIBLE)' />
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0195 17.372L27.7347 25.0872L25.4056 27.4163L17.6904 19.7011V18.4638L17.2537 18.0271C15.3613 19.6283 13.1777 20.429 10.703 20.429C7.88868 20.429 5.49891 19.4464 3.53372 17.4812C1.56853 15.516 0.585938 13.1384 0.585938 10.3483C0.585938 7.55819 1.56853 5.18055 3.53372 3.21536C5.49891 1.25017 7.87655 0.267578 10.6666 0.267578C13.4567 0.267578 15.8344 1.25017 17.7995 3.21536C19.7647 5.18055 20.7473 7.57032 20.7473 10.3847C20.7473 12.8594 19.9467 15.0429 18.3454 16.9353L18.7821 17.372H20.0195ZM10.703 17.372C12.644 17.372 14.2937 16.6927 15.6524 15.334C17.011 13.9754 17.6904 12.3256 17.6904 10.3847C17.6904 8.44374 17.011 6.79395 15.6524 5.4353C14.2937 4.07665 12.644 3.39733 10.703 3.39733C8.7621 3.39733 7.11231 4.07665 5.75366 5.4353C4.39501 6.79395 3.71568 8.44374 3.71568 10.3847C3.71568 12.3256 4.39501 13.9754 5.75366 15.334C7.11231 16.6927 8.7621 17.372 10.703 17.372Z" fill="#0B2775" />
                </svg>
            </div>
        </div>
    );
};

export default SearchBar;