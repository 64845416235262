import { AlertColor } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Messages = () => {
  const MySwal = withReactContent(Swal)
  const generalMessage = useSelector((state: any) => state.message)
  const dispatch = useDispatch()
  useEffect(() => {
    if (generalMessage.type !== "") {
      displayErroMessage(generalMessage.type,generalMessage.message)
  
    } 
  }, [generalMessage])
  const displayErroMessage = (type: SweetAlertIcon  | undefined, message:string) => {
    MySwal.fire({
      showConfirmButton: true,
      allowOutsideClick: true,
      confirmButtonColor: '#0B2775',
      showClass: {
        popup: 'animate__animated animate__heartBeat'
      },
      hideClass: {
        popup: 'animate__animated animate__backOutDown'
      },
      icon: type,
      title: type === "error" ? "Une erreur est survenue" : type === "warning" ? "remarque" : "",
      text: message,
    })
    MySwal.getPopup()?.classList.add('shadow-notification-course')
    MySwal.getPopup()?.classList.add('rounded-2xl')
    MySwal.getPopup()?.classList.add('w-[602px]')
    MySwal.getPopup()?.classList.add('md:p-4')
  }
  return (
    <div>

      {/* <Stack sx={{ width: '100%' }} spacing={2}>
            {type && <Alert severity={type} className='justify-center lg:justify-start'>{message}</Alert>}
            </Stack> */}
    </div>
  );
};

export default Messages;