import React, { useEffect, useState } from 'react';


import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from '../../../../Components/Admin/DateInput';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import ValidateSiret from '../../../../Validation/Siret';


const dateInputStyle = {
    backgroundColor: '#fff',
    padding: '2px 8px',
    boxShadow: 'none',
    border: '1px solid rgba(77,77,77,0.29)',
    borderRadius: '9.3px'

}
const Second = (props: any) => {
    const [firstName, setFirstName] = useState('')
    const [birthName, setBirthName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [signCity, setSignCity] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [siret, setSiret] = useState('')
    const [error, setError] = useState({
        firstName: true,
        birthName: true,
        birthDate: true,
        signCity: true,
        city: true,
        address: true,
        siret: true
    })
    const validate = () => {
        let error_firstName = true
        let error_birthName = true
        let error_birthDate = true
        let error_signCity = true
        let error_city = true
        let error_address = true
        let error_siret = true
        if (firstName === '') {
            error_firstName = true
        } else {
            error_firstName = false
        }
        if (birthName === '') {
            error_birthName = true
        } else {
            error_birthName = false
        }
        if (birthDate === '') {
            error_birthDate = true
        } else {
            error_birthDate = false
        }
        if (city === '') {
            error_city = true
        } else {
            error_city = false
        }
        if (address === '') {
            error_address = true
        } else {
            error_address = false
        }
        if (signCity === '') {
            error_signCity = true
        } else {
            error_signCity = false
        }
        error_siret = !ValidateSiret(siret)
        setError({
            firstName: error_firstName,
            birthName: error_birthName,
            birthDate: error_birthDate,
            signCity: error_signCity,
            city: error_city,
            address: error_address,
            siret: error_siret
        })
        if (!error_firstName && !error_birthName && !error_birthDate && !error_signCity && !error_city && !error_address && !error_siret) return true
        return false
    }
    const getBirthday = (value: string) => {
        setBirthDate(value)
    }
    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            setAddress(response[0].formatted_address)
            setSignCity(response[0].address_components[2].long_name)
        })
    }
    const nextStep = () => {
        if (validate()) {
            props.callBack(2)
        }
    }
    useEffect(() => {
        if (firstName !== "" || birthName !== "" || birthDate !== "" || city !== "" || address !== "" || siret !== "") {
            props.sendData({
                firstName: firstName,
                birthName: birthName,
                birthDate: birthDate,
                city: city,
                address: address,
                signCity: signCity,
                siret: siret
            })

        }
    }, [address, birthDate, birthName, city, firstName, signCity, siret])

    return (
        <div className='flex h-full gap-10'>

            <form action="" autoComplete='false'>
                <div className='relative w-full'>
                    <div className='flex items-end pb-10 gap-x-4'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#0B2775" />
                            <path d="M20.4263 20.712C21.3063 20.712 21.7463 21.086 21.7463 21.834C21.7463 22.6113 21.3063 23 20.4263 23H12.9903C12.0956 23 11.6483 22.6113 11.6483 21.834C11.6483 21.086 12.0956 20.712 12.9903 20.712H15.2783V10.68L13.1663 11.978C12.961 12.0953 12.7776 12.154 12.6163 12.154C12.3083 12.154 12.0443 12.022 11.8243 11.758C11.619 11.494 11.5163 11.2007 11.5163 10.878C11.5163 10.4527 11.707 10.1227 12.0883 9.888L15.4983 7.776C15.9383 7.512 16.3563 7.38 16.7523 7.38C17.163 7.38 17.493 7.50467 17.7423 7.754C17.9916 8.00333 18.1163 8.348 18.1163 8.788V20.712H20.4263Z" fill="white" />
                        </svg>
                        <h2 className='text-first lg:text-[22px]'>Votre procuration :</h2>
                    </div>
                    <div className="grid justify-between w-full grid-cols-2 gap-4 items-end">
                        <div>
                            {error.firstName ? <p className='text-third pb-1'>Veuillez renseigner un prénom</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Prénom</label>
                                <input type="text" onBlur={(e) => setFirstName(e.target.value)} className='placeholder:text-[#808080] text-base bg-transparent w-full block' placeholder='Julie' />
                            </div>
                        </div>
                        <div>
                            {error.birthName ? <p className='text-third pb-1'>Veuillez renseigner un nom</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Nom de naissance</label>
                                <input type="text" onBlur={(e) => setBirthName(e.target.value)} placeholder='Dupont' className='placeholder:text-[#808080] text-base bg-transparent' />
                            </div>
                        </div>
                        <div>
                            {error.birthDate ? <p className='text-third pb-1'>Veuillez renseigner une date</p> : null}
                            <DateInput field='birthday' style={dateInputStyle} callBack={getBirthday} />
                        </div>
                        <div>
                            {error.city ? <p className='text-third pb-1'>Veuillez renseigner une ville</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Ville de naissance</label>
                                <input type="text" onBlur={(e) => setCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-base bg-transparent' />
                            </div>
                        </div>

                    </div>
                    <div className='my-4'>
                        {error.siret ? <p className='text-third pb-1'>Veuillez renseigner un numéro de SIRET</p> : null}
                        <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full '>
                            <label htmlFor="" className='block text-base'>Numéro de SIRET <span className='text-xs'>(SIREN + NIC)</span></label>
                            <input type="text" onBlur={(e) => setSiret(e.target.value)} placeholder='XXX XXX XXX XXXXX' className='placeholder:text-[#808080] text-base bg-transparent' />
                        </div>
                    </div>
                    <div className='w-full'>
                        <hr className='text-[rgba(128,128,128,0.80)] border-dashed my-8' />

                        <div className="flex gap-4 items-end">
                            <div className='w-full'>
                                {error.address ? <p className='text-third pb-1'>Veuillez renseigner une adresse</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-1 px-5 bg-white w-full'>
                                    <label htmlFor="" className='block text-base'>Adresse</label>
                                    <AddressComponent getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" />
                                </div>
                            </div>
                            <div className='w-full'>
                                {error.signCity ? <p className='text-third pb-1'>Veuillez renseigner une ville</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='block text-base'>Procuration faite à</label>
                                    <input defaultValue={signCity} type="text" onBlur={(e) => setSignCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-base bg-transparent' />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-end py-10">
                        <div className="flex justify-between w-full gap-10">
                            <Button onClick={() => props.callBack(0)} className='flex w-1/3 py-3 text-xl font-regular rounded-full gap-x-4 text-normal'>
                                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                PRÉCÉDENT
                            </Button>
                            <Button onClick={nextStep} className='w-1/3 py-3 text-xl font-bold text-white rounded-lg bg-first'>SUIVANT</Button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default Second;