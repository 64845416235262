import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Second = () => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        // console.log(open);

    }, [open])
    return (
        <div>
            <Accordion
                onChange={(event, expaned) => setOpen(expaned)}
                sx={{
                    '&': {
                        borderRadius: "20px !important",
                        border: "1px solid #219CFF"
                    }
                }}
            >
                <AccordionSummary
                    className={open ? 'bg-[#F2F9FF] rounded-[20px] rounded-b-none' : ''}
                    expandIcon={<div>
                        <svg className='hidden lg:block' width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.7803 22.3033C18.0292 22.3033 17.3163 22.0105 16.7817 21.4759L0.830649 5.5124C-0.276883 4.40489 -0.276883 2.62268 0.830649 1.5279C1.93818 0.420387 3.72042 0.420387 4.81522 1.5279L18.7803 15.4928L32.7454 1.5279C33.8529 0.420387 35.6352 0.420387 36.73 1.5279C37.8375 2.63541 37.8375 4.41762 36.73 5.5124L20.779 21.4759C20.2443 22.0105 19.5314 22.3033 18.7803 22.3033Z" fill="#4D4D4D" />
                        </svg>
                        <svg className='lg:hidden' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2344 12.8632C9.85444 12.8632 9.49382 12.6908 9.22336 12.3758L1.15456 2.97301C0.594314 2.32065 0.594314 1.27089 1.15456 0.626043C1.7148 -0.0263078 2.61634 -0.0263078 3.17015 0.626043L10.2344 8.85166L17.2986 0.626043C17.8588 -0.0263078 18.7604 -0.0263078 19.3142 0.626043C19.8744 1.27839 19.8744 2.32815 19.3142 2.97301L11.2454 12.3758C10.9749 12.6908 10.6143 12.8632 10.2344 12.8632Z" fill="#4D4D4D" />
                        </svg>
                    </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {open ?
                        <div>
                            <h3 className='text-normal font-regular lg:text-2xl text-base'>Calcul de l’impôt sur les revenus extérieurs</h3>
                        </div>
                        :
                        <div>
                            <h3 className='text-first font-regular lg:text-2xl text-base'>Calculs des impôts sur le montant des cours particuliers déclarés</h3>
                            <h4 className='text-normal font-regular lg:text-[22px]'>Étape 2 : Volet calcul de l’impôt sur les revenus extérieurs  </h4>
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails className='p-8 flex flex-col gap-y-8'>
                    <p className='font-regular text-normal lg:text-[22px] '>On considère que les revenus déclarés par le professeur pour ses cours particuliers viennent s’ajouter en dernière partie sur ses autres revenus. C’est-à-dire que nous allons d’abord calculer le montant d’impôts sur ses revenus extérieurs, puis y ajouter en dernière partie les impôts sur ses cours Teach’r.</p>
                    <div>
                        <h4 className='text-radio lg:text-[22px]  font-regular'>Quotient familial :</h4>
                        <p className='text-normal lg:text-lg text-sm font-regular'>Pour ce faire, nous avons besoin du quotient familial.</p>
                        <ul>
                            <li className='text-normal lg:text-lg font-regular text-sm'> → 1 ou 2 si la personne est mariée ou pacsée</li>
                            <li className='text-normal lg:text-lg font-regular text-sm'>→ +0.5 pour chaque enfant à charge, +0.25 en cas de garde alternée</li>
                        </ul>
                        <p className='text-normal lg:text-lg font-regular text-sm'> Le quotient familial est la somme des deux données ci-dessus. <br />
                            Par exemple, un couple marié avec 2 enfants à charge aura un quotient familial de 3.</p>
                    </div>
                    <div>
                        <h4 className='text-radio lg:text-[22px] font-regular'>Barème progressif :</h4>
                        <p className='text-normal lg:text-lg font-regular'>On prend le montant net d’impôts des revenus extérieurs du foyer fiscal auquel on enlève 10%.
                            On divise ensuite ce montant par le quotient familial que l’on soumet au barème progressif.
                            Ainsi, les 10.777 premiers € seront imposés à 0%, les revenus compris entre 10.778€ à 27.478€ seront imposés à 11%, etc. comme le tableau ci-dessous (exemple pour les revenus de 2022).</p>
                        <table>
                            <tr>
                                <th className='text-first font-[800] lg:text-xl border-[rgba(128,128,128,0.6)] border-2 text-left p-4'>Revenus 2022</th>
                                <th className='text-first font-[800] lg:text-xl border-[rgba(128,128,128,0.6)] border-2 text-left p-4'>Taux d’imposition</th>
                            </tr>
                            <tr>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4'>Revenus &lt; 10 777€ </td>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4 text-center'>0%</td>
                            </tr>
                            <tr>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4'>10 777€ &gt; Revenus &gt; 27 478€</td>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4 text-center'>11%</td>
                            </tr>
                            <tr>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4'>27 478€ &gt; Revenus &gt; 78 570</td>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4 text-center'>30%</td>
                            </tr>
                            <tr>
                                <td className=' font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4'>Ne relève plus du régime auto-entrepreneur BIC (prestation de services) au delà de 77 700€</td>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4 text-center'>41%</td>
                            </tr>
                            <tr>
                                <td className=' font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4'>Ne relève plus du régime auto-entrepreneur BIC au delà de 77 700€</td>
                                <td className='text-normal font-regular lg:text-lg border-[rgba(128,128,128,0.6)] border-2 p-4 text-center'>45%</td>
                            </tr>
                        </table>
                    </div>
                    <div className='flex flex-col gap-y-4'>
                        <h4 className='text-radio lg:text-[22px] font-regular'>Vérification du plafonnement dû au quotient familial :</h4>
                        <p className='text-normal font-regular lg:text-xl leading-[27.28px]'>Chaque enfant à charge permet de diminuer le montant d’impôts. Cet avantage a une limite qui est de 1.678€ par enfant à charge.
                            Pour vérifier si le plafonnement n’est pas dépassé, il s’agit d’effectuer ces quelques étapes :</p>
                        <ol>
                            <li className="text-normal lg:text-xl font-regular leading-[27.28px] before:content-['1/'] before:pr-2">Calcul d’impôts dans le cas où le foyer fiscal n’aurait aucun enfant à charge, que l’on va appeler Impôts-sans-demis-parts</li>
                            <li className="text-normal lg:text-xl font-regular leading-[27.28px] before:content-['2/'] before:pr-2">Calcul d’impôts dans le cas précis où ce foyer fiscal a x enfants à charge, que l’on va appeler Impôts-avec-demis-parts</li>
                            <li className="text-normal lg:text-xl font-regular leading-[27.28px] before:content-['3/'] before:pr-2">On calcule Impôts-sans-demis-parts - Impôts-avec-demis-parts</li>
                        </ol>
                        <ul>
                            <li className="text-normal lg:text-xl font-regular leading-[27.28px] before:content-['&#x2022;'] before:pr-2">Si le résultat calculé en 3/ est supérieur à x (nombre d’enfants dans le foyer fiscal) * 1.678€, alors on ajoute cette différence au montant d’impôts obtenus dans Impôts-avec-demis-parts</li>
                            <li className="text-normal lg:text-xl font-regular leading-[27.28px] before:content-['&#x2022;'] before:pr-2">Si ce résultat est inférieur à *x*1.678€,* alors on conserve *Impôts-avec-demis-parts* comme montant d’impôts pour ce foyer fiscal</li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Second;