import { SetStateAction, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AvatarF from '../../../../Assets/Images/profile__image.png'
import AvatarH from '../../../../Assets/Images/avatar-h.svg'
import startIcon from '../../../../Assets/Icons/star.svg'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, CircularProgress, Skeleton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import emptyComment from '../../../../Assets/Images/empty-comment.svg'

import Teachr from '../../../../Objects/models/Teachr';
import moment from 'moment';
import AddressRepository from '../../../../Repositories/AddressRepository';
import { Address } from '../../../../Objects/models/Address';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import closeCircle from '../../../../Assets/Icons/close_circle.svg'
import TeachrRepository from '../../../../Repositories/TeachrRepository';

import ReviewClientRepository from '../../../../Objects/models/ReviewClientRepository';
import ReviewClient from '../../../../Objects/models/ReviewClient';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Reviews from '../../../../Components/Dashboard/Account/Reviews';
import UploadButton from '../../../../Components/utils/UploadButton';
import Functions from '../../../../Helpers/Functions';
import ImageRepository from '../../../../Repositories/ImageRepository';
import Teachr_Repository from '../../../../Repositories/Teachr_Repository';
import { useNavigate } from 'react-router-dom';
import FacturationUpdate from './IsAe/FacturationUpdate';

const Profile = () => {
    const MySwal = withReactContent(Swal)
    const [profileLoading, setProfileLoading] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    const [address, setAddress] = useState<Address>()
    const [reviews, setReview] = useState<ReviewClient[]>()
    const [average, setAverage] = useState(0)
    const [tagHeight, setTagHeight] = useState(0)
    const [file, setFile] = useState();
    const [image, setImage] = useState<string>();
    const [erroImage, setErrorImage] = useState('')
    const getFile = (value: File, error: boolean) => {
        if (error) {
            setErrorImage('Mauvais format de fichier')
        } else {
            setErrorImage('')
            Functions.resizeFile(value, false).then((response: any) => {
                setFile(response);
                setImage(URL.createObjectURL(response))
            })
        }
    }
    
    const navigate = useNavigate()

    const [description, setDescription] = useState({
        value: '',
        editMode: false
    })

    useEffect(() => {
        const TagElement = document.getElementById('profile-left')
        if (TagElement) {
            setTagHeight(TagElement.offsetHeight)
        }
    })
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response) {
                setTeachr(response)
                setDescription({
                    value: response?.description,
                    editMode: false
                })
                ReviewClientRepository.getReviewsClient(1, ["punctualCourse.acceptedProposal.teachr.id=" + response.id]).then((responseReview) => {
                    if (responseReview) {
                        setReview(responseReview.members)
                    }
                })
            }
        })
        AddressRepository.getDefaultAddress().then((response) => setAddress(response))
    }, [])
    useEffect(() => {
        if (image && teachr) {
            setProfileLoading(true)
            ImageRepository.createImage(file).then((response) => {
                teachr.profilePicture = response['@id']
                if (teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity['@id']
                }
                if (teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE['@id']
                }
                if ( teachr.proofSignSap) {
                    teachr.proofSignSap = teachr.proofSignSap['@id']
                }
                TeachrRepository.updateTeachr(teachr).then(() => {
                    setProfileLoading(false)
                }).catch(() => {
                    setProfileLoading(false)
                })
            })
        }
    }, [image])

    const formatPhone = (phone: string | null | undefined) => {
        if (phone) {

            return '0' + phone.slice(3, 4) + '-' + phone.slice(4, 6) + '-' + phone.slice(6, 8) + '-' + phone.slice(8, 10) + '-' + phone.slice(10, 12)


        }
        return
    }
    useEffect(() => {
        averageReview()
    }, [reviews])
    const averageReview = () => {
        let notes = 0
        let total = 0

        if (reviews) {
            reviews.map((review) => {
                total = reviews.length
                notes += review.note!
            })
            setAverage(notes / total)
        }
    }

    const validateWithCode = (code:string) => {
        return Teachr_Repository.teachrValidateAccountWithCode(teachr!.id!, code!)
    }

    const showEditWidget = () => {
        let defaultAddress: Address | null = null
        const getPlaceId = (placeId: string) => {
            geocodeByPlaceId(placeId).then((response) => {
                const addressObject = new Address(
                    response[0].place_id,
                    response[0].address_components[0].long_name,
                    response[0].address_components[1].long_name,
                    response[0].address_components[2].long_name,
                    response[0].address_components[3].long_name,
                    response[0].address_components[4].long_name,
                    response[0].address_components[5].long_name,
                    response[0].address_components[6].long_name,
                    response[0].geometry.location.lat().toString(),
                    '',
                    response[0].formatted_address,
                    teachr?.['@id']!,
                    true)
                addressObject.sessionToken = ''
                defaultAddress = addressObject
            })
        }

        const createAddress = () => {
            let submitError = true
            AddressRepository.createAddress(defaultAddress!).then(() => {
                submitError = true
                return true
            })
                .catch((error) => {
                    submitError = false
                    return false
                })
            return submitError
        }
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            html:
                <div className='h-full'>
                    <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-first'>
                        <p className=''>Modifier mon adresse</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                            <label htmlFor="">Adresse</label>
                            <AddressComponent getPlaceId={getPlaceId} />
                        </div>
                        <div className="flex justify-end py-4">
                            <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-first lg:w-1/4 border-first'>Modifier</Button>
                        </div>
                    </div>
                </div>,
            preConfirm: () => {
                if (defaultAddress) {
                    return true
                } else {
                    Swal.showValidationMessage('Veuillez entrer une adresse valide')
                    return false
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    html:
                        <div className='h-full'>
                            <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-first'>
                                <p className=''>Modifier mon adresse</p>
                                <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <p>Êtes-vous sûr de vouloir changer d'adresse, celle-ci remplacera la précédente : {teachr?.address?.formattedAddress}</p>
                                <div className="flex justify-between gap-8 py-4">
                                    <Button onClick={() => Swal.close()} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-third border-third'>Annuler</Button>
                                    <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-first border-first'>Confirmer</Button>
                                </div>
                            </div>
                        </div>
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!createAddress()) {
                            Swal.showValidationMessage('Erreur: nous rencontrons un problème')
                        }
                    } else {
                        showEditWidget()
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('h-full')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }


    const showValidateWidget = () => {
        MySwal.fire({
            showCancelButton: false,
            showConfirmButton: false,
            html:
            <div className='h-full'>
                <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-first'>
                    <p className=''>Valider mon compte</p>
                    <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                </div>
                <div className='flex flex-col gap-4'>
                    <span className=''>Entrez votre code d'administration confidentiel</span>
                    <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                    <label className='block lg:text-base'>Mon code</label>
                    <input id="swal-input-code" className={` w-full lg:text-base bg-transparent placeholder:text-gray`} type="text" placeholder='...' />
                    </div>
                    <div className="flex py-8">
                        <Button onClick={() => Swal.clickConfirm()} 
                        className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-bold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-first border-first'>
                            Valider
                        </Button>
                    </div>
                </div>
            </div>,
            preConfirm: () => {
                const code = (MySwal!.getPopup()!.querySelector('#swal-input-code')! as HTMLInputElement).value;
                if(!code){
                    Swal.showValidationMessage(`Erreur : code invalide.`);
                    return;
                }
                return validateWithCode(code).then(response => {
                if (response[0]==="BAD_CODE") {
                    Swal.showValidationMessage(`Erreur : code invalide.`)
                  }else{
                    return { response : response[0] }
                  }
                })
            }
            , allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
            MySwal.fire({
                showCancelButton: false,
                showConfirmButton: false,    
                html:
                <div className='h-full'>
                    <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-first'>
                        <p className=''>Valider mon compte</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                            <span>Félicitations, votre compte est désormais validé 🥳</span>
                            <span>Vous pouvez dès maintenant accéder à votre Dashboard et profiter de l'avance immédiate. </span>
                        </div>
                        <div className="flex py-8">
                            <Button onClick={ () => {navigate('/declarons/dashboard');MySwal.close()}} className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-bold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-first border-first'>Dashboard</Button>
                        </div>
                    </div>
                </div>,
            })
        }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('h-half')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')

    }

    const updateDescription = () => {
        if (teachr) {
            teachr.description = description.value
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            TeachrRepository.updateTeachr(teachr).then((succes) => {
            }).catch((error) => { console.warn(error) })
        }

    }
    const getProfileImage = () => {
        if (teachr) {
            if (image) {
                return (<img className='w-full h-full rounded-full' src={image} alt="profile" />)
            } else {
                if (!teachr.profilePicture) {
                    if (teachr.sex === 'MAN') {
                        return (<img className='w-full h-full rounded-full' src={AvatarH} alt="profile" />)
                    }
                    return (<img className='w-full h-full rounded-full' src={AvatarF} alt="profile" />)
                } else {
                    return (<img className='w-full h-full rounded-full' src={teachr.profilePicture.contentUrl!} alt="profile" />)
                }
            }

        }
    }

    return (
        <div>
            <div className="flex flex-col gap-8 md:gap-8 md:flex-row lg:gap-12">
                <div className='flex flex-col gap-8 md:w-2/3 md:gap-8' id='profile-left'>
                    {teachr ?
                        <div className='flex flex-col p-4 rounded-lg lg:flex-row lg:gap-14 shadow-account text-normal'>
                            <div>
                                <div className='relative h-[136px] w-[136px]   mx-auto'>
                                    {profileLoading && <div className='absolute w-full top-1/3'>
                                        <div className="mx-auto w-max">
                                            <CircularProgress size="2rem" />
                                        </div>
                                    </div>}
                                    {getProfileImage()}
                                    <div className='absolute top-2 right-2'>
                                        <UploadButton getFile={getFile} button={<AddIcon className='p-[2px] text-2xl text-white font-regular cursor-pointer rounded-full bg-third' />} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center w-full lg:justify-between lg:flex-row-reverse">
                                <div className='flex flex-col items-center justify-center py-2 text-sm lg:justify-between text-first'>
                                    <div>
                                        <div className="flex rounded-full justify-center w-max gap-2 items-center bg-[rgba(0,0,0,0.1)] p-2 mx-auto">
                                            <img src={startIcon} alt="" />
                                            <p className='text-[16px] font-regular text-black'>{average === 0 ? 'N/A' : average.toFixed(1)}</p>
                                        </div>
                                        <h1 className='py-2'>Membre depuis {moment(teachr?.creationDatetime).format('YYYY')}</h1>
                                    </div>
                                    <div className=''>
                                        <Button onClick={() => showEditWidget()} className=" bg-[#0B2775] rounded-xl py-1 text-[12px] text-white border-solid border-[2px] border-[#0B2775] font-regular no-underline uppercase px-6" >
                                            <span className='uppercase'>éditer mon adresse</span>
                                        </Button>
                                    </div>
                                </div>
                                <div className='py-4'>
                                    <h1 className='text-xl font-regular capitalize text-first'><span className='font-bold uppercase'>{teachr?.lastName}</span> {teachr?.firstName} <br />{moment().subtract(moment(teachr?.birthday).format('YYYY'), 'year').format('YY')} ans</h1>
                                    <ul className='flex flex-col justify-center gap-2 pt-4 lg:justify-start'>
                                        <li className='text-sm'><EmailIcon className='mr-2 text-first' />{teachr?.mail}</li>
                                        <li className='text-sm'><LocalPhoneIcon className='mr-2 text-first' />{formatPhone(teachr?.phone)}</li>
                                        <li className='text-sm'><LocationOnIcon className='mr-2 text-first' />{address?.formattedAddress} </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        :
                        <Skeleton variant="rounded" width={"100%"} height={200} />}
                    {teachr ? <div className='p-4 my-2 rounded-lg lg:px-12 lg:pb-12 shadow-account text-first'>
                        <h1 className='py-2 font-bold uppercase text-first'>Ma description <DescriptionIcon /></h1>
                        <div className="py-4">
                            <textarea readOnly={description.editMode ? false : true} value={description.value} onChange={(e) => setDescription({ value: e.target.value, editMode: true })} className="text-left lg:h-[160px] md:h-[100px] h-[160px] w-full resize-none border-2 border-[rgba(0,0,0,0.3)]  p-3 text-normal outline-none rounded-xl" placeholder="Expliquez ici à l’élève ou parent pourquoi il devrait retenir votre candidature et pas une autre. Pensez à soigner l’orthographe 😉"></textarea>
                        </div>
                        <div className='flex justify-end'>
                            {!description.editMode ? <Button onClick={() => setDescription({ value: description.value, editMode: true })} className=" bg-[#0B2775] rounded-xl py-1 text-[12px] text-white border-solid border-[2px] border-[#0B2775] font-regular no-underline uppercase px-6" >
                                éditer ma description
                            </Button> :
                                <Button onClick={() => { setDescription({ value: description.value, editMode: false }); updateDescription() }} className="bg-[#0B2775] rounded-xl py-1 text-[12px] text-white border-solid border-[2px] border-[#0B2775] font-regular no-underline uppercase px-6" >
                                    Valider ma description
                                </Button>}
                        </div>
                    </div> :
                        <Skeleton variant="rounded" width={"100%"} height={200} />
                    }
                    <FacturationUpdate/>
                    <Button onClick={showValidateWidget} className="w-full uppercase" 
                    style={{background: "#4B6AD8", boxSizing: "border-box",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius: 4, color:"white", height: 32}}
                    
                    >
                        <span className='uppercase'>Valider mon compte</span>
                    </Button>
                    <div className='pb-5' />

                </div>
                {reviews ? reviews.length > 0 ?
                    <div className='px-4 pt-4 mb-2 rounded-lg lg:w-1/3 shadow-account' style={{ height: `${tagHeight - 8}px` }}>
                        <div>
                            <h1 className='py-2 text-xl font-bold uppercase text-first'>mes avis</h1>
                            <div className='flex items-center gap-2 text-base font-regular text-first'>
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.89919 14.3704L9.43991 14.1267L8.97664 14.3627L4.32593 16.7316L5.24878 11.6523L5.34352 11.1309L4.96523 10.7597L1.26973 7.13397L6.43713 6.43683L6.95693 6.3667L7.19474 5.89921L9.55023 1.26869L11.8272 5.93343L12.0572 6.40471L12.5757 6.48344L17.7292 7.26599L13.9721 10.8314L13.5878 11.1961L13.6737 11.7189L14.5111 16.8172L9.89919 14.3704Z" stroke="#0B2775" stroke-width="2" />
                                </svg>
                                {reviews?.length > 1 ? reviews?.length + " commentaires" : reviews?.length + " commentaire"}
                            </div>
                        </div>
                        <div className="flex flex-col pr-4 my-8 overflow-y-auto gap-y-2 scroll h-[80%]" >
                            {
                                reviews.map((review) => (<Reviews review={review} />))
                            }

                        </div>
                    </div>
                    :
                    <div>
                        <h2 className='text-lg font-regular text-center text-first lg:text-2xl'>Aucun teach’r n’a laissé de commentaires pour l’instant</h2>
                        <div className="py-8">
                            <img src={emptyComment} alt="" className='mx-auto' />
                        </div>
                    </div>
                    :
                    <Skeleton variant="rounded" className='lg:w-1/3' height={tagHeight} />
                }


          
            </div>

        </div>
    );
};

export default Profile;