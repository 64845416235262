import React, { useEffect, useState } from 'react';

const Second = (props: any) => {
    const [select, setSelect] = useState(0)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (select > 0) {
            setError(false)
            props.callBack({
                situation: select
            })
            props.nextStep(3) 
        }else{
            setError(true)
        }

    }, [select])
    return (
        <div className=''>
            {error ? <p className='text-third pb-1'>Veuillez sélectionner une situation</p> : null}
            <label htmlFor="" className='text-normal text-xl font-regular'>Situation matrimoniale :</label>
            <ul className="flex flex-col ml-8 gap-y-4 py-4 cursor-default">
                <li onClick={() => setSelect(1)} className={`${select === 1 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Célibataire </li>
                <li onClick={() => setSelect(2)} className={`${select === 2 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Divorcé.e </li>
                <li onClick={() => setSelect(3)} className={`${select === 3 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Veuf.ve </li>
                <li onClick={() => setSelect(4)} className={`${select === 4 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Marié.e </li>
                <li onClick={() => setSelect(5)} className={`${select === 5 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Pacsé.e </li>
                <li onClick={() => setSelect(6)} className={`${select === 6 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>En concubinage </li>
            </ul>
        </div>
    );
};

export default Second;