import React from 'react';
import NavBar from '../../../../Components/ComponentsUtils/NavBar';
import simulateurBg from '../../../../Assets/Images/simulateur_bg.svg'
import First from './First';
import Third from './Third';
import Second from './Second';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate()
    return (
        <div className='md:h-screen lg:pb-20 h-full'>
            <header className='lg:bg-white bg-[rgba(239,246,255,1)]'>
                <NavBar />
            </header>
            <main className='h-full'>
                <div className='flex flex-col justify-between h-full bg-bottom bg-no-repeat bg-cover lg:px-14 px-4 md:px-8 pb-8 lg:pb-0' style={{ backgroundImage: `url(${simulateurBg})` }}>
                    <div className='flex flex-col gap-y-4 py-10'>
                        <h3 className='text-[#B7B7B7] font-regular lg:text-lg text-base'>JUSQU'À 50% À 80% EN PLUS SUR VOS COURS PARTICULIERS</h3>
                        <h1 className='font-[900] text-first lg:text-[32px] lg:leading-[43.65px] uppercase'>LES ÉTAPES POUR CONNAITRE SON BENEFICE NET DE SES COURS PARTICULIERS</h1>
                        <h2 className='font-bold text-first lg:text-[26px] lg:leading-[38.19px]'>Renseignez vous ici sur toutes les étapes afin de connaitre le montant d’impôt et de cotisations sociales. </h2>
                        <h4 className='text-normal lg:text-xl font-regular text-sm'>Un auto-entrepreneur a le droit à un abattement de 34% sur les impôts qu’il déclare, lorsqu’il exerce une activité de service aux particuliers.
                            <br /> C'est-à-dire que l’auto-entrepreneur ne paiera pas d'impôts sur la totalité de son C.A. mais seulement sur la partie du C.A. après abattement.
                            <br />Cet abattement ne concerne que les auto-entrepreneur qui choisissent le régime classique d’imposition.</h4>
                    </div>
                    <div className='flex flex-col gap-y-8'>
                        <First />
                        <Second />
                        <Third />
                        <div className='flex flex-col gap-y-4'>
                            <Button className='text-white bg-radio rounded-[30px] font-bold lg:text-2xl mx-auto block px-10 py-2 text-base' onClick={()=> navigate('/declarons/simulate')}>SIMULATEUR ET DIAGNOSTIQUE</Button>
                            <Button className='text-normal font-regular lg:text-lg mx-auto bg-transparent block text-sm' onClick={()=> navigate(-1)}>PRÉCÉDENT</Button>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    );
};

export default Index;