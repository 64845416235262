import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import SubjectsSelect from '../../../../Components/Dashboard/Account/SubjectSelect'
// import Input from '../../../../Components/utils/Input'
// import SimpleSelect from '../../../../Components/utils/SimpleSelect'
import StepperStore from '../../../../LocalForage/StepperStore'
import { Subject } from '../../../../Objects/models/Subject'
import { auth } from '../../../../Services/FirebaseService'
import validateName from '../../../../Validation/Name'
import validateSelect from '../../../../Validation/Select'
import validateSelects from '../../../../Validation/Selects'
import { useDispatch, useSelector } from 'react-redux'
import Teachr from '../../../../Objects/models/Teachr'
import moment from 'moment'
import ImageRepository from '../../../../Repositories/ImageRepository'
import TeachrRepository from '../../../../Repositories/TeachrRepository'
import ApiService from '../../../../Services/ApiService'

function Second(props: any) {
  const generalTeachr = useSelector((state: any) => state.teachr)
  const dispatch = useDispatch()
  const [teachr, setTeachr] = useState<Teachr>()
  const [file, setFile] = useState<File>()
  const [subjectsToTeach, setSubjectsToTeach] = useState<{
    id: string,
    level: number
  }[]>([])

  useEffect(() => {
    // console.log(generalTeachr);
    if (generalTeachr.user) {
      setTeachr(generalTeachr.user)
    }
    if (generalTeachr.file) {
      setFile(generalTeachr.file)
    }
  }, [generalTeachr])
  const [user] = useAuthState(auth)
  const [jwtToken, setJwtToken] = useState<string>('')
  const [level, setLevel] = useState("");
  // const [courseType, setCourseType] = useState("");
  const [school, setSchool] = useState("");
  const [courses, setCourses] = useState<Subject[]>([]);
  const [extraCourses, setExtraCourses] = useState('');
  // const [postCourses, setPostCourses] = useState<Subject[]>([]);
  const [loading, setLoading] = useState(true)
  const getLevel = (value: any) => {
    setLevel(value)
  };
  const getSchool = (value: any) => {
    setSchool(value)
  };
  const getCourses = (value: Subject[]) => {
    let array: any[] = []
    value.map((subject) => (array.push(subject['@id'])))
    setCourses(value)
  };
  const getExtraCourses = (value: string) => {
    setExtraCourses(value)
  };
  const [errors, setErrors] = useState({
    courses: ""
  });
  const validate = () => {
    // let errlevel = true
    // // let errcourseType = true
    // let errschool = true
    let errcourses = true

    // if (!validateSelect(level).isValid) {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     // courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errlevel = true;
    // } else {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     // courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errlevel = false;
    // }
    // if (!validateSelect(courseType).isValid) {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errcourseType = true;
    // } else {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errcourseType = false;
    // }
    // if (!validateName(school).isValid) {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     // courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errschool = true;
    // } else {
    //   setErrors({
    //     level: validateSelect(level).message,
    //     // courseType: validateSelect(courseType).message,
    //     school: validateName(school).message,
    //     courses: validateSelects(courses).message
    //   });
    //   errschool = false;
    // }
    if (!validateSelects(courses).isValid && !validateName(extraCourses).isValid) {
      setErrors({
        courses: validateSelects(courses).message
      });
      errcourses = true;
    } else {
      setErrors({
        courses: ''
      });
      errcourses = false;
    }
    if (errcourses) return false;
    else return true;
  };
  const subjectFormat = (subjects: Subject[]) => {
    let result = ''
    subjects.map((subject: Subject, index) => {
      if (index === subjects.length - 1) {
        result += subject.fr_FR
      } else {
        result += subject.fr_FR + ', '
      }

    })
    return result
  }
  const handleSubmit = (event: any) => {
    if (validate()) {
      console.log(teachr)
      if (teachr) {
        teachr.firebaseAuthToken = jwtToken
        teachr.yearInStudies = parseInt(level)
        teachr.whereStudies = school
        const description = `Bonjour,
        Je m’appelle ${teachr.firstName} et j’ai ${moment().subtract(moment(teachr.birthday).format('YYYY'), 'year').format('YY')} ans. 
        Je suis professeur particulier en ${subjectFormat(courses)} ${extraCourses !== undefined ? ', ' + extraCourses : ''} et inscrit sur Teach’r depuis ${moment().format('MMMM YYYY')}.
        Au plaisir d’échanger avec vous,
        Cordialement`
        teachr.description = description
        if (courses.length > 0) {
          let subjectsId: string[] = []
          courses.map((subject: Subject) => {
            subjectsId.push(subject['@id']!)
          })
          teachr.subjectToTeach = subjectsToTeach
        }
        if (extraCourses !== "") {
          teachr.otherSubjects = extraCourses
        }
        TeachrRepository.createTeachr(teachr).then((responseTeachr) => {
          ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)

          if (file) {
            ImageRepository.createImage(file).then((responseImage) => {
              responseTeachr.profilePicture = responseImage['@id']

              TeachrRepository.updateTeachr(responseTeachr).then((response) => {
                dispatch({
                  type: 'SET_INFO',
                  payload: {
                    teachr: response,
                    file: file,
                    authorization: "teachr " + jwtToken
                  }
                })
                props.nextStep(4)
              }).catch((error) => {
                document.getElementById('teachr-loader')?.classList.add('hidden')
              })
            }).catch((error) => {
              document.getElementById('teachr-loader')?.classList.add('hidden')
            })
          } else {
            dispatch({
              type: 'SET_INFO',
              payload: {
                teachr: responseTeachr,
                file: file,
                authorization: "teachr " + jwtToken
              }
            })
            props.nextStep(4)
          }

        }).catch((error) => {
          if (error._description.search("This user is already registered.") > -1) {
            TeachrRepository.getCurrentTeachr().then((response) => {
              setTeachr(response)
            }).catch(() =>
              document.getElementById('teachr-loader')?.classList.add('hidden'))
          } else {
            document.getElementById('teachr-loader')?.classList.add('hidden')
          }
        })


      }



      // StepperStore.setItem("secondStepData" + user?.uid, {
      //   'level': level,
      //   // 'courseType': courseType,
      //   'school': school,
      //   'courses': courses,
      //   // 'postCourses': postCourses,
      //   'extraCourse': extraCourses
      // }, function (err) { });
      // props.nextStep(3)
    }
    event.preventDefault();
  };
  useEffect(() => {

    if (user) {
      user.getIdToken().then((token) => setJwtToken(token))
      StepperStore.getItem("secondStepData" + user.uid).then((response: any) => {
        if (response) {
          setLevel(response.level)
          setSchool(response.school)
          setCourses(response.courses)
          setExtraCourses(response.extraCourse)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    }
  }, [user])
  const selectExperience = (course: string | undefined, level: number) => {
    if (course) {
      subjectsToTeach.push({ id: course, level })
    }
  }
  const renderExperience = () => {
    if (courses) {
      return courses.map((course) => {
        return <div>
          <h2 className='pt-4 text-lg font-bold text-first xl:text-xl lg:text-lg md:text-base' >Votre niveau d’expérience ? </h2>
          <div>
            <p className='text-[#4d4d4d] xl:text-lg  lg:text-base md:text-sm pt-2 text-xs ' >Depuis combien de temps travaillez-vous pour des prestations de : {course.fr_FR} ? </p>
            <div className='flex justify-around pt-3'>
              <div className='bg-[#F4F7F8] border-[1px] border-[#80808050] px-2 py-1 xl:text-lg  lg:text-base md:text-sm  text-[#4d4d4d] rounded-sm cursor-pointer' onClick={() => selectExperience(course['@id'], 0)} >0-5ans</div>
              <div className='bg-[#F4F7F8] border-[1px] border-[#80808050] px-2 py-1 xl:text-lg  lg:text-base md:text-sm  text-[#4d4d4d] rounded-sm cursor-pointer' onClick={() => selectExperience(course['@id'], 1)}>5-10ans</div>
              <div className='bg-[#F4F7F8] border-[1px] border-[#80808050] px-2 py-1 xl:text-lg  lg:text-base md:text-sm  text-[#4d4d4d] rounded-sm cursor-pointer' onClick={() => selectExperience(course['@id'], 2)}>10-20ans</div>
              <div className='bg-[#F4F7F8] border-[1px] border-[#80808050] px-2 py-1 xl:text-lg  lg:text-base md:text-sm  text-[#4d4d4d] rounded-sm cursor-pointer' onClick={() => selectExperience(course['@id'], 3)}>+ de 20ans</div>
            </div>
          </div>
        </div>
      })
    }
    return <div></div>
  }


  return (
    <div className=''>
      <div className="/flex /flex-col /justify-start /gap-6 lg:gap-8 p-6 md:w-4/5  md:mx-0 mx-4 lg:flex-row bg-[#FFFFFF] border-[#758FDA78] border-[1px] rounded-lg">
        <h1 className='text-lg font-bold text-first xl:text-3xl lg:text-2xl md:text-xl' >Bienvenue parmis nous ! </h1>
        <h2 className='pt-4 text-lg font-bold text-first xl:text-xl lg:text-lg md:text-base' >Quelle(s)  prestation(s) souhaitez vous donner ? </h2>
        <div className='items-start mx-auto /grid /md:grid-cols-2 /gap-x-12 /lg:gap-x-32 /gap-y-2 /md:gap-y-4'>

          <div className='box-content h-full'>
            <p className="h-6 font-bold text-third">
              {errors.courses === "" ? null : errors.courses}
            </p>
            <SubjectsSelect default={courses} label='Quelle(s) matière(s) souhaitez-vous enseigner ?' placeholder="Jardinage, bricolage, service ménagés..." getSubjects={getCourses} customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-3' badgeClass=' border-white text-white' />


          </div>
          {renderExperience()}
          <div className='flex flex-col mt-12 md:gap-4 md:flex-row '>
            <button className='w-full px-4 py-2 mt-4 font-bold text-white uppercase rounded-lg md:order-last font-nunito bg-first' onClick={event => handleSubmit(event)}>Enregistrer</button>
            <button className='w-full px-4 py-2 font-bold uppercase border-white rounded-lg md:text-white text-first md:bg-first focus:border font-nunito md:mt-4' onClick={event => props.prevStep(2)}>précédent</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Second