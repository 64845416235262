import RadioButton from '../../../../../Components/Utilitis/RadioButton';
import prifile from "../../../../../Assets/Icons/profile.svg"
// import { ReportHandler } from 'web-vitals';
// import Radio from '@mui/material/Radio';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar, ListItemAvatar } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ChildRepository from '../../../../../Repositories/ChildRepository';
import { useEffect, useState } from 'react';
import PopUpVerification from '../UtilsDashboard/PopUpVerification';
import React from 'react';

const ComponentChildDeclareCourse = (props: any) => {
    const { child, teachrId, handleLoad } = props
    const [children, setChildren] = useState<any>()

    useEffect(() => {
        ChildRepository.getChildren(child.id).then((res) => {
            setChildren(res)
        })
    }, [child.id])


    return (

        <>
            {child?.id_client_tiers_prestation !== null &&
                <div className='//flex w-full  '>
                    {children?.map((childs: any) => (
                        <div className='flex w-full '>

                            <div className=' w-10 h-10 md:mt-0 font-sans'>
                                <div className='bg-first text-center text-white text-lg font-bold pt-1 w-10 h-10 rounded-full'>
                                {childs.firstName[0]}
                                </div>
                            </div>
                            <div className='font-sans w-3/4 lg:text-lg md:text-base text-base pt-1 pl-4 //md:pl-0 text-first font-regular'>
                                {childs.firstName}
                            </div>
                            <div className=' w-1/4 md:mt-1  md:pl-10 pl-4'>
                                <FormControlLabel
                                    value={childs.id}
                                    key={childs.id}
                                    control={child?.is_client_validate === null || child?.is_client_validate === false || child?.is_client_validate === undefined ? <PopUpVerification client={child} teachrId={teachrId} handleLoad={handleLoad} /> : <RadioButton />}
                                    // control={<RadioButton />}
                                    labelPlacement="start"
                                    label=""
                                />
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default ComponentChildDeclareCourse;