import React from 'react';
import monabanqCreditCardS from "../../Assets/Images/monabanq-double-credit-card.png"
const First = () => {
    return (
        <section className='bg-[#424242] text-white lg:py-10 py-4 font-sans'>
            <div className="mx-auto lg:w-[90%] flex flex-col gap-y-10 px-4 md:px-8 lg:px-0">
                <h2 className='text-white lg:text-[26px] font-regular text-lg font-sans'>SANS FRAIS CACHÉS NI CONDITIONS DE REVENU</h2>
                <div className='flex items-start lg:items-center lg:gap-x-10 gap-x-4'>
                    <img className='w-[37px] lg:w-[114px]' src={monabanqCreditCardS} alt="" />
                    <p className='lg:text-[22px] text-[12px] font-sans'>Chez Monabanq, tous les comptes courants et toutes les cartes bancaires sont accessibles sans condition de revenus et sont sans frais cachés.
                        Cette absence de condition de revenus est valable aussi pour les cartes Visa Platinum et Visa Premier, lesquelles proposent plus d’assurances et d’assistances pour vos déplacements.</p>
                </div>
                <div className='flex flex-col gap-y-8'>
                    <h2 className='text-white lg:text-[26px] font-regular text-lg font-sans'>ASTUCES ET OUTILS POUR PILOTER VOS DÉPENSES GRATUITEMENT</h2>
                    <div className='grid w-full grid-cols-1 gap-4 lg:grid-cols-2'>
                        <div className='flex lg:w-3/4 gap-x-4'>
                            <div className='hidden lg:block'>
                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="21.5" cy="21.5" r="20" stroke="#BAD606" stroke-width="3" />
                                    <path d="M17.1172 18.1172V16.5C18.6406 16.3516 19.7031 16.1055 20.3047 15.7617C20.9062 15.4102 21.3555 14.5859 21.6523 13.2891H23.3164V30H21.0664V18.1172H17.1172Z" fill="#BAD606" />
                                </svg>

                            </div>
                            <div className='lg:hidden'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.5" cy="12.5" r="11.5" stroke="#BAD606" stroke-width="2" />
                                    <path d="M9.94336 10.0684V9.125C10.832 9.03841 11.4518 8.89486 11.8027 8.69434C12.1536 8.48926 12.4157 8.00846 12.5889 7.25195H13.5596V17H12.2471V10.0684H9.94336Z" fill="#BAD606" />
                                </svg>


                            </div>
                            <p className="lg:text-[22px] text-[12px] font-sans"><span className='text-[#BAD606] font-regular'>Les alertes SMS</span> pour connaître la situation de votre compte et ajuster votre budget </p>
                        </div>
                        <div className='flex order-4 lg:w-3/4 gap-x-4 lg:order-none'>
                            <div className='hidden lg:block'>
                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="21.5" cy="21.5" r="20" stroke="#BAD606" stroke-width="3" />
                                    <path d="M22.7539 24.0586V16.4531L17.375 24.0586H22.7539ZM22.7891 30V25.8984H15.4297V23.8359L23.1172 13.1719H24.8984V24.0586H27.3711V25.8984H24.8984V30H22.7891Z" fill="#BAD606" />
                                </svg>

                            </div>
                            <div className='lg:hidden'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.5" cy="12.5" r="11.5" stroke="#BAD606" stroke-width="2" />
                                    <path d="M13.2314 13.5342V9.09766L10.0938 13.5342H13.2314ZM13.252 17V14.6074H8.95898V13.4043L13.4434 7.18359H14.4824V13.5342H15.9248V14.6074H14.4824V17H13.252Z" fill="#BAD606" />
                                </svg>


                            </div>
                            <p className="lg:text-[22px] text-[12px] font-sans"><span className='text-[#BAD606] font-regular'>La carte qui épargne</span> avec laquelle les achats carte bancaire sont arrondis à l’euro supérieur puis placés, chaque fin de mois sur un Livret d’Epargne Monabanq. </p>
                        </div>
                        <div className='flex order-2 lg:w-3/4 gap-x-4 lg:order-none'>
                            <div className='hidden lg:block'>
                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="21.5" cy="21.5" r="20" stroke="#BAD606" stroke-width="3" />
                                    <path d="M15.5703 30C15.6484 28.5547 15.9453 27.2969 16.4609 26.2266C16.9844 25.1562 18 24.1836 19.5078 23.3086L21.7578 22.0078C22.7656 21.4219 23.4727 20.9219 23.8789 20.5078C24.5195 19.8594 24.8398 19.1172 24.8398 18.2812C24.8398 17.3047 24.5469 16.5312 23.9609 15.9609C23.375 15.3828 22.5938 15.0938 21.6172 15.0938C20.1719 15.0938 19.1719 15.6406 18.6172 16.7344C18.3203 17.3203 18.1562 18.1328 18.125 19.1719H15.9805C16.0039 17.7109 16.2734 16.5195 16.7891 15.5977C17.7031 13.9727 19.3164 13.1602 21.6289 13.1602C23.5508 13.1602 24.9531 13.6797 25.8359 14.7188C26.7266 15.7578 27.1719 16.9141 27.1719 18.1875C27.1719 19.5312 26.6992 20.6797 25.7539 21.6328C25.207 22.1875 24.2266 22.8594 22.8125 23.6484L21.207 24.5391C20.4414 24.9609 19.8398 25.3633 19.4023 25.7461C18.6211 26.4258 18.1289 27.1797 17.9258 28.0078H27.0898V30H15.5703Z" fill="#BAD606" />
                                </svg>

                            </div>
                            <div className='lg:hidden'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.5" cy="12.5" r="11.5" stroke="#BAD606" stroke-width="2" />
                                    <path d="M9.04102 17C9.08659 16.1569 9.25977 15.4232 9.56055 14.7988C9.86589 14.1745 10.4583 13.6071 11.3379 13.0967L12.6504 12.3379C13.2383 11.9961 13.6507 11.7044 13.8877 11.4629C14.2614 11.0846 14.4482 10.6517 14.4482 10.1641C14.4482 9.5944 14.2773 9.14323 13.9355 8.81055C13.5938 8.47331 13.138 8.30469 12.5684 8.30469C11.7253 8.30469 11.1419 8.6237 10.8184 9.26172C10.6452 9.60352 10.5495 10.0775 10.5312 10.6836H9.28027C9.29395 9.83138 9.45117 9.13639 9.75195 8.59863C10.2852 7.65072 11.2262 7.17676 12.5752 7.17676C13.6963 7.17676 14.5143 7.47982 15.0293 8.08594C15.5488 8.69206 15.8086 9.36654 15.8086 10.1094C15.8086 10.8932 15.5329 11.5632 14.9814 12.1191C14.6624 12.4427 14.0905 12.8346 13.2656 13.2949L12.3291 13.8145C11.8825 14.0605 11.5316 14.2952 11.2764 14.5186C10.8206 14.915 10.5335 15.3548 10.415 15.8379H15.7607V17H9.04102Z" fill="#BAD606" />
                                </svg>


                            </div>
                            <p className="lg:text-[22px] text-[12px] font-sans"><span className='text-[#BAD606] font-regular'>La facilité de caisse</span> pour des dépenses exceptionnelles et anticiper les imprévus</p>
                        </div>
                        <div className='flex order-last lg:w-3/4 gap-x-4 lg:order-none'>
                            <div className='hidden lg:block'>
                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="21.5" cy="21.5" r="20" stroke="#BAD606" stroke-width="3" />
                                    <path d="M17.7852 25.7227C17.9258 26.9258 18.4844 27.7578 19.4609 28.2188C19.9609 28.4531 20.5391 28.5703 21.1953 28.5703C22.4453 28.5703 23.3711 28.1719 23.9727 27.375C24.5742 26.5781 24.875 25.6953 24.875 24.7266C24.875 23.5547 24.5156 22.6484 23.7969 22.0078C23.0859 21.3672 22.2305 21.0469 21.2305 21.0469C20.5039 21.0469 19.8789 21.1875 19.3555 21.4688C18.8398 21.75 18.3984 22.1406 18.0312 22.6406L16.2031 22.5352L17.4805 13.5H26.1992V15.5391H19.0625L18.3477 20.2031C18.7383 19.9062 19.1094 19.6836 19.4609 19.5352C20.0859 19.2773 20.8086 19.1484 21.6289 19.1484C23.168 19.1484 24.4727 19.6445 25.543 20.6367C26.6133 21.6289 27.1484 22.8867 27.1484 24.4102C27.1484 25.9961 26.6562 27.3945 25.6719 28.6055C24.6953 29.8164 23.1328 30.4219 20.9844 30.4219C19.6172 30.4219 18.4062 30.0391 17.3516 29.2734C16.3047 28.5 15.7188 27.3164 15.5938 25.7227H17.7852Z" fill="#BAD606" />
                                </svg>

                            </div>
                            <div className='lg:hidden'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.5" cy="12.5" r="11.5" stroke="#BAD606" stroke-width="2" />
                                    <path d="M10.333 14.5049C10.415 15.2067 10.7409 15.6921 11.3105 15.9609C11.6022 16.0977 11.9395 16.166 12.3223 16.166C13.0514 16.166 13.5915 15.9336 13.9424 15.4688C14.2933 15.0039 14.4688 14.4889 14.4688 13.9238C14.4688 13.2402 14.2591 12.7116 13.8398 12.3379C13.4251 11.9642 12.9261 11.7773 12.3428 11.7773C11.9189 11.7773 11.5544 11.8594 11.249 12.0234C10.9482 12.1875 10.6908 12.4154 10.4766 12.707L9.41016 12.6455L10.1553 7.375H15.2412V8.56445H11.0781L10.6611 11.2852C10.889 11.112 11.1055 10.9821 11.3105 10.8955C11.6751 10.7451 12.0967 10.6699 12.5752 10.6699C13.473 10.6699 14.234 10.9593 14.8584 11.5381C15.4827 12.1169 15.7949 12.8506 15.7949 13.7393C15.7949 14.6644 15.5078 15.4801 14.9336 16.1865C14.3639 16.8929 13.4525 17.2461 12.1992 17.2461C11.4017 17.2461 10.6953 17.0228 10.0801 16.5762C9.4694 16.125 9.1276 15.4346 9.05469 14.5049H10.333Z" fill="#BAD606" />
                                </svg>


                            </div>
                            <p className="lg:text-[22px] text-[12px] font-sans"><span className='text-[#BAD606] font-regular'>Une application mobile</span> parmi les meilleures du marché pour piloter vos comptes en ligne</p>
                        </div>
                        <div className='flex order-3 lg:w-3/4 gap-x-4 lg:order-none'>
                            <div className='hidden lg:block'>
                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="21.5" cy="21.5" r="20" stroke="#BAD606" stroke-width="3" />
                                    <path d="M21.0547 30.457C19.0703 30.457 17.6289 29.9141 16.7305 28.8281C15.8398 27.7344 15.3945 26.4062 15.3945 24.8438H17.5977C17.6914 25.9297 17.8945 26.7188 18.207 27.2109C18.7539 28.0938 19.7422 28.5352 21.1719 28.5352C22.2812 28.5352 23.1719 28.2383 23.8438 27.6445C24.5156 27.0508 24.8516 26.2852 24.8516 25.3477C24.8516 24.1914 24.4961 23.3828 23.7852 22.9219C23.082 22.4609 22.1016 22.2305 20.8438 22.2305C20.7031 22.2305 20.5586 22.2344 20.4102 22.2422C20.2695 22.2422 20.125 22.2461 19.9766 22.2539V20.3906C20.1953 20.4141 20.3789 20.4297 20.5273 20.4375C20.6758 20.4453 20.8359 20.4492 21.0078 20.4492C21.7969 20.4492 22.4453 20.3242 22.9531 20.0742C23.8438 19.6367 24.2891 18.8555 24.2891 17.7305C24.2891 16.8945 23.9922 16.25 23.3984 15.7969C22.8047 15.3438 22.1133 15.1172 21.3242 15.1172C19.918 15.1172 18.9453 15.5859 18.4062 16.5234C18.1094 17.0391 17.9414 17.7734 17.9023 18.7266H15.8164C15.8164 17.4766 16.0664 16.4141 16.5664 15.5391C17.4258 13.9766 18.9375 13.1953 21.1016 13.1953C22.8125 13.1953 24.1367 13.5781 25.0742 14.3438C26.0117 15.1016 26.4805 16.2031 26.4805 17.6484C26.4805 18.6797 26.2031 19.5156 25.6484 20.1562C25.3047 20.5547 24.8594 20.8672 24.3125 21.0938C25.1953 21.3359 25.8828 21.8047 26.375 22.5C26.875 23.1875 27.125 24.0312 27.125 25.0312C27.125 26.6328 26.5977 27.9375 25.543 28.9453C24.4883 29.9531 22.9922 30.457 21.0547 30.457Z" fill="#BAD606" />
                                </svg>

                            </div>
                            <div className='lg:hidden'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.5" cy="12.5" r="11.5" stroke="#BAD606" stroke-width="2" />
                                    <path d="M12.2402 17.2666C11.0827 17.2666 10.2419 16.9499 9.71777 16.3164C9.19824 15.6784 8.93848 14.9036 8.93848 13.9922H10.2236C10.2783 14.6257 10.3968 15.0859 10.5791 15.373C10.8981 15.888 11.4746 16.1455 12.3086 16.1455C12.9557 16.1455 13.4753 15.9723 13.8672 15.626C14.2591 15.2796 14.4551 14.833 14.4551 14.2861C14.4551 13.6117 14.2477 13.14 13.833 12.8711C13.4229 12.6022 12.8509 12.4678 12.1172 12.4678C12.0352 12.4678 11.9508 12.4701 11.8643 12.4746C11.7822 12.4746 11.6979 12.4769 11.6113 12.4814V11.3945C11.7389 11.4082 11.846 11.4173 11.9326 11.4219C12.0192 11.4264 12.1126 11.4287 12.2129 11.4287C12.6732 11.4287 13.0514 11.3558 13.3477 11.21C13.8672 10.9548 14.127 10.499 14.127 9.84277C14.127 9.35514 13.9538 8.97917 13.6074 8.71484C13.2611 8.45052 12.8577 8.31836 12.3975 8.31836C11.5771 8.31836 11.0098 8.5918 10.6953 9.13867C10.5221 9.43945 10.4242 9.86784 10.4014 10.4238H9.18457C9.18457 9.69466 9.3304 9.07487 9.62207 8.56445C10.1234 7.65299 11.0052 7.19727 12.2676 7.19727C13.2656 7.19727 14.0381 7.42057 14.585 7.86719C15.1318 8.30924 15.4053 8.95182 15.4053 9.79492C15.4053 10.3965 15.2435 10.8841 14.9199 11.2578C14.7194 11.4902 14.4596 11.6725 14.1406 11.8047C14.6556 11.946 15.0566 12.2194 15.3438 12.625C15.6354 13.026 15.7812 13.5182 15.7812 14.1016C15.7812 15.0358 15.4736 15.7969 14.8584 16.3848C14.2432 16.9727 13.3704 17.2666 12.2402 17.2666Z" fill="#BAD606" />
                                </svg>


                            </div>

                            <p className="lg:text-[22px] text-[12px] font-sans"><span className='text-[#BAD606] font-regular'>L’outil de gestion budgétaire </span> pour mieux piloter vos dépenses et suivre votre budget</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default First;