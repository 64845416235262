import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SubjectRepository from '../../../Repositories/SubjectRepository';
import { Subject } from '../../../Objects/models/Subject';
import SubjectComponent from './Subject';
// import SubjectComponent from './Subject';

export default function SubjectsSelect(props: any) {
  
  const [open, setOpen] = useState(false);
  const [selects, setSelects] = useState<Subject[]>([])
  const [options, setOptions] = useState<Subject[]>([]);
  

  const getSelectsOptions =  (value: Subject)=> {
    const index  = selects.indexOf(value)
    if (index > -1) {
    selects.splice(index,1)
    }
    props.getSubjects(selects)
  }
  useEffect(()=> {
    setSelects(props.default)
  },[])
  const loading = open && options.length === 0;
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await SubjectRepository.getSubjects()
      if (active) {
        setOptions(response.members);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    const TagElement = document.getElementById('tags')
    if (!open) {
      setOptions([]);
      if (TagElement) {
        props.callBack(TagElement.offsetHeight)
      }
    }
  }, [open]);
  
  
  return (
    <div
      className={
        " /text-[#808080] relative  rounded-lg  " +
        props.customClass
      }
    >
      <label className={!props.label ? 'hidden' : 'text-black pl-2 block'}>{props.label}</label>
      
      <Autocomplete
        id="subjects-select"
        fullWidth
        noOptionsText='Cette matière nest pas encore disponible'
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          
        }}
        disableClearable = {true}
        multiple
        limitTags={window.screen.width > 768 ? 4 : 1}
        getLimitTagsText={(more)=> more > 1 ? ` +${more} matières`: ` +${more} matière` }
        onChange={(value, getTagProps) => { props.getSubjects(getTagProps);setSelects(getTagProps)}}
        disablePortal={true}
        isOptionEqualToValue={(option, value) => option.fr_FR === value.fr_FR}
        getOptionLabel={(option) => option.fr_FR}
        options={options}
        loading={loading}
        defaultValue = {props.default}
        value = {selects}
        loadingText="chargement ..."
        popupIcon=''
        renderTags={props.type === 1 ? (value, getTagProps) =>
          <div className='absolute flex flex-wrap gap-2 top-12' id='tags'>
            {value.map((option, index) => (
            // eslint-disable-next-line no-octal-escape
            <SubjectComponent getSelectsOptions = {getSelectsOptions} subject = {option} key = {index} />
            // <span className="font-sans" key={index} >{option.fr_FR}<span className={`${!open && index === value.length - 1  && 'hidden'}`}>,&nbsp;</span></span>
          ))}
          </div>
        : undefined}

        renderInput={(params) => (

          <TextField
            {...params}
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress className='mr-2' size={20} color="inherit" /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              width: "100%",
              padding: '0 10px !important',
              "& .MuiOutlinedInput-root": { fontFamily: "Nunito", paddingRight: '0 10px !important' },
              "& input": {
                fontFamily: "Nunito",
                padding: '0 !important',
              },
              "& .MuiInputBase-root": {
                padding: '0 !important',
              },
              "& fieldset": {
                border: "none",
              },
              

            }}
          />
        )}
      />
    </div>
  );
}
