import React, { useState } from 'react';
import { SubscriptionCourse } from '../../../../../Objects/models/SubscriptionCourse';
import checkCircle from '../../../../../Assets/Icons/check-circle.svg'
import ProfileImg from '../../../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../../../Assets/Icons/hours.svg'
import CalendarIcon from '../../../../../Assets/Icons/calendar.svg' 
import ClipIcon from '../../../../../Assets/Icons/address.svg'
import LocationIcon from '../../../../../Assets/Icons/address.svg'
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import { Subject } from '../../../../../Objects/models/Subject';
import Child from '../../../../../Objects/models/Child';
import Client from '../../../../../Objects/models/Client';
import Functions from '../../../../../Helpers/Functions';
import moment from 'moment';
import { Address } from '../../../../../Objects/models/Address';
import CoursePrice from '../../../../../Components/utils/CoursePrice';
import MessageCourse from '../../../../../Components/Dashboard/MessageCourse';
import { Button } from '@mui/material';
import { SubscriptionCourseProposal } from '../../../../../Objects/models/SubscriptionCourseProposal';
import SubscriptionCourseProposalRepository from '../../../../../Repositories/SubscriptionCourseProposalRepository';
import Teachr from '../../../../../Objects/models/Teachr';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const days = [
    {
        fr: 'lundi',
        en: 'monday'
    },
    {
        fr: 'mardi',
        en: 'tuesday'
    },
    {
        fr: 'mercredi',
        en: 'wednesday'
    },
    {
        fr: 'jeudi',
        en: 'thursday'
    },
    {
        fr: 'vendredi',
        en: 'friday'
    },
    {
        fr: 'samedi',
        en: 'saturday'
    },
    {
        fr: 'dimanche',
        en: 'sunday'
    }
]
const RegularDetails = (
    regular: {
        teachr: Teachr,
        course: SubscriptionCourse,
        subject: Subject,
        child: Child | Client,
        address: Address
    }
) => {
    const navigate = useNavigate()
    const translateDay = (day: string) => {
        const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
        if (translate.length >= 0) {
            return translate[0].fr
        }
        return ''

    }
    const formatTime = (time: string | undefined) => {
        let format = ''
        if (time) {
            const regex = "(.*):(.*)"
            const match = time.match(regex)
            if (match) {
                format = `${match[1]}h${match[2]}`
            }
        }
        return format
    }
    const [description, setDrescription] = useState('')
    const getDescrition = (value: string) => {
        setDrescription(value)
    }
    const [price, setPrice] = useState('')
    const getPrice = (value: string) => {
        setPrice(value)
    }
    const createdProposal = () => {
        document.getElementById('teachr-loader')?.classList.remove('hidden')
        const proposal = new SubscriptionCourseProposal(regular.course.id!,regular.teachr.id!, price, description)
        proposal.isFromTeachr=true;
        SubscriptionCourseProposalRepository.createSubscriptionCourseProposal(proposal,regular.teachr.id!,regular.course.id).then((success) => {
            navigate('/declarons/prestations/postuler/confirm')
            document.getElementById('teachr-loader')?.classList.add('hidden')
        }).catch((error) => {
            console.warn(error);
            
        })
    }
    const MySwal = withReactContent(Swal)
    const showConfirmSubscriptionWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between pb-8 text-xl font-bold uppercase text-third'>
                        <p className=''>Envoyer sans personnalisation ?</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p>Êtes-vous sûr{regular.teachr.sex === 'WOMAN' && 'e'} de ne pas vouloir ajouter une description à votre candidature ?
                            Nous avons remarqué qu'avoir une description personnalisée augmente de 42% vos chances d'être sélectionné{regular.teachr.sex === 'WOMAN' && 'e'} !
                        </p>
                        <div className="flex justify-between py-4">
                        <Button onClick={() => Swal.clickCancel()} className='font-regular bg-transparent rounded-full py-2 text-first lg:w-1/4 w-full border-solid border-[1px] border-first px-8 uppercase ' >Annuler</Button>
                        <Button onClick={() => { Swal.clickConfirm() ; createdProposal() }} className='font-regular bg-transparent rounded-full py-2 text-third lg:w-1/4 w-full border-solid border-[1px] border-third px-8 uppercase ' >postuler</Button>
                        </div>
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    return (
        <div className='mx-auto lg:w-4/5'>
            <div className="flex flex-col justify-center gap-8 py-8 lg:flex-row">
                <div className='shadow-course-select  w-full p-6 rounded-[20px] mx-auto  flex'>
                    <div className="mx-auto w-max">
                        <div className='flex items-center  gap-4 text-[22px] font-bold uppercase text-first'>
                            <p>RéCAPITULATIF </p>
                            <img className='h-[25px]' src={checkCircle} alt="" />
                        </div>
                        <div className="flex flex-col gap-5 py-8">
                            <div className="flex gap-x-4">
                                <img src={ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                                <div>
                                    <p className='pb-2 overflow-hidden text-xl font-regular text-first'>régulière</p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px]'>pour {regular.child?.firstName}</p>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div>
                                    <p className='text-xl font-regular text-first'>À partir de <span className='lowercase'>{formatTime(regular.course?.startTime)}</span></p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px]'> {Functions.renderDuration(regular.course?.durationPerDay!)}/jour </p>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div>
                                    <p className='flex flex-wrap text-xl font-regular text-first'>Tous les{regular.course?.daysOfTheWeek.map((day: string, key) => <span key={key} className="after:content-[','] last:after:content-['']">&#160;{translateDay(day)}</span>)}</p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px] whitespace-nowrap'>à partir du {moment(regular.course?.startDate).format('dddd DD MMMM')}</p>
                                </div>
                            </div>
                            {regular.course?.isRemote
                                ? <div className="flex items-center gap-4">
                                    <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <p className='text-xl font-regular text-first'>Cours à distance</p>
                                </div>
                                : <div className="flex items-center gap-4">
                                    <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <a href={`https://www.google.com/maps/search/?api=1&query=${regular.address?.streetName}%2C+${regular.address?.city}`} target='_blanck' className='underline text-[16px] font-regular  text-first'>{regular.address?.streetName + ', ' + regular.address?.city}</a>
                                </div>}

                        </div>
                    </div>
                </div>
                <CoursePrice teachr = {regular.teachr} courseType = 'regular' duration={regular.course.durationPerDay} days={regular.course.daysOfTheWeek.length} getPrice = {getPrice} />
                <MessageCourse child={regular.child}  getDescrition = {getDescrition}/>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-8 py-4">
                <Button href='/declarons/prestations' className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-third lg:w-1/4 border-third' >précédent</Button>
                <Button onClick={() => {description === '' ? showConfirmSubscriptionWidget() :createdProposal()}} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-first lg:w-1/4 border-first' >postuler</Button>
            </div>
        </div>
    );
};

export default RegularDetails;