import React, { useEffect, useState } from 'react';
import NavBar from '../../../../Components/ComponentsUtils/NavBar';
import Overview from './Overview';
import Sponsor from './Sponsor';
import Steps from './Steps';
import Subscribe from './Subscribe';
import Why from './Why';

const style1 = {
    background: "linear-gradient(146.74deg, rgba(146, 211, 255, 0.34) 1.18%, rgba(196, 196, 196, 0) 50.58%), linear-gradient(222.07deg, rgba(146, 211, 255, 0.07) 43.74%, rgba(196, 196, 196, 0) 109.98%)"
};
const style2 = {
    background: "linear-gradient(35.74deg, rgba(146, 211, 255, 0.34) 1.18%, rgba(196, 196, 196, 0) 50.58%), linear-gradient(222.07deg, rgba(146, 211, 255, 0.07) 43.74%, rgba(196, 196, 196, 0) 109.98%)"
};
const style3 = {
    background: "linear-gradient(146.74deg, rgba(146, 211, 255, 0.34) 1.18%, rgba(196, 196, 196, 0) 50.58%), linear-gradient(222.07deg, rgba(146, 211, 255, 0.07) 43.74%, rgba(196, 196, 196, 0) 109.98%)"
};
const style4 = {
    background:  "linear-gradient(35.74deg, rgba(146, 211, 255, 0.34) 1.18%, rgba(196, 196, 196, 0) 50.58%), linear-gradient(222.07deg, rgba(146, 211, 255, 0.07) 43.74%, rgba(196, 196, 196, 0) 109.98%)"
};
const style5 = {
    background:"linear-gradient(146.74deg, rgba(146, 211, 255, 0.34) 1.18%, rgba(196, 196, 196, 0) 50.58%), linear-gradient(222.07deg, rgba(146, 211, 255, 0.07) 43.74%, rgba(196, 196, 196, 0) 109.98%)"
};
const PresentationIndex = () => {
    useEffect(()=>{
      if (window) {
         
      }
    },[])
    const [currentSection,setCurrentSection] = useState(0)
  
      window.addEventListener('scroll', () => {
        setCurrentSection(getCurrentSection(window.pageYOffset))
        
      })
    const getCurrentSection = (postion: number) => {
        const sections = document.querySelectorAll('.presentation-section')
        let result = 0
        if (sections) {
            for (let index = 0; index < sections.length; index++) {
                const element:any = sections[index];
                if (postion >= element.offsetTop - 500) {
                   result = (index + 1) * 20
                }
            }
        }
        
        return result
    }
    const convertSectionNumberTopercent = (value:number)=>{
        switch (value) {
            case 0:
             return 'w-0'
            case 20:
             return 'w-0'
            case 40:
             return 'w-1/4'
            case 60:
             return 'w-2/4'
            case 80:
             return 'w-3/4'
            case 100:
             return 'w-4/4'
        
            default:
                return 'w-0'
        }
    }
    return (
        <div >
            <NavBar />
            <div className='fixed top-0 w-screen h-1 bg-white shadow-sm lg:h-2'>
                <div className={` ${convertSectionNumberTopercent(currentSection)} h-1 lg:h-2 bg-first transition-all ease-in-out duration-500`}>

                </div>
            </div>
            <div className="flex flex-col text-normal">
                <section className="py-8 presentation-section lg:py-14" style={style1}>
                    <Overview />
                </section>
                <section className="py-8 presentation-section lg:py-14" style={style2}>
                    <Why />
                </section>
                <section className="py-8 presentation-section lg:py-14" style={style3}>
                    <Steps />
                </section>
                <section className="py-8 presentation-section lg:py-14" style={style4}>
                    <Subscribe />
                </section>
                <section className="py-8 presentation-section lg:py-14" style={style5}>
                    <Sponsor />
                </section>
            </div>

        </div>
    );
};


export default PresentationIndex;

