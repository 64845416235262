import image from "../../../../Assets/Images/attente.svg"

function NoHistoriqueCourse() {
  return (
    <div className="h-screen overflow-hidden font-sans">
      <div className=" md:mt-16  gap-x-12  md:flex ">
        <div className=" md:w-1/2 w-full mt-6 text-first xl:text-3xl lg:text-2xl md:text-xl text-base  ">
          <p className="font-bold md:mb-14 mb-4">Aucune demande de prestations effectuée</p>
          <p className="font-regular xl:text-2xl md:w-4/5 lg:text-xl md:text-lg text-sm ">
            Effectuez vite votre première prestation,
            Notre communauté vous attend avec impatience.
            <br /> <br />
            Rendez-vous dans l’onglet “Nouvelle prestation” et effectuez votre demande.
          </p>
        </div>
        <div className=" md:w-1/2 //h-[350px] /md:flex /hidden mt-4">
          <img src={image} alt="images" className=" mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default NoHistoriqueCourse;
