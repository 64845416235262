import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';

const Seventh = (props: any) => {
    const [fixAddress, setFixAddress] = useState<number>()
    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFixAddress(parseInt((event.target as HTMLInputElement).value));
    };
    const [homeSocity, setHomeSocity] = useState<number>()
    const handleChangeHome = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHomeSocity(parseInt((event.target as HTMLInputElement).value));
    };
    const [error, setError] = useState({
        fixAddress: false,
        homeSocity: false
    })
    useEffect(() => {
        props.callBack({
            homeSocity: homeSocity,
            fixAddress: fixAddress
        })
        if (fixAddress !== undefined) {
            if (fixAddress === 0) {
                if (homeSocity !== undefined) {
                    props.nextStep(8) 
                    setError({
                        fixAddress: false,
                        homeSocity: false
                    })
                }else{
                    setError({
                        fixAddress: false,
                        homeSocity: true
                    })
                }
            }else{
                props.nextStep(8) 
            }
        }else{
            setError({
                fixAddress: true,
                homeSocity: true
            })
        }
    }, [homeSocity, fixAddress])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-4">
            {error.fixAddress ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Souhaitez-vous fixer l’adresse de votre auto-entreprise à votre entreprise ? </label>
                <RadioGroup row
                    value={fixAddress}
                    name="customized-radios"
                    onChange={handleChangeAddress}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            {fixAddress === 0 &&
                <div className="flex flex-col gap-y-4">
                     {error.homeSocity ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                    <label className='text-normal text-xl font-regular' htmlFor="">Avez-vous recours à une société de domiciliation ?</label>
                    <RadioGroup row
                        value={homeSocity}
                        name="customized-radios"
                        onChange={handleChangeHome}
                    >
                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                    </RadioGroup>
                </div>
            }
            {/* <div className='flex flex-col gap-y-4'>
                <label htmlFor="" className='text-xl block font-regular'>Quelle sera votre adresse de domiciliation ?
                    <span className='text-base'> <br />(renseigner votre adresse personnelle si vous avez répondu oui à la question précédente)</span></label>
                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-3 px-5 bg-white w-full'>
                    <input type="text" placeholder='Ex : 12 rue du temple, 75004' className='w-full' />
                </div>
            </div> */}
        </div>
    );
};

export default Seventh;