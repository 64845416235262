import { useTheme } from '@mui/material/styles';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, SyntheticEvent, useEffect } from 'react';
import NavBar from '../../../../Components/Dashboard/NavBar';
import Empty from './Empty';
import checkIcon from '../../../../Assets/Icons/check-circle.svg'
import loadingIcon from '../../../../Assets/Icons/loading.svg'
import Accepted from './Accepted/Index';
import Pending from './pending/Index';
import { SubscriptionCourseProposal } from '../../../../Objects/models/SubscriptionCourseProposal';
import { PackProposal } from '../../../../Objects/models/PackProposal';
import CourseProposal from '../../../../Objects/models/CourseProposal';
import Teachr from '../../../../Objects/models/Teachr';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import SubscriptionCourseProposalRepository from '../../../../Repositories/SubscriptionCourseProposalRepository';
import CourseProposalRepository from '../../../../Repositories/CourseProposalRepository';
import PackProposalRepository from '../../../../Repositories/PackProposalRepository';
import SubscriptionCourseRepository from '../../../../Repositories/SubscriptionCourseRepository';
import { SubscriptionCourse } from '../../../../Objects/models/SubscriptionCourse';
import CoursePackRepository from '../../../../Repositories/CoursePackRepository';
import { CoursePack } from '../../../../Objects/models/CoursePack';
import loaderIcon from '../../../../Assets/Icons/loader-bleu.gif'


const CandidacyIndex = () => {
    const [regularproposals, setRegularProposals] = useState<SubscriptionCourseProposal[]>()
    const [regularproposalsAccepted, setRegularProposalsAccepted] = useState<SubscriptionCourse[]>()
    const [packProposals, setPackProposal] = useState<PackProposal[]>()
    const [packProposalsAccepted, setPackProposalAccepted] = useState<CoursePack[]>()
    const [punctualProposals, setPunctualProposals] = useState<CourseProposal[]>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [punctualProposalsAccepted, setPunctualProposalsAccepted] = useState<CourseProposal[]>()
    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }


    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response) {
                setTeachr(response)
                SubscriptionCourseRepository.getActiveSubscriptionCourseForTeachr(response.id!).then((proposals) => {
                    setRegularProposalsAccepted(proposals);
                })
                CoursePackRepository.getActiveCoursePacks(response.id!).then((response) => {
                    setPackProposalAccepted(response.members);

                })
                SubscriptionCourseProposalRepository.getAllPendingSubscriptionCourseProposals(response.id!).then((response) => {
                    setRegularProposals(response)
                })
                PackProposalRepository.fetchPackProposalByIdTeachr(response.id!).then((response) => {
                    setPackProposal(response?.members!)

                })
                let nowDate: Date | string = new Date()
                nowDate = nowDate.toISOString()
                CourseProposalRepository.getCourseProposalsTeachr(1, [
                    "isRefused=false",
                    "punctualCourse.isCancelled=false",
                    "isAccepted=false",
                    "endDatetime[after]" + nowDate,
                    "order[startDatetime]=ASC",
                ]).then((response) => {
                    setPunctualProposals(response.members)
                })
                CourseProposalRepository.getCourseProposalsTeachr(1, ["isAccepted=true"]).then((response) => {

                    setPunctualProposalsAccepted(response.members)
                })
            }
        })
    }, [])

    const [value, setValue] = useState(0);
    const theme = useTheme();
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ pt: 4 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const displayPendingProposal = () => {
        if (punctualProposals && regularproposals && packProposals) {
            if (punctualProposals.length === 0 && regularproposals.length === 0 && packProposals.length === 0) {
                return (
                    <Empty />
                )


            } else {
                return (<Pending punctual={punctualProposals} pack={packProposals} regular={regularproposals} />)
            }


        } else {
            return (
                <div className='relative flex items-end mx-auto my-auto py-14 w-max'>
                    <img src={loaderIcon} width="120" alt="" />
                </div>
            )
        }
    }
    const isAcceptedProposal = () => {

        if (punctualProposalsAccepted && regularproposalsAccepted && packProposalsAccepted) {


            if (punctualProposalsAccepted.length > 0 || regularproposalsAccepted.length > 0 || packProposalsAccepted.length > 0) {


                return true
            }
            return false

        }
        return false
    }
    return (
        <div>
            <NavBar active={1} />
            <div className='px-4 pt-8 lg:px-14'>
                <div className='//md:pl-6'>
                    <h1 className='text-[#0B2875] lg:text-2xl font-bold //pl-6 md:text-lg text-base'>MES CANDIDATURES</h1>
                </div>
                <div className="//lg:px-4">
                    <Tabs sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#758FDA',
                            height: '4px'
                        }
                    }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab iconPosition='end' sx={{ gap: 1 }} icon={<img src={loadingIcon} alt='loading' />} className='font-bold  text-[#243862]' label="CANDIDATURES EN ATTENTE" />
                        {isAcceptedProposal() && <Tab iconPosition='end' sx={{ gap: 1 }} icon={<img src={checkIcon} alt='loading' />} className='font-bold text-[#243862]' label="CANDIDATURES ACCEPTÉES " />}

                    </Tabs>
                </div>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    {displayPendingProposal()}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    {
                        teachr || punctualProposalsAccepted || packProposalsAccepted || regularproposalsAccepted ?
                            <Accepted teachr={teachr!} punctual={punctualProposalsAccepted} pack={packProposalsAccepted} regular={regularproposalsAccepted} />

                            :
                            <div className='relative flex items-end mx-auto my-auto pt-14 w-max'>
                                <img src={loaderIcon} width="120" alt="" />
                            </div>
                    }

                </TabPanel>
            </div>
        </div>
    );
};

export default CandidacyIndex;