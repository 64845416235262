import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TeachrRepository from '../../../../../../Repositories/TeachrRepository';
import SubjectRepository from '../../../../../../Repositories/SubjectRepository';
import ChildRepository from '../../../../../../Repositories/ChildRepository';
import { Subject } from '../../../../../../Objects/models/Subject';
import Child from '../../../../../../Objects/models/Child';
import Functions from '../../../../../../Helpers/Functions';
import CourseProposalRepository from '../../../../../../Repositories/CourseProposalRepository';

const CompPonctual = (props: any) => {

    const { punctual } = props
    const [subject, setSubjectSub] = useState<Subject>()
    const [child, setChildSub] = useState<Child>()
    const [proposal, setProposal] = useState<any>()

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubjectRepository.fetchSubjectById(punctual.subjectAsked_id).then((subject) => {
                    setSubjectSub(subject)
                })
                ChildRepository.fetchChildByIdForTeachr(punctual.forChild_id, punctual.directAskedTeachr_id).then((child) => {
                    setChildSub(child)
                })
                CourseProposalRepository.fetchCourseProposalById(punctual.acceptedProposal_id).then((proposals) => {
                    setProposal(proposals)
                })
            }
        });
    }, [punctual.acceptedProposal_id, punctual.directAskedTeachr_id, punctual.forChild_id, punctual.subjectAsked_id]);

    return (
        <>
            <tr className="bg-white" >
                <td className="py-3 px-1 hidden text-center font-regular lg:block">{moment(punctual.startDatetime).format('dddd DD/MM/YYYY')}</td>
                <td className="py-3 px-1 lg:hidden font-regular">{moment(punctual.startDatetime).format('DD/MM/YY')}</td>
                {/* <td className="py-3 px-1 text-center">{moment(punctual.endDatetime).format('HH:mm')}</td> */}
                <td className="py-3 px-1 text-center font-regular">{child?.firstName}</td>
                <td className="py-3 px-1 text-center font-regular">{subject?.fr_FR}</td>
                <td className="py-2 px- text-center font-bold //flex justify-around">
                        <div>
                            {(((proposal?.teachrPrice + (proposal?.teachrPrice * 10 / 90) + 0) * (punctual.duration / 2)) / 2).toFixed(2) + "€"}
                        </div>
                    </td>
                    <td className="py-2 text-center md:block hidden"> <div className="border-b-2 w-[10px]"></div></td>
                    <td className="py-2 px- text-center font-bold text-first //flex justify-around">
                        <div>
                            {(proposal?.teachrPrice * (punctual.duration / 2)).toFixed(2) + "€"}
                        </div>
                    </td>
                <td className="py-3 px-1 text-center font-regular text-center">{Functions.renderDuration(punctual.duration)}</td>
                {/* <td className="py-3 px-1 text-center text-center">Ponctuelle</td> */}
                <td className="py-3 px-2 //border-4 h-[20px] //border-t-[0.5px] space-y-2 //border-t-[#D2D2D2] border-white rounded-full text-center text-[#2b2b2b] font-regular bg-[#F4F4F8]">Cours prévu</td>
            </tr>
        </>
    );
};

export default CompPonctual;