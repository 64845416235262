import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';

const Eleventh = (props: any) => {
    const [saveSiren, setSaveSiren] = useState<number>()
    const [savePersonnalInfo, setSavePersonnnalInfo] = useState<number>()
    const [paiementMode, setPaiementMode] = useState<number>()
    const [error, setError] = useState({
        paiementMode: true,
        saveSiren: true,
        savePersonnalInfo: true
    })
    const handleChangePaiementMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaiementMode(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSavePersonnalInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSavePersonnnalInfo(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSaveSiren = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveSiren(parseInt((event.target as HTMLInputElement).value));
    };
    const validate = () => {
        let error_paiment_mode = true
        let error_save_siren = true
        let error_save_personnal_info = true

        if (paiementMode !== undefined && paiementMode >= 0) {
            error_paiment_mode = false
        } else {
            error_paiment_mode = true
        }
        if (savePersonnalInfo !== undefined && savePersonnalInfo >= 0) {
            error_save_personnal_info = false
        } else {
            error_save_personnal_info = true
        }
        if (saveSiren !== undefined && saveSiren >= 0) {
            error_save_siren = false
        } else {
            error_save_siren = true
        }
        setError({
            paiementMode: error_paiment_mode,
            saveSiren: error_save_siren,
            savePersonnalInfo: error_save_personnal_info
        })
        if (!error_paiment_mode && !error_save_siren && !error_save_personnal_info) {
            return true
        }
        return false
    }
    useEffect(() => {
        props.callBack({
            saveSiren: saveSiren,
            savePersonnalInfo: savePersonnalInfo,
            paiementMode: paiementMode
        })
        if (validate()) {
            props.nextStep(12)
        }
    }, [paiementMode, savePersonnalInfo, saveSiren])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-2">
                <div>
                    {error.paiementMode ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                    <label className='text-normal text-xl font-regular' htmlFor="">Souhaitez-vous opter pour le versement libératoire ?</label>
                    <a className='text-radio text-sm font-regular underline block py-1' href="https://www.economie.gouv.fr/entreprises/micro-entreprise-auto-entreprise-versement-liberatoire-impot-revenu" target='_blank'>Cliquez-ici pour obtenir plus d’information sur le prélèvement libératoire</a>
                </div>
                <RadioGroup row
                    value={paiementMode}
                    name="customized-radios"
                    onChange={handleChangePaiementMode}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex flex-col">
                {error.saveSiren ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Autorisez-vous que vos informations enregistrés dans le répertoire SIREN soit consultable ou utilisable par des tiers ? </label>
                <RadioGroup row
                    value={saveSiren}
                    name="customized-radios"
                    onChange={handleChangeSaveSiren}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex flex-col">
                {error.savePersonnalInfo ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Autorisez-vous que vos données personnelles puissent être utilisées à des fin de prospection ? </label>
                <RadioGroup row
                    value={savePersonnalInfo}
                    name="customized-radios"
                    onChange={handleChangeSavePersonnalInfo}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
        </div>
    );
};

export default Eleventh;