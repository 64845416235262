import React, { useEffect, useState } from 'react';

const Input = (props: any) => {
    const [value, setValue] = useState('')
    const [editMode, setEditMode] = useState(false)
    useEffect(()=>{
        setValue(props.defaultValue)
      },[props.defaultValue])
      useEffect(() => {
        props.callBack(value)
      }, [value])
    useEffect(() => {
        if (props.editMode && props.defaultValue) {
            setEditMode(true)
        }
    }, [props.editMode])
    return (
        <div className='relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
            <label className='block lg:text-base'>{props.label} <span className='text-third'>{props.require && '*'}</span></label>
            <input className={` w-full lg:text-base bg-transparent placeholder:text-gray`} type="text" value={value} onChange={(e) => setValue(e.target.value)} placeholder={props.placeholder} />
            <div onClick={() => { setEditMode(false); setValue("") }} className={`absolute cursor-pointer top-1/3 right-2`}>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.72152 0C14.6972 0 18.7344 4.03714 18.7344 8.98714C18.7344 13.9629 14.6972 18 9.72152 18C4.77152 18 0.734375 13.9629 0.734375 8.98714C0.734375 4.03714 4.77152 0 9.72152 0ZM9.72152 7.49571L11.8815 5.36143C12.8587 4.37143 14.3501 5.86286 13.3858 6.86572L11.2258 8.98714L13.3858 11.1471C14.3629 12.1243 12.8587 13.6157 11.8815 12.6514L9.72152 10.4914L7.58723 12.6514C6.59723 13.6286 5.11866 12.1243 6.08295 11.1471L8.21723 8.98714L6.08295 6.85286C5.09295 5.86286 6.58438 4.35857 7.58723 5.34857L9.72152 7.49571Z" fill="#808080" />
                </svg>

            </div>
        </div>
    );
};

export default Input;