import { FormControl, NativeSelect, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SelectComponent = (props) => {
  const [value, setValue] = useState('')
  useEffect(()=>{
    setValue(props.defaultValue)
  },[props.defaultValue])
  useEffect(() => {
    props.callBack(value)
  }, [value])
  useEffect(()=>{
    setValue(props.defaultValue)
  },[props.defaultValue])
  useEffect(() => {
    props.callBack(value)
  }, [value])
  return (
    <div className={props.containerClass ? props.containerClass : 'px-4 py-2 rounded-lg bg-input-color shadow-dashboard-setup-input h-max '}>
      <FormControl sx={{ m: 0, p: 0, width: '100%' }}>
        <label className={props.labelClass ? props.labelClass : 'block lg:text-base'}>{props.label}</label>
        <NativeSelect
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sx={{
            width: "100%",
            fontFamily: 'nunito',
            fontSize: '1rem',
            '& select': {
              padding: 0,
              width: "100%"
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              fontFamily: 'nunito',
              backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
              borderWidth: 0,
              outline: 0
            },
          }}
          input={<OutlinedInput sx={{
            'bgcolor': 'transparent',
            'border': 'none',
            '.MuiOutlinedInput-notchedOutline': {
              'border': 'none',
              'fontFamily': 'nunito',
              'backgroundColor': 'transparent',
            },
            '& div': {
              'padding': 0
            }
          }} />}
        >
          <option disabled={props.defaultValue | value !== "" ? true : false}>{props.placeholder}</option>
          {props.data.map((option, key) => (<option key={key} value={option.value}>{option.label}</option>))}
        </NativeSelect>
        {/* <Select
          displayEmpty
          value={value}
          defaultValue = {1}
          defaultValue = {1}
          onChange={(e) => setValue(e.target.value)}
          input={<OutlinedInput sx = {{
            'bgcolor': 'transparent',
            'border' :  'none',
            '.MuiOutlinedInput-notchedOutline': {
              'border': 'none',
              'fontFamily': 'nunito',
              'backgroundColor':'transparent',
            },
            '& div': {
              'padding': 0
            }
          }} />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>{props.placeholder}</span>;
              return <span>{props.placeholder}</span>;
            }
            return selected;
          }}
          inputProps={{  }}
        >
          <MenuItem disabled value="">
            <span className='font-sans'>{props.placeholder}</span>
            <span className='font-sans'>{props.placeholder}</span>
          </MenuItem>
          {props.data.map((name,key) => (
          {props.data.map((name,key) => (
            <MenuItem
              key={key}
              value={key}
              key={key}
              value={key}
            >
              {name}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
    </div>
  );
};

export default SelectComponent;