
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import errorIcon from "../../../../../Assets/Images/error.png";
import Functions from '../../../../../Helpers/Functions';
import StepperStore from '../../../../../LocalForage/StepperStore';
import TeachrPutTva from '../../../../../Objects/models/TeachrPutTva';
import Teachr_Repository from '../../../../../Repositories/Teachr_Repository';
import { auth } from '../../../../../Services/FirebaseService';
import IndexPrice from './IndexPrice';
import React from 'react';

const validatePrice = (value: any) => {
    if (value === undefined) {
        return {
            isValid: false,
            message: "Entrez le prix",
            icon: <img src={errorIcon} alt="" className="w-4 h-4" />
        };
    }
    else if (value < 9) {
        return {
            isValid: false,
            message: "le prix doit se situer dans une fourchette allant de 11€ à 250€",
            icon: <img src={errorIcon} alt="" className="w-4 h-4" />
        };
    }
    else if (value === 0) {
        return {
            isValid: false,
            message: "Veuillez entrer le prix",
            icon: <img src={errorIcon} alt="" className="w-4 h-4" />
        };
    }
    else
        return {
            isValid: true,
            message: "",
        };
};

const StepPrice = (props: any) => {
    const { handleChangeStep } = props
    const [user] = useAuthState(auth)
    const [price, setPrice] = useState<any>()
    const [cours, setCours] = useState<any>()
    const [teachr, setTeachr] = useState<TeachrPutTva>()
    const [duration, setDuration] = useState<any>()
    const navigate = useNavigate()
    const [error, setError] = useState({
        price: "null"
    })

    useEffect(() => {
        Teachr_Repository.getCurrentTeachr().then((res: any) => {
            setTeachr(res)
        })
    }, [])


    useEffect(() => {
        (async () => {
            const value: any = await StepperStore.getItem("price" + user?.uid);
            if (value !== null) {
                setPrice(value.price);
            }
            const cours: any = await StepperStore.getItem("cours" + user?.uid);
            setCours(cours)

            const duration: any = await StepperStore.getItem("punctual" + user?.uid);
            const durationSub: any = await StepperStore.getItem("regulier" + user?.uid);
            const durationPast: any = await StepperStore.getItem("dataPast" + user?.uid);

            if (cours.cours === "regulier") {
                if (durationSub !== null) {
                    setDuration(durationSub.duration);
                }
            } else if (cours.cours === "punctual") {
                if (duration !== null) {
                    setDuration(duration.duration);
                }
            }
            else if (cours.cours === "punctualPast") {
                if (durationPast !== null) {
                    setDuration(durationPast.duration);
                }
            }
        })();
    }, [user?.uid]);

    const validate = () => {
        let errprice = true;
        if (!validatePrice(price).isValid) {
            setError({ price: validatePrice(price).message });
            errprice = true;
        } else {
            setError({ price: validatePrice(price).message });
            errprice = false;
        }

        if (errprice) {
            return false;
        } else {
            return true;
        }
    };
    const handleSubmit = () => {
        if (validate()) {
            StepperStore.setItem("price" + user?.uid, { price: price });
            handleChangeStep(7)
        }
    };

    const handleBack = () => {
        if (cours.cours === "regulier") {
            handleChangeStep(3)
        } else if (cours.cours === "punctual") {
            handleChangeStep(4)
        } else if (cours.cours === "punctualPast") {
            handleChangeStep(5)
        }
    }


    return (
        <div className="overflow-y-auto list-teacher relative bg-white md:py-8 py-4 xl:px-14 px-4 md:h-[100%] h-[100%] rounded-[11px]">
            <div className="space-y-4 md:h-[80%]">
                <div className="mt-8">
                    <p className="text-first font-bold lg:text-2xl text-lg //text-base">
                    Combien coûte une heure de prestation à votre client ?
                    </p>
                </div>
                <div className="bg-input-color translate-y-6 shadow-md shadow-[rgba(0,0,0,25%)] rounded-md md:py-5 py-3 px-3 md:w-1/2 lg:text-lg md:text-base text-sm">
                    <div className="flex p-2 text-sm text-gray-900 md:p-0 lg:text-lg md:text-md">
                        <div className="w-4/5 ">
                            <input
                                type="number"
                                min="11"
                                id="firs_firstName"
                                className="w-full bg-input-color focus:outline-none focus:ring-0 "
                                placeholder="Ex : 30"
                                onChange={(e) => setPrice(e.target.value)}
                                value={price}
                            />
                        </div>
                        <div className="w-1/6 font-regular ">€/h</div>
                    </div>
                </div>
                <div className="flex w-full mx-auto space-x-2 text-sm translate-y-4 lg:text-lg md:text-md ">
                    <p className="text-[#FF724F] h-6 w-full lg:text-lg md:text-md text-sm //mb-4 md:-mt-[2px]">
                        {error.price === "null" || error.price === ""
                            ? null
                            : validatePrice(price).message}
                    </p>
                </div>
                {price === undefined ? "" :
                    <div className="flex text-sm translate-y-8 lg:text-lg md:text-md gap-x-4">
                        <div>
                            <p>{duration * 2 === 2 ? "" : "Vous allez donc recevoir " + Functions.priceTotal(price).toFixed(2) + "€ par heure dispensée."}<br></br> Soit pour {Functions.renderDuration(duration * 2)} de prestation, vous allez recevoir sur votre compte bancaire {((0.9 * (teachr?.tauxTva === 10 ? price * 0.9 : teachr?.tauxTva === 20 ? price * 0.8 : price) * 2) * (duration)).toFixed(2)}€  </p>
                        </div>
                        <div className='w-8'>
                            <IndexPrice price={price} teachr={teachr} />
                        </div>
                    </div>
                }
                {teachr?.tauxTva === undefined || teachr?.tauxTva === 0 ? "" : teachr?.tauxTva !== null && <div className='text-sm translate-y-20 lg:text-lg md:text-md //flex'>
                    <div>
                        <p className='text-first'>Le prix que vous mentionnez ici est le prix TTC, en y incluant vos {teachr?.tauxTva}% de TVA</p>
                    </div>
                    <div className='//flex //gap-x-2'>
                        <p>Vous souhaitez modifier vos taux de TVA : <strong className='text-sm lg:text-lg md:text-md //flex text-first' onClick={() => navigate('/declarons/profile')} >  cliquez ici</strong>
                        </p>
                    </div>
                </div>
                }

            </div>

            <div className="md:hidden absolute inset-x-0 bottom-0 flex justify-around space-x-4 ">
                <div className="flex w-1/2 rounded-xl bg-white text-orange border-2 border-orange font-bold justify-center">
                    <div
                        onClick={() => {
                            handleBack();
                        }}
                        className="text-xs pt-2 w-full text-center uppercase"
                    >
                        Précédent
                    </div>
                </div>
                <div className="flex justify-center w-1/2 ">
                    <div
                        onClick={() => {
                            handleSubmit();
                        }}
                        className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-xs border-first text-first uppercase rounded-xl active:bg-first active:text-[#4d4d4d]  "
                    >
                        Suivant
                    </div>
                </div>
            </div>
            <div className="hidden px-12 absolute inset-x-0 bottom-4 md:flex justify-between">
                <div>
                    <button
                        onClick={() => {
                            handleBack();
                        }}
                        className="py-2 font-bold text-first uppercase rounded-xl text-sm px-14 bg-white border-2 border-first"
                    >
                        precedent
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => {
                            handleSubmit();
                        }}
                        className="py-2 font-bold text-white uppercase rounded-xl border-2 border-first text-sm px-14 bg-first"
                    >
                        SUIVANT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StepPrice;
