import React, { useEffect, useState } from "react";
import moment from "moment";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import PunctualCourseRepository from "../../../../../../Repositories/PunctualCourseRepository";
import Functions from "../../../../../../Helpers/Functions";
import SubscriptionCourseRepositorys from "../../../../../../Repositories/SubscriptionCourseRepository";
import nouneuro from "../../../../../../Assets/Icons/noun-euro-2.svg"
// import ComponentWeekChart from "./ComponentDurationChart/ComponentWeekChart";
// import ComponentMonthChart from "./ComponentDurationChart/ComponentMonthChart";
import ComponentMonthPriceChart from "./ComponentDurationChart/ComponentMonthPriceChart";
import ComponentWeekPriceChart from "./ComponentDurationChart/ComponentWeekPriceChart";
import ComponentYearChart from "./ComponentDurationChart/ComponentYearChart";
function DateOfWeeksPrice() {
  const [cours, setCourse] = useState([]);
  const [durationByDay, setDurationByDay] = useState({});
  const [durationByMonth, setDurationByMonth] = useState({});
  const [current, setCurrent] = useState(2)
  const handleChange = (val) => {
    setCurrent(val)
  }

  const handlePosition = (val) => {
    switch (val) {
      case 1:
        return <ComponentWeekPriceChart durationByDay={durationByDay} />
      case 2:
        return <ComponentMonthPriceChart durationByMonth={durationByMonth} />
        case 3 :
          return <ComponentYearChart durationByMonth={durationByMonth} />
      default:
        return <ComponentWeekPriceChart durationByDay={durationByDay} />
    }
  }

  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachr) => {
      if (teachr.id) {
        const coursePromises = [
          PunctualCourseRepository.teachrGetCourseForDashboard(teachr.id),
          SubscriptionCourseRepositorys.getActiveChargeForSubscriptionCourseByTeachrForDashboard(teachr.id)
        ];

        Promise.all(coursePromises).then(([punctualCourses, subscriptionCourses]) => {
          const combinedCourses = punctualCourses.concat(subscriptionCourses);
          combinedCourses.sort((a, b) => {
            // Trier en fonction de la durée et de l'heure de début
            const aDuration = a.duration || a.durationPerDay;
            const bDuration = b.duration || b.durationPerDay;
            const astartDateOccurrence = a.startDateTime || a.startDateOccurrence;
            const bstartDateOccurrence = b.startDateTime || b.startDateOccurrence;

            if (aDuration !== bDuration) {
              return aDuration - bDuration;
            } else {
              return new Date(astartDateOccurrence) - new Date(bstartDateOccurrence);
            }
          });
          const coursesWithCommonAttributes = combinedCourses.map((course) => {
            // Créer deux attributs communs à partir des différentes propriétés
            const duration = course.durationPerDay || course.duration;
            const startDateTime = course.startDateOccurrence || course.startDateTime;

            return { ...course, duration, startDateTime };
          });
          setCourse(coursesWithCommonAttributes);
        });
      }
    });
  }, []);


  useEffect(() => {
    // Calcul des dates de la semaine en cours
    const debutAnnee = moment().startOf("year");
    const datesAnnee = [];
    const debutSemaine = moment().startOf("isoWeek");
    const datesSemaine = [];

    for (let i = 0; i < 7; i++) {
      datesSemaine.push(moment(debutSemaine).add(i, "days").format("YYYY-MM-DD"));
    }
    for (let i = 0; i < 12; i++) {
      datesAnnee.push(moment(debutAnnee).add(i, "months").format("MMMM"));
    }
    const coursSemaine = cours.filter((cours) =>
      datesSemaine.includes(moment(cours.startDatetime || cours.startDateOccurrence).format("YYYY-MM-DD"))
    );
    const coursAnnee = cours.filter((cours) =>
      moment(cours.startDatetime || cours.startDateOccurrence).isSameOrAfter(moment(), "year")
    );

    // Calcul de la durée totale de chaque jour
    const durationByDay = {};
    const durationByDayInHour = [];

    for (let i = 0; i < 7; i++) {
      durationByDay[moment(debutSemaine).add(i, "days").format("dd")] = 0;
    }
    coursSemaine.forEach((cours) => {
      const jour = moment(cours.startDatetime || cours.startDateOccurrence).format("dd");
      durationByDay[jour] += cours.duration || cours.durationPerDay;
    });
    Object.values(durationByDay).forEach((day) => {
      durationByDayInHour.push(Functions.renderDuration(day * 2));
    });

    // Calcul de la durée totale de chaque mois
    const durationByMonth = {};
    const durationByMonthInHour = [];
    for (let i = 0; i < 12; i++) {
      durationByMonth[moment(debutAnnee).add(i, "months").format("MMMM")] = 0;
    }
    coursAnnee.forEach((cours) => {
      const month = moment(cours.startDatetime || cours.startDateOccurrence).format("MMMM");
      durationByMonth[month] += cours.duration || cours.durationPerDay;
    });
    Object.values(durationByMonth).forEach((month) => {
      durationByMonthInHour.push(Functions.renderDuration(month * 2));
    });
    setDurationByDay(durationByDay);
    setDurationByMonth(durationByMonth);
  }, [cours]);

  return (
    <div className="text-first px-4 py-2 ">
    <div className=" flex gap-x-2 mx-auto //w-4/5">
      <div className=" p-1 rounded-base bg-[#E8F5FF]">
        <img src={nouneuro} alt="" />
      </div>
      <div>
        <p className="font-bold text-base">Mes prestations données</p>
      </div>
    </div>
    <div className="">
      <div className="pl-12 t-2 w-1/4">
        {/* <p className="font-bold text-xl 7">08h30</p> */}
        <p className="//lg:text-xl md:text-xl text-base font-bold"> {Functions.renderDuration(Object.values(current === 1 ? durationByDay : current === 2 ? durationByMonth : durationByDay).reduce((a, b) => a + b, 0))}</p>
      </div>
      <div className=" //mx-auto flex mt-2  bg-[#E8F5FF] mx-auto text-center font-bold w-3/4" >
        {/* <div className=" flex gap-x-2 w-3/4 border  mx-auto text-center font-bold pt-1"> */}
        <div className={current === 1 ? "bg-first rounded-xl text-white w-1/3 pt-1  cursor-pointer" : "bg-[#E8F5FF]  pt-1 w-1/3  cursor-pointer"} 
        // onClick={() => handleChange(1)}
        >Semaine</div>
        <div className={current === 2 ? "bg-first rounded-xl text-white w-1/3   pt-1 cursor-pointer" : "bg-[#E8F5FF] w-1/3   pt-1 cursor-pointer"} 
        // onClick={() => handleChange(2)}
        >Mois</div>
        <div className={current === 3? "bg-first rounded-xl text-white w-1/3   pt-1 cursor-pointer" : "bg-[#E8F5FF] w-1/3   pt-1 cursor-pointer"} 
        // onClick={() => handleChange(3)}
        >Année</div>
      </div>
      {/* </div> */}
    </div>
    <p className="pl-3 font-regular text-sm text-[#4d4d4d] mt-2 w-4/5 mx-auto">Sur les 12 derniers mois de cette année</p>
    <div className="mx-auto ">
      {handlePosition(current)}
    </div>
  </div>
  );
}

export default DateOfWeeksPrice;
