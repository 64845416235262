import validateSelects from "../../../Validations/MultiStep";
import { useEffect, useState } from "react";
import StepperStore from "../../../LocalForage/StepperStore";
import { Subject } from "../../../Objects/models/Subject";
import SubjectsSelects from "../../Utilitis/SelectMatiere";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../Services/FirebaseService";
import image1 from "../../../Assets/Images/formMatiere.svg";

const First = (props: { handleNext: any }) => {

  const { handleNext } = props;
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [prestation, setPrestation] = useState<Subject[]>();
  const [error, setError] = useState({
    prestation: "null",
  });
  const getPrestation = (value: Subject[]) => {
    setPrestation(value);
  };
  useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem(
        user ? "firstPrestation" + user?.uid : "firstPrestation"
      );
      if (value !== null) {
        setPrestation(value.prestation);
      }
    })()
  }, [user]);

  const validate = () => {
    let errPrestation = true;
    if (!validateSelects(prestation).isValid) {
      setError({ prestation: validateSelects(prestation).message });
      errPrestation = true;
    } else {
      setError({ prestation: validateSelects(prestation).message });
      errPrestation = false;
    }
    if (errPrestation) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (event: any) => {
    if (validate()) {
      StepperStore.setItem(user ? "firstPrestation" + user?.uid : "firstPrestation", prestation);
      handleNext();
    }
    event.preventDefault();
  };
  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h2 className="text-base font-regular lg:text-3xl md:text-2xl text-first">Quel type de prestations souhaitez-vous ?</h2>
      <div className=" bg-input-color py-2 relative rounded-lg shadow-md shadow-[rgba(0,0,0,25%)]" >
        <SubjectsSelects
          callBack={getPrestation}
          validation={validateSelects}
          value={prestation}
          placeholder="Ex : Bricolage"
        />
      </div>
      <div className="flex space-x-2 md:text-lg">
        {error.prestation === "null" || error.prestation === ""
          ? " "
          : validateSelects(prestation).icon}
        <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
          {error.prestation === "null" || error.prestation === ""
            ? null
            : validateSelects(prestation).message}
        </p>
      </div>
      <div>
        <div className="md:hidden -translate-y-6    w-[150%]  mx-auto ">
          <img src={image1} alt="" className="-translate-x-28 h-[150%] " />
        </div>
        <div className="md:hidden //-translate-y-6 flex justify-around mx-auto space-x-4 w-4/5 -translate-y-36 -mt-20 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
            <div
              onClick={() => navigate(-1)}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent

            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent

          </button>
        </div>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={(e) => handleSubmit(e)}
            className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default First;
