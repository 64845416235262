import { Link } from "@mui/material";
import React, { useState } from "react";
import logobleu from "../../Assets/Images/logo_dec_white.svg"
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Services/FirebaseService";
import ApiService from "../../Services/ApiService";
import { signOut } from "firebase/auth";
import InfoAcceuil from "./InfoAcceuil";

function NavBar(props: any) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  const logout = () => {
    signOut(auth).then(() => {
      ApiService.disableAuth()
      navigate("/")
    }).catch((error) => {
      // An error happened.
    });

  }


  return (
      <div className="bg-gradient-to-r from-[#001E73] to-[#6F80AE] md:h-[80px]">
        <div className="flex items-center justify-between p-6 ">
          <div className="hidden lg:block">
            <img className="cursor-pointer xl:w-[200px] lg:w-[140px]" onClick={() => navigate('/')} src={logobleu} alt="" />
          </div>
          <div className="lg:hidden">
            <img className="cursor-pointer w-[84px]" onClick={() => navigate('/')} src={logobleu} alt="" />
          </div>
          <div className="lg:hidden">
            <MenuIcon
              onClick={() => {
                setToggle(!toggle);
              }}
            ></MenuIcon>
          </div>
          <div className="items-center hidden gap-5 lg:flex justify-around xl:w-1/2 text-base  ">
            <Link
              // href="/user/declaration"
              underline="none"
              className="font-regular text-white"
            >
              {/* TROUVER UNE PRESTATION */}
              <InfoAcceuil title="TROUVER UNE PRESTATION"/>
            </Link>
            <Link
              // href="/declarons/register"
              underline="none"
              className="block font-regular text-white"
            >
              {/* DEVENIR PRESTATAIRE */}
              <InfoAcceuil title="DEVENIR PRESTATAIRE"/>
            </Link>
            {user ?
              <Link
                onClick={() => logout()}
                underline="none"
                className="font-regular uppercase text-white"
              >
                DéCONNEXION
              </Link>
              :
              <Link
                // onClick={() => navigate('/declarons/login')}
                // onClick={openInfo}
                underline="none"
                className="font-regular uppercase text-white"
              >
                {/* CONNEXION */}
                <InfoAcceuil title="CONNEXION"/>
              </Link>
            }
          </div>
        </div>
        {toggle ? (
          <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
            <Link
              href="/user/declaration"
              underline="none"
              className="font-regular text-white"
            >
              {/* TROUVER UNE PRESTATION */}
              <InfoAcceuil title="TROUVER UNE PRESTATION"/>
            </Link>
            <Link
              href="/declarons/register"
              underline="none"
              className="block font-regular text-white"
            >
              {/* DEVENIR PRESTATAIRE */}
              <InfoAcceuil title="DEVENIR PRESTATAIRE"/>
            </Link>
            {user ?
              <Link
                onClick={() => logout()}
                underline="none"
                className="font-regular uppercase text-white"
              >
                DéCONNEXION
              </Link>
              :
              <Link
                // onClick={() => logout()}
                underline="none"
                className="font-regular uppercase text-white"
              >
                <InfoAcceuil title="CONNEXION"/>
                {/* CONNEXION */}
              </Link>
            }

          </div>
        ) : null}
      </div>
  );
}

export default NavBar;
