import Teachr from "../../Objects/models/Teachr";

const SET_INFO = 'SET_INFO';

const initialState = {
  teachr: null,
  file: undefined,
  authorization: '',
};

export default  function teachrReducer(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return { user: action.payload.teachr, authorization: action.payload.authorization, file: action.payload.file };
      default:
        return state
    }
  }