import React, { useEffect, useState } from 'react';
import { PunctualCourseDashboard } from '../../../../../Objects/models/PunctualCourseDashboard';
import { useNavigate } from 'react-router-dom';
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
import PunctualCourseRepository from '../../../../../Repositories/PunctualCourseRepository';
import TeachrContactRepository from '../../../../../Repositories/TeachrContactRepository';
import DatesOfWeek from './ChartDashboard/DatesOfWeek';
import DatesOfWeekForPrice from './ChartDashboard/DateOfWeeksPrice';
import SubscriptionCourseRepositorys from '../../../../../Repositories/SubscriptionCourseRepository';
// import CompSub from './Component/CompSub';
import ponct_dash from "../../../../../Assets/Images/ponct_dash.svg"
import CompPonctual from './Component/CompPonctual';
import CompChild from './Component/CompChild';
import moment from 'moment';


const DashboardLayout = () => {
    const [clientTiersPrest, setClientTiersPrest] = useState<any>();
    const [courses, setCourses] = useState<any>();
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const navigate = useNavigate()
    const handleChange = () => {
        navigate("/declarons/dashboard/prestations")
    }
    const handleChangeStudent = () => {
        navigate("/declarons/dashboard/client")
    }
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                TeachrContactRepository.fetchClientByTeachr(teachr.id).then((res) => {
                    setClientTiersPrest(res);
                })
                PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
                    (course) => {
                        setCourse(course);
                    }
                )
                SubscriptionCourseRepositorys.getActiveChargeForSubscriptionCourseByTeachrForDashboard(teachr.id).then(
                    (course: any) => {
                        setCourses(course);
                    }
                );
            }
        });
    }, []);

    // console.log(course?.length);

    return (
        <div className='h-[90%]  grid md:grid-cols-3 gap-4 grid-cols-1'>
            <div className=' h-[100%] md:col-span-2 space-y-4'> 
                <div className="h-[40%] bg-white md:shadow-md border-[1px] border-[#4B6AD86B] rounded-xl">
                    <div className="pl-7 py-2 md:block //hidden rounded-xl bg-[#EDF0FB]">
                        <h1 className="font-bold text-first lg:text-xl md:text-lg text-lg ">Mes demandes d'ajouts clients </h1>
                    </div>
                    <table className="w-full px-2 mt-2  text-sm text-left text-gray-500 ">
                        <tbody>
                            {clientTiersPrest?.sort(function (a: any, b: any) {
                                const date1: any = moment(a.createdAt.date).toDate()
                                const date2: any = moment(b.createdAt.date).toDate()
                                return (date1 - date2);
                            })?.slice(0, 2)?.map((child: any, key: any) => (
                                <CompChild child={child} key={key} />
                            ))
                            }
                        </tbody>
                    </table>
                    <div className="flex items-center mt-1 relative justify-between md:block hidden"  >
                        <div className="right-2 top-0 absolute">
                            <p className="block px-3 py-2 cursor-pointer text-first font-bold ml-0 leading-tight text-gray-500 bg-transparent border-gray-300 rounded-lg" onClick={handleChangeStudent}>
                                Voir plus
                            </p>
                        </div>
                    </div>
                </div>
                {course?.length === 0 ? 
                <div className="md:h-[57%] h-[70%] md:overflow-hidden overflow-x-auto bg-white shadow-md border-[1px] border-[#4B6AD86B] rounded-xl md:rounded-transparent">
                <div className="mx-auto">
                            <div className="md:w-4/5 mt-6  mx-auto ">
                                <p className="text-[#2B2B2B] lg:text-xl md:text-lg text-center text-base font-regular mx-auto " >
                                    Afin de recevoir un paiement via notre plateforme, Veuillez déclarer votre première prestation, ainsi que tout autre prestation que vous avez déjà dispensé.
                                </p>
                            </div>
                            <div className="mx-auto mt-6 md:w-1/4">
                                <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
                            </div>
                        </div>
                </div> 
                : 
                <div className="md:h-[57%] h-[70%] md:overflow-hidden overflow-x-auto bg-white shadow-md border-[1px] border-[#4B6AD86B]  rounded-xl md:rounded-transparent">
                    <div className="relative md:h-[85%]">
                        <table className="w-full //text-sm //text-left //text-gray-500">
                            <thead className="md:text-base lg:text-lg font-bold text-first //uppercase bg-[#EDF0FB] border-b-[0.5px] border-[#D2D2D2]">
                                <tr>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Date
                                    </th>
                                    {/* <th scope="col" className="px-6 py-3 text-center" >
                                        Heure
                                    </th> */}
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Client
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Prestations
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Paiement
                                    </th>
                                    <th scope="col" className="//px-4 py-3 text-center">
                                        <div className="border-b-2"></div>
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Rénumération
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Durée
                                    </th>
                                    <th scope="col" className="px-4  py-3 text-center">
                                        Statut
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-[#2B2B2B] ">
                                {course?.sort(function (a: any, b: any) {
                                    const date1: any = moment(a.startDatetime).toDate()
                                    const date2: any = moment(b.startDatetime).toDate()
                                    return (date1 - date2);

                                }).slice(0, 4)?.map((punctual: any, key) => (
                                    <CompPonctual punctual={punctual} key={key} />
                                ))}
                                {/* {courses?.sort(function (a: any, b: any) {
                                    const date1: any = moment(a.startDate).toDate()
                                    const date2: any = moment(b.startDate).toDate()
                                    return (date1 - date2);

                                }).slice(0, 2)?.map((sub: any, key: any) => (
                                    <CompSub sub={sub} key={key} /> 
                                ))} */}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex items-center mt-1 relative justify-between md:block hidden"  >
                        <div className="right-2 top-0 absolute">
                            <p className="block px-3 py-2 cursor-pointer text-first font-bold ml-0 leading-tight text-gray-500 bg-transparent  border-gray-300 rounded-lg" onClick={handleChange}>
                                Voir plus
                            </p>
                        </div>
                    </div>
                </div>}
            </div>
            <div className=' //-translate-y-12 px-6 md:col-span-1 space-y-4'>
                <div className="rounded-xl shadow-md border-[1px] border-[#4B6AD86B] col-span-1 bg-white md:block hidden h-[40%] ">
                    <DatesOfWeek />
                </div>
                <div className="rounded-xl shadow-md border-[1px] border-[#4B6AD86B]  h-[57%] bg-white md:block hidden">
                    <DatesOfWeekForPrice />
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;