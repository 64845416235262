import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import hours from "../../../../../../Assets/Icons/hour.svg";
import cours_ponctuel from "../../../../../../Assets/Images/cours_ponctuel.png";
import moment from "moment";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import Functions from "../../../../../../Helpers/Functions";
import DatePickers from "../../../../../../Components/Utilitis/DatePickers";
// import { TimePicker } from "antd";
import { auth } from "../../../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
// import { Button } from "react-bootstrap";
// const format = "HH:mm";


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
// import './TimePicker.css';

const StepPunctual = (props) => {
  const { handleChangeStep } = props

  const [user] = useAuthState(auth)
  moment.locale("fr");
  const today = new Date();
  const tomorrow = today.setDate(today.getDate());
  const start = moment().add(1, "hours");
  const end = moment().add(2.5, "hours");
  let minute = start.minute(); // Obtenez les minutes
  let minuteEnd = end.minute(); // Obtenez les minutes
  if (minute % 5 !== 0) { // Si les minutes ne sont pas divisibles par 5
    minute += (5 - (minute % 5)); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  if (minuteEnd % 5 !== 0) { // Si les minutes ne sont pas divisibles par 5
    minuteEnd += (5 - (minuteEnd % 5)); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }

  const heureAvecMinute = moment(start).minute(minute); // Créez une nouvelle instance Moment avec les minutes mises à jour
  const heureAvecMinuteEnd = moment(end).minute(minuteEnd); // Créez une nouvelle instance Moment avec les minutes mises à jour

  // const heureFormatee = heureAvecMinute; // Formatez l'heure avec Moment.js
  const mostLikelyTime = moment();
  mostLikelyTime.set({ hour: 17, minute: 0 });
  const [date, setDate] = useState(tomorrow);
  const [duration, setDuration] = useState(1.5);
  const [startTime, setStartTime] = useState(heureAvecMinute.toDate());
  const [endTime, setEndTime] = useState(heureAvecMinuteEnd.toDate());
  const getDate = (value) => {
    setDate(value);
  };
  const handleDurationChange = (event, newValue) => {
    setDuration(newValue);
    setEndTime(moment(startTime).add(newValue, "hours").toDate());
  };

  const [error, setError] = useState({
    endTime: "",
  });

  const handleChange = (value) => {
    let startTimes = moment(value);
    let startDate = moment(date);
    let startDateTime = startDate
      .set("hour", startTimes.hours())
      .set("minutes", startTimes.minutes())
    if (startDateTime < startTime) {
      setStartTime(heureAvecMinute.toDate());
      setEndTime(
        moment(heureAvecMinute).add(duration, "hours").toDate()
      );
    } else {
      setStartTime(value);
      setEndTime(
        moment(value).add(duration, "hours").toDate()
      );
    }
  };

  const validateDateTime = (value) => {
    const hour = moment(endTime).hours() - moment(startTime).hours();
    const minute = (moment(endTime).minutes() - moment(startTime).minutes()) / 60;
    const duration = hour + minute;
    if (duration < value) {
      return {
        isValid: false,
        message:
          // "Attention, le créneau horaire de votre cours ne peut être inférieur à la durée de votre cours !",
          "Horaires de cours particulier non valides, veuillez sélectionner une plage horaire entre 00h01 et 23h59 sans chevauchement de journées.",
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };

  const validate = () => {
    let errEnd = true;
    if (!validateDateTime(duration).isValid) {
      setError({ endTime: validateDateTime(duration).message });
      errEnd = true;
    } else {
      setError({ endTime: validateDateTime(duration).message });
      errEnd = false;
    }
    if (errEnd) {
      return false;
    } else {
      return true;
    }
  };

  // const setEndTimes = (newValue) => {
  //   const hour = endTime.hours() - startTime.hours();
  //   const minute = (endTime.minutes() - startTime.minutes()) / 60;
  //   const duration = hour + minute;
  //   if (duration < duration) {
  //     setEndTime(newValue);
  //     setStartTime(moment(newValue).add(-duration, "hours"));
  //   } else {
  //     setEndTime(newValue);
  //   }
  // };

  useEffect(() => {
    StepperStore.removeItem("activeStepundefined")
  }, [])
  const handleSubmit = () => {
    if (validate()) {
      StepperStore.setItem("punctual" + user.uid, {
        date: date,
        duration: duration,
        startTime: startTime,
        endTime: endTime,
      });
      handleChangeStep(6);
    }

  };


  return (
    <div className="overflow-y-auto list-teacher relative bg-white md:py-8 py-4 xl:px-14 px-4 md:h-[100%] h-[100%] rounded-[11px]">
      <div className="md:h-[80%] w-full">
          <div className="mt-8">
            <p className="text-first font-bold lg:text-2xl text-lg //text-base">
              Prestation Ponctuelle
            </p>
          </div>
        <div className="mx-auto w-full //mt-8 ">
          <div className="mt-6 md:w-2/3 w-full ">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[2px] px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Nombre d'heure
              </label>
              <div className=" h-9 md:-translate-y-1 md:mt-0 -mt-2">
                <Functions.PrettoSlider
                  defaultValue={1}
                  step={1}
                  min={1}
                  max={4}
                  marks
                  value={duration}
                  onChange={handleDurationChange}
                />
              </div>
            </div>
            <span className="font-regular bg-transparent text-first">
              <input
                className="bg-transparent"
                type="text"
                value={Functions.renderDuration(duration * 2)}
                onChange={(e) => setDuration(e.target.value)}
              />
            </span>
          </div>
          <div className="w-full md:mt-8 mt-4 gap-8 md:space-y-0 space-y-6  md:flex block mt- mx-auto">
            <div className="md:w-1/2">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-sm font-medium"
                >
                  {" "}
                  Date de Prestation :
                </label>
                <div className="md:-mt-3 -mt-6 md:translate-y-0 translate-y-2  ">
                  <div className="relative  -ml-4 ">
                    <DatePickers value={date} callBack={getDate} />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-sans  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-sm font-medium"
                >
                  {" "}
                  Horaires de Prestation
                </label>
                <div className="flex mt-2">
                  <div className="w-full relative">
                    <div className="md:w-[90%] //border flex">
                      <div className=" w-1/4 ">
                        <DatePicker
                          selected={startTime}
                          value={startTime}
                          onChange={(newValue) => {
                            handleChange(newValue)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          timeCaption=""
                          locale="fr"
                          placeholderText="Sélectionner l'heure"
                          customInput={<input className='//border-2 //text-center mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                          // timeInputLabel="Heure :"
                          renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                            <div className="time-picker-header border-4 border-red-2 flex justify-between items-center h-12 bg-red-400 px-4">
                              <button className="text-red-700 text-2xl" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{'<'}</button>
                              <span className="text-red-800 text-xl font-regular">{format(date, 'MMMM yyyy', { locale: 'fr' })}</span>
                              <button className="text-red-700 text-2xl" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                            </div>
                          )}
                        />
                      </div>
                      <div className="" >
                        <span className="lg:text-xl md:text-base">à</span>
                      </div>
                      <div className="//text-base //w-1/2 //mt-2.5 //xl:ml-5 //lg:ml-2//ml-5 w-1/4 ">
                        <DatePicker
                          selected={endTime}
                          onChange={(newValue) => {
                            handleChange(newValue)
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          disabled={true}
                          timeIntervals={15}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          timeCaption=""
                          locale="fr"
                          placeholderText="Sélectionner l'heure"
                          customInput={<input className=' text-[#808080] text-right mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="-mt-2 md:mt-0">
                    <img
                      src={hours}
                      alt="calendar icon"
                      className="md:w-8 xl:w-10 w-7 md:-translate-x-3 //-translate-x-6 xl:-mt-2 "
                    />

                  </div>
                  {/* <div>
                  <img src={hours} alt="calendar icon" classname="w-6 -mt-4 -translate-y-4" />
                  </div> */}
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-2 text-lg md:translate-y-4 translate-y-2 ">
          <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
            {error.endTime === "null" || error.endTime === ""
              ? null
              : validateDateTime(duration).message}
          </p>
        </div>
      </div>

      <div className="md:hidden absolute inset-x-0 bottom-0 flex justify-around space-x-4 ">
        <div className="flex w-1/2 rounded-xl bg-white text-orange border-2 border-orange font-bold justify-center">
          <div
            onClick={() => {
              handleChangeStep(2);
            }}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={() => {
              handleSubmit();
            }}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-xs border-first text-first uppercase rounded-xl active:bg-first active:text-[#4d4d4d]  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="hidden px-12 absolute inset-x-0 bottom-4 md:flex justify-between">
        <div>
          <button
            onClick={() => {
              handleChangeStep(2);
            }}
            className="py-2 font-bold text-first uppercase rounded-xl text-sm px-14 bg-white border-2 border-first"
          >
            precedent
          </button>
        </div>
        <div className="">
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="py-2 font-bold text-white uppercase rounded-xl border-2 border-first text-sm px-14 bg-first"
          >
            SUIVANT
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepPunctual;
