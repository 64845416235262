import { useState } from 'react'
import NavBar from '../../../Components/Dashboard/NavBar'
import SubjectsSelect from '../../../Components/ComponentsUtils/SubjectsSelect';
import CourseIndex from './Course/Index';
import { Subject } from '../../../Objects/models/Subject';
import Loader from '../../../Components/utils/Loader';
import Pub from '../MonaBank/Pub';
function Index() {

    const [matieres, setMatieres] = useState<Subject[]>()
    const callBack = (value: Subject[]) => {
        setMatieres(value)
    }

    return (

        <div className='w-screen h-screen mx-auto overflow-y-auto pb-14'>
            {/* <CourseFinish /> */}
            <Loader />
            <NavBar active={2} />
            <div className="flex flex-col gap-14">
                {/* <div>
                    <Pub />
                </div> */}
                <div className='md:pl-8 mt-4'>
                    <h1 className='text-[#0B2875] lg:text-2xl font-bold //pl-6 md:text-lg text-base'>PRESTATIONS DISPONIBLES</h1>
                </div>
                <div className='px-2 md:pl-8 lg:w-1/2 md:w-3/4 md:-mt-4'>
                    <SubjectsSelect placeholder="SVT, piano, anglais..." callBack={callBack} customClass="bg-[#F2F5FB] p-3" />
                </div>
                <div className='md:pl-8 md:-mt-4'>
                <CourseIndex search={matieres} />
                </div>
            </div>

        </div>
    )
}

export default Index