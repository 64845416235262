import { useEffect, useState } from "react";
import TeachrRepository from "../../../../../Repositories/TeachrRepository";
import Teachr from "../../../../../Objects/models/Teachr";
import Functions from "../../../../../Helpers/Functions";
import EditIcon from '@mui/icons-material/Edit';
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import TeachrBusinessType from "../../../../../Objects/models/TeachrBusinessType";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import TeachrRCS from "../../../../../Objects/models/TeachrUpdateRCS";

const FacturationUpdate = () => {
    const [teachr, setTeachr] = useState<any>()
    const [isEdit, setisEdit] = useState(false)
    const [isEdit2, setisEdit2] = useState(false)
    const [isUpdate, setisUpdate] = useState(false)
    const [isUpdate2, setisUpdate2] = useState(false)
    const [selectedValue, setSelectedValue] = useState<any>();
    const [selectedValue2, setSelectedValue2] = useState<any>();

    const handleSelectChange = (event: any) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
    };

    const handleEdit = () => {
        setisEdit(!isEdit)
    }
    const handleEdit2 = () => {
        setisEdit2(!isEdit2)
    }
    const handleEditSave = () => {
        setisUpdate(true)
        if (teachr.id) {
            const businessTypes = new TeachrBusinessType(selectedValue)
            Teachr_Repository.teachrUpdateBusinessType(teachr.id, businessTypes).then((res) => {
                setisUpdate(true)
                handleEdit()
            }).catch(() => {
                setisUpdate(false)
            })
        }
    }
    const handleEditSave2 = () => {
        setisUpdate2(true)
        if (teachr.id) {
            const rcs = new TeachrRCS(selectedValue2)
            Teachr_Repository.teachrUpdateRCS(teachr.id, rcs).then((res) => {
                setisUpdate2(true)
                handleEdit2()
            }).catch(() => {
                setisUpdate2(false)
            })
        }
    }

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((res) => {
            setTeachr(res)
        })
    }, [])



    return (
        <div className='flex flex-col px-4 py-4 rounded-lg lg:p-5 shadow-profile-isAe gap-y-4 md:px-8'>
            <h3 className='text-base font-bold text-first lg:text-2xl'>Informations générales de facturation</h3>
            <div className='py-4 space-y-2 px-4 //text-center bg-[#F2F5FB] rounded-[7px] flex justify-between'>
                <p className=' //font-regular lg:text-[16px] text-normal'> <strong className='font-bold'>Forme de la société : </strong>
                    {isEdit ?
                        // <input type="text" value={businessType} onChange={(e) => setbusinessType(e.target.value)} className="//border-2 " /> 
                        <select id="businessType" name="businessType" onChange={handleSelectChange} value={selectedValue === undefined ? Functions.renderBusinessType(teachr?.businessType) : selectedValue}>
                            {/* <option value={true}>Sélectionner...</option> */}
                            <option value="AE">Auto-entrepreneur</option>
                            <option value="EI">Entreprise Individuelle</option>
                            <option value="EURL">Entreprise Unipersonnelle à Responsabilité Limitée</option>
                            <option value="SARL">Société à Responsabilité Limitée</option>
                            <option value="SA">Société Anonyme</option>
                            <option value="SAS">Société par Actions Simplifiée</option>
                            <option value="SASU">Société par Actions Simplifiée Unipersonnelle</option>
                            <option value="SNC">Société en Nom Collectif</option>
                            <option value="SCOP">Société Coopérative de Production</option>
                            <option value="SCS">Société en Commandite Simple</option>
                            <option value="SCA">Société en Commandite, par Action</option>
                            <option value="A">Association</option>
                        </select>
                        : Functions.renderBusinessType(isUpdate ? selectedValue : teachr?.businessType)
                    }
                </p>
                {isEdit ? <input type="button" value="Enregistrer" className="text-first font-bold -translate-y-1 cursor-pointer" onClick={handleEditSave} /> : <EditIcon className="text-first -translate-y-1 cursor-pointer" onClick={handleEdit} />}
            </div>
            <div className='py-4 space-y-2 px-4 //text-center bg-[#F2F5FB] rounded-[7px] flex justify-between'>
                <p className=' //font-regular lg:text-[16px] text-normal'> <strong className='font-bold'>Numéro du SIRET : </strong>

                    {isEdit2 ?
                        <input type="text" value={selectedValue2 === undefined ?  (teachr?.RCS) : selectedValue2} onChange={(e) => setSelectedValue2(e.target.value)} className="//border-2 " />
                        : isUpdate2 ? selectedValue2 :  teachr?.RCS
                    }
                </p>
                {isEdit2 ? <input type="button" value="Enregistrer" className="text-first font-bold -translate-y-1 cursor-pointer" onClick={handleEditSave2} /> : <EditIcon className="text-first -translate-y-1 cursor-pointer" onClick={handleEdit2} />}
            </div>
        </div>
    );
};

export default FacturationUpdate;