import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import PunctualCandidacyComponent from '../../../../../Components/Dashboard/Course/candidacy/Punctual';
import CourseProposalRepository from '../../../../../Repositories/CourseProposalRepository';

const PunctualCandidacy = (proposal: any) => {
    const MySwal = withReactContent(Swal)
    const cancelProposal = () => {
        Swal.clickConfirm()
        CourseProposalRepository.deleteCourseProposal(proposal).then(() => window.location.reload())
    }
    const showDialogue = () => {
        let timerInterval: any
        MySwal.fire({
            showConfirmButton: false,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                        <p>RETIRER MA CANDIDATURE</p>
                        <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                    </div>
                    <div className="py-8 text-left">
                        <div>
                            <p className='text-[20px] font-regular text-[#4D4D4D]'>Êtes-vous sûr de vouloir retirer votre candidature ?</p>
                        </div>
                        <div className="flex justify-between gap-8 pt-14">
                            <Button onClick={() => Swal.clickCancel()} className='w-full font-bold uppercase bg-transparent border-2 border-solid rounded-full text-third border-third' >Annuler</Button>
                            <Button onClick={() => cancelProposal()} className='w-full font-bold uppercase bg-transparent border-2 border-solid rounded-full text-first border-first' >Confirmer</Button>
                        </div>
                    </div>
                </div>
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Supprimée !',
                    icon: 'success',
                    text: "Votre candidature viens d'être retirée.",
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-candidature-close')
        MySwal.getPopup()?.classList.add('w-max')
        MySwal.getPopup()?.classList.add('rounded-[20px]')
    }
    return (
        <div className='w-max'>
            <PunctualCandidacyComponent {... proposal} />
            <div className='shadow-candidancy-sub p-6 pb-2 lg:pb-6 rounded-[10px] mx-auto md:mx-0 my-4'>
                <p className='flex items-center text-xl font-semibold text-[#4B6AD8]'>Rémunération de la prestation</p>
                <p className='pl-2 font-semibold text-[28px] text-[#4B6AD8]'>{(proposal.teachrPrice * parseInt(proposal.punctualCourse.duration) /2).toFixed(2) } €</p>
                <button onClick={() => showDialogue()} className='text-[16px] underline text-normal mx-auto w-full  lg:hidden'> Retirer la candidature </button>
            </div>
            <button onClick={() => showDialogue()} className='text-[16px] underline text-normal mx-auto w-full hidden lg:block'> Retirer la candidature </button>
        </div>
    );
};

export default PunctualCandidacy;