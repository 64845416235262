import React, { useEffect, useState } from "react";
// import errorIcon from "../../../Assets/Images/error.png";
import addresse from "../../../Assets/Images/addresse.png";
import StepperStore from "../../../LocalForage/StepperStore";
import { Address } from "../../../Objects/models/Address";
import { auth } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import ClientRepository from "../../../Repositories/ClientRepository";
import AddressRepository from "../../../Repositories/AddressRepository";
import CreateAddress from "./Address/CreateAddresse";
import { FormControlLabel, RadioGroup } from "@mui/material";
import RadioButton from "../../Utilitis/RadioButton";
import validateFirstName from "../../../Validations/FirstName";

const Third = (props: { handleNext: any; handleBack: any }) => {

  const { handleNext, handleBack } = props;
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  useEffect(() => {
    (async () => {
      try {
        if (user) {
          const clientWithId = await ClientRepository.getCurrentClient();
          if (clientWithId.id) {
            await AddressRepository.getClientAddresses(
              clientWithId.id
            );
          }
        }
      } catch (error) {
        console.warn(error);
      }
    })();
  }, [loading, user]);

  // const [add, setAdd] = useState<any>([]);
  const [dataAddresse, setDataAddresse] = useState<Address>();
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [options, setOptions] = useState<Address[]>([]);
  const [selected, setSelected] = useState("");
  const [add, setAdd] = useState<any>([]);

  const [error, setError] = useState({
    add: "null",
    // term: "null",
  });
  const [errors, setErrors] = useState({
    dataAddresse: "null",
    firstName: "null",
    lastName: "null",
  });
  const reLoad = () => {
    setLoading(!loading);
  };

  useEffect(() => {
    (async () => {
      const addresse: any = await StepperStore.getItem("addresse");
      if (addresse !== null) {
        setDataAddresse(addresse.addresseObject);
      }
    })();
  }, [loading]);

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          const clientWithId = await ClientRepository.getCurrentClient();
          if (clientWithId.id) {
            const res = await AddressRepository.getClientAddresses(clientWithId.id);
            setOptions(res);
          }
        }
      } catch (error) {
        console.warn(error);
      }
    })();
  }, [loading, user]);



  const validateAdresseConnect = (value: any) => {
    if (value.length === 0) {
      return {
        isValid: false,
        message: "Veuillez séléctionner une adresse",
        //icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
      };
    }
    else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  }

  const validateAdresseDisConnect = (value: any) => {
    if (value === undefined) {
      return {
        isValid: false,
        message: "Veuillez entrer votre adresse",
        //icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
      };
    }
    else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  }
  const validate = () => {
    let erraddress = true;
    let errfirstname = true;
    let errlastname = true;
    if (!validateAdresseDisConnect(dataAddresse).isValid) {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      erraddress = true;
    } else {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      erraddress = false;
    }
    if (!validateFirstName(firstName).isValid) {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      errfirstname = true;
    } else {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      errfirstname = false
    }
    if (!validateFirstName(lastName).isValid) {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      errlastname = true;
    } else {
      setErrors({
        dataAddresse: validateAdresseDisConnect(dataAddresse).message,
        firstName: validateFirstName(firstName).message,
        lastName: validateFirstName(lastName).message,
      });
      errlastname = false;
    }
    if (erraddress || errfirstname || errlastname) {
      return false;
    } else {
      return true;
    }

  }
  const validateConnect = () => {
    let erraddress = true;
    if (!validateAdresseConnect(add).isValid) {
      setError({
        add: validateAdresseConnect(add).message,
      });
      erraddress = true;
    } else {
      setError({
        add: validateAdresseConnect(add).message,
      });
      erraddress = false;
      if (erraddress) {
        return false;
      } else {
        return true;
      }
    }
  }

  const handleSubmitUserDisConnect = () => {
    if (validate()) {
      StepperStore.setItem(
        "thirdPrestation",
        {
          dataAddresse,
          firstName,
          lastName
        },
        function () { }
      );
      handleNext();
    }
  };
  const handleSubmitUserConnect = () => {
    if (validateConnect()) {
      const idAddress = parseInt(add)
      StepperStore.setItem(
        user ? "thirdPrestation" + user?.uid : "thirdPrestation",
        {
          idAddress,
        },
        function () { }
      );
      handleNext();
    }
  };
  const handleSubmit = (event: any) => {
    if (user) {
      handleSubmitUserConnect();
    } else {
      handleSubmitUserDisConnect();
    }
    event.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdd((event.target as HTMLInputElement).value);
  };

  const radioGroupRef = React.useRef<HTMLElement>(null);
  return (
    <>
      <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative ">
        <h5 className="xl:text-3xl md:text-2xl text-lg text- text-first font-regular">
          Lieu de la prestation :
        </h5>
        {!user ?
          <>
            <CreateAddress reLoad={reLoad} />
            <div className="md:flex justify-around h-6 md:-translate-y-10 lg:text-base md:text-sm text-xs">
              {errors.dataAddresse === "null" || errors.dataAddresse === ""
                ? null :
                <p className="text-[#FF724F]  w-full   translate-y-1 ">
                  {validateAdresseDisConnect(dataAddresse).message}
                </p>
              }
            </div>
          </>
          :
          user && options.length === 0 ?
            <CreateAddress reLoad={reLoad} />
            :
            <div>
              <div className="text-first lg:text-lg md:text-base text-sm  md:p-4 font-sans py-2 px-4 overflow-x-hidden bg-input-color relative rounded-lg shadow-md shadow-[rgba(0,0,0,25%)]">
                <RadioGroup
                  ref={radioGroupRef}
                  aria-label="ringtone"
                  name="ringtone"
                  value={add}
                  onChange={handleChange}
                >
                  {options.map((val: any, index: any) => (
                    <div className='flex relative space-y-2'>
                      <div className="mt-2">
                        <img
                          alt={`Avatar n°${val + 1}`}
                          src={addresse}
                          className="pr-4"
                        />
                      </div>
                      <div className="">
                        <p>
                          {val.formattedAddress}
                        </p>
                      </div>
                      <div className=" right-0 absolute">
                        <FormControlLabel
                          value={val.id}
                          key={val.id}
                          control={<RadioButton />}
                          labelPlacement="start"
                          label=""
                        />
                      </div>
                    </div>
                  ))}
                </RadioGroup>

                <CreateAddress reLoad={reLoad} />
              </div>
            </div>
        }
        {error.add === "null" || error.add === ""
          ? null :
          <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
            <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
              {validateAdresseConnect(add).message}
            </p>
          </div>
        }
        {!user && <>
          <h5 className="xl:text-3xl lg:text-2xl md:text-2xl text-lg text-first font-regular ">
            Informations personnelle :
          </h5>
          <div className="grid w-full md:gap-x-6 font-sans mx-auto lg:grid-cols-2">
            <div>
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[3px] px-3 w-full lg:text-lg md:text-base text-sm text-[#4d4d4d]">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-sm text-[#4d4d4d] font-medium"
                >
                  {" "}
                  Prénom :
                </label>
                <input
                  type="text"
                  id="firs_firstName"
                  className="w-full lg:text-lg md:text-md text-sm  text-gray-900 bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                  placeholder="Ex : Johny"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
            </div>
            <div className="">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[3px] px-3 w-full lg:text-lg md:text-base text-sm text-[#4d4d4d]">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-sm text-[#4d4d4d] font-medium"
                >
                  {" "}
                  Nom :
                </label>
                <input
                  type="text"
                  id="firs_firstName"
                  className="w-full lg:text-lg md:text-md text-sm  text-gray-900 bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                  placeholder="Ex : Doe"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>
            {errors.firstName === "null" || errors.firstName === ""
              ? null :
              <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
                <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
                  {validateFirstName(firstName).message}
                </p>
              </div>
            }
            {errors.lastName === "null" || errors.lastName === ""
              ? null :
              <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
                <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
                  {validateFirstName(lastName).message}
                </p>
              </div>
            }
          </div>
        </>}
        <div className="md:hidden  flex justify-around mx-auto space-x-4 translate-y-12 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
            <div
              onClick={handleBack}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleBack()}
              className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Third;
