
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import closeCircle from '../../../../../../../Assets/Icons/close_circle.svg'
// import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import PunctualCourseRepository from '../../../../../../../Repositories/PunctualCourseRepository';
// import { PunctualCourseDashboard } from '../../../../../../../Objects/models/PunctualCourseDashboard';
import StepperStore from '../../../../../../../LocalForage/StepperStore';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';

export default function PopUpDeletePunctualCourse(props: any) {
    let timerInterval: any;
    const { handleLoad, openDelete, handleOpenDelete, handleLockDelete } = props
    // const [course, setCourse] = React.useState<PunctualCourseDashboard[]>()
    const [value, setValue] = React.useState<any>();
    const handleClose = () => {
        handleOpenDelete(false);
    };
    const [loader, setLoader] = React.useState(false)

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (openDelete) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDelete]);



    React.useEffect(() => {
        StepperStore.getItem("course_id").then((value) => {
            if (value !== null) {
                setValue(value);
                handleLoad()
            }
        })
    }, [handleLoad])

    const handleDelete = () => {
        handleLoad()
        setLoader(true)
        const id = parseFloat(value)
        PunctualCourseRepository.deletePunctualByTeachrDashboard(id).then((res) => {
            setLoader(true)
            handleLoad()
            handleClose()
            Swal.fire({
                icon: "success",
                title: "Cours supprimé",
                text: "  Votre cours a bien été supprimé. Il ne sera pas déclaré et a disparu de votre liste de cours prévus.",
                timer: 5000,
                willClose: () => {
                    clearInterval(timerInterval);
                },
            });
            handleLockDelete()
            handleLoad()
            window.location.reload();

            // props.nextStep()
        }).catch((err) => {
            handleLoad()
            setLoader(false)
            handleLockDelete()
            console.warn(err);
        })
        setLoader(false)
    };


    return (
        <div className=''>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                // scroll={scroll} 
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"

            >
                <DialogTitle id="scroll-dialog-title" >
                    <div className='flex w-full //border gap-x-10 //px-10 //items-center relative //justify-between text-base font-bold uppercase text-first'>
                        <div className="//w-full w-3/4 //border pt-1">
                            <p className="text-left">Supprimer ce cours</p>
                        </div>
                        <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div className="//py-8 //px-10 text-left ">
                            <div className=" //ml-2 mt-4 w-full">
                                <p className='text-lg -mt-6 mb-4 text-normal font-regular'>En cliquant sur "Valider", ce cours sera définitivement supprimé et ne sera pas déclaré. Voulez-vous continuer ?
                                </p>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full px-4 justify-between //gap-x-8 py-3 //md:pt-8'>
                        <Button onClick={() => handleClose()} className='block bg-[#4d4d4d] font-bold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Retour</Button>
                        <Button onClick={() => handleDelete()} className='block bg-first font-bold   rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >{loader ? <CircularProgress /> : "Valider"}</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}