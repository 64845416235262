
import add from "../../../../Assets/Icons/add.svg";
import { FormControlLabel, RadioGroup } from "@mui/material";
// import prifile from "../../../../../Assets/Icons/profile.svg"
import Navbar from '../../../../Components/Admin/Navbar';

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import RadioButton from '../../../../Components/Utilitis/RadioButton';
// import ClientRepository from '../../../../Repositories/ClientRepository';
// import Client from "../../../../Objects/models/Client";
import TeachrRepository from "../../../../Repositories/TeachrRepository";
// import ChildRepository from "../../../../Repositories/ChildRepository";
// import Child from "../../../../Objects/models/Child";
import ComponentChildStudent from "./AddStudent/ComponentChildStudent";
import TeachrContactRepository from "../../../../Repositories/TeachrContactRepository";
import AddSutentValid from "./AddStudent/AddSutentValid";
// import Description from "@mui/icons-material/Description";
import NoteForTeachrRepository from "../../../../Repositories/NoteForTeachrRepository";
import NoteForTeachr from "../../../../Objects/models/NoteForTeachr";


import { Button, FormControl } from '@mui/material';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import RadioButton from '../../../../Components/utils/RadioButton';
import closeCircle from '../../../../Assets/Icons/close_circle.svg'
import Input from '../../../../Components/utils/Input';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import validateZipCode from '../../../../Validation/zipCode';
import ValidateNni from '../../../../Validation/Nni';
import ValidateIban from '../../../../Validation/Iban';
import { Address } from "../../../../Objects/models/Address";
import AddressRepository from "../../../../Repositories/AddressRepository";
import Teachr from "../../../../Objects/models/Teachr";
import moment from "moment";
import Index from "../Setup/Index";
import React from "react";
// import ComponentChildDeclareCourse from "./AddStudent/ComponentChildDeclareCourse";
// import TeachrRepository from "../../../../Repositories/TeachrRepository";


const AddStudentPage = () => {
    const navigate = useNavigate()
    const radioGroupRef = useRef<HTMLElement>(null);
    const [value, setValue] = useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const [clientTiersPrestation, setClientTiersPrestation] = useState<any>();
    const [childs, setChilds] = useState<any>();
    const [isEdit, setIsEdit] = useState(false);
    const [description, setDescription] = useState("");
    const [note, setNote] = useState<any>()
    const [load, setLoad] = useState(false)

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                TeachrContactRepository.fetchClientByTeachr(teachr.id).then((res) => {
                    setChilds(res);
                })
                TeachrRepository.get_client_by_teacher_favorite(teachr.id).then((client: any) => {
                    setClientTiersPrestation(client.clients)
                })
                NoteForTeachrRepository.fetchNoteByTeachr(teachr.id).then((res) => {
                    setNote(res);
                    setLoad(false)
                })
            }

        })
    }, [load])

    const handleCreate = () => {
        setLoad(true)
        TeachrRepository.getCurrentTeachr().then((teachr) => {


            if (note === undefined || note.length === 0) {
                const noteFor = new NoteForTeachr(
                    'title',
                    description,
                    "/teachrs/" + teachr.id
                )
                NoteForTeachrRepository.createNoteForTeachr(noteFor).then((notes) => {
                    setLoad(true)
                    setIsEdit(!isEdit)
                }).catch((err) => {
                    console.warn(err);

                    setLoad(false)
                })
            } else {
                const noteFor = new NoteForTeachr(
                    'title',
                    description === "" ? note.description : description,
                    "/teachrs/" + teachr.id
                )
                NoteForTeachrRepository.updateNoteForTeachr(note.id, noteFor).then((notes) => {
                    setLoad(true)
                    setIsEdit(!isEdit)
                }).catch((zerr) => {
                    console.warn(zerr);

                    setLoad(false)

                })

            }
        })
    }


    const [teachr, setTeachr] = useState<Teachr>()
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response && response.id) {
                setTeachr(response)
            }
        })
    }, [])


    const acceptCourse = () => {
        let isBornFrance = false
        let nni = ''
        let secondName = ''
        let postalCode = ''
        let defaultAddress: Address | null = null
        let iban = ''

        const getPlaceId = (placeId: string) => {
            geocodeByPlaceId(placeId).then((response) => {
                const addressObject = new Address(
                    response[0].place_id,
                    response[0].address_components[0].long_name,
                    response[0].address_components[1].long_name,
                    response[0].address_components[2].long_name,
                    response[0].address_components[3].long_name,
                    response[0].address_components[4].long_name,
                    response[0].address_components[5].long_name,
                    response[0].address_components[6].long_name,
                    response[0].geometry.location.lat().toString(),
                    '',
                    response[0].formatted_address,
                    teachr?.['@id']!,
                    true)
                addressObject.sessionToken = ''
                defaultAddress = addressObject
            })
        }
        const getPostal = (value: string) => {
            postalCode = value
        }
        const getNni = (value: string) => {
            nni = value.replace(/\s/g, '')
        }
        const getSecondName = (value: string) => {
            secondName = value
        }
        const validate = () => {
            let errAddress = true
            let errZipCode = true
            let errNni = true
            let errIban = true
            const errorsTag = document.getElementsByClassName('errors')
            if (defaultAddress) {
                errAddress = false
                errorsTag[0].innerHTML = ""
            } else {
                errAddress = true
                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"
            }
            if (validateZipCode(postalCode).isValid) {
                errZipCode = false
                errorsTag[1].innerHTML = validateZipCode(postalCode).message
            } else {
                errZipCode = true
                errorsTag[1].innerHTML = validateZipCode(postalCode).message
            }
            if (ValidateNni(nni).isValid) {
                errNni = false
                errorsTag[2].innerHTML = ValidateNni(nni).message
            } else {
                errNni = true
                errorsTag[2].innerHTML = ValidateNni(nni).message
            }
            if (ValidateIban(iban).isValid) {
                errorsTag[3].innerHTML = ValidateIban(iban).message
                errIban = false
            } else {
                errorsTag[3].innerHTML = ValidateIban(iban).message
                errIban = true
            }
            switch (teachr?.isAE) {


                case false:
                    if (teachr?.address !== null) {
                        if (teachr?.iban == null) {
                            if (errZipCode || errNni || errIban) {
                                return false
                            } else {
                                return true
                            }
                        } else {
                            if (errZipCode || errNni) {
                                return false
                            } else {
                                return true
                            }
                        }

                    } else {
                        if (teachr?.iban == null) {
                            if (errAddress || errZipCode || errNni || errIban) {
                                return false
                            } else {
                                return true
                            }
                        } else {
                            if (errAddress || errZipCode || errNni) {
                                return false
                            } else {
                                return true
                            }
                        }

                    }

                default:
                    if (errAddress) {
                        return false
                    } else {
                        return true
                    }
            }
        }
        const createAddress = () => {
            let submitError = true
            AddressRepository.createAddress(defaultAddress!).then(() => {
                submitError = true
                return true
            })
                .catch((error) => {
                    submitError = false
                    return false
                })
            return submitError
        }
        const updateTeachr = () => {
            let submitError = true
            const newTeachr = teachr!
            if (teachr && teachr.iban == null) {
                newTeachr.iban = iban.replace(/ /g, "")
            }
            newTeachr.NNI = nni
            newTeachr.bornPlace = postalCode
            newTeachr.secondFirstName = secondName
            newTeachr.isBornFrance = isBornFrance
            if (teachr && teachr.proofIdentity) {
                newTeachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr && teachr.proofIsAE) {
                newTeachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr && teachr.proofSignSap) {
                newTeachr.proofSignSap = teachr.proofSignSap['@id']
            }
            TeachrRepository.updateTeachr(newTeachr!).catch((error) => {
                submitError = false
            })
            return submitError
        }
        switch (teachr?.isAE) {
            case true:
                if (teachr?.address !== null) {
                    if (teachr && teachr.iban == null) {
                        MySwal.fire({
                            showConfirmButton: false,
                            html:
                                <div>
                                    <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                                        <p>Renseignements supplémentaires</p>
                                        <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                    </div>
                                    {/* <div className='py-8'>
                                        <p className='w-full text-sm font-regular text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                    </div> */}
                                    <div className="py-8 text-left ">
                                        <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                            Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                        </p>
                                        <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                            <div className={teachr.iban ? 'hidden' : ''}>
                                                <p className='text-sm text-red-600 errors'></p>
                                                <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                    <p className='mx-2'>Iban</p>
                                                    <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-end pt-4 md:pt-8'>
                                            <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                        </div>
                                    </div>
                                </div>,
                            preConfirm: () => {
                                const errorsTag = document.getElementsByClassName('errors')
                                if (ValidateIban(iban).isValid) {
                                    errorsTag[0].innerHTML = ValidateIban(iban).message
                                    teachr.iban = iban.replace(/ /g, "")
                                    if (teachr && teachr.proofIdentity) {
                                        teachr.proofIdentity = teachr.proofIdentity['@id']
                                    }
                                    if (teachr && teachr.proofIsAE) {
                                        teachr.proofIsAE = teachr.proofIsAE['@id']
                                    }
                                    if (teachr && teachr.proofSignSap) {
                                        teachr.proofSignSap = teachr.proofSignSap['@id']
                                    }
                                    TeachrRepository.updateTeachr(teachr).catch(() => {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    })
                                } else {
                                    errorsTag[0].innerHTML = ValidateIban(iban).message

                                }
                            }
                        }).then((result) => result.isConfirmed && navigate("/declarons/dashboard/declare"))
                        MySwal.getPopup()?.classList.add('shadow-candidature-close')
                        MySwal.getPopup()?.classList.add('w-[698px]')
                        MySwal.getPopup()?.classList.add('rounded-[20px]')
                    } else {
                        navigate("/declarons/dashboard/declare");
                    }

                } else if (teachr && teachr.iban == null) {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                {/* <div className='py-8'>
                                    <p className='w-full text-sm font-regular text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div> */}
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                        <div className={teachr.iban ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Iban</p>
                                                <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value.replace(/ /g, "")} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            let errAddress = true
                            let errIban = true
                            if (defaultAddress) {
                                errAddress = false
                                errorsTag[0].innerHTML = ""
                            } else {
                                errAddress = true
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"

                            }
                            if (ValidateIban(iban).isValid) {
                                errorsTag[1].innerHTML = ValidateIban(iban).message
                                errIban = false
                            } else {
                                errorsTag[1].innerHTML = ValidateIban(iban).message
                                errIban = true
                            }
                            if (!errAddress && !errIban) {
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                } else {
                                    teachr.iban = iban.replace(/ /g, "")
                                    if (teachr && teachr.proofIdentity) {
                                        teachr.proofIdentity = teachr.proofIdentity['@id']
                                    }
                                    if (teachr && teachr.proofIsAE) {
                                        teachr.proofIsAE = teachr.proofIsAE['@id']
                                    }
                                    if (teachr && teachr.proofSignSap) {
                                        teachr.proofSignSap = teachr.proofSignSap['@id']
                                    }
                                    TeachrRepository.updateTeachr(teachr).catch(() => {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    })
                                }
                            } else {

                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/declarons/dashboard/declare"))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                } else {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                {/* <div className='py-8'>
                                    <p className='w-full text-sm font-regular text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div> */}
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            if (defaultAddress) {
                                errorsTag[0].innerHTML = ""
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                }
                            } else {
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"

                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/declarons/dashboard/declare"))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                }
                break;

            default:
                if (teachr?.isBornFrance === null && teachr?.NNI === null) {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                <div className='py-8'>
                                    <p className='w-full text-sm font-regular text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div>
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <p className='py-1 text-sm text-normal'>
                                        PS : si vous avez le statut auto-entrepreneur, cette étape n’est pas nécessaire.
                                        <a href='https://drive.google.com/drive/folders/1ua_E5nxAJQzB8NIE4E-4fI6hkwD_SS0e?usp=sharing' className='ml-1 underline' target="_blank" rel="noreferrer">Cliquez ici</a> pour en savoir plus et vous créer le statut 😉</p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <FormControl>
                                            <div className="flex items-center justify-between">
                                                <label className='text-sm font-bold text-first md:text-base'>Êtes-vous né(e) en France ? </label>
                                                <RadioGroup row
                                                    defaultValue="1"
                                                    name="customized-radios"
                                                >
                                                    <FormControlLabel value="1" onClick={() => isBornFrance = true} control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui" />
                                                    <FormControlLabel value="0" onClick={() => isBornFrance = false} control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                                                </RadioGroup>
                                            </div>
                                        </FormControl>
                                        <div className={teachr.address ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <Input callback={getPostal} label="Code postal de naissance" inset="true" placeholder='Ex : 75013' customClass="bg-bg-input-light p-2 text-sm text-normal font-regular" />
                                        </div>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <Input callback={getNni} label="Numéro de sécurité sociale" inset="true" placeholder='Ex : 1 97 12 83 232 428 48' customClass="bg-bg-input-light p-2 text-sm text-normal font-regular" />
                                        </div>
                                        <div className={teachr.iban ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Iban</p>
                                                <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value.replace(/ /g, "")} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                            </div>
                                        </div>
                                        <div>
                                            <Input callback={getSecondName} label="Deuxième prénom (Facultatif)" inset="true" placeholder='Ex : Hélène' customClass="bg-bg-input-light p-2 text-sm text-normal font-regular" />
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            if (validate()) {
                                if (!teachr.address) {
                                    if (createAddress()) {
                                        if (!updateTeachr()) {
                                            return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                        }
                                    }
                                } else {
                                    if (!updateTeachr()) {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    }
                                }


                            } else {

                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/declarons/dashboard/declare"))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                } else if (teachr?.address) {
                    navigate("/declarons/dashboard/declare");
                } else {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                <div className='py-8'>
                                    <p className='w-full text-sm font-regular text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div>
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>

                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-regular rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            if (defaultAddress) {
                                errorsTag[0].innerHTML = ""
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                }
                            } else {
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"

                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/declarons/dashboard/declare"))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                }
                break;
        }

    }

    return (
        <>
            {childs?.length === 0 ? <Index /> :
                <div className='h-screen  overflow-hidden bg-white'>
                    <Navbar index={2} />
                    <div className='lg:py-8 md:h-[100%]  //5border-2 h-[85%] md:overflow-hidden overflow-y-auto lg:px-16 md:px-8 px-4 py-4'>
                        <div className=" md:-mt-8 h-[100%]">
                            <div className="my-4 ">
                                <div onClick={() => navigate('/declarons/dashboard/setup')} className="cursor-pointer lg:text-xl md:text-lg text-base //text-sm text-[#4d4d4d] font-regular flex space-x-2">
                                    <div>
                                        <img src={add} alt="" className="w-6 md:w-full text-[#4d4d4d]" />
                                    </div>
                                    <div className="text-first pt-1">
                                        <p>Ajouter un client</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:flex md:space-x-10  h-[100%]  ">
                                <div className=" h-[100%]  md:w-5/6 ">
                                    <div className="b-white h-[55%] rounded-[11px] shadow-md border-[1px] border-[#4B6AD86B]  rounded-xl md:rounded-transparent">
                                        <div className="h-[80%]">
                                            <div className="md:p-4 p-3 bg-[#EDF0FB] rounded-[11px]">
                                                <h1 className="font-bold text-first lg:text-xl md:text-lg text-lg //md:pl-4 ">Mes demandes d'ajouts client </h1>
                                            </div>
                                            <div className="relative overflow-x-auto  scroll  h-[100%] //shadow-md sm:rounded-lg md:p-4 p-3">
                                                <table className="w-full text-sm text-left  text-gray-500 /text-gray-400">
                                                    <tbody>
                                                        {
                                                            childs?.sort(function (a: any, b: any) {
                                                                const date1: any = moment(a.createdAt.date).toDate()
                                                                const date2: any = moment(b.createdAt.date).toDate()
                                                                return (date2 - date1);
                                                            })?.map((child: any, key: any) => (
                                                                <ComponentChildStudent child={child} key={key} />
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block relative mt-4 py-6  overflow-hidden  rounded-[11px] shadow-md border-[1px] border-[#4B6AD86B] md:rounded-transparent bg-white  h-[28%] rounded-[11px]">
                                        <div>
                                            <h1 className="text-[#4d4d4d] lg:text-lg md:text-base text-sm pl-4 font-bold ">Besoin de noter ?</h1>
                                        </div>
                                        <div className="md:text-base text-sm mt-2   //border h-[60%]">
                                            {!isEdit ?
                                                <div className="w-full h-full px-6 overflow-y-auto scroll">
                                                    <p >
                                                        {load ? 'Chargement ...' : note === undefined ? description : note.description}
                                                    </p>

                                                </div>
                                                :
                                                <div className="w-full h-full overflow-y-auto //border-2 scroll">
                                                    <textarea onChange={(e) => setDescription(e.target.value)} className="w-full h-full px-6 //border-2 //overflow-y-auto list-teacher //scroll">{note === undefined ? description : note.description}</textarea>
                                                </div>
                                            }
                                        </div>
                                        { 
                                            !isEdit ?
                                                <button
                                                    onClick={() => setIsEdit(!isEdit)}
                                                    className=" px-10 right-2 absolute mt-2 py-1 rounded-lg uppercase md:text-base text-sm font-regular bg-[#EDEEF0] text-center">
                                                    Editer
                                                </button>
                                                :
                                                <button
                                                    onClick={() => handleCreate()}
                                                    className=" px-10 right-2 absolute mt-2 py-1 rounded-lg uppercase md:text-base text-sm font-regular bg-[#EDEEF0] text-center">

                                                    {load ? 'Chargement ...' : 'Enregistrer'}
                                                </button>
                                        }
                                    </div>
                                </div>
                                <div className=" md:h-[85%] h-[60%] md:mt-0 -mt-64 w-full rounded-xl  pb-4 md:pb-0 md:w-2/6 rounded-[11px] shadow-md border-[1px] border-[#4B6AD86B] md:rounded-transparent bg-white">
                                    <div className="bg-[#EDF0FB] rounded-xl md:p-4 p-2">
                                        <h1 className="lg:text-lg md:text-lg text-lg text-first font-bold">Mes clients</h1>
                                    </div>
                                    <div className="h-[80%] overflow-y-auto scroll md:px-4 px-2 md:pt-2 pt-2" >
                                        <RadioGroup
                                            ref={radioGroupRef}
                                            aria-label="ringtone"
                                            name="ringtone"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            {
                                                clientTiersPrestation?.map((child: any, key: any) => (
                                                    <AddSutentValid child={child} key={key} />
                                                ))
                                            }
                                        </RadioGroup>
                                    </div>
                                    <div
                                        onClick={() => acceptCourse()}
                                        className="rounded-lg border-first bg-first cursor-pointer mx-auto p-1 w-4/5 mt-2">
                                        <p className="lg:text-base md:text-sm text-xs text-white font-bold text-center cursor-pointer" >DÉCLARER UNE PRESTATION</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    );
};
export default AddStudentPage;