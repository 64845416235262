import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import info from "../../../../../Assets/Images/infos.jpg";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../../Assets/Images/checkclose.png";
// import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Functions from "../../../../../Helpers/Functions";
// import { useNavigate } from "react-router-dom";
// import CoursPonctuel from "../CoursPonctuel";
// import useState from 'react';

export default function IndexPrice(props: any) {
    const [open, setOpen] = React.useState(false);
    // const navigate = useNavigate()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {  price } = props

    return (
        <div className="font-sans">
            <span className="text-first " onClick={handleClickOpen}>
                {/* <img src={info} alt="calendar icon" className="" /> */}
                <InfoOutlinedIcon/>
            </span>
            <Dialog open={open} onClose={handleClose}>
                <div className="border-first border-1 p-3">
                    <DialogTitle>
                        <div className="flex justify-between mb-3">
                            <span className="text-first font-bold uppercase font-sans lg:text-[22px] md:text-lg text-base">
                                Comment cela est-il possible ?
                            </span>
                            <img
                                src={close}
                                alt=""
                                className="md:w-6 w-6 md:h-6 h-6 //translate-y-2"
                                onClick={handleClose}
                            />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <p className="text-[#4d4d4d] font-sans lg:text-lg md:text-base text-sm">
                            En facturant votre prestation à {price === undefined ? "70" : (price * 2).toFixed(2)}€, votre client ne paiera que la moitié. La commission de Teach'r étant de 10% du montant facturé, Soit {price === undefined ? "70" : (price * 2 * 0.1).toFixed(2)}€, vous gagnerez donc {price === undefined ? "70" : (price * 2).toFixed(2)}€ - {price === undefined ? "70" : (price * 2 * 0.1).toFixed(2)}€ = {price === undefined ? "70" : (price * 2 * 0.9).toFixed(2)}€ par heure de prestation facturée à ce prix-là.
                          </p>
                    </DialogContent>
                    <DialogActions>
                        <div className="md:w-1/2 w-full mb-4 mx-auto  "
                            onClick={handleClose}
                        >
                            <button className=" bg-white rounded-full py-2 text-first border-[2px] font-bold  shadow-shadow-button uppercase px-4 w-full hover:bg-first hover:text-white hover:border-white active:bg-first active:text-white active:border-white">
                                D'accord
                            </button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
