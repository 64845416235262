import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SubjectRepository from '../../Repositories/SubjectRepository';
// import searchIcon from "../../Assets/Images/searchIcon.png";
import { Subject } from '../../Objects/models/Subject';
//import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: "yellow"
//   },
//   clearIndicator: {
//     backgroundColor: "gray",
//     "& span": {
//       "& svg": {
//         backgroundColor: "red"
//       }
//     }
//   },
//   popupIndicator: {
//     "& span": {
//       "& svg": {
//         "& path": {
//           d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')"
//         }
//       }
//     }
//   }
// }));

export default function SubjectsSelects(props: any) {
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState<any>();
  const [options, setOptions] = useState<Subject[]>([]);
  const loading = open && options.length === 0;

  
  useEffect(() => {
    let active = true;

    // if (!loading) {
    //   return undefined;
    // } 

   
    
    
    (async () => {
      const response = await SubjectRepository.getSubjects()
      if (active) {
        setOptions(response.members);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <div
      className={
        " text-[#808080] relative rounded-lg font-sans" +
        props.customClass
      } 
    >
      <Autocomplete
        sx={{ "& .MuiOutlinedInput-root": {fontFamily:'nunito'},}}
        id="dubjects-select"
        fullWidth 
        open={open}
        classes={{
        //  clearIndicatorDirty: classes.clearIndicator,
          //popupIndicator: classes.popupIndicator
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={false}
        ///limitTags={1}
        noOptionsText= {subject === 'Maths' || subject === 'maths' ? <span className='font-sans lg:text-lg md:text-md text-sm'>Écrivez plutôt Mathématiques dans la barre de recherche 😊</span> : <span className='font-sans lg:text-lg md:text-md text-sm'>Cette matière n'est pas encore disponible. Veuillez sélectionner uniquement une seule matière de la liste.</span> }
        onChange={(value, getTagProps) => {props.callBack(getTagProps)}}
        isOptionEqualToValue={(option, value) => option.fr_FR === value.fr_FR}
        getOptionLabel={(option) => option.fr_FR}
        options={options}
        loading={loading}
        loadingText="chargement ..."
        defaultValue={props.value}
        popupIcon=''
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <span key={index} className='font-sans lg:text-lg md:text-md text-sm ' >{option.fr_FR} </span>
          ))
        }
        renderInput={(params) => (

          <TextField
            {...params}
            
            placeholder={props.placeholder}
            onChange={(e)=> setSubject(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <span className="">
                   
                </span>
            ),
              type: "search",
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              width: "95%",
              "& .MuiOutlinedInput-root": {fontFamily:'nunito'},
              "& fieldset": {
                border: "none",
              },
            }}
          />
        )}
      />
    </div>
  );
}