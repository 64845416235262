import moment from 'moment';
import React from 'react';
import cakeIcon from '../../../../../Assets/Icons/cake.svg'
import hatIcon from '../../../../../Assets/Icons/hat.svg'
import ProfileImage from '../../../../../Assets/Images/profile__image.png'
import Functions from '../../../../../Helpers/Functions';
import Child from '../../../../../Objects/models/Child';
import Client from '../../../../../Objects/models/Client';
const Profile = (child: Child | Client) => {
    const formatPhone = (phone: string | null | undefined) => {
        if (phone) {

            return '0' + phone.slice(3, 4) + '-' + phone.slice(4, 6) + '-' + phone.slice(6, 8) + '-' + phone.slice(8, 10) + '-' + phone.slice(10, 12)


        }
        return
    }
    return (
        <div className='h-full px-8 py-12 rounded-lg shadow-account'>
            <div className="flex flex-col gap-4">
                <img src={ProfileImage} className='w-[103px] mx-auto' alt="" />
                <div className='text-center'>
                    <h3 className='text-[20px] font-regular capitalize text-first'>{child.firstName} </h3>
                    <p className='text-normal text-[13px]'>{formatPhone(child.phone)} </p>
                </div>
                <div className="flex items-end justify-center gap-8">
                    <div className='flex flex-col items-center gap-1'>
                        <img src={cakeIcon} className="mx-auto"  alt="" />
                        <p className='font-regular text-first text-[16px]'>{moment().subtract(moment(child.birthday).year(), 'year').format('YY')} ans</p>
                    </div>
                    <div className='w-[4px] rounded-t-full rounded-b-full h-[45px] bg-radio self-center'>

                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <img src={hatIcon} alt="" />
                        <p className='font-regular text-first text-[16px]'>{Functions.getLevel(child.class)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;