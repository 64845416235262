import React, { useEffect, useState } from 'react';

const Fourth = (props: any) => {
    const [select, setSelect] = useState(0)
    const [error, setError] = useState(false)
    useEffect(() => {
        if (select > 0) {
            setError(false)
            props.callBack({
                socialOrganism: select
            })
            props.nextStep(5)
        }else{
            setError(true)
        }

    }, [select])
    return (
        <div className=''>
            {error ? <p className='text-third pb-1'>Veuillez sélectionner un organisme</p> : null}
            <label htmlFor="" className='text-normal text-xl font-regular'>Votre organisme d’assurance maladie ? </label>
            <ul className="flex flex-col ml-8 gap-y-4 py-4 cursor-default">
                <li onClick={() => setSelect(1)} className={`${select === 1 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Agricole </li>
                <li onClick={() => setSelect(2)} className={`${select === 2 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Autre </li>
                <li onClick={() => setSelect(3)} className={`${select === 3 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>ENIM </li>
                <li onClick={() => setSelect(4)} className={`${select === 4 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Non salarié </li>
                <li onClick={() => setSelect(5)} className={`${select === 5 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Non agricole</li>
                <li onClick={() => setSelect(6)} className={`${select === 6 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-lg font-regular hover:border-first hover:bg-[#E8F5FF]`}>Régime général</li>
            </ul>
        </div>
    );
};

export default Fourth;