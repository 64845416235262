// import { FormControlLabel, ListItemButton, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
// import RadioButton from "../../../../../../../Components/Utilitis/RadioButton";
import Child from "../../../../../../../../Objects/models/Child";
// import CourseProposal from "../../../../../../../Objects/models/CourseProposal";
import { Subject } from "../../../../../../../../Objects/models/Subject";
import SubjectRepository from "../../../../../../../../Repositories/SubjectRepository";
import CourseProposalRepository from '../../../../../../../../Repositories/CourseProposalRepository';
import ChildRepository from "../../../../../../../../Repositories/ChildRepository";
import moment from "moment";
import PunctualCourseRepository from '../../../../../../../../Repositories/PunctualCourseRepository';
import Functions from "../../../../../../../../Helpers/Functions";
// import paye from "../../../../../../Assets/Icons/paye.svg";
import DateTime from "../../../../UtilsDashboard/DateTime";
import MultipleSelect from "../../../../../../../../Components/Utilitis/MultiSelect";
// import { TimePicker } from "antd";
import { PunctualCourseDashboardUpdate } from "../../../../../../../../Objects/models/PunctualCourseDashboardUpdate";
// import PunctualCourseRepository from "../../../../../../../Repositories/PunctualRepository";
// import moment from "moment";
// import axios from "axios";
// import config from "../../../../../../../config";
import DatePicker from 'react-datepicker';
import CourseProposalDashboard from "../../../../../../../../Objects/models/CourseProposalDashbordUpdate";
import StepperStore from "../../../../../../../../LocalForage/StepperStore";
import React from "react";
const dureeListe: Array<any> = [
    { label: "1h", value: 2 },
    { label: "1h30", value: 3 },
    { label: "2h", value: 4 },
    { label: "2h30", value: 5 },
    { label: "3h", value: 6 },
    { label: "3h30", value: 7 },
    { label: "4h", value: 8 },
];

const EditChoicePunctual = (props: any) => {
    const { option } = props
    const [load, setLoad] = useState(false);
    const [subject, setSubject] = useState<Subject>()
    const [proposal, setProposal] = useState<any>()
    const [child, setChild] = useState<Child>()
    // const format = "HH:mm";
    const [date, setDate] = useState(option.startDatetime);
    const [duration, setDuration] = useState<any>((option.duration));
    const formattedTime = moment(option.startDatetime).format('HH:mm')
    const formattedEndTime = moment(option.endDatetime).format('HH:mm')
    const modifiedTime = moment(formattedTime, 'HH:mm').subtract(2, 'hours');
    const modifiedEndTime = moment(formattedEndTime, 'HH:mm').subtract(2, 'hours');
    // const start = moment(option.startDatetime)
    // const end = moment(option.endDatetime)
    const [startTime, setStartTime] = useState<any>(modifiedTime.toDate());
    const [endTime, setEndTime] = useState<any>(modifiedEndTime.toDate());
    const price = proposal?.teachrPrice.toString()
    const [teachrPrice, setTeachrPrice] = useState<any>();
    const [priceT, setPriceT] = useState<any>()




    const getDuration = (value: any) => {
        setDuration(value);
        setEndTime(moment(startTime).add(value.value === option.duration ? option.duration / 2 : value.value / 2, "hours"));
    };

    const getDate = (value: any) => {
        setDate(value);
    };

    useEffect(() => {
        SubjectRepository.fetchSubjectById(option.subjectAsked_id).then((subject) => {
            setSubject(subject)
        })
        CourseProposalRepository.fetchCourseProposalById(option.acceptedProposal_id).then((proposal) => {
            setProposal(proposal)
        })
        ChildRepository.fetchChildByIdForTeachr(option.forChild_id, option.directAskedTeachr_id).then((child) => {
            setChild(child)
        })
        PunctualCourseRepository.fetchChargePunctualCourseById(option.charge_id).then((res) => {
            setPriceT(res.amount);

        })
    }, [option.acceptedProposal_id, option.charge_id, option.directAskedTeachr_id, option.forChild_id, option.subjectAsked_id])



    const updatePunctualCourse = () => {
        setLoad(true)
        let startDate = moment(date);
        let startDateTime = startDate
            .set("hour", moment(startTime).hours())
            .set("minutes", moment(startTime).minutes()).format("YYYY-MM-DD HH:mm");
        let endDateTime = startDate
            .set("hour", moment(endTime).hours())
            .set("minutes", moment(endTime).minutes()).format("YYYY-MM-DD HH:mm");
        StepperStore.setItem("course_ponctual_update", startDateTime)

        const course = new PunctualCourseDashboardUpdate(
            startDateTime,
            endDateTime,
            duration === option.duration ? option.duration : duration.value
        )




        PunctualCourseRepository.updatePunctualCourseByTeachr(option.id, course).then((res) => {
            setLoad(true)
            const proposals = new CourseProposalDashboard(
                teachrPrice === undefined ? price : (teachrPrice * 0.9 * 2).toString(),
                startDateTime,
                endDateTime
            )
            if (proposal?.id) {
                CourseProposalRepository.updateCourseProposalCourseByTeachr(proposal?.id, proposals).then((res) => {
                    props.backStep(3)
                }).catch((err) => {
                    setLoad(false)
                })
            }
        }).catch((err) => {
            setLoad(false)
        })

        setLoad(false)
    }


    return (
        <>
            <div className=" h-[100%] hidden md:block shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent" key={option.id}>
                <table className="w-full text-sm text-left font-regular    ">
                    <thead className="text-sm rounded-lg md:text-base lg:text-lg font-bold text-first bg-[#EDF0FB]">
                        <tr className=" w-full ">
                            <th scope="col " className="py-4 rounded-tl-xl px-1 w-[16%]  ">
                                Date
                            </th>
                            <th scope="col " className="py-4 px-1 w-[16%]  ">
                                Heure
                            </th>
                            <th scope="col " className="py-4 px-1 w-[13%] ">
                                Élève
                            </th>
                            <th scope="col " className="py-4 px-1 w-[16%] ">
                                Matière
                            </th>
                            <th
                                scope="col "
                                className="py-4 "
                            >
                                <div className="text-center">
                                    <p>Paiement </p>
                                </div>
                            </th>
                            <th scope="col " className="py-4 px-1 w-[10%] ">
                                <div className="border-b-2 w-5 mx-auto -translate-y-2" ></div>
                            </th>
                            <th
                                scope="col "
                                className="py-4 "
                            >
                                <div className="text-center">
                                    <p>Rémunération</p>
                                </div>
                            </th>
                            <th scope="col " className="py-4 px-1 text-center rounded-tr-xl w-[12%] ">
                                Durée
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-[#4D4D4D] bg-transparent">
                        <tr className="  w-full">
                            <td className="py-4 px-1  hidden rounded-bl-xl lg:flex">{moment(option.startDatetime).format('dddd DD/MM/YYYY')}</td>
                            <td className="py-4 px-1 rounded-bl-xl lg:hidden">{moment(option.startDatetime).format('DD/MM/YYYY')}</td>
                            <td className="py-4 px-1">{modifiedTime.format('HH:mm')} - {modifiedEndTime.format('HH:mm')}</td>
                            <td className="//py-4  px-1">{child?.firstName}</td>
                            <td className="//py-4  px-1">{subject?.fr_FR}</td>
                            <td className="py-4 px- text-center //flex justify-around">
                                <div>
                                    {(((proposal?.teachrPrice + (proposal?.teachrPrice * 10 / 90) + 0) * (option.duration / 2)) / 2).toFixed(2) + "€"}
                                </div>
                            </td>
                            <td className="py-4 px-1"><div className="border-b-2 w-5 mx-auto md:-translate-y-2" ></div></td>
                            <td className="py-4 px- text-center //flex justify-around">
                                <div>
                                    {priceT === undefined ? "chargement..." : (proposal?.teachrPrice * (option.duration / 2)).toFixed(2) + "€"}
                                </div>
                            </td>
                            <td className="py-4 px-1 text-center rounded-br-xl //text-center">{Functions.renderDuration(option.duration)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <div className="">
                    <h1 className="lg:text-xl md:text-lg text-sm font-regular text-first">
                        Champs modifiables :{" "}
                    </h1>
                </div>
                <div className="mt-2 md:-ml-2 md:pr-2 grid grid-cols-2 gap-4 //md:px-10 md:space-x-2 space-y-3 md:space-y-0 justify-between md:text-lg text-sm bold">
                    <div className=" ml-2 w-full">
                        <div>
                            <p className="text-[#4d4d4d] font-regular">Durée :</p>
                        </div>
                        <MultipleSelect
                            data={dureeListe}
                            callBack={getDuration}
                            placeholder="Ex : CE2"
                            value={duration === option.duration ? Functions.renderDuration(option.duration) : Functions.renderDuration(duration.value)}
                            customClass="text-sm shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent text-center "
                        />
                    </div>
                    <div className="w-full ">
                        <div>
                            <p className="text-[#4d4d4d] font-regular">
                                Heure de cours :
                            </p>
                        </div>
                        <div className="shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent rounded-lg text-center py-4 pl-4">
                            <DatePicker
                                selected={startTime}
                                onChange={(newValue) => {
                                    setStartTime(newValue);
                                    setEndTime(moment(newValue).add(duration === option.duration ? option.duration / 2 : duration.value / 2, "hours").toDate());
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                timeCaption=""
                                locale="fr"
                                placeholderText="Sélectionner l'heure"
                                customInput={<input className='//border-2 //text-center mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                            // timeInputLabel="Heure :"

                            />
                        </div>
                    </div>
                    <div className="text-sm rounded-md w-full font-sans lg:text-lg md:text-base text-sm">
                        {/* <div className=" md:w-1/4 w-full  md:pr-4"> */}
                        <div>
                            <p className="text-[#4d4d4d] font-regular">
                                Jour de cours :
                            </p>
                        </div>
                        <div className="shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent rounded-lg md:py-2">
                            <DateTime value={date} callBack={getDate} />
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="block md:w-full">
                        <div>
                            <p className="text-[#4d4d4d] font-regular">Prix horaire payé après crédit d'impôt :</p>
                        </div>
                        <div className="shadow-md border-[1px] border-[#4B6AD86B]  rounded-lg md:rounded-transparent  rounded-lg w-full text-center py-4">
                            <input
                                type="text"
                                value={teachrPrice === undefined ? Math.round(price / 1.8) : teachrPrice}
                                className="//text-center pl-4  w-full"
                                onChange={(e) => setTeachrPrice(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden flex justify-between space-x-4 pb-4 translate-y-6">
                <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
                    <div
                        onClick={() => {
                            props.backStep(1);
                        }}
                        className="text-xs pt-2 w-full text-center uppercase"
                    >
                        Précédent
                    </div>
                </div>
                <div className="flex justify-center w-1/2 ">
                    <div
                        onClick={() => {
                            updatePunctualCourse()
                        }}
                        className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
                    >
                        {load ? "chargement..." : "Enregistrer"}
                    </div>
                </div>
            </div>
            <div className=" hidden translate-y-10 py-4 -mt-2 md:flex justify-between">
                <div>
                    <button
                        onClick={() => {
                            props.backStep(1);
                        }}
                        className="py-2 font-bold text-first uppercase rounded-lg text-sm px-14 bg-white border-2 border-first"
                    >
                        precedent
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => {
                            updatePunctualCourse()
                        }}
                        className="py-2 font-bold text-white uppercase rounded-lg text-sm px-14 bg-first border-2 border-first"
                    >
                        {load ? "chargement..." : "ENREGISTRER"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditChoicePunctual;