import React, { useEffect, useState } from "react";
import StepperStore from "../../../LocalForage/StepperStore";
import Functions from "../../../Helpers/Functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../Services/FirebaseService";
import ClientRepository from "../../../Repositories/ClientRepository";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import moment from "moment";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { CoursePack } from "../../../Objects/models/CoursePack";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
// import ChildRepository from "..:/../../Repositories/ChildRepository";
import { useNavigate } from "react-router-dom";
import { APIErrorEnum } from "../../../Objects/enums/APIErrorEnum";
// import ApiService from "../../../Services/ApiService";
import errorIcon from "../../../Assets/Images/error.png";
import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";
import AddressRepository from "../../../Repositories/AddressRepository";
import { Address } from "../../../Objects/models/Address";
import Client from "../../../Objects/models/Client";

const Fourth = (props: any) => {
  const date = Date.now()
  const { handleNext, handleBack } = props;
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  let timerInterval: any;
  const [datas, setDatas] = useState<any>({});
  const [datasUserDisconnect, setDatasUserDisconnect] = useState<any>({});
  const [address, setAddress] = useState<Address>();
  const [client, setClient] = useState<any >();
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [terms, setTerms] = useState(false);
  const [load, setLoad] = useState(false)
  const [error, setError] = useState({
    terms: "null",
    termsTeachr: "null",
  });



  useEffect(() => {
    (async () => {
      try {
        const firstPrestation: any = await StepperStore.getItem("firstPrestation");
        const secondPrestation: any = await StepperStore.getItem("secondPrestation");
        const thirdPrestation: any = await StepperStore.getItem("thirdPrestation");
        const addresse: any = await StepperStore.getItem("addresse");

        if (firstPrestation !== null && secondPrestation !== null && thirdPrestation !== null && addresse !== null) {
          setDatasUserDisconnect({
            prestation: firstPrestation.fr_FR,
            prestations: firstPrestation,
            adresse: addresse.addresseObject.streetName + ", " + addresse.addresseObject.city,
            adresses: addresse.addresseObject.formattedAddress,
            typePrestation: secondPrestation.type,
            duration: secondPrestation.data.duration * 2 + "",
            date: secondPrestation.data.date,
            dates: secondPrestation.data.dates,
            hour: secondPrestation.data.startTime,
            day: secondPrestation.data.day,
            startTime: secondPrestation.data.startTime,
            endTime: secondPrestation.data.endTime,
            firstName: thirdPrestation.firstName,
            lastName: thirdPrestation.lastName,
          });
        }
      } catch (error) {
        console.warn(error);
      }
    })()
  }, [datasUserDisconnect]);

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const firstPrestation: any = await StepperStore.getItem("firstPrestation" + user.uid);
          const secondPrestation: any = await StepperStore.getItem("secondPrestation" + user.uid);
          const thirdPrestation: any = await StepperStore.getItem("thirdPrestation" + user.uid);

          if (
            firstPrestation !== null &&
            secondPrestation !== null &&
            thirdPrestation !== null
          ) {
            setDatas({
              prestation: firstPrestation.fr_FR,
              prestations: firstPrestation["@id"],
              prestationId: firstPrestation.id,
              typePrestation: secondPrestation.type,
              duration: secondPrestation.data.duration * 2 + "",
              date: secondPrestation.data.date,
              dates: secondPrestation.data.dates,
              hour: secondPrestation.data.startTime,
              day: secondPrestation.data.day,
              startTime: secondPrestation.data.startTime,
              endTime: secondPrestation.data.endTime,
              idAddress: thirdPrestation.idAddress,
            });
          }
        } catch (error) {
          console.warn(error);
        }

      }
    })()
  }, [user])
  useEffect(() => {
    if (user) {
      AddressRepository.fetchAddressById(datas.idAddress).then((res) => {
        setAddress(res)
      })
      ClientRepository.getCurrentClient().then((res) => {
        setClient(res)
      })
    }
  }, [datas.idAddress, user])
  const validateTerms = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez accepter la politique des données",
        icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
      };
    } else
      return {
        isValid: true,
        message: "",
        icon: "",
      };
  };
  const validateTermsTeachr = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez accepter les GCUs et CGVs",
        icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
      };
    } else
      return {
        isValid: true,
        message: "",
        icon: "",
      };
  };
  const createPackCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        if (client !== undefined && client.id && address !== undefined && address?.id) {
          const forChild_id = client?.children[0].id
          const forClient_id = client?.children[0].id
          const subjectAsked_id = datas.prestationId;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.dates;
          const durationPerDay = Math.round(datas.duration);
          const isRemote = false;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address?.id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);
          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const createSubscriptionCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        if (client !== undefined && client.id && address !== undefined && address?.id) {
          const subjectAsked_id = datas.prestationId;
          const isRemote = false;
          const daysOfTheWeek = datas.day;
          const durationPerDay = Math.round(datas.duration);
          const paymentMean = "CASH";
          const forChild_id = client?.children[0].id;
          const forClient_id = client?.id;
          const extraInformation = "";
          let startTime = moment(datas.hour).format("HH:mm");
          let startDate = moment(datas.date).format("Y-MM-DD");
          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isRemote,
            address?.id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );


          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            client.id
          );

          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const inCourseInTheHour = async () => {
    try {
      setLoad(true)
      if (user) {
        if (client !== undefined && client.id && address !== undefined && address["@id"]) {
          const paymentMean = "CARD";
          const duration = Math.round(datas.duration);
          const subjectAsked = datas.prestations;
          const isCourseInTheHour = true;
          const forClient = client.isParent
            ? undefined
            : client?.children[0]["@id"];
          const forChild = client.isParent
            ? client?.children[0]["@id"]
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          let endTime = moment(datas.endTime);
          let startDate = moment(new Date().toDateString());
      

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address["@id"],
            duration,
            endDateTime,
            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );

          await PunctualCourseRepository.createPunctualCourse(course);


          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setLoad(false)
      // await setIsLoading(false);
      // await ApiService.disableAuth();
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de prestation en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
      }
    }
  };
  const createPonctualCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        if (client !== undefined && client.id && address !== undefined && address["@id"]) {
          const paymentMean = "CARD";
          const duration = Math.round(datas.duration);
          const subjectAsked = datas.prestations;
          const forClient = client.isParent
            ? undefined
            : client?.children[0]["@id"];
          const forChild = client.isParent
            ? client?.children[0]["@id"]
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          let startTime = moment(datas.startTime);
          let endTime = moment(datas.endTime);
          let startDate = moment(datas.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address["@id"],
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          await PunctualCourseRepository.createPunctualCourse(course);
          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setLoad(false)
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de prestation en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un Prestation dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };

  const validate = () => {
    let errterms = true;
    let errtermsTeachr = true;
    if (!validateTerms(terms).isValid) {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errterms = true;
    } else {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errterms = false;
    }
    if (!validateTermsTeachr(termsTeachr).isValid) {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errtermsTeachr = true;
    } else {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errtermsTeachr = false;
    }

    if (errterms || errtermsTeachr) {
      return false;
    } else {
      return true;
    }
  };
 
  const handleSubmit = () => {
    if (user) {
      if (datas.typePrestation === "Régulier") {
        createSubscriptionCourse();
      } else if (datas.typePrestation === "Ponctuel") {
        createPonctualCourse();
      } else if (datas.typePrestation === "Urgence") {
        inCourseInTheHour();
      } else if (datas.typePrestation === "Stage") {
        createPackCourse();
      }
    } else
      if (validate()) {
        StepperStore.setItem(
          "register",
          {
            terms: terms,
            termsTeachr: termsTeachr,
            datas: datasUserDisconnect
          },
          function () { }
        );
        handleNext();
      }
  };

  return (
    <div className="space-y-2 lg:space-y-10 md:space-y-4 w-4/5 mx-auto h-[100%] relative">
      <h5 className="text-lg font-bold text-first lg:text-3xl md:text-2xl"> Confirmation :</h5>
      {/* <span className="/translate-y-2 text-[#4d4d4d] md:text-[20px]">Veuillez vérifier vos informations</span> */}
      <div className="/-mt-4 space-y-2 ">
        {user ? (
          <div className="flex space-x-2">
            <span className="font-bold xl:text-xl lg:text-lg md:text-base text-sm text-[#4d4d4d]">Prestation :</span>
            <span className="text-sm truncate xl:text-xl lg:text-lg md:text-base text-first font-regular">
              {datas.prestation}
            </span>
          </div>
        ) : (
          <div className="flex space-x-2">
            <span className="font-bold xl:text-xl lg:text-lg md:text-base text-sm text-[#4d4d4d]">Prestation :</span>
            <span className="text-sm truncate xl:text-xl lg:text-lg md:text-base text-first font-regular">
              {datasUserDisconnect.prestation}
            </span>
          </div>
        )}

        {user &&
          (datas.typePrestation === "Régulier" ||
            datas.typePrestation === "Ponctuel" ||
            datas.typePrestation === "Urgence" ? (
            <div className="flex space-x-2">
              <span className="font-bold xl:text-xl lg:text-lg md:text-base text-sm text-[#4d4d4d]">Durée de prestation :</span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
                {Functions.renderDuration(datas.duration)}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.typePrestation === "Régulier" ||
          datasUserDisconnect.typePrestation === "Ponctuel" ||
          datasUserDisconnect.typePrestation === "Urgence" ? (
          <div className="flex space-x-2">
            <span className="font-bold xl:text-xl lg:text-lg md:text-base text-sm text-[#4d4d4d]">Durée de prestation :</span>
            <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
              {Functions.renderDuration(datasUserDisconnect.duration)}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="flex space-x-2">
          <span className="font-bold xl:text-xl lg:text-lg md:text-base text-sm text-[#4d4d4d]">Type de prestation :</span>
          <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
            {user ?
             datas.typePrestation === "Ponctuel" ? "Pontuelle" : datas.typePrestation === "Régulier" ? "Régulière" : datas.typePrestation === "Stage" ? "Regroupement des prestations" : datas.typePrestation
             : datasUserDisconnect.typePrestation === "Ponctuel" ? "Pontuelle" : datasUserDisconnect.typePrestation === "Régulier" ? "Régulière" : datasUserDisconnect.typePrestation === "Stage" ? "Regroupement des prestations" : datasUserDisconnect.typePrestation}
          </span>
        </div>

        {user &&
          (datas.typePrestation === "Ponctuel"  ? (
            <div className="flex space-x-2">
              <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Date/Heure :</span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
                {moment(datas.date).format("dddd DD MMMM YYYY")} à {" "}
                {Functions.renderTime(datas.startTime)}
              </span>
            </div>
          ) : datas.typePrestation === "Urgence" && (
            <div className="flex space-x-2">
              <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Fin de disponibilité :</span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
                {/* {moment(datas.date).format("dddd DD MMMM YYYY")} à {" "} */}
                {Functions.renderTime(datas.endTime)}
              </span>
            </div>
          ))}

        {datasUserDisconnect.typePrestation === "Ponctuel" ? (
          <div className="flex space-x-2">
            <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Date/Heure :</span>
            <span className="hidden text-sm xl:text-xl lg:text-lg md:text-base md:flex text-first font-regular">
              {moment(datasUserDisconnect.date).format("dddd DD MMMM YYYY")}/
              {Functions.renderTime(datasUserDisconnect.startTime)}
            </span>
            <span className="text-sm xl:text-xl lg:text-lg md:text-base md:hidden text-first font-regular">
              {moment(datasUserDisconnect.date).format("dddd DD/MM")}/{" "}
              {Functions.renderTime(datasUserDisconnect.startTime)}
            </span>
          </div>
        ) : (
          datasUserDisconnect.typePrestation === "Urgence" && (
            <div className="flex space-x-2">
              <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Fin de disponibilité :</span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular">
                {/* {moment(datas.date).format("dddd DD MMMM YYYY")} à {" "} */}
                {Functions.renderTime(datasUserDisconnect.endTime)}
              </span>
            </div>
          )
        )}
        {user &&
          (datas.type === "Régulier" ? (
            <div className="flex space-x-2">
              <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
              <span className="hidden text-sm xl:text-xl lg:text-lg md:text-base md:flex text-first font-regular">
                {moment(datas.date).format("dddd DD MMMM YYYY")} à{" "}
                {Functions.renderTime(datas.hour)}
              </span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base md:hidden text-first font-regular">
                {moment(datas.date).format("dddd DD/MM")} à{" "}
                {Functions.renderTime(datas.hour)}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.typePrestation === "Régulier" ? (
          <div className="flex space-x-2">
            <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
            <span className="hidden text-sm xl:text-xl lg:text-lg md:text-base md:flex text-first font-regular">
              {moment(datas.date).format("dddd DD MMMM YYYY")} à{" "}
              {Functions.renderTime(datas.hour)}
            </span>
            <span className="text-sm xl:text-xl lg:text-lg md:text-base md:hidden text-first font-regular">
              {moment(datas.date).format("dddd DD/MM")} à{" "}
              {Functions.renderTime(datas.hour)}
            </span>
          </div>
        ) : (
          ""
        )}

        {user &&
          (datas.type === "Stage" ? (
            <div className="flex space-x-2">
              <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
              <span className="hidden text-sm xl:text-xl lg:text-lg md:text-base md:flex text-first font-regular">
                {moment(datas.date).format("dddd DD MMMM YYYY")}
              </span>
              <span className="text-sm xl:text-xl lg:text-lg md:text-base md:hidden text-first font-regular">
                {moment(datas.date).format("dddd DD/MM")}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.typePrestation === "Stage" ? (
          <div className="flex space-x-2">
            <span className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
            <span className="hidden text-sm xl:text-xl lg:text-lg md:text-base md:flex text-first font-regular">
              {moment(datasUserDisconnect.date).format("dddd DD MMMM YYYY")}
            </span>
            <span className="text-sm xl:text-xl lg:text-lg md:text-base md:hidden text-first font-regular">
              {moment(datasUserDisconnect.date).format("dddd DD/MM")}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="flex space-x-2">
          <p className="xl:text-xl lg:text-lg md:text-base text-sm truncate font-bold text-[#4d4d4d]">
            Pour :{" "}
            <span className="text-sm xl:text-xl lg:text-lg md:text-base text-first font-regular ">
              {user ? client?.firstName + " " + client?.lastName : datasUserDisconnect.firstName + " " + datasUserDisconnect.lastName}
            </span>{" "}
          </p>
        </div>

        <div className="hidden space-x-2  md:flex">
          <p className="xl:text-xl lg:text-lg md:text-base text-sm truncate font-bold text-[#4d4d4d]">
            Adresse :{" "}
            <span className="text-sm  xl:text-xl lg:text-lg md:text-base text-first font-regular ">
              {user ? address?.formattedAddress : datasUserDisconnect.adresses}
            </span>{" "}
          </p>
        </div>
        <div className="flex space-x-2 md:hidden">
          <p className="xl:text-xl lg:text-lg md:text-base text-sm truncate  font-bold text-[#4d4d4d]">
            Adresse :{" "}
            <span className="text-sm font-normal xl:text-xl lg:text-lg md:text-base text-first font-regular ">
              {user ? address?.streetName + " "+ address?.city : datasUserDisconnect.adresse}
            </span>{" "}
          </p>
        </div>
      </div>

      {user ? (
        ""
      ) : (
        <div>
          <div className="hidden  mt-6  //-translate-y-6 md:block /gap-x-10">
            <div className="">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "27px",
                  // marginLeft: "4rem",
                  fontFamily: "Nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "Nunito",
                      },
                    }}
                    onClick={() => setTerms(!terms)}
                  />
                }
                label={
                  <Typography style={{ fontFamily: "nunito" }}>
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/policy"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J’accepte la politique des données.
                    </a>{" "}
                  </Typography>
                }
              />

              <div className="flex space-x-2 -translate-y-3 //ml-6 /lg:mt-4  ">
                {error.terms === "null" || error.terms === ""
                  ? " "
                  : validateTerms(terms).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.terms === "null" || error.terms === ""
                    ? null
                    : validateTerms(terms).message}
                </p>
              </div>
            </div>
            <div className=" font-sans -mt-4">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "27px",
                  // marginLeft: "4rem",
                  fontFamily: "nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "nunito",
                      },
                    }}
                    onClick={() => setTermsTeachr(!termsTeachr)}
                  />
                }
                label={
                  <Typography style={{ fontFamily: "nunito" }}>
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/cgu_cgv"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J'accepte les CGUs et CGVs.
                    </a>
                  </Typography>
                }
              />

              <div className="flex space-x-2 -translate-y-3 //ml-6 lg:mt-4 ">
                {error.termsTeachr === "null" || error.termsTeachr === ""
                  ? " "
                  : validateTermsTeachr(termsTeachr).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.termsTeachr === "null" || error.termsTeachr === ""
                    ? null
                    : validateTermsTeachr(termsTeachr).message}
                </p>
              </div>
            </div>
          </div>
          <div className="block   md:hidden">
            <div className="">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "16px",
                  // marginLeft: "4rem",
                  fontFamily: "Nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "Nunito",
                      },
                    }}
                    onClick={() => setTerms(!terms)}
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: "nunito" }}
                    className="text-sm xl:text-xl lg:text-lg md:text-base"
                  >
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/policy"
                      className="underline text-[#4d4d4d] "
                      rel="noreferrer"
                    >
                      J’accepte la politique des données.
                    </a>
                  </Typography>
                }
              />

              <div className="flex pt-2 space-x-2 text-xs -translate-y-3 md:text-sm md:ml-6 ">
                {error.terms === "null" || error.terms === ""
                  ? " "
                  : validateTerms(terms).icon}
                <p className="text-[#FF724F] h-6 w-full ">
                  {error.terms === "null" || error.terms === ""
                    ? null
                    : validateTerms(terms).message}
                </p>
              </div>
            </div>
            <div className="-mt-6  font-sans">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "10px",
                  // marginLeft: "4rem",
                  fontFamily: "nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "nunito",
                        fontSize: "10px",
                      },
                    }}
                    onClick={() => setTermsTeachr(!termsTeachr)}
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: "nunito" }}
                    className="text-sm xl:text-xl lg:text-lg md:text-base"
                  >
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/cgu_cgv"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J'accepte les CGUs et CGVs.
                    </a>{" "}
                  </Typography>
                }
              />

              <div className="flex pt-2 space-x-2 text-xs -translate-y-3 md:text-sm md:ml-6 ">
                {error.termsTeachr === "null" || error.termsTeachr === ""
                  ? " "
                  : validateTermsTeachr(termsTeachr).icon}
                <p className="text-[#FF724F] h-6 w-full ">
                  {error.termsTeachr === "null" || error.termsTeachr === ""
                    ? null
                    : validateTermsTeachr(termsTeachr).message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={user ? "md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-10 md:w-full" : "md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-0 md:w-full"}>
        <div className="flex justify-center w-1/2 font-bold bg-white border-2 rounded-full text-first border-first ">
          <div
            onClick={handleBack}
            className="w-full pt-2 text-sm text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <button
            onClick={() => handleSubmit()}
            disabled={load}
            className="w-full px-3 py-2 text-sm font-bold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-bold border-first text-first active:bg-first active:text-white "
          >
            {load ? (
              <CircularProgress
                sx={{
                  color: "#4d4d4d",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              user ? "Envoyer" : "Suivant"
            )}{" "}
          </button>
        </div>
      </div>
      <div className="md:flex bottom-0 absolute hidden justify-between mx-auto pb-6 md:w-full">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => handleBack()}
            className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent

          </button>
        </div>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={() => handleSubmit()}
            className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-bold hover:font-bold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-lg  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

          >
            {load ? (
              <CircularProgress
                sx={{
                  color: "#4d4d4d",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              user ? "Envoyer" : "Suivant"
            )}{" "}
          </button>
        </div>
      </div>
      {/* <div className={user ? "md:flex hidden translate-y-10 justify-around md:w-full mx-auto" : "md:flex hidden translate-y-0 justify-around md:w-full mx-auto"}>
        <div className="flex justify-center space-x-2">
          <button
            type="button"
            onClick={handleBack}
            className="  text-sm lg:text-[17px] font-regular  text-[#4d4d4d]  leading-tight uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent
          </button>
        </div>
        <div className="flex justify-center space-x-2 ">
          <button
            type="button"
            disabled={load}
            onClick={() => handleSubmit()}
            className={
              " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-regular hover:font-bold  hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
            }
          >
            {load ? (
              <CircularProgress
                sx={{
                  color: "#4d4d4d",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              user ? "Envoyer" : "Suivant"
            )}{" "}

          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Fourth;
