import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from '../../../../Components/Admin/Input';
import SelectComponent from '../../../../Components/Admin/Select';
import Functions from '../../../../Helpers/Functions';
import StepperStore from '../../../../LocalForage/StepperStore';
import AiciSetupInterface from '../../../../Objects/interfaces/AiciSetup';
import validateEmail from '../../../../Validation/Email';
import Layout from './Layout';

const First = (props: any) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [studentName, setStudentFirstName] = useState('')
    const [level, setLevel] = useState<number>()
   
    const getStudentName = (value: string) => {
        if (value) {
            setStudentFirstName(value)
        }
    }
    const getLevel = (value: number) => {
        if (value) {
            setLevel(value)
        }
    }
    const getFirstName = (value: string) => {
        if (value) {
            setFirstName(value)
        }
    }

    const getLastName = (value: string) => {
        if (value) {
            setLastName(value)
        }
    }
    const getMail = (value: string) => {
        if (value) {
            setEmail(value)
        }
    }
    const getPhone = (value: string) => {
        if (value) {
            setPhone(value)
        }
    }
    const [save, setSave] = useState<AiciSetupInterface>()
    const [errors, setErrors] = useState({
        mail: false
    })
    useEffect(() => {
        StepperStore.getItem('dashboard_setup').then((response: any) => {
            setSave(response)
            if (response) {
                if (response.student.firstName) {
                    setStudentFirstName(response.student.firstName)
                }
                if (response.student.level) {
                    setLevel(response.student.level)
                }
                if (response.Parent.first.firstName) {
                    setFirstName(response.Parent.first.firstName)
                }
                if (response.Parent.first.name) {
                   setLastName(response.Parent.first.name)
                }
                if (response.Parent.first.mail) {
                  setEmail(response.Parent.first.mail)
                }
                if (response.Parent.first.telephone) {
                    setPhone(response.Parent.first.telephone)
                }
            }
        })
    }, [])
    const validation = (): boolean => {
        setErrors({
            mail: !validateEmail(email).isValid
        })
        
        return validateEmail(email).isValid
    }
    const nextStep = () => {
        const screenState: AiciSetupInterface = {
            currentStep: 2,
            key: '',
            paiment: {
                bic: null,
                iban: null
            },
            Parent: {
                first: {
                    firstName: firstName,
                    name: lastName,
                    mail: email,
                    telephone: phone,
                },
                second: {
                    address: null,
                    birthDay: null,
                    country: null,
                    gender: null,
                    isBornFrance: null,
                    zipCode: null,
                    city: null,
                    birthName: null
                }
            },
            student: {
                firstName: studentName,
                level: level?.toString()!
            },
        }
        if (save) {
            if (save.paiment) {
                screenState.paiment = save.paiment
            }
            if (save.Parent) {
                screenState.Parent.second = save.Parent.second
            }
        }
        if (validation()) {
            StepperStore.setItem('dashboard_setup', screenState).then(()=>  props.nextStep(2))
        }
    }
    const prevStep = () => {
        if (save) {
            
        props.nextStep(0)
        }
    }
    return (
        <Layout
            next={<Button onClick={() => nextStep()} className='w-full font-bold text-white uppercase rounded-lg md:text-base px-14 bg-first md:w-max' >SUIVANT</Button>}
            prev={<Button onClick={() => prevStep()} className='w-full font-bold uppercase bg-white border-2 border-solid rounded-lg text-first md:text-base px-14 md:w-max border-first' >précédent</Button>}
        >
            <div className='md:w-3/4'>
                <div className='pb-6'>
                    <h1 className='pb-4 text-lg font-regular lg:text-xl text-first '>Informations du client (à voir avec Eytan/Margot) :</h1>
                    <div className="flex flex-col gap-8 md:flex-row">
                        <div className='w-full'>
                            <Input field='studentFirstName' defaultValue={studentName !== '' ? studentName : null} callBack={getStudentName} label='Prénom' placeholder='Ex: john' />
                        </div>
                        <div className='w-full'>
                            <SelectComponent
                                defaultValue={save?.student.level}
                                field='level'
                                placeholder='Ex: CE2'
                                callBack={getLevel}
                                label='Niveau scolaire'
                                data={Functions.level}
                            />
                        </div>
                    </div>
                </div>

                <h1 className='text-lg font-regular lg:text-xl text-first '>Informations de votre client (1/2) :</h1>
                <div className="flex flex-col gap-8 py-4">
                    <div className="flex flex-col justify-between gap-8 md:flex-row">
                        <div className="w-full">
                            <Input field='firstName' defaultValue={firstName !== '' ? firstName : null} callBack={getFirstName} label='Prénom' placeholder='Ex: john' />
                        </div>
                        <div className="w-full">
                            <Input field='lastName' defaultValue={lastName !== '' ? lastName : null} callBack={getLastName} label='Nom' placeholder='Ex: DUPONT' />
                        </div>
                    </div>
                    <div className={`${errors.mail ? 'pt-5' : 'pt-0'} flex flex-col gap-8 md:flex-row`}>
                        <div className="relative w-full">
                            <p className={`${errors.mail  ? 'absolute' : 'hidden'} text-third text-base md:text-base  -top-7`}>{validateEmail(email).message}</p>
                            <Input field='mail' defaultValue={email !== '' ? email : null} callBack={getMail} label='E-mail' placeholder='Ex: mail@mail.com' require />
                        </div>
                        <div className='w-full '>
                            <div className="w-full">
                                <Input field='telephone' defaultValue={phone !== '' ? phone : null} callBack={getPhone} label='Téléphone' placeholder='Ex: 06 XX XX XX XX' />
                            </div>
                        </div>
                    </div>

                </div>
                <p className='py-4 text-sm text-third'>*champs obligatoires</p>
            </div>
        </Layout>
    );
};

export default First;