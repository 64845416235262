// import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import ClientRepository from "../../Repositories/ClientRepository";
import AvailablePromotionalCode from "../../Objects/models/AvailablePromotionalCode";
import AvailablePromotionalCodeRepository from "../../Repositories/AvailablePromotionalCodeRepository";
// import PromotionalCode from "../Objects/models/PromotionalCode";
import config from "../../config";
// import Client from "../Objects/models/Client";
import { CircularProgress } from "@mui/material";

// interface Props {
//   collapsible?: boolean;
//   callBack?:(promotionalCode : PromotionalCode)=>void;
// }

export default function PromoComponent(props: any) {
  const Swal = require("sweetalert2");
  let timerInterval: any;
  const [codePromo, setCodePromo] = useState<string>();
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    setLoading(true);
    props.HandleLaod(true)
    const client = await ClientRepository.getCurrentClient();
    if (
      codePromo !== undefined &&
      codePromo.length >= 2 &&
      client.id &&
      client["@id"]
    ) {
      let cleanedCodePromo: any = codePromo?.replace(/\s/g, "");

      const availablePromotionalCode = new AvailablePromotionalCode(
        client["@id"],
        cleanedCodePromo?.toUpperCase()
      );

      try {
        const createCodeRequest: any =
          await AvailablePromotionalCodeRepository.createAvailablePromotionalCode(
            client.id,
            availablePromotionalCode
          );
        Swal.fire({
          icon: "success",
          title: "Code promo ajouté !",
          text: "Vos avantages seront appliqués",
          timer: 2000,
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.callBack ? props.callBack(createCodeRequest) : null; // si c'est un Prestation ponctuelle, on renvoie le code promo pour l'afficher sur le paiement

        try {
          await sendSlackMessage(createCodeRequest.promotionalCode);
        } catch (err) {
          Swal.fire({
            icon: "warning",
            title: "Erreur",
            text: "Veuillez contacter le service client",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "warning",
          title: "Erreur",
          text: "Impossible d'ajouter ce code promotionnel",
          timer: 2000,
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Erreur",
        text: "Ce code promotionnel est invalide",
        timer: 2000,
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    }
    setLoading(false);
    props.HandleLaod(false)
    setCodePromo("");
  };

  const sendSlackMessage = async (promo_code: any) => {
    const clients = await ClientRepository.getCurrentClient();

    let messageBody = {
      username: "Code promotionnel activé",
      text: "Nouvelle activation <!everyone> <!here>",
      icon_emoji: ":moneybag:",
      attachments: [
        {
          color: "#2eb886",
          fields: [
            {
              title: "Environment",
              value: config.debugMode ? "debug" : "prod",
              short: true,
            },
            {
              title: "User",
              value: clients?.id,
              short: true,
            },
            {
              title: "User mail",
              value: clients?.mail,
              short: true,
            },
            {
              title: "Code",
              value: promo_code.id + " - " + promo_code.fixReduction + "€",
              short: true,
            },
          ],
          actions: [
            {
              type: "button",
              text: "Go to Stripe",
              style: "primary",
              url: "https://dashboard.stripe.com/customers",
            },
          ],
        },
      ],
    };


    return new Promise((resolve, reject) => {
      // general request options, we defined that it's a POST request and content is JSON
      fetch(config.webHookSlackPromoCode, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-type": "application/json",
        },
        body: JSON.stringify(messageBody),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <div className="">
      <Accordion
        sx={{
          borderRadius: "10px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#F2F5FB",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Typography>
            <p className="uppercase lg:text-lg md:text-base text-sm text-first font-regular font-sans">
              Entrer un code promo
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="pb-3 //-mt-4">
              <p className="lg:text-lg md:text-base text-sm font-sans text-gray">
                Entrez un code promotionnel pour bénéficier des avantages
                associés lors de vos prochains cours Teach'r
              </p>
            </div>
            <div className="bg-input-color rounded-md py-2 px-3 w-full shadow-inputCard">
              <label
                htmlFor=""
                className="md:truncate text-sm md:text-base text-first"
              >
                Code promotionnel
              </label>
              <input
                type="text"
                className="border-none uppercase focus:outline-none text-sm md:text-base block bg-transparent w-full"
                placeholder="EX : MON_CSE_2022"
                onChange={(e: any) => setCodePromo(e.target.value)}
              />
            </div>
            <div className="mt-6">
              <div className="md:w-1/2 mx-auto p-2 lg:text-base hover:text-[#219CFF]  //md:translate-y-20 //translate-y-44  hover:border-2 hover:border-[#219CFF]   bg-first text-center lg:py-2 lg:px-4 rounded-full uppercase text-xs md:text-sm ">
                <button
                disabled={loading}
                  onClick={() => validate()}
                  className="no-underline uppercase hover:font-regular text-white text-center  font-bold"
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />
                  ) : (
                    "valider"
                  )}{" "}
                  
                </button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
