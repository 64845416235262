import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";

const ComponentMonthPriceChart = (props) => {
    const { durationByMonth } = props
    const chartRef = useRef(null);

    useEffect(() => {
        const chartData = {
            labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jui", "Aôut", "Sept", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "",
                    data: Object.values(durationByMonth),
                    fill: true,
                    backgroundColor: "#E8F5FF",
                    borderColor: "#0B2775",
                    borderRadius: 10,
                },
            ],
        };
        function hideNegativeTicks(value, index, values) {
            return value >= 0 ? value : null;
          }

        const chartConfig = {
            type: "line",
            data: chartData,
            options: {
                scales: {
                    y: {
                        display: true,
                        ticks: {
                            // Définir les valeurs personnalisées que vous souhaitez afficher sur l'axe Y
                            stepSize: 20, // Pas entre les valeurs
                            min: 0, // Valeur minimale affichée sur l'axe Y
                            suggestedMin: 0,
                            callback: hideNegativeTicks,
                            // max: 1000 // Valeur maximale affichée sur l'axe Y
                        }
                    },
                },
                barPercentage: 0.3,
                responsive: true,
            },
        };

        const canvas = chartRef.current;
        canvas.style.width = '50px'; // Set custom width
        canvas.style.height = '120px'; // Set custom height

        const ctx = canvas.getContext('2d');
        const myChart = new Chart(ctx, chartConfig)

        return () => {
            myChart.destroy();
        };
    }, [durationByMonth]);

    return (
        <div className=''>
            <canvas className="" ref={chartRef} />
        </div>
    );
};

export default ComponentMonthPriceChart;