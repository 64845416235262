
import * as React from 'react';
import info from '../../../../../Assets/Icons/info.svg'
// import add from "../../../../../Assets/Icons/add.svg";
// import clipSettings from '../../../../../Assets/Icons/clip-settings.svg'
// import msg from '../../../../../Assets/Images/msg.svg'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PopUpInfo(props: any) {
    const {handleDeclareCourse, acceptCourse, handleClose, open} = props
    // const [open, setOpen] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };


    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                INFO
            </Button> */}
          
            <Dialog
                // fullWidth={true}
                // maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <div className='flex w-full  gap-x-10  relative text-base font-bold uppercase text-first'>
                        <div className="pt-1 pl-2 gap-x-3 flex">
                            <div>
                                <p className="text-left lg:text-xl md:text-base text-sm">INFORMATION CLIENT</p>
                            </div>
                            <div>
                                <img onClick={() => handleClose()} className='cursor-pointer w-7' src={info} alt="" />
                            </div>
                        </div>
                        {/* <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div> */}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="text-left px-2 ">
                            <div className=" ">
                                <p className='text-lg text-normal '>
                                    Il se peut que certaines banques bloquent les prélèvements de divers émetteurs.   <br />
                                    Afin d’éviter cela, nous vous conseillons de transmettre à vos clients le numéro de créancier et l’émetteur des prélèvements URSSAF afin qu’ils puissent prévenir leurs banques.
                                </p>
                            </div>
                            <div className='flex gap-x-4 mt-6'>
                                <div className=''>
                                    <p>Numéro créancier ICS :</p>
                                    <div className='text-first bg-[#F2F5FB] p-2 '>
                                        FR55HOM143065
                                    </div>
                                </div>
                                <div className=''>
                                    <p>Émetteur :</p>
                                    <div className='text-first bg-[#F2F5FB] p-2'>
                                        URSSAF RHONE-ALPES CNCESU
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='w-full px-4 py-3 '>
                        <Button onClick={() => acceptCourse()} className='block mx-auto bg-first font-bold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >C’EST COMPRIS</Button>
                        <p onClick={() => handleDeclareCourse()} className='underline text-center pt-1 cursor-pointer'>Ne plus afficher ce message</p>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}