import React, { useState } from 'react';

import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../../Components/utils/RadioButton';
import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';
import Fifth from './Fifth';
import Sixth from './Sixth';
import Seventh from './Seventh';
import Eighth from './Eighth';
import Ninth from './Ninth';
import Tenth from './Tenth';
import Eleventh from './Eleventh';


const Index = (props: any) => {
    const [step, setStep] = useState(1)
    const [currentStep, setCurrentStep] = useState(1)
    const getData = (value: any) => {
        props.sendData(value)
    }
    const getStep = (value: number) => {
       setStep(value)
       
       
    }
    const nextStep = () => {
        if (step <= 11) {
            if (step === 9) {
                props.nextPage(4)
            }
            setCurrentStep(step)
        } else {
            props.callBack(4)
        }
    }
    const prevStep = ()=>{
        if (step > 1) {
            setStep(step - 1)
        }else{
            props.callBack(2)
        }
    }
    const renderScreen = () => {
        switch (currentStep) {
            case 1:
                
                return <First nextStep = {getStep} callBack={getData} />;
            case 2:

                return <Second nextStep = {getStep}  callBack={getData} />;
            case 3:

                return <Third nextStep = {getStep} callBack={getData} />;
            case 4:

                return <Fourth nextStep = {getStep} callBack={getData} />;
            case 5:

                return <Fifth nextStep = {getStep} callBack={getData} />;
            case 6:

                return <Sixth nextStep = {getStep} callBack={getData} />;
            case 7:

                return <Seventh nextStep = {getStep} callBack={getData} />;
            case 8:

                return <Eighth nextStep = {getStep} callBack={getData} />;
            case 9:

                return <Ninth nextStep = {getStep} callBack={getData} />;
            case 10:

                return <Tenth nextStep = {getStep} callBack={getData} />;
            case 11:

                return <Eleventh nextStep = {getStep} callBack={getData} />;

            default:
                return <First nextStep = {getStep} callBack={getData} />;
        }
    }
    return (
        <div className='flex h-full gap-10'>

            <div className='relative w-full'>
                <div className='flex items-center gap-x-4 '>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#0B2775" />
                        <path d="M18.3803 15.036C19.3043 15.2707 20.0083 15.718 20.4923 16.378C20.991 17.0233 21.2403 17.83 21.2403 18.798C21.2403 20.1327 20.749 21.196 19.7663 21.988C18.7836 22.78 17.4563 23.176 15.7843 23.176C14.831 23.176 13.9143 23.0293 13.0343 22.736C12.1543 22.4427 11.4063 22.032 10.7903 21.504C10.453 21.2107 10.2843 20.866 10.2843 20.47C10.2843 20.1473 10.3723 19.876 10.5483 19.656C10.739 19.4213 10.959 19.304 11.2083 19.304C11.355 19.304 11.487 19.3333 11.6043 19.392C11.7216 19.436 11.883 19.5167 12.0883 19.634C12.7043 20.0447 13.291 20.36 13.8483 20.58C14.4056 20.8 15.0143 20.91 15.6743 20.91C16.6423 20.91 17.361 20.7193 17.8303 20.338C18.2996 19.942 18.5343 19.348 18.5343 18.556C18.5343 17.7787 18.285 17.2067 17.7863 16.84C17.3023 16.4587 16.547 16.268 15.5203 16.268H14.2663C13.929 16.268 13.665 16.158 13.4743 15.938C13.2983 15.7033 13.2103 15.4393 13.2103 15.146C13.2103 14.838 13.2983 14.574 13.4743 14.354C13.665 14.134 13.929 14.024 14.2663 14.024H15.1463C17.1556 14.024 18.1603 13.2687 18.1603 11.758C18.1603 11.054 17.9476 10.5113 17.5223 10.13C17.097 9.74867 16.5103 9.558 15.7623 9.558C14.6916 9.558 13.5476 9.98333 12.3303 10.834C12.125 10.9513 11.9636 11.0393 11.8463 11.098C11.729 11.142 11.597 11.164 11.4503 11.164C11.201 11.164 10.981 11.054 10.7903 10.834C10.6143 10.5993 10.5263 10.3207 10.5263 9.998C10.5263 9.778 10.563 9.59467 10.6363 9.448C10.7243 9.28667 10.8563 9.12533 11.0323 8.964C11.6483 8.45067 12.3816 8.04733 13.2323 7.754C14.0976 7.446 14.9776 7.292 15.8723 7.292C17.3976 7.292 18.6076 7.666 19.5023 8.414C20.4116 9.162 20.8663 10.1593 20.8663 11.406C20.8516 12.2567 20.6243 13.0047 20.1843 13.65C19.759 14.2807 19.1576 14.7427 18.3803 15.036Z" fill="white" />
                    </svg>
                    <h2 className='text-first lg:text-[22px]'>Annexe 2 : Fiche de renseignement</h2>
                </div>
                <div className='pt-4 pb-10'>{currentStep}/11</div>
                {renderScreen()}
                <div className="flex justify-end py-10">
                    <div className="flex justify-between w-full gap-10">
                        <Button onClick={ ()=>props.prevStep()} className='flex w-1/3 py-3 text-xl font-regular rounded-full gap-x-4 text-normal'>
                            <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            PRÉCÉDENT
                        </Button>
                        <Button onClick={nextStep} className='w-1/3 py-3 text-xl font-bold text-white rounded-lg bg-first'>SUIVANT</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;