import React from "react";
import { useState } from "react";
import ComponentPoncual from "./ComponentPoncual";
import UpdatePonctuelChoice from "../EditPunctual/EditPunctualChoice";
import UpdateConfirme from "../EditPunctual/EditConfirme";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import PopUpDeletePunctualCourse from "../ComponentPunctual/PopUpDeletePunctualCourse";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import StepEnd from "../../../StepDeclare/StepEnd";


const LightTooltipNoError = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#F2F9FF",
        color: '#4d4d4d',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontFamily: "Nunito"
    },
}));
 
const CoursePonctual = () => {
    const [currentStep, setCurrentStep] = useState<any>(1);
    const [load, setLoad] = useState(false)
    const [isHere, setIsHere] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [openDelete, setOpenDelete] = useState(false);

    const handleLoad = () => {
        setLoad(!load)
    }

    const changeComponent = (comp: number) => {
        switch (comp) {
            case 1: return <ComponentPoncual handleLoad={handleLoad} isHere={isHere} isEdit={isEdit} isDelete={isDelete} handleEdit={handleEdit} handleDelete={handleDelete} />;
            case 2: return <UpdatePonctuelChoice nextStep={handleBack} backStep={handleBack} />;
            case 3: return <UpdateConfirme backStep={handleBack} />;
            case 4: return <StepEnd handleBack={handleBack} />;
            default: return <ComponentPoncual />;
        }
    };
    const handleBack = (val: number) => {
        setCurrentStep(val)
    }
    const handleLock = () => {
        handleBack(2)
        setIsHere(false)
        setIsEdit(false)
    }
    const handleLockDelete = () => {
        setIsHere(false)
        setIsDelete(false)
    }
    const handleEditTrue = () => {
        setIsHere(true)
        setIsDelete(false)
        setIsEdit(true)
    }
    const handleDeleteTrue = () => {
        setIsHere(true)
        setIsEdit(false)
        setIsDelete(true)
    }
    const handleOpenDelete = (val: boolean) => {
        setOpenDelete(val)
    }
    const handleEdit = () => {
        handleLoad()
        handleLock()
    }
    const handleDelete = () => {
        setOpenDelete(true)
    }


    return (
        <>
            <div className="  h-[100%] -mt-6 md:-mt-0">
                <div className="h-[70%] md:flex md:gap-x-6">
                    <div className="h-[100%]   mt-4 overflow-hidden w-full">
                        <div className="flex  h-12 md:pb-3 pb-2 //p-1 relative //shadow-dashboard-setup-intro-form">
                            <div className="flex absolute md:right-4 right-0  ">
                                <div className={"cursor-pointer "} >
                                    <LightTooltipNoError title={"Modifier"}>
                                        <Button onClick={() => handleEditTrue()}>
                                            {
                                                isEdit || currentStep === 2 || currentStep === 3 ?
                                                    <ModeEditOutlineIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0B2775', boxShadow: 3 }} />
                                                    :
                                                    <ModeEditOutlineIcon sx={{ color: "#0B2775", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                            }
                                        </Button>
                                    </LightTooltipNoError>
                                 </div>
                                <div className="cursor-pointer ">
                                    <LightTooltipNoError title={"Supprimer"}>
                                        <Button disabled={isEdit && currentStep === 2} onClick={() => handleDeleteTrue()}>
                                            {
                                                isDelete ?
                                                    <DeleteIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0B2775', boxShadow: 3 }} /> :
                                                    <DeleteIcon sx={{ color: "#0B2775", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                            }
                                        </Button>
                                    </LightTooltipNoError>
                                    <PopUpDeletePunctualCourse handleLoad={handleLoad} load={load} handleOpenDelete={handleOpenDelete} openDelete={openDelete} handleLockDelete={handleLockDelete} />
                                </div>
                            </div>
                        </div>
                        {changeComponent(currentStep)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CoursePonctual;
