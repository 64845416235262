import React from 'react';
import illu1 from "../../../../Assets/Icons/presentation-step1.svg"
import illu2 from "../../../../Assets/Icons/presentation-step2.svg"
import illu3 from "../../../../Assets/Icons/presentation-step3.svg"
import illu4 from "../../../../Assets/Icons/presentation-step4.svg"
const Steps = () => {
    return (
        <div className='px-4 mx-auto lg:w-[95%] md:px-8'>
            <div>
                <h2 className='font-bold text-first lg:text-[34px] uppercase text-lg lg:leading-10'>LE DASHBOARd un dispositif SPÉCIALEMENT conçu POUR LES PROFESSEURS</h2>
                <p className='font-bold lg:text-[26px] text-first md:py-10 py-8 text-base lg:leading-[40.92px]'>Pour pouvoir bénéficier de cet avantage mis en place par l'État, il n’y à qu’une seule solution :
                    Le Dashboard Teach’r.
                    Sans cette solution les parents ne pourront bénéficier du crédit d’impôt et vous ne pourrez déclarer vos cours particuliers.</p>
            </div>
            <div>
                <h3 className='py-8 text-base font-bold lg:text-3xl text-first'>Comment ça marche ?</h3>
                <div className="grid justify-between md:grid-cols-2 gap-y-14 gap-x-10">
                    <div>
                        <div className="flex gap-4 lg:gap-8">
                            <div>
                                <img src={illu1} alt="" />
                            </div>
                            <div className='w-full'>
                                <h5 className='font-bold lg:text-2xl'>1. Créez votre compte professeur</h5>
                                <p className='text-sm font-regular lg:text-xl'>Créer votre compte sur Teach’r.fr et accédez à votre Dasboard</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-4 lg:gap-8">
                            <div>
                                <img src={illu2} alt="" />
                            </div>
                            <div className='w-full'>
                                <h5 className='font-bold lg:text-2xl'>2. Ajoutez votre premier élève et son parent</h5>
                                <p className='text-sm font-regular lg:text-xl'>Ajouter les informations de votre client, celui-ci finalise son inscription, vous êtes ensuite allerté dès la validations de ses infomations </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-4 lg:gap-8">
                            <div>
                                <img src={illu3} alt="" />
                            </div>
                            <div className='w-full'>
                                <h5 className='font-bold lg:text-2xl'>3. Déclarez un cours</h5>
                                <p className='text-sm font-regular lg:text-xl'>Indiquer les information de votre cours.
                                    Le parent reçoit une demande de confirmation et valide votre demande.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-4 lg:gap-8">
                            <div>
                                <img src={illu4} alt="" />
                            </div>
                            <div className='w-full'>
                                <h5 className='font-bold lg:text-2xl'>4. Recevez votre paiement</h5>
                                <p className='text-sm font-regular lg:text-xl'>Une fois validé, recevez votre paiement et accédez à toutes les foncitonnalités de votre Dasboard</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Steps;