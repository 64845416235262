import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import TeachrRepository from '../../../../Repositories/TeachrRepository'
import ApiService from '../../../../Services/ApiService'
import { auth, getJWTToken } from '../../../../Services/FirebaseService'

export default function Last() {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (!loading) {
      if (user) {
        (async () => {
          // StepperStore.setItem("currentStep" + user.uid, 1, function (err) { })
          const jwtToken = await getJWTToken(user)
          ApiService.disableAuth()
          ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
          TeachrRepository.getCurrentTeachr().then().catch(() => {
            ApiService.disableAuth()
            navigate("/declarons/register")
          })
        })()
      }
    }
  },[user])
  return (
    <div className='/bg-center /bg-no-repeat //bg-contain /last-step-height //md:bg-right-bottom //bg-bg-register /md:h-full xl:gap-8 p-8 md:w-4/5  md:mx-0 mx-4 lg:flex-row bg-[#FFFFFF] border-[#758FDA78] border-[1px] rounded-lg'>
      <h1 className='text-first xl:text-3xl  lg:text-2xl md:text-xl text-lg font-bold' >Felicitations votre profil est complété !</h1>
      <div className='flex flex-col //md:w-2/4 xl:mt-8 mt-6 xl:gap-y-8 gap-y-4'>
        <p className='text-base font-bold text-first xl:text-xl lg:text-lg md:text-base '>Vous pouvez désormais postuler aux prestations disponibles sur notre plateforme !</p>
        <p className='text-base font-bold text-first xl:text-xl lg:text-lg md:text-base '>Vous pouvez également décider de remplir vos informations juridiques afin d’accéder à l’avance immédiate et à votre Dashboard.</p>
        {/* <p className='text-base text-first lg:text-xl '>⏱️ Durée ≈ 10 minutes </p> */}
        {/* <a href='https://m.me/teachrOfficial' target='_blank' className='w-full px-4 py-2 mt-2 text-xl font-bold text-center text-white uppercase rounded-full text bg-first' rel="noreferrer">PARTICIPER À L'ENTRETIEN</a> */}
        <p onClick={()=> navigate('/declarons/prestations')} className='xl:mt-20 mt-2 bg-first xl:py-3 py-2 rounded-lg font-bold uppercase text-center text-white ld:text-lg md:text-base cursor-pointer'>Postuler</p>
      </div>
    </div>
  )
}
