import React from 'react';
import emptyCandidature from '../../../../Assets/Images/empty_candidature.svg'
const Empty = () => {
    return (
        <div className='relative w-screen md:h-[calc(100vh_-_16rem)] overflow-hidden text-first'>
            <div className='flex flex-col gap-8 md:gap-8 lg:w-1/2'>
                <p className='lg:text-[32px]  text-xl font-regular whitespace-nowrap md:text-2xl'>
                    Aucune candidature en attente 🙁
                </p>
                <p className='text-lg whitespace-pre-wrap lg:text-2xl md:text-xl'>Effectuez vite une candidature,
                    nos élèves vous attendent avec impatience ! </p>
                <p className='font-regular whitespace-pre-wrap lg:text-xl md:text-xl'>Rendez-vous dans l’onglet “cours disponibles” et trouvez le cours qui vous correpond ! </p>
                <p className='font-regular lg:text-xl md:text-xl'>Vous avez déjà postulé ?
                    c’est que votre candidature a déjà été acceptée, bravo !⚡️ </p>
            </div>
            <div className='absolute bottom-0 right-0 hidden lg:top-0 md:block -z-40'>
                <img src={emptyCandidature} className="h-[100%]" alt="" />
            </div>
        </div>
    );
};

export default Empty;