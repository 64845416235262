import React, { useEffect, useState } from 'react';
import SimulatorNumberInput from '../../../../Components/utils/SimulatorNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import Simulator from '../../../../Objects/models/Simulator';
import { FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../../Components/utils/RadioButton';

interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
  }
const Second = (props:any) => {
    const [lastIncome, setLastIncome] = useState(0)
    const [externalIncome, setExternalIncome] = useState(0)
    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    const [education, setEducation] = useState(1)
    const dispatch = useDispatch()
    useEffect(() => {
        if (lastIncome >= 0 && externalIncome >= 0 && education >= 0 &&  education <= 1 && simulatorData) {
            dispatch({
                type: 'SET_INFO', payload: {
                    weekHours: simulatorData.weekHours,
                    hourlyPrice: simulatorData.hourlyPrice,
                    adults: simulatorData.adults,
                    childs: simulatorData.childs,
                    last_income: lastIncome,
                    external_income: externalIncome,
                    education: education,
                    isAcre: simulatorData.isAcre,
                    Response: null
                }
            })
            props.callBack(true)
        } else {
            props.callBack(false)
        }
    }, [externalIncome, lastIncome,education])
    const getLastIncome = (value: string) => {
        setLastIncome(parseInt(value))
    }
    const getExterneIncome = (value: string) => {
        setExternalIncome(parseInt(value))
    }
    useEffect(() => {
        if (simulatorData) {
            setExternalIncome(simulatorData.external_income)
            setLastIncome(simulatorData.last_income)
        }
    }, [simulatorData])

    return (
        <div className='flex flex-col gap-y-4'>
            <SimulatorNumberInput callBack={getLastIncome} placeholder="Ex : 25.000€" label="Revenu fiscal de référence N-2 du foyer" />
            <SimulatorNumberInput callBack={getExterneIncome} placeholder="Ex : 10.000€" label="Revenus actuels imposables hors A.E " />
            <div>
                <label htmlFor="" className='text-lg font-normal'>Enseignement :</label>
                <RadioGroup row
                    className='pl-1 gap-x-4 w-max'
                    name="customized-radios"
                    value={education}
                >
                    <FormControlLabel className='text-xs lg:text-xl' sx={{
                        '& .MuiTypography-root': {
                            fontFamily: 'nunito',
                            color: '#4D4D4D',
                            fontWeight: 'bold',
                            fontSize: 'inherit'
                        }
                    }} onClick={() => setEducation(0)} value={0} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Scolaire" />
                    <FormControlLabel className='text-xs lg:text-xl' sx={{
                        '& .MuiTypography-root': {
                            fontFamily: 'nunito',
                            color: '#4D4D4D',
                            fontWeight: 'bold',
                            fontSize: 'inherit'
                        }
                    }} onClick={() => setEducation(1)} value={1} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Extra-scolaire" />
                </RadioGroup>
            </div>
        </div>
    );
};

export default Second;