import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import uploadIcon from '../../../../../../Assets/Icons/upload.svg'
import Teachr from '../../../../../../Objects/models/Teachr';
import TeachrRepository from '../../../../../../Repositories/TeachrRepository';
import ImageRepository from '../../../../../../Repositories/ImageRepository';
import Functions from '../../../../../../Helpers/Functions';

const fileTypes = ["PDF", "PNG", "JPEG", "JPG"];
const Fourth = (props: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => setTeachr(response))
    }, [])
    const handleChange = (value: any) => {
        if (value) {
            setError(false)
            if (value.type.search("image") === 0) {
                Functions.resizeFile(value, true).then((response : any) => {
                    setFile(response);
                })
            } else {
                console.warn(URL.createObjectURL(new File([value], 'proof-is-ae', {type: value.type})));
                
                setFile(value)
            }
        }
    };
    const uploadFile = () => {
        if (file && teachr) {
            document.getElementById('teachr-loader')?.classList.remove('hidden')
            ImageRepository.createImage(new File([file], 'proof-is-ae', {type: file.type})).then((response) => {
                teachr.proofSignSap = response['@id']
                if ( teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity['@id']
                }
                if ( teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE['@id']
                }
                TeachrRepository.updateTeachr(teachr).then(() => {
                    document.getElementById('teachr-loader')?.classList.add('hidden')
                    // props.nextScreen(2)
                    props.nextScreen(4)
                })
            }).catch((error) => {
                document.getElementById('teachr-loader')?.classList.add('hidden')
            })
        }
    }
    return (
        <div className='lg:w-[55%] flex flex-col gap-y-10 md:w-2/3'>

            <div>
                <h3 className='text-base font-bold text-first lg:text-2xl'>Mandat de facturation : </h3>
                <p className='py-4 font-regular text-normal lg:text-lg'>Veuillez télécharger le mandat de facturation en <a target="_blank" href="https://docs.google.com/file/d/1ErIOZf4HvYIS5gt44DJRQoue8l9n4avs/edit?usp=docslist_api&filetype=msword" className='font-regular underline text-radio'>cliquant ici</a> et l’ajouter ensuite signé en format pdf ci dessous
                </p>
            </div>
            <div className='md:w-[55%]'>
                {
                    error ?
                        <FileUploader
                            classes='focus-within:bg-first'
                            label="qdqsdsqsd"
                            hoverTitle="qdsdqsdqsdsdsqdsq"
                            children={
                                <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-third bg-[#F2F5FB] p-4  gap-y-8'>
                                    <p className='text-third'>Veuillez insérer un fichier au format : ”PDF, PNG ou JPEG” ❌</p>
                                    <p className='text-sm font-regular text-center text-normal'>
                                        glissez  ou choisissez <a href="" className='font-regular text-radio'>un fichier</a>
                                    </p>
                                </div>
                            }
                            onTypeError={(value: any) => setError(true)}
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                        />

                        :
                        file ?
                            <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8'>
                                <div className="flex items-center gap-x-4">
                                    <p>“{file.name}” </p>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0B2775" />
                                    </svg>
                                </div>
                                <Button onClick={() => setFile(null)} className='mx-auto text-xs font-regular underline text-normal w-max'>Supprimer le fichier</Button>
                            </div> :
                            <FileUploader
                                classes='focus-within:bg-first'
                                label="qdqsdsqsd"
                                hoverTitle="qdsdqsdqsdsdsqdsq"
                                children={
                                    <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-[#B7B7B7] bg-white p-4  gap-y-8'>
                                        <img src={uploadIcon} alt="" />
                                        <p className='text-sm font-regular text-center text-normal'>
                                            Faites glisser votre mandat de facturation signé ou choisissez<a href="" className='font-regular text-radio'> un fichier</a>
                                        </p>
                                    </div>}
                                onTypeError={(value: any) => setError(true)}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                            />
                }
            </div>
            <Button onClick={uploadFile} className='w-full px-8 my-4 font-bold text-white rounded-lg bg-first md:my-8'>suivant</Button>
        </div>
    );
};

export default Fourth;