import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SubjectRepository from '../../Repositories/SubjectRepository';
import { Subject } from '../../Objects/models/Subject';

export default function SubjectsSelect(props: any) {
  const [open, setOpen] = useState(false);
  const [selects, setSelects] = useState<Subject[]>([])
  const [options, setOptions] = useState<Subject[]>([]);
  const loading = open && options.length === 0;
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await SubjectRepository.getSubjects()
      if (active) {
        setOptions(response.members);

      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div
      className={
        " text-[#808080] relative rounded-lg " +
        props.customClass
      }
    >
      <label className={!props.label ? 'hidden' : 'text-black pl-2 block'}>{props.label}</label>
      <Autocomplete
        id="subjects-select"
        fullWidth
        noOptionsText='Cette matière nest pas encore disponible'
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          
        }}
        multiple
        limitTags={window.screen.width > 768 ? 4 : 1}
        getLimitTagsText={(more)=> more > 1 ? ` +${more} matières`: ` +${more} matière` }
        onChange={(value, getTagProps) => { setSelects(getTagProps); props.callBack(getTagProps) }}
        disablePortal={true}
        isOptionEqualToValue={(option, value) => option.fr_FR === value.fr_FR}
        getOptionLabel={(option) => option.fr_FR}
        options={options}
        loading={loading}
        loadingText="chargement ..."
        popupIcon=''
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line no-octal-escape
            <span className="font-sans" key={index} >{option.fr_FR}<span className={`${!open && index === value.length - 1  && 'hidden'}`}>,&nbsp;</span></span>
          ))
        }
        renderInput={(params) => (

          <TextField
            {...params}
            placeholder={selects.length === 0 ? props.placeholder : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress className='mr-2' size={20} color="inherit" /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              width: "100%",
              padding: '0 10px !important',
              "& .MuiOutlinedInput-root": { fontFamily: "Nunito", paddingRight: '0 10px !important' },
              "& input": {
                fontFamily: "Nunito",
                padding: '0 !important',
              },
              "& .MuiInputBase-root": {
                padding: '0 !important',
              },
              "& fieldset": {
                border: "none",
              },
              

            }}
          />
        )}
      />
    </div>
  );
}
