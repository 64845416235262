import TeachrContact from "../Objects/models/TeachrContact";
import { Route } from "../Objects/enums/RouteEnum";
import ApiService from "../Services/ApiService";
// import HydraCollection from "../Objects/models/HydraCollection";
// import { plainToClass } from "class-transformer";
export default class TeachrContactRepository {
    /**
     * Creates an instance of Teachr into the API.
     * @param teachrContact
     */
    public static createTeachrContact(teachrContact: TeachrContact) {
        return ApiService.postInstance<TeachrContact>(
            Route.TEACHRCONTACT_BASE,
            TeachrContact,
            teachrContact
        );
    }
    public static fetchTeachrContactByKey(key: string) {
        return ApiService.get(`/teachrContact/client/${key}`)
    }
    public static fetchClientByTeachr(teachrId: number) {
        return ApiService.get(`/teachrs/get/client/${teachrId}`)
    }
    
    public static updateTeachrContact(teachrContact: TeachrContact) {
        return ApiService.putInstance<TeachrContact>(
            Route.TEACHRCONTACT_BASE +'/' + teachrContact.id,
            TeachrContact,
            teachrContact
        );
    }
}