import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import AddCards from "../CreditCards/AddCards";
import { useNavigate } from "react-router-dom";
import ClientRepository from "../../../../../Repositories/ClientRepository";
import PunctualCourseRepository from "../../../../../Repositories/PunctualCourseRepository";
import CourseProposal from "../../../../../Objects/models/CourseProposal";
import CreditCardRepository from "../../../../../Repositories/CreditCardRepository";
import DisplayCards from "../CreditCards/DisplayCards";
import CreditCard from "../../../../../Objects/models/CreditCard";
import secure from "../../../../../Assets/Images/securepayment.png";
import bgPayment from "../../../../../Assets/Images/bg-payment.svg";
import gif from "../../../../../Assets/Icons/load.gif";
// import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import PromoComponent from "../../../../../Components/Utilitis/PromoComponent";
import AvailablePromotionalCodeRepository from "../../../../../Repositories/AvailablePromotionalCodeRepository";
import AvailablePromotionalCode from "../../../../../Objects/models/AvailablePromotionalCode";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BusinessError } from "../../../../../Objects/exceptions";
import { Checkbox } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
// import validateSelect from "../../../../../Validation/Select";
import URSSAFParent from "../../../../../Objects/models/URSSAFParent";
import URSSAFParentRepository from "../../../../../Repositories/URSSAFParentRepository";
import DateInput from "../../../../../Components/Utilitis/DateInput";
import Functions from "../../../../../Helpers/Functions";

import MultiSelect from "../../../../../Components/Utilitis/MultiSelect";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import config from "../../../../../config";
import StepperStore from "../../../../../LocalForage/StepperStore";

const stripePromise = loadStripe(config.stripePublicKey);

const validateDate = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Veuillez sélectionner une date",
    };
  }
  if (parseInt(moment().subtract(value.year(), "year").format("YY")) < 16) {
    return {
      isValid: false,
      message: "Vous devez avoir au moins 16 ans",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};
const validateName = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Entrez votre nom",
    };
  } else {
    return {
      isValid: true,
      message: "",
    };
  }
};
const validateTerms = (value: any) => {
  if (!value) {
    return {
      isValid: false,
      message: "Veuillez accepter les conditions d'utilisation",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};

const validateSelect = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Veuillez sélectionner une valeur",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};

const PunctualCoursePayment = (props: any) => {

  const duration =
    Math.round(2 * (props.state.proposal.totalPrice / props.state.proposal.hourlyPrice));

  Math.round(2 * (props.state.proposal.totalPrice / props.state.proposal.hourlyPrice));

  const [promotionalCodes, setPromotionalCodes] =
    useState<Array<AvailablePromotionalCode>>();
  const [cards, setCards] = useState<CreditCard[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load] = useState(false);
  const [cguChecked, setCguChecked] = useState(false);
  const [payLoad, setPayLoad] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  // const MySwal = withReactContent(Swal);
  let timerInterval: any;
  const [birth, setBirth] = useState<any>();
  const [sex, setSex] = useState<any>();
  const [name, setName] = useState<any>();
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [errors, setError] = useState({
    name: "",
    sex: "",
    birth: "",
    terms: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getBirthDay = (value: any) => {
    if (value !== "") {
      setBirth(value);
    }
  };
  const getGender = (value: any) => {
    switch (value) {
      case "Femme":
        setSex("WOMAN");
        break;
      default:
        setSex("MAN");
        break;
    }
  };
  const validate = () => {
    let errlastName = true;
    let errbirthday = true;
    let errgender = true;
    let errterm = true;

    if (!validateName(name).isValid) {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errlastName = true;
    } else {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errlastName = false;
    }
    if (!validateDate(birth).isValid) {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errbirthday = true;
    } else {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errbirthday = false;
    }
    if (!validateSelect(sex).isValid) {
      setError({
        name: validateName(name).message,

        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errgender = true;
    } else {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errgender = false;
    }
    if (!validateTerms(sex).isValid) {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errterm = true;
    } else {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errterm = false;
    }

    if (errlastName || errgender || errbirthday || errterm) {
      return false;
    } else {
      return true;
    }
  };
  const check = () => {
    setCguChecked(!cguChecked);
  };
  const sendUpdate = async () => {
    const clientWithId = await ClientRepository.getCurrentClient();
    clientWithId!.birthday = birth;
    clientWithId!.marriedName = name;
    clientWithId!.sex = sex;
    clientWithId!.profilePicture = undefined;

    if (clientWithId?.isParent) {
      try {
        await ClientRepository.updateClient(clientWithId!);
        Swal.close();
      } catch (error) {
        console.warn("Erreur", "Veuillez réessayer");
      }
    } else {
      const sexe: any = sex;
      const urssaf = new URSSAFParent(
        clientWithId!["@id"]!,
        clientWithId.firstName,
        clientWithId.lastName,
        sexe,
        birth,
        name
      );
      // setUrssaf(urssaf)
      try {
        if (clientWithId.URSSAFNumber) {
          await URSSAFParentRepository.updateURSSAFParent(urssaf);
        } else {
          await URSSAFParentRepository.createURSSAFParent(urssaf);
        }
      } catch (error) {
        console.warn("Erreur", "Veuillez réessayer");
      }
    }
  };
  const handleSubmit = () => {
    if (validate()) {
      sendUpdate();
      handleClose();
    }
  };
  const HandleLaod = (bool: boolean) => {
    setIsLoading(bool);
  };
  const handleLaoding = (bool: boolean) => {
    setLoading(bool);
  };
  const getCards = () => {
    CreditCardRepository.getCreditCards().then((getCard) => {
      setCards(getCard.members);
    });
  };
  useEffect(() => {
    getCards();
  }, [loading, load]);
  const updatePunctual = () => {
    PunctualCourseRepository.updatePunctualCourse(props.state.proposal);
  };
  useEffect(() => {
    updatePunctual();
  }, []);



  useEffect(() => {
    (async () => {
      const payMean: any = await StepperStore.getItem("payMean");
      if (payMean !== null) {
        setPaymentMethod(payMean);
      }
    })();
  }, []);
  const handlePayerPress = async () => {
    setPayLoad(true);
    const client = await ClientRepository.getCurrentClient();
    let courseProposal: CourseProposal = props.state.proposal;
    let acceptResults: any;
   
    if (client.id) {
      try {
        setPayLoad(true);
        acceptResults = await PunctualCourseRepository.tryAcceptPunctualCourse(
          client.id,
          courseProposal.id!
        );
        console.log(acceptResults);
        
      } catch (error) {
       
        setPayLoad(false);
        if (error instanceof BusinessError) {
          switch (error.data.message) {
            case "must_complete_urssaf_info":
              handleClickOpen();
              break;
            case "no_presaved_credit_card":
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Vous devez enregistrer au moins une carte bancaire.",
                timer: 4000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
            case "expired_credit_card ":
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Votre carte bancaire n'est plus valide en raison de sa date d'expiration.",
                timer: 4000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
            default:
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Erreur inconnue. Veuillez nous contacter.",
                timer: 4000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Erreur inconnue. Veuillez réessayer.",
            timer: 4000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      }
      if (acceptResults.id) {
        setPayLoad(true);
        // the payment requires 3d secure, we call stripe to load customer's bank page
        try {
          // setPayLoad(true);
          if (stripe) {
            // setPayLoad(true);
            const card =
              paymentMethod?.error?.payment_intent?.last_payment_error
                ?.payment_method.id;
            const clientSecret =
              paymentMethod?.error?.payment_intent?.client_secret;
            stripe
              .confirmCardPayment(clientSecret, { payment_method: card })
              .then((res) => {
                // setPayLoad(true);
                if (res.paymentIntent?.status === "requires_capture") {
                  if (client.id) {
                    PunctualCourseRepository.acceptCourseProposalAfter3dSecure(
                      client.id,
                      courseProposal.id!
                    )
                      .then(() => {
                        setPayLoad(true);
                        Swal.fire({
                          icon: "success",
                          title: "Reussi",
                          text: "Paiement effectué avec succès !",
                          timer: 4000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        navigate("/user/confirm");
                      })
                      .catch((error) => {
                        setPayLoad(false);
                        console.warn(error);
                      });
                  }
                }
              });
          }
        } catch (error) {
          setPayLoad(false);
          console.warn(error);
        }
        setPayLoad(false);
      } else {
        setPayLoad(true);
        // the payment was directly succesfull, nothing more to do
        if (acceptResults.paymentIntentNeedAuthentification === false) {
          setPayLoad(true);
          Swal.fire({
            icon: "success",
            title: "Reussi",
            text: "Paiement effectué avec succès !",
            timer: 4000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          navigate("/user/confirm");
        }
        // the payment requires 3d secure, we call stripe to load customer's bank page
        try {
          setPayLoad(true);
          if (stripe) {
            stripe
              .confirmCardPayment(acceptResults.paymentIntentClientSecretId)
              .then((result) => {
                setPayLoad(true);
                setPaymentMethod(result);
                StepperStore.setItem("payMean", result);
                if (result.paymentIntent?.status === "requires_capture") {
                  if (client.id) {
                    PunctualCourseRepository.acceptCourseProposalAfter3dSecure(
                      client.id,
                      courseProposal.id!
                    )
                      .then(() => {
                        setPayLoad(true);
                        Swal.fire({
                          icon: "success",
                          title: "Reussi",
                          text: "Paiement effectué avec succès !",
                          timer: 4000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        navigate("/user/confirm");
                      })
                      .catch((error) => {
                        setPayLoad(false);
                        console.warn(error, "azer");
                      });
                  }
                }
              })
              .catch((error) => {
                setPayLoad(false);
                console.warn(error);
              });
          }
        } catch (error) {
          setPayLoad(false);
          console.warn(error);
        }
      }
      setPayLoad(false);
    }
    setPayLoad(false);
  };
  const getPromotionalCodes = async () => {
    const codes =
      await AvailablePromotionalCodeRepository.getAvailablePromotionalCodes();
    let actualCodesList: AvailablePromotionalCode[] = [];
    if (codes.members[0]) {
      codes.members.map((availablePromoCodes: AvailablePromotionalCode) => {
        if (
          moment(availablePromoCodes.promotionalCode!.endDatetime).isAfter(
            moment()
          )
        ) {
          actualCodesList.push(availablePromoCodes);
        }
      });
      setPromotionalCodes(actualCodesList);
    }
  };
  useEffect(() => {
    getPromotionalCodes();
  }, [isLoading]);
  const onPromotionalCodeAdded = (
    availablePromotionalCode: AvailablePromotionalCode
  ) => {
    if (
      moment(availablePromotionalCode.promotionalCode!.endDatetime).isAfter(
        moment()
      )
    ) {
      setPromotionalCodes([availablePromotionalCode]);
    }
  };

  return (
    <div className="w-screen h-screen overflow-y-auto list-teacher flex font-sans md:px-10 /border-2 ">
      <div className=" /border-2 w-1/2">
        <div className="md:flex ">
          <div className="pb-8">
            <div className="mt-3 md:w-2/3 md:mx-0 mx-7 md:mt-0 ">
              <div className="flex mx-auto gap-x-2 text-first">
                {promotionalCodes !== undefined && promotionalCodes[0] ? (
                  <div className="flex text-base font-regular xl:text-3xl lg:text-xl">
                    <p className="">
                      Payer :
                      <strong className="line-through text-[#D2D2D2] ml-2">
                        {(
                          JSON.parse(props.state.proposal.totalPrice!) / 100
                        ).toFixed(2) + "€"}
                      </strong>
                      <strong className="ml-2 ">
                        {promotionalCodes !== undefined &&
                          (
                            JSON.parse(props.state.proposal.totalPrice!) /
                            100 -
                            100 -
                            promotionalCodes[0].promotionalCode!.fixReduction!
                          ).toFixed(2)}{" "}
                        €
                      </strong>
                      <strong className="text-sm lg:text-lg md:text-base text-gray text-semibold //flex //gap-x-2">
                        {duration === 2
                          ? " par heure"
                          : " soit pour " +
                          Functions.renderDuration(duration)}
                      </strong>
                    </p>
                  </div>
                ) : (
                  <div className="flex text-base font-regular xl:text-3xl lg:text-xl">
                    <p className="">
                      Payer :
                      <strong className="ml-2 ">
                        {(
                          JSON.parse(props.state.proposal.totalPrice!) / 100
                        ).toFixed(2) + "€ "}
                      </strong>
                      <strong className="text-sm lg:text-lg md:text-base text-gray text-semibold //flex //gap-x-2">
                        {duration === 2
                          ? " par heure"
                          : "  soit pour "
                          +
                          Functions.renderDuration(duration)
                        }

                      </strong>
                    </p>
                  </div>
                )}
                {promotionalCodes !== undefined && promotionalCodes[0] ? (
                  <div className="hidden md:block text-[#5DA360] bg-[#91E79578] shadow-md p-1 rounded-md">
                    <p className=" font-regular //pt-2.5 text-sm">
                      -{" "}
                      {promotionalCodes !== undefined &&
                        promotionalCodes[0].promotionalCode?.fixReduction}
                      €
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {promotionalCodes !== undefined && promotionalCodes[0] ? (
                <div className="flex text-sm lg:text-lg md:text-base text-gray text-bold gap-x-2">
                  {props.state.course.isRemote ? null :
                    <p className="">
                      Soit après crédit d’impôt :
                      <strong className="line-through text-[#D2D2D2] //ml-2">
                        {" " +
                          (
                            JSON.parse(props.state.proposal.totalPrice!) / 200
                          ).toFixed(2) +
                          "€ "}
                      </strong>
                      <strong>
                        {promotionalCodes &&
                          (
                            (props.state.proposal.totalPrice! / 100 -
                              promotionalCodes[0].promotionalCode!
                                .fixReduction!) /
                            2
                          ).toFixed(2)}
                        €
                      </strong>
                    </p>}

                </div>
              ) : (
                <div>
                  {props.state.course.isRemote ? null :
                    <p className="text-sm lg:text-lg md:text-base text-gray text-bold">
                      Soit après crédit d’impôt :{" "}
                      {(
                        JSON.parse(props.state.proposal.totalPrice!) / 200
                      ).toFixed(2) + "€"}
                    </p>}

                </div>
              )}
              {cards === undefined ? (
                <div className="mx-auto my-auto //mt-40">
                  <img src={gif} alt="gif" className="mx-auto " />
                </div>
              ) : cards?.length! > 0 ? (
                <div className="w-4/5 mt-2 mb-4 //mx-auto">
                  {/* <AddCard HandleLaodCard={HandleLaodCard} /> */}
                  {cards?.map((card: CreditCard, key) => (
                    <DisplayCards {...card} key={key} />
                  ))}
                </div>
              ) : (
                <Elements stripe={stripePromise}>
                  <AddCards handleLaoding={handleLaoding} />
                </Elements>
              )}

              <div className="mx-auto /mt-2 //w-4/5">
                <PromoComponent
                  callBack={(promotionalCode: AvailablePromotionalCode) =>
                    onPromotionalCodeAdded(promotionalCode)
                  }
                  HandleLaod={HandleLaod}
                />
              </div>
              {cards?.length! > 0 ? (
                <div className="flex justify-around mt-8 cursor-pointer //z-50 md:w-full ">
                  {promotionalCodes !== undefined && promotionalCodes[0] ? (
                    <button
                      className="z-50 w-full py-2 text-sm font-bold text-center uppercase transition duration-150 ease-in-out bg-white border-2 rounded-lg cursor-pointer border-first text-first"
                      onClick={() => handlePayerPress()}
                    >
                      {payLoad ? (
                        <CircularProgress
                          sx={{
                            color: "blue",
                          }}
                          size={20}
                          thickness={4}
                          value={100}
                        />
                      ) : (
                        promotionalCodes !== undefined &&
                        "Payer " +
                        (
                          JSON.parse(props.state.proposal.totalPrice!) /
                          100 -
                          promotionalCodes[0].promotionalCode!.fixReduction!
                        ).toFixed(2)
                      )}{" "}
                      €
                    </button>
                  ) : (
                    <button
                      className="z-50 w-full py-2 text-sm font-bold text-center uppercase transition duration-150 ease-in-out bg-white border-2 rounded-lg cursor-pointer border-first text-first"
                      onClick={() => handlePayerPress()}
                    >
                      {payLoad ? (
                        <CircularProgress
                          sx={{
                            color: "blue",
                          }}
                          size={20}
                          thickness={4}
                          value={100}
                        />
                      ) : (
                        "Payer " +
                        (
                          JSON.parse(props.state.proposal.totalPrice!) / 100
                        ).toFixed(2) +
                        "€"
                      )}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="">
          <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div className="flex justify-between text-sm font-bold uppercase lg:text-lg md:text-base text-first">
                <p>dernière étape : Crédit d'impot</p>
                <ClearIcon
                  onClick={() => handleClose()}
                  className="font-bold cursor-pointer"
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="pl-2 pr-1 pb-2 h-[100%]">
                  <div className="//mt-4 text-left text-[#4d4d4d]">
                    <div>
                      <p>
                        Afin d'effectuer les démarches auprès de l'URSSAF,
                        Nous avons besoin de quelques renseignements.{" "}
                        <strong>
                          Ces informations sont obligatoires pour pouvoir
                          bénéficier du crédit d'impot.
                        </strong>
                      </p>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="w-full  shadow-md shadow-[rgba(0,0,0,25%)] rounded-md">
                        <DateInput
                          callback={getBirthDay}
                          customClass="bg-input-color"
                        />
                      </div>
                      <div className="flex w-full mx-auto text-lg -translate-y-3 //space-x-2 //md:translate-y-4 ">
                        <p className="text-[#FF724F] h-3   w-full text-sm md:text-base ">
                          {errors.birth === ""
                            ? null
                            : validateDate(birth).message}
                        </p>
                      </div>
                      <div className="flex //flex-col -mt-10 -translate-y-2 md:flex-row gap-x-4 text-[#4d4d4d]">
                        <div className="w-full">
                          <MultiSelect
                            data={["Femme", "Homme"]}
                            callBack={getGender}
                            placeholder="Ex : Homme"
                            customClass="bg-input-color py-[6px] text-[#4d4d4d] shadow-md shadow-[rgba(0,0,0,25%)] lg:text-lg md:text-base text-sm"
                          />
                          <div className="flex w-full mx-auto text-lg //space-x-2 //md:translate-y-4 //translate-y-1 ">
                            <p className="text-[#FF724F] h-3 w-ull text-sm md:text-base ">
                              {errors.sex === ""
                                ? null
                                : validateSelect(sex).message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="bg-input-color mt- rounded-md py-1.5 px-3 w-full lg:text-lg md:text-base text-sm text-[#4d4d4d] shadow-md shadow-[rgba(0,0,0,25%)]">
                          <label
                            htmlFor=""
                            className="lg:text-lg md:text-base text-sm text-[#4d4d4d] font-medium"
                          >
                            Nom
                          </label>
                          <input
                            type="text"
                            id="last_firstName"
                            className="w-full lg:text-lg md:text-md text-sm text-[#4d4d4d] bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                            placeholder="Ex : Doe"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="flex w-full mx-auto text-lg //space-x-2 //md:translate-y-4 //translate-y-2 ">
                          <p className="text-[#FF724F] h-3 w-full text-sm md:text-base ">
                            {errors.name === ""
                              ? null
                              : validateName(name).message}
                          </p>
                        </div>
                        <div className="text-sm h- //-translate-y-2 md:text-base text-third"></div>
                      </div>
                      <div className=" font-sans //-mt-6">
                        <FormControlLabel
                          sx={{
                            color: "#4d4d4d",
                            fontSize: "24px",
                            //marginLeft: "4rem",
                            fontFamily: "nunito",
                          }}
                          control={
                            <Checkbox
                              sx={{
                                color: "#4d4d4d",
                                "&.Mui-checked": {
                                  color: "#4d4d4d",
                                  fontFamily: "nunito",
                                },
                              }}
                              onClick={() => check()}
                            />
                          }
                          label={
                            <Typography style={{ fontFamily: "nunito" }}>
                              Je donne l'autorisation à Teach'r de déclarer
                              mes cours particuliers en mon nom et pour mon
                              compte
                            </Typography>
                          }
                        />
                      </div>
                      <div className="flex w-full mx-auto text-lg -translate-y-3 //space-x-2 //md:translate-y-4 ">
                        <p className="text-[#FF724F] h-3 w-full text-sm md:text-base ">
                          {errors.terms === ""
                            ? null
                            : validateTerms(cguChecked).message}
                        </p>
                      </div>
                    </div>
                    <div
                      className="z-50 w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-bold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-first border-first"
                      onClick={() => handleSubmit()}
                    >
                      Valider
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="w-1/2">
        <div className="mt-4">
          <div className="md:w-4/5">
            <div className="flex gap-x-3">
              <div className="w-6">
                <img src={secure} alt="" />
              </div>
              <div className="text-sm font-regular text-first lg:text-lg md:text-base">
                <p>PAIEMENT SÉCURISÉ</p>
              </div>
            </div>
            <div className="mx-auto text-xs lg:text-base md:text-sm text-gray">
              <p>
                Teach’r utilise une technologie de protection perfomante
                : le procédé de chiffrement SSL qui protège toutes les
                données liées aux moyens de paiement, afin de garantir
                une transaction entièrement sécurisée.
              </p>
            </div>
          </div>
        </div>
        <img src={bgPayment} alt="bgPayment" className="mt-4 pl-8"  />
      </div>
    </div>
  );
};

export default PunctualCoursePayment;
