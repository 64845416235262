import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import NavBar from '../../../Components/ComponentsUtils/NavBar'
import Input from '../../../Components/utils/Input'
import validateEmail from '../../../Validation/Email'
import validatePassword from '../../../Validation/Password'
import googleIcon from '../../../Assets/Icons/google.svg'
import appleIcon from '../../../Assets/Icons/apple.svg'
import facebookIcon from '../../../Assets/Icons/facebook.svg'
import SmsIcon from '../../../Assets/Icons/sms.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { auth, FirebaseAuthServiceErrorCode, getJWTToken, loginWithPhoneNumber, sendPasswordReset, signInWithApple, signInWithFacebook, signInWithGoogle } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { ConfirmationResult, getAuth, inMemoryPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import ApiService from '../../../Services/ApiService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { validatePhoneNumber } from '../../../Validation/Phone'
import Code from './Phone/code'
import TeachrRepository from '../../../Repositories/TeachrRepository'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ClientRepository from '../../../Repositories/ClientRepository'
import Functions from '../../../Helpers/Functions'
import Loader from '../../../Components/utils/Loader'
import { Checkbox } from '@mui/material'
import fond from '../../../Assets/Images/fond-login.svg'
import { useDispatch } from 'react-redux'

function Login() {
    const dispatch = useDispatch()
    // const generalTeachr = useSelector((state: any) => state.teachr)
    const MySwal = withReactContent(Swal)
    let timerInterval: any
    const navigate = useNavigate()
    let restEmail = ''
    let phone = ''
    const [user] = useAuthState(auth)
    const [remember, setRemember] = useState(false)
    // useEffect(()=>{
    //     if (generalTeachr) {
    //         console.log(generalTeachr);
    //     }
    // },[generalTeachr])
    useEffect(() => {
        if (user) {
            (async () => {
                Swal.close()
                const jwtToken = await getJWTToken(user)
                ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
                TeachrRepository.getCurrentTeachr().then((response) => {
                    dispatch({
                        type: 'SET_INFO',
                        payload: {
                            teachr: response,
                            authorization: "teachr " + jwtToken
                        }
                    })
                    navigate("/declarons/prestations")
                }).catch(() => {
                    ApiService.disableAuth()
                    ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
                    ClientRepository.getCurrentClient().then((response) => {
                        dispatch({
                            type: 'SET_INFO',
                            payload: {
                                client: response,
                                authorization: "client " + jwtToken
                            }
                        })
                        navigate("/user/prestations")
                        // window.location.reload()
                    }).catch(() => {
                        ApiService.disableAuth()
                        navigate("/declarons/register")
                    })

                })
            })()
        }
    }, [dispatch, navigate, user]);
    const [showPass, setShowPass] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const getEmail = (value: string) => {
        setForm({
            email: value,
            password: form.password
        })
    }
    // const getPassword = (value: string) => {
    //     setForm({
    //         email: form.email,
    //         password: value
    //     })
    // }
    const [errors, setErrors] = useState({
        'email': '',
        'password': ''
    })
    const validate = () => {

        let erremail = true
        let errpass = true
        if (!validateEmail(form.email).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = false
        }
        if (!validatePassword(form.password, form.password).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = false
        }


        if (errpass || erremail)
            return false
        else return true
    }
    const login = async () => {
        const auth = getAuth()
        signInWithEmailAndPassword(auth, form.email, form.password).catch((error: any) => {
            document.getElementById('teachr-loader')?.classList.add('hidden')
            switch (error.code) {
                case FirebaseAuthServiceErrorCode.USER_NOT_FOUND:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.WRONG_PASSWORD:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.USER_DISABLED:
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Votre compte a été désactié!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                default:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "quelque chose s'est mal passé!",
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
            }
        })
    }
    const handleSubmit = (event: any) => {
        event.preventDefault()
        if (validate()) {
            document.getElementById('teachr-loader')?.classList.remove('hidden')

            if (remember) {
                login()
            } else {
                auth.setPersistence(inMemoryPersistence).then(() => {
                    login()
                })
            }
            // login()

        }

    }

    const showPhoneLoginWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            customClass: {
                container: 'm-0'
            },
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-bold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Téléphone</label>
                            <input type="text" onChange={(e) => phone = e.target.value} className='block w-full bg-transparent border-none focus:outline-none' placeholder='Ex. 06 XX XX XX XX' />
                        </div>
                        <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 mx-auto my-8 text-sm uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first whitespace-nowrap md:text-base' >ENVOYER LE CODE DE VERIFICATION</Button>
                    </div>
                </div>,
            preConfirm: (confirmationCode) => {
                if (validatePhoneNumber(phone) === '') {
                    return loginWithPhoneNumber(Functions.formatPhoneNumber(phone)).then((response) => {
                        return response
                    }).catch((error) => {
                        Swal.showValidationMessage("Nous rencontrons actuellement un problème technique. Veuillez re-essaayer plus tard ou nous contacter par mail à l'adresse support@teachr.fr")
                    })

                } else {
                    return Swal.showValidationMessage(validatePhoneNumber(phone))
                }

            },
        }).then((result) => {
            if (result.isConfirmed) {
                showPhoneCodeWidget(result.value!)
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showPhoneCodeWidget = (code: ConfirmationResult) => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-bold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <Code phone={phone} code={code} />
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showResetPasswordWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-bold uppercase text-first'>
                        <p>MOT DE PASSE OUBLIé</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-bold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <p className='font-regular text-first text-[20px]'>Entrez l’adresse mail associée à votre compte.
                            Vous allez recevoir un lien pour réinitialiser votre mot de passe </p>
                        {errors.email === '' ? null : <p className='py-2 font-regular text-red-600'>{errors.email}</p>}
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Email</label>
                            <input onChange={(e) => restEmail = e.target.value} type="text" className='block w-full bg-transparent border-none focus:outline-none' placeholder='mail@mail.com' />
                        </div>
                        <Button onClick={() => { Swal.enableInput(); Swal.clickConfirm() }} className='block px-8 py-2 my-8 uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first w-max' >envoyer le lien</Button>
                    </div>
                </div>,
            preConfirm: () => {
                if (validateEmail(restEmail).isValid) {
                    return sendPasswordReset(restEmail).catch((error) => {
                        if (error.code === FirebaseAuthServiceErrorCode.USER_NOT_FOUND) {
                            Swal.showValidationMessage('Aucun compte associé à cette adresse mail')
                        }
                    })
                }
                Swal.showValidationMessage(validateEmail(restEmail).message)
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'Envoyé!',
                    text: "Nous vous avons envoyé un e-mail de réinitilisation du mot de passe Veuillez consulter votre boîte e-mail",
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    return (
        <div className='w-screen overflow-hidden //list-teacher md:h-screen md:overflow-y-auto'>
            <Loader />
            <NavBar theme="light" />
            <div className="flex h-[calc(100vh_-_5rem)] overflow-hidden bg-[#EDEFF8]" >
                <div className='w-full h-[100%] bg-[#F9FAFF] xl:px-10 lg-px-0 px-10 md:w-1/2'>
                    <div className="flex flex-col gap-4 xl:gap-y-8 lg:gap-y-4 md:w-3/4 mx-auto">
                        <p className='xl:pt-20 xl:pb-4 pt-8 //lg:leading-[44px] xl:text-[32px] lg:text-xl //leading-[15px] md:text-[22px] text-first //text-center font-bold'>CONNEXION</p>
                        <div className='flex flex-col gap-4 md:gap-y-3'>
                            <div>
                                <Input callback={getEmail} label="Email" inset="true" placeholder='exemple@exemple.com' customClass="bg-[#F4F7F8] border-[1px] border-[#80808090] font-regular xl:p-2 p-1" onChange={(e: any) => setForm({ email: e.target.value, password: form.password })} />
                                <div className='h-6'>
                                    {errors.email === '' ? null : <p className='font-regular text-red-300'>{errors.email}</p>}
                                </div>
                            </div>
                            <div>
                                <div className='relative w-full //p-4 //py-4 rounded-md bg-[#F4F7F8] border-[1px] border-[#80808090] font-regular xl:p-2 p-1'>
                                    <label className="xl:pl-0 lg:pl-2">Mot de passe</label>
                                    <input type={showPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none xl:pl-0 lg:pl-2' onChange={(e: any) => setForm({ email: form.email, password: e.target.value })} placeholder='********' />
                                    <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowPass(!showPass)}>{showPass ? <VisibilityOffIcon className='text-normal' /> : <VisibilityIcon className='text-normal' />}</span>
                                </div>
                                <div className='h-6'>
                                    {errors.password === '' ? null : <p className='font-regular text-red-300'>{errors.password}</p>}
                                </div>
                            </div>
                            <div className='flex items-center gap-x-2 //py-4'>
                                <Checkbox onChange={() => setRemember(!remember)} className='p-0' />
                                <a href='#' className="text-black font-sans " rel="noreferrer">Se souvenir de moi lors de ma prochaine connexion</a>
                            </div>
                            <div className='//pt-4 //mx-auto'>
                                <p className='text-normal font-sans'>Mot de passe oublié ? <Link to='#' onClick={() => showResetPasswordWidget()} className='ml-2 font-regular underline text-first font-sans'>Cliquez ici</Link></p>
                            </div>
                        </div>
                        <div className='text-[#808080] w-full xl:py-4 py-2 mx-auto'>
                            <div className="flex items-center justify-between gap-4 text-normal"><hr className='w-full' /><span className='w-full whitespace-nowrap'>Ou se connecter avec :</span><hr className='w-full' /></div>
                            <div className="flex justify-center gap-8 pt-4 md:gap-14">
                                <img src={googleIcon} onClick={signInWithGoogle} alt="" className='w-[43px] cursor-pointer' />
                                <img src={appleIcon} onClick={signInWithApple} alt="" className='w-[43px] cursor-pointer' />
                                <img src={facebookIcon} onClick={signInWithFacebook} alt="" className='w-[43px] cursor-pointer' />
                                <img src={SmsIcon} alt="" onClick={() => showPhoneLoginWidget()} className='w-[43px] cursor-pointer' />
                            </div>
                        </div>
                            <Button className='  rounded-lg xl:py-2 py-1 text-white bg-first border-solid border-[1px] border-first  uppercase px-4 w-full font-bold font-sans' onClick={(e) => handleSubmit(e)} >CONNEXION</Button>
                
                    </div>
                </div>
                <div className='hidden w-1/2 xl:bg-fond-left-top bg-no-repeat md:block'>
                    <img src={fond} alt="" className='border-2 xl:hidden' />
                </div>
            </div>

            <div id="recaptcha-container"></div>
        </div>
    )
}

export default Login