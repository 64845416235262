import React, { useEffect, useState } from 'react';
import NavBar from '../../../Components/ComponentsUtils/NavBar';
import Stepper from './Stepper';
import First from './First';
import Second from './Second';
import { Button, Skeleton } from '@mui/material';
import Third from './Third';
import FouthIndex from './Fourth/Index';
import FiveIndex from './Fifth/Index';
import Sixth from './Sixth';
import Seventh from './Seventh';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ProcurationRepository from '../../../Repositories/ProcurationRepository';
import mandataireSign from '../../../Assets/Images/sign.png'
import emailjs from '@emailjs/browser';
import Teachr from '../../../Objects/models/Teachr';
import { useNavigate } from 'react-router';
import ImageRepository from '../../../Repositories/ImageRepository';
import TeachrRepository from '../../../Repositories/TeachrRepository';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
interface pdfDocInterface {
    first: {
        firstName: string,
        birthName: string,
        birthDate: string,
        signCity: string,
        city: string,
        address: string
    },
    second: {

    },
    third: {

    },
    fourth: {

    },
    fifth: {

    },
    sixth: {

    },
    seventh: {

    },
}

const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [file, setFile] = useState<any>();
    const [proofIentity, setProofIdentity] = useState<File>();
    const [pdfFile, setPdfFile] = useState<any>(process.env.PUBLIC_URL + '/Pouvoir_procuration_creation_auto-entreprise-TeachR_VDEF.pdf')
    const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null)
    const [numPages, setNumPages] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const generalTeachr = useSelector((state: any) => state.teachr)
    const [signUrl, setSignUrl] = useState<string>()
    const [signText, setSignText] = useState<string>()
    const [isSign, setIsSign] = useState(false)
    const [isSignText, setIsSignText] = useState(false)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }
    useEffect(() => {
        if (generalTeachr) {

            if (generalTeachr.user) {
                if (generalTeachr.user.proofIsAE !== null) {
                    // setPdfFile(generalTeachr.user.proofIsAE.contentUrl)
                    setCurrentStep(6)
                }
                setTeachr(generalTeachr.user)
            } else {
                navigate('/declarons/login')
            }

        } else {
            navigate('/declarons/login')
        }
    }, [generalTeachr])
    useEffect(() => {
        const loadPDF = async () => {
            const response = await fetch(pdfFile);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            setPdfDoc(pdfDoc)
            // Modifier la valeur d'un champ de saisie

            // const textField = pdfDoc.getForm().getTextField('text_2szbk');
            // console.log(textField);
            // textField.setText('frédéric');
            // const modifiedPdfBytes = await pdfDoc.save();
            // setPdfBytes(modifiedPdfBytes);
        };
        loadPDF();
    }, [pdfFile]);
    const changePdf = () => {
        (async () => {
            if (pdfDoc) {
                const pdfBytes = await pdfDoc.save();
                const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
                setFile(pdfBytes)
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfFile(pdfUrl)
                // const link = document.createElement('a');
                // link.href = pdfUrl;
                // link.download = 'pdf_modifie.pdf';
                // link.click();
                // URL.revokeObjectURL(pdfUrl);
            };
        }
        )()


    }

    const getInfos = (value: any) => {
        if (pdfDoc && value) {
            const firstNameField1 = pdfDoc.getForm().getTextField('text_2szbk');
            const birthNameField1 = pdfDoc.getForm().getTextField('text_3ggbf');
            const firstNameField2 = pdfDoc.getForm().getTextField('text_9khzw');
            const birthNameField2 = pdfDoc.getForm().getTextField('text_10zhxm');
            const birthDateField1 = pdfDoc.getForm().getTextField('text_4uena');
            const birthDateField2 = pdfDoc.getForm().getTextField('text_13mwhy');
            const cityBirthField1 = pdfDoc.getForm().getTextField('text_5pkzj');
            const cityBirthField2 = pdfDoc.getForm().getTextField('text_15zpyt');
            const addressField1 = pdfDoc.getForm().getTextField('text_6zwby');
            const addressField2 = pdfDoc.getForm().getTextField('text_47frij');
            const signCityField = pdfDoc.getForm().getTextField('text_7kchz');
            const genderField = pdfDoc.getForm().getDropdown('dropdown_12tbys');
            const phoneField = pdfDoc.getForm().getTextField('text_1htbt');
            const countryField = pdfDoc.getForm().getTextField('text_14gqys');
            const situationField = pdfDoc.getForm().getRadioGroup('radio_group_37rzdc');
            const isActivityField = pdfDoc.getForm().getRadioGroup('radio_group_43jzdi');
            const socilaTaxField = pdfDoc.getForm().getRadioGroup('radio_group_1fgsy');
            const otherChiefField = pdfDoc.getForm().getRadioGroup('radio_group_73axhk');
            const nniField = pdfDoc.getForm().getTextField('text_34jxhh');
            const socialOrganismField = pdfDoc.getForm().getRadioGroup('radio_group_45munl');
            const isAeField = pdfDoc.getForm().getRadioGroup('radio_group_51dxxa');
            const otherActivityField = pdfDoc.getForm().getRadioGroup('radio_group_53iyyl');
            const fixAddressField = pdfDoc.getForm().getRadioGroup('radio_group_55qzhv');
            const homeSocityField = pdfDoc.getForm().getRadioGroup('radio_group_57wzmj');
            const activitiesOptionsField = pdfDoc.getForm().getTextField('text_44bwe');
            const otherChiefFirstNameField = pdfDoc.getForm().getTextField('text_71bthn');
            const otherChiefNameField = pdfDoc.getForm().getTextField('text_72flsj');
            const otherChiefNationalField = pdfDoc.getForm().getTextField('text_75ofjg');
            const otherChiefbirthCountryField = pdfDoc.getForm().getTextField('text_76knwj');
            const otherChiefgenderField = pdfDoc.getForm().getDropdown('dropdown_74dqgu');
            const firstResidenceField = pdfDoc.getForm().getRadioGroup('radio_group_59psyj');
            const commercialNameField = pdfDoc.getForm().getRadioGroup('radio_group_61dyou');
            const salariesField = pdfDoc.getForm().getRadioGroup('radio_group_63tvft');
            const domainNameField = pdfDoc.getForm().getRadioGroup('radio_group_65cldx');
            const paimentModeField = pdfDoc.getForm().getRadioGroup('radio_group_67ihup');
            const savePersonnalInfoField = pdfDoc.getForm().getRadioGroup('radio_group_71kfss');
            const saveSirenField = pdfDoc.getForm().getRadioGroup('radio_group_69lcym');
            const domainNameValueField = pdfDoc.getForm().getTextField('text_49atxb');
            const salariesNumberField = pdfDoc.getForm().getTextField('text_50qete');

            if (value.firstName && value.firstName !== '') {
                firstNameField1.setText(value.firstName);
                firstNameField2.setText(value.firstName);
            }
            if (value.birthName && value.birthName !== '') {
                birthNameField1.setText(value.birthName);
                birthNameField2.setText(value.birthName);
            }
            if (value.birthDate && value.birthDate !== '') {
                birthDateField1.setText(value.birthDate);
                birthDateField2.setText(value.birthDate);
            }
            if (value.city && value.city !== '') {
                cityBirthField1.setText(value.city);
                cityBirthField2.setText(value.city);
            }
            if (value.address && value.address !== '') {
                addressField1.setText(value.address);
                addressField2.setText(value.address);
            }
            if (value.signCity && value.signCity !== '') {
                signCityField.setText(value.signCity);
            }
            if (value.phone && value.phone !== '') {

                phoneField.setText(value.phone);
            }
            if (value.country && value.country !== '') {
                countryField.setText(value.country);
            }
            if (value.gender !== undefined) {

                switch (value.gender) {
                    case 0:
                        genderField.select('Féminin')
                        break;
                    case 1:
                        genderField.select('Masculin')
                        break;

                    default:
                        genderField.select('Féminin')
                        break;
                }
            }
            if (value.situation) {
                switch (value.situation) {
                    case 1:
                        situationField.select('Value_zpyl')
                        break;
                    case 2:
                        situationField.select('Value_stfi')
                        break;
                    case 3:
                        situationField.select('Value_vvki')
                        break;
                    case 4:
                        situationField.select('Value_gati')
                        break;
                    case 5:
                        situationField.select('Value_qtvb')
                        break;
                    case 6:
                        situationField.select('Value_qatz')
                        break;

                    default:
                        situationField.select('Value_zpyl')
                        break;
                }
            }
            if (value.socialOrganism) {
                switch (value.socialOrganism) {
                    case 1:
                        socialOrganismField.select('Value_elbp')
                        break;
                    case 2:
                        socialOrganismField.select('Value_ccea')
                        break;
                    case 3:
                        socialOrganismField.select('Value_uzwh')
                        break;
                    case 4:
                        socialOrganismField.select('Value_mdl')
                        break;
                    case 5:
                        socialOrganismField.select('Value_qzzc')
                        break;
                    case 6:
                        socialOrganismField.select('Value_hplx')
                        break;

                    default:
                        socialOrganismField.select('Value_elbp')
                        break;
                }
            }
            if (value.isActivity !== undefined) {
                switch (value.isActivity) {
                    case 0:
                        isActivityField.select('Value_ioez')
                        break;
                    case 1:
                        isActivityField.select('Value_p')
                        break;
                    default:
                        isActivityField.select('Value_ioez')
                        break;
                }
            }
            if (value.socialTax !== undefined) {
                switch (value.socialTax) {
                    case 0:
                        socilaTaxField.select('Value_yibd')
                        break;
                    case 1:
                        socilaTaxField.select('Value_ruy')
                        break;
                    default:
                        socilaTaxField.select('Value_ruy')
                        break;
                }
            }
            if (value.isAe !== undefined) {
                switch (value.isAe) {
                    case 0:
                        isAeField.select('Value_qmgq')
                        break;
                    case 1:
                        isAeField.select('Value_dfii')
                        break;
                    default:
                        isAeField.select('Value_qmgq')
                        break;
                }
            }
            if (value.otherActivity !== undefined) {
                switch (value.otherActivity) {
                    case 0:
                        otherActivityField.select('Value_ul')
                        break;
                    case 1:
                        otherActivityField.select('Value_zzea')
                        break;
                    default:
                        otherActivityField.select('Value_ul')
                        break;
                }
            }
            if (value.fixAddress !== undefined) {
                switch (value.fixAddress) {
                    case 0:
                        fixAddressField.select('Value_rtku')
                        break;
                    case 1:
                        fixAddressField.select('Value_plmt')
                        break;
                    default:
                        fixAddressField.select('Value_rtku')
                        break;
                }
            }
            if (value.homeSocity !== undefined) {
                switch (value.homeSocity) {
                    case 0:
                        homeSocityField.select('Value_giai')
                        break;
                    case 1:
                        homeSocityField.select('Value_zpxw')
                        break;
                    default:
                        homeSocityField.select('Value_giai')
                        break;
                }
            }
            if (value.activitiesOptions && value.activitiesOptions !== '') {
                activitiesOptionsField.setText(value.activitiesOptions);

            }
            if (value.nni && value.nni !== '') {
                nniField.setText(value.nni);
            }
            if (value.otherChief) {
                otherChiefField.select('Value_dbb')
                if (value.otherChief.firstName && value.otherChief.firstName !== '') {
                    otherChiefFirstNameField.setText(value.otherChief.firstName);
                }
                if (value.otherChief.firstName && value.otherChief.firstName !== '') {
                    otherChiefNameField.setText(value.otherChief.firstName);
                }
                if (value.otherChief.firstName && value.otherChief.firstName !== '') {
                    otherChiefNationalField.setText(value.otherChief.firstName);
                }
                if (value.otherChief.firstName && value.otherChief.firstName !== '') {
                    otherChiefbirthCountryField.setText(value.otherChief.firstName);
                }
                if (value.otherChief.gender !== undefined) {

                    switch (value.otherChief.gender) {
                        case 0:
                            otherChiefgenderField.select('Féminin')
                            break;
                        case 1:
                            otherChiefgenderField.select('Masculin')
                            break;

                        default:
                            otherChiefgenderField.select('Féminin')
                            break;
                    }
                }
            } else {
                otherChiefField.select('Value_ausx')
            }
            if (value.firstResidence !== undefined) {

                switch (value.firstResidence) {
                    case 0:
                        firstResidenceField.select('Value_ntgw')
                        break;
                    case 1:
                        firstResidenceField.select('Value_ypo')
                        break;

                    default:
                        firstResidenceField.select('Value_ntgw')
                        break;
                }
            }
            if (value.commercialName !== undefined) {

                switch (value.commercialName) {
                    case 0:
                        commercialNameField.select('Value_sfkw')
                        break;
                    case 1:
                        commercialNameField.select('Value_gryw')
                        break;

                    default:
                        commercialNameField.select('Value_sfkw')
                        break;
                }
            }
            if (value.salaries !== undefined) {

                switch (value.salaries) {
                    case 0:
                        salariesField.select('Value_bnsk')
                        break;
                    case 1:
                        salariesField.select('Value_qpgv')
                        break;

                    default:
                        salariesField.select('Value_bnsk')
                        break;
                }
            }
            if (value.paiementMode !== undefined) {

                switch (value.paiementMode) {
                    case 0:
                        paimentModeField.select('Value_byhs')
                        break;
                    case 1:
                        paimentModeField.select('Value_qaka')
                        break;

                    default:
                        paimentModeField.select('Value_byhs')
                        break;
                }
            }
            if (value.savePersonnalInfo !== undefined) {

                switch (value.savePersonnalInfo) {
                    case 0:
                        savePersonnalInfoField.select('Value_zbzj')
                        break;
                    case 1:
                        savePersonnalInfoField.select('Value_ltqx')
                        break;

                    default:
                        savePersonnalInfoField.select('Value_zbzj')
                        break;
                }
            }
            if (value.saveSiren !== undefined) {

                switch (value.saveSiren) {
                    case 0:
                        saveSirenField.select('Value_boeh')
                        break;
                    case 1:
                        saveSirenField.select('Value_lpyp')
                        break;

                    default:
                        saveSirenField.select('Value_boeh')
                        break;
                }
            }
            if (value.domainName !== undefined) {

                switch (value.domainName) {
                    case 0:
                        domainNameField.select('Value_lwgq')
                        break;
                    case 1:
                        domainNameField.select('Value_boxp')
                        break;

                    default:
                        domainNameField.select('Value_lwgq')
                        break;
                }
            }
            if (value.salaryNumber !== undefined) {

                salariesNumberField.setText(value.salaryNumber.toString());
            }
            if (value.domainNameValue && value.domainNameValue !== '' && value.domainName === 1) {
                domainNameValueField.setText(value.domainNameValue);
            } else {
                domainNameValueField.setText('');
            }
            changePdf()
        }
    }

    const nextStep = (value: number) => {
        // console.log(proofIentity, value , teachr);

        if (value <= 3) {
            if (value === 3) {

                if (proofIentity && teachr) {
                    setLoading(true)
                    ImageRepository.createImage(new File([proofIentity], 'proof-is-ae', { type: 'application/pdf' })).then((response) => {
                        teachr.proofIdentity = response['@id']
                        if (teachr.proofSignSap) {
                            teachr.proofSignSap = teachr.proofSignSap['@id']
                        }
                        TeachrRepository.updateTeachr(teachr).then((response) => {
                            dispatch({
                                type: 'SET_INFO',
                                payload: {
                                    teachr: response
                                }
                            })
                            setCurrentStep(value)
                            setPage(value)
                            setLoading(false)
                        })
                    }).catch((error) => {
                        setLoading(false)
                    })
                }
            } else {
                setCurrentStep(value)
                setPage(value)
            }
        } else {
            if (value >= 5) {
                if (value === 6) {
                    if (file && teachr && isSign && isSignText) {
                        setLoading(true)
                        ImageRepository.createImage(new File([file], 'proof-is-ae', { type: 'application/pdf' })).then((response) => {
                            teachr.proofIdentity = teachr.proofIdentity['@id']
                            teachr.proofIsAE = response['@id']
                            TeachrRepository.updateTeachr(teachr).then(() => {
                                dispatch({
                                    type: 'SET_INFO',
                                    payload: {
                                        teachr: response
                                    }
                                })
                                setCurrentStep(value)
                                setLoading(false)
                            })
                        }).catch((error) => {
                            setLoading(false)
                        })
                    }
                } else {
                    setPage(1)
                    setCurrentStep(value)
                }
            } else {
                setCurrentStep(value)
                setPage(value + 1)
            }
        }


    }
    const nextPage = (value: number) => {
        setPage(value)
    }
    const getSignature = (value: any) => {
        setSignUrl(value)
    }
    const getSignatureText = (value: any) => {
        setSignText(value)
    }
    const getProofIdentity = (value: any) => {
        setProofIdentity(value)
    };
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                const { width, height } = currentPage.getSize();

                if (!isSign) {
                    if (signUrl) {
                        const dateField = pdfDoc.getForm().getTextField('text_8ixqv');
                        dateField.setText(moment().format('L'))
                        const imageBytes1 = await fetch(signUrl).then((res) => res.arrayBuffer());
                        const image1 = await pdfDoc.embedPng(imageBytes1);
                        const imageBytes2 = await fetch(mandataireSign).then((res) => res.arrayBuffer());
                        const image2 = await pdfDoc.embedPng(imageBytes2);
                        let imageWidth = 100;
                        let imageHeight = 30;
                        currentPage.drawImage(image1, {
                            x: imageWidth + 20,
                            y: 90,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        imageWidth = 230;
                        imageHeight = 40;
                        currentPage.drawImage(image2, {
                            x: width - imageWidth - 10,
                            y: 80,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setIsSign(true)
                        changePdf()
                    }

                }

            }
        }
        )()
    }, [signUrl])
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                if (!isSignText) {
                    if (signText) {
                        const imageBytes = await fetch(signText).then((res) => res.arrayBuffer());
                        const image = await pdfDoc.embedPng(imageBytes);
                        const imageWidth = 100;
                        const imageHeight = 20;
                        currentPage.drawImage(image, {
                            x: 10,
                            y: imageWidth,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setIsSignText(true)
                        changePdf()
                    }
                }

            }
        }
        )()
    }, [signText, pdfFile])
    const renderScreen = () => {
        switch (currentStep) {
            case 0:
                if (teachr && teachr.proofIsAE) {

                    return <Seventh callBack={nextStep} />
                } else {
                    return <First callBack={nextStep} />
                }
            case 1:
                return <Second prevStep={prevStep} sendData={getInfos} callBack={nextStep} />
            case 2:
                if (teachr && teachr.proofIdentity !== null) {
                    setCurrentStep(3)
                    setPage(3)
                    break;
                } else {
                    return <Third prevStep={prevStep} loading={loading} getProofIdentity={getProofIdentity} callBack={nextStep} />
                }
            case 3:
                return <FouthIndex prevStep={prevStep} nextPage={nextPage} sendData={getInfos} callBack={nextStep} />
            case 4:
                return <FiveIndex prevStep={prevStep} nextPage={nextPage} callBack={nextStep} />
            case 5:

                return <Sixth loading={loading} prevStep={prevStep} getSignatureText={getSignatureText} getSignature={getSignature} callBack={nextStep} />
            case 6:
                return <Seventh callBack={nextStep} />
            default:
                return <First prevStep={prevStep} callBack={nextStep} />
        }
    }
    const prevStep = () => {
        if (currentStep > 0 && currentStep < 6) {
            if (currentStep === 3) {
                if (teachr && teachr.proofIdentity !== null) {
                    setCurrentStep(currentStep - 2)
                } else {
                    setCurrentStep(currentStep - 1)
                }
            } else {
                setCurrentStep(currentStep - 1)
            }

        }
    }
    return (
        <div className='h-screen overflow-hidden '>
            <header className='lg:bg-white bg-[rgba(239,246,255,1)]'>
                <NavBar />
            </header>
            <div className='flex h-full pb-14 '>
                <div className='h-full px-4 pt-10 pb-40 lg:w-1/4 md:px-8 lg:px-10'>
                    <h1 className='text-first font-bold lg:text-[22px] mb-8'>ESPACE PROCURATION 📄</h1>
                    <Stepper current={currentStep} />
                </div>
                <div className='w-full overflow-y-scroll overflow-x-hidden bg-[#F5FAFF] px-4 md:px-8 lg:px-10 pt-10'>
                    <div className='flex justify-between'>
                        <div className="flex items-center cursor-pointer gap-x-4" onClick={prevStep}>
                            <span>
                                <svg width="39" height="34" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="39" height="34" rx="8" fill="#219CFF" fill-opacity="0.14" />
                                    <path d="M31 17.85C31.4694 17.85 31.85 17.4694 31.85 17C31.85 16.5306 31.4694 16.15 31 16.15L31 17.85ZM10.399 16.399C10.067 16.7309 10.067 17.2691 10.399 17.601L15.8083 23.0104C16.1403 23.3424 16.6785 23.3424 17.0104 23.0104C17.3424 22.6785 17.3424 22.1403 17.0104 21.8083L12.2021 17L17.0104 12.1917C17.3424 11.8597 17.3424 11.3215 17.0104 10.9896C16.6785 10.6576 16.1403 10.6576 15.8083 10.9896L10.399 16.399ZM31 16.15L11 16.15L11 17.85L31 17.85L31 16.15Z" fill="#0B2775" />
                                </svg>
                            </span>
                            <span className='text-first font-regular lg:text-[22px]'>Précédent</span>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className='py-10 '>
                        <div className='flex justify-between gap-10 '>
                            {currentStep !== 0 &&
                                <div>
                                    <Document
                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}

                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            }
                            <div className={currentStep !== 0 ? 'w-1/2' : 'w-full'}>
                                {renderScreen()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Index;