import { useEffect, useState } from 'react'
import { PunctualCourse } from '../../../../Objects/models/PunctualCourse'
import PunctualCourseRepository from '../../../../Repositories/PunctualRepository';
import ApiService from '../../../../Services/ApiService';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../Services/FirebaseService';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import StepperStore from '../../../../LocalForage/StepperStore';
import ArrowRightCircle from '../../../../Assets/Icons/arrow-right-circle.svg'

import 'moment/locale/fr'
import CoursePackRepository from '../../../../Repositories/CoursePackRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import SubscriptionCourseRepository from '../../../../Repositories/SubscriptionCourseRepository';
import { SubscriptionCourse } from '../../../../Objects/models/SubscriptionCourse';
import { CoursePack } from '../../../../Objects/models/CoursePack';
import AddressRepository from '../../../../Repositories/AddressRepository';
import DirectCourse from '../../../../Components/Dashboard/Course/DirectCourse';
import UrgentCourses from '../../../../Components/Dashboard/Course/Urgent';
import RegularCourses from '../../../../Components/Dashboard/Course/Regular';
import PunctualCourses from '../../../../Components/Dashboard/Course/Punctual';
import PackCourses from '../../../../Components/Dashboard/Course/Pack';
import { Subject } from '../../../../Objects/models/Subject';
import loaderCourse from '../../../../Assets/Images/Hourglass-3.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Search from './Search';
export default function CourseIndex(props: any) {

  const [user] = useAuthState(auth)
  const [punctualCourses, setPunctualCourses] = useState<PunctualCourse[]>()
  const [timeCourses, setTimeCourses] = useState<PunctualCourse[]>()
  const [regularCourses, setRegularCourses] = useState<SubscriptionCourse[]>()
  const [packCourses, setPackCourses] = useState<CoursePack[]>()
  const [directCourses, setDirectCourses] = useState<PunctualCourse[]>()
  const [subjects, setSubjects] = useState<Subject[]>([])
  const [teachrId, setTeachrId] = useState<number>()
  const [isSearch, setIsSearch] = useState(true)


  useEffect(() => {
    (async () => {
      TeachrRepository.getCurrentTeachr().then((teachrWithId) => {
        if (teachrWithId) {
          setTeachrId(teachrWithId.id)
        }
      }).catch(() => {
        ApiService.disableAuth()
      })
      if (teachrId) {
        const response = await PunctualCourseRepository.getAllAvailablePunctualCourse(teachrId);
        const urgentCourse = response.filter((course: PunctualCourse) => course.isCourseInTheHour)
        const subscriptionCourseResponse = await SubscriptionCourseRepository.getAllAvailableSubscriptionCourse(
          teachrId
        );
        const coursePackResponse = await CoursePackRepository.getAvailableCoursePacks(
          teachrId
        );
        setTimeCourses(urgentCourse)
        filterPunctualCourses(response)
        setRegularCourses(subscriptionCourseResponse)
        setPackCourses(coursePackResponse.members)
        if (regularCourses) {
          regularCourses.map((course: SubscriptionCourse) => ((async () => {
            const addressResponse = await AddressRepository.fetchAddressById(course.address_id)
            if (addressResponse) {
              StepperStore.setItem('address' + addressResponse.id, addressResponse)
            }
          })()))
        }
      } else {
      }
    })();
  }, [user, teachrId, props]);
  const filterPunctualCourses = (courses: PunctualCourse[]) => {
    const directCourse: PunctualCourse[] = []
    const punctualCourse: PunctualCourse[] = []
    courses.map((course: PunctualCourse, key) => (course.directAskedTeachr_id ? directCourse.push(course) : punctualCourse.push(course)))
    setDirectCourses(directCourse)
    setPunctualCourses(punctualCourse)
  }


  const scrollCoursesToRight = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(screen.width, 0)
  }
  const scrollCoursesToLeft = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(-screen.width, 0)
  }
  useEffect(() => {
    if (props.search) {
      setSubjects(props.search)
      setIsSearch(isSearchCourse)
    }

  }, [props])
  const isSearchCourse = () => {
    let result = true;
    if (props.search) {
      props.search.map((subject: Subject) => {
        const regular = regularCourses?.filter((course) => course.subjectAsked_id === subject.id)
        const punctual = punctualCourses?.filter((course) => course.subjectAsked_id === subject.id)
        const urgent = timeCourses?.filter((course) => course.subjectAsked_id === subject.id)
        const pack = packCourses?.filter((course) => course.subjectAsked_id === subject.id)

        if (regular!.length === 0 && punctual!.length === 0 && urgent!.length === 0 && pack!.length === 0) {
          result = false
        } else {
          result = true
        }
      })
    }

    return result
  }

  // const finishCourseWidget = () => {
  //   MySwal.fire({
  //     showConfirmButton: false,
  //     allowOutsideClick: false,
  //     html:
  //       <div className='relative h-full text-left'>
  //         <div className='flex flex-col gap-8'>
  //           <h1 className='uppercase text-[24px] font-bold text-first'>cours terminé</h1>
  //           <p className='text-[20px] font-regular text-first'>Laisser un commentaire à votre élève !</p>
  //           <div>
  //             <textarea className="lg:h-[160px] md:h-[100px] h-[160px] w-full resize-none border-2 border-[rgba(0,0,0,0.3)]  p-3 text-normal text-[18px] leading-[24px] font-[400] outline-none rounded-xl" placeholder="Ex : élève à l’écoute et curieux. Julien écoute les conseils et pose de bonnes questions !"></textarea>
  //           </div>
  //         </div>
  //         <div className="flex justify-end pb-4 pt-14">
  //           <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 font-regular uppercase bg-transparent border-2 border-solid rounded-full text-first border-first'>Confirmer</Button>
  //         </div>
  //       </div>
  //   })
  // }
  // finishCourseWidget()
  return (
    <div>
      {!isSearch ?
        <Search subjects={subjects} /> :
        <div>
          {/* Cours direct */}
          {directCourses?.length !== 0 && directCourses !== undefined ?

            <div className=''>
              <h1 className='text-[#4B6AD8] lg:text-[24px] lg:leading-[154%] text-lg  md:text-left font-regular pl-4 py-3'>Nouvelle demande de prestation directe ! </h1>
              <Box id='direct-course' className='flex w-full gap-10 px-8 py-2 overflow-x-scroll scroll-smooth ' sx={{ '&::-webkit-scrollbar': { visibility: 'hidden' }, }}>
                {directCourses.map((course: PunctualCourse, key: number) => (
                  <DirectCourse key={key} {...course} />
                ))}
              </Box>
              {directCourses.length >= 5 && <Link to="" className='hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center' ><img onClick={(e: any) => { e.preventDefault(); scrollCoursesToLeft('direct-course') }} className='-rotate-180' src={ArrowRightCircle} alt="arrow icon" />Tous les cours disponibles <img src={ArrowRightCircle} onClick={(e: any) => { e.preventDefault(); scrollCoursesToRight('direct-course') }} alt="arrow icon" /></Link>}
            </div>
            : null
          }
          {/* END Cours direct */}
          {/* Cours dans l'heur */}


          <Accordion square={false} sx={{ '&.MuiAccordion-root': { boxShadow: 'none !important' } }} disableGutters={false} defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='font-bold text-first' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.MuiAccordionSummary-root': {
                  width: 'max-content',
                  gap: '14px'
                }
              }}
            >
              <h1 className='text-first lg:text-[24px] lg:leading-[154%] text-lg  md:text-left font-regular'>Prestations dans l’heure </h1>
            </AccordionSummary>
            <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0, paddingBottom: '30px' } }}>
              {timeCourses !== undefined ?
                <div className=''>
                  <Box id='urgent-course' className='flex w-full gap-10 px-8 py-2 overflow-x-scroll scroll-smooth' sx={{ '&::-webkit-scrollbar': { visibility: 'hidden' }, }}>
                    {timeCourses.map((course: PunctualCourse, key: number) => (

                      <UrgentCourses key={key} course={course} subjects={subjects} />

                    ))}
                  </Box>
                  {timeCourses.length >= 5 && <Link to="" className='hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center' >
                    <img onClick={(e: any) => { e.preventDefault(); scrollCoursesToLeft('urgent-course') }} src={ArrowRightCircle} alt="arrow icon" className='-rotate-180' />
                    Toutes les prestations disponibles <img onClick={(e: any) => { e.preventDefault(); scrollCoursesToRight('urgent-course') }} src={ArrowRightCircle} alt="arrow icon" />
                  </Link>}
                </div>
                : <div className='pb-4'>
                  <img src={loaderCourse} alt="loading" className='mx-auto ml-32' width='80px' />
                </div>
              }
            </AccordionDetails>
          </Accordion>

          {/* END Cours dans l'heur */}
          {/* prestation régulière */}
          <Accordion square={false} sx={{ '&.MuiAccordion-root': { boxShadow: 'none !important' } }} disableGutters={false} defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='font-bold text-first' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.MuiAccordionSummary-root': {
                  width: 'max-content',
                  gap: '14px'
                }
              }}
            >
              <h1 className='text-first lg:text-[24px] lg:leading-[154%] text-lg  md:text-left font-regular'>Prestations régulières </h1>
            </AccordionSummary>
            <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0, paddingBottom: '30px' } }}>
              {regularCourses !== undefined ?
                <div className=''>
                  <Box id='regular-course' className='flex w-full gap-10 px-8 py-2 overflow-x-scroll scroll-smooth' sx={{ '&::-webkit-scrollbar': { visibility: 'hidden' }, }}>
                    {regularCourses.map((course: SubscriptionCourse, key: number) => (
                      <RegularCourses key={key} course={course} subjects={subjects} />
                    ))}
                  </Box>
                  {regularCourses.length >= 5 && <Link to="" className='hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center' ><img onClick={(e: any) => { e.preventDefault(); scrollCoursesToLeft('regular-course') }} className='-rotate-180' src={ArrowRightCircle} alt="arrow icon" />Tous les cours disponibles <img onClick={(e: any) => { e.preventDefault(); scrollCoursesToRight('regular-course') }} src={ArrowRightCircle} alt="arrow icon" /></Link>}
                </div>
                : <div className='pb-4'>
                  <img src={loaderCourse} alt="loading" className='ml-32' width='80px' />
                </div>
              }
            </AccordionDetails>
          </Accordion>

          {/* END prestation régulière*/}

          {/* Prestation ponctuelle */}
          <Accordion square={false} sx={{ '&.MuiAccordion-root': { boxShadow: 'none !important' } }} disableGutters={false} defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='font-bold text-first' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.MuiAccordionSummary-root': {
                  width: 'max-content',
                  gap: '14px'
                }
              }}
            >
              <h1 className='text-first lg:text-[24px] lg:leading-[154%] text-lg  md:text-left font-regular'>Prestations ponctuelles</h1>
            </AccordionSummary>
            <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0, paddingBottom: '30px' } }}>
              {punctualCourses !== undefined ?
                <div className=''>
                  <Box id='punctual-course' className='flex w-full gap-10 px-8 py-2 overflow-x-scroll scroll-smooth' sx={{ '&::-webkit-scrollbar': { visibility: 'hidden' }, }}>
                    {punctualCourses.map((course: PunctualCourse, key: number) => (

                      <PunctualCourses key={key} course={course} subjects={subjects} />

                    ))}
                  </Box>
                  {punctualCourses.length >= 5 && <Link to="" className='hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center' ><img className='-rotate-180' onClick={(e: any) => { e.preventDefault(); scrollCoursesToLeft('punctual-course') }} src={ArrowRightCircle} alt="arrow icon" />Tous les cours disponibles <img onClick={(e: any) => { e.preventDefault(); scrollCoursesToRight('punctual-course') }} src={ArrowRightCircle} alt="arrow icon" /></Link>}
                </div>
                : <div className='pb-4'>
                  <img src={loaderCourse} alt="loading" className='ml-32' width='80px' />
                </div>
              }
            </AccordionDetails>
          </Accordion>


          {/* END Cours ponctuel */}
          {/* stage de revision */}
          <Accordion square={false} sx={{ '&.MuiAccordion-root': { boxShadow: 'none !important' } }} disableGutters={false} defaultExpanded={true} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='font-bold text-first' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.MuiAccordionSummary-root': {
                  width: 'max-content',
                  gap: '14px'
                }
              }}
            >
              <h1 className='text-first lg:text-[24px] lg:leading-[154%] text-lg  md:text-left font-regular'>Regroupements des prestations</h1>
            </AccordionSummary>
            <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0, paddingBottom: '30px' } }}>
              {packCourses !== undefined ?
                <div className=''>
                  <Box id='pack-course' className='flex w-full gap-10 px-8 py-2 overflow-x-scroll scroll-smooth' sx={{ '&::-webkit-scrollbar': { visibility: 'hidden' }, }}>
                    {packCourses.map((course: CoursePack, key: number) => (

                      <PackCourses key={key} course={course} subjects={subjects} />

                    ))}
                  </Box>
                  {packCourses.length >= 5 && <Link to="" className='hidden lg:flex gap-4 text-radio font-regular text-[16px] justify-center items-center' ><img className='-rotate-180' onClick={(e: any) => { e.preventDefault(); scrollCoursesToLeft('pack-course') }} src={ArrowRightCircle} alt="arrow icon" />Tous les cours disponibles <img onClick={(e: any) => { e.preventDefault(); scrollCoursesToRight('pack-course') }} src={ArrowRightCircle} alt="arrow icon" /></Link>}
                </div>
                : <div className='pb-4'>
                  <img src={loaderCourse} alt="loading" className='ml-32' width='80px' />
                </div>
              }
            </AccordionDetails>
          </Accordion>

        </div>
      }
    </div>
  )
}