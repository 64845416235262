// import picture from "../../../../Assets/Images/picture.png";
// import star from "../../../../Assets/Images/groupstar.png";
// import toch from "../../../../Assets/Images/toch.png";
import money from "../../../../Assets/Images/money.svg";
import { useNavigate } from "react-router-dom";
// import { SubscriptionCourseProposal } from "../../../../Objects/models/SubscriptionCourseProposal";
import { useEffect, useState } from "react";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";
// import TeachrHours from "../../../../Objects/models/TeachrHours";
import Functions from "../../../../Helpers/Functions";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
// import { Button } from "react-bootstrap";
// import { any } from "prop-types";
// import { PackProposal } from "../../../../Objects/models/PackProposal";
import CourseProposal from "../../../../Objects/models/CourseProposal";
import Skeleton from "@mui/material/Skeleton";
// import { PunctualCourse } from "../../../../Objects/models/PunctualCourse";
// import { Subject } from '../../../../Objects/models/Subject';
import { PunctualCourse } from "../../../../Objects/models/PunctualCourse";

function ResponseTuilesPunctualCourse(props: { proposal: CourseProposal, course: PunctualCourse }) {
  const proposal = props.proposal
  const course = props.course
  const [teachr, setTeachr] = useState<any>();
  const [teachrHours, setTeachrHours] = useState<any>();
  const navigate = useNavigate();
  const handleChange = () => {
    navigate("/user/profile", {
      state: { teachr, teachrHours, proposal, course },
    });
  };




  useEffect(() => {
    (async () => {
      if (proposal.teachr_id) {
        const teachrData = await Teachr_Repository.fetchTeachrByIdClient(
          proposal.teachr_id
        );
        setTeachr(teachrData);
        const teachrHours = await Teachr_Repository.getTeachrHours(
          proposal.teachr_id
        );

        setTeachrHours(teachrHours);
      }
    })();
  }, [proposal]);


  return (
    <div onClick={() => handleChange()} className="p-4 mt-12 ml-1 rounded-lg md:mt-2 shrink-0 md:w-80 md:ml-0 bg-gradient-to-br from-[#7383B140] via-transparent to-[#ffffff] border-[rgba(117, 143, 218, 0.24)] /border-[1px] shadow-res">
      <div className="mx-auto rounded-full bg-gradient-to-b from-[#001E73] to-[#4B6AD8] md:-translate-y-16 -translate-y-14 md:h-20 md:w-20 w-14">
        <p className="xl:text-3xl lg:text-2xl md:text-xl text-white font-bold mx-auto pt-5 h-full text-center ">{teachr?.firstName[0] + "." + teachr?.lastName[0]}</p>
      </div>
      <div className="mx-auto -mt-12 md:-mt-14">
        <div className="w-1/2 mx-auto //md:pl-2">
          {teachr?.firstName ? (<p className="text-sm font-regular text-center lg:text-lg md:text-base text-first">{teachr?.firstName}</p>) : (<Skeleton width="50%" />)}
        </div>
        <div className="w-1/2 mx-auto md:pl-2">
          {teachr?.averageNote === 0 ? "" :
            teachr?.averageNote ? (
              <Rating
                name="half-rating-read"
                sx={{
                  color: " #7383B1"
                }}
                defaultValue={teachr?.averageNote}
                precision={0.1}
                readOnly
              />
            ) : (<Skeleton width="50%" />)}
        </div>
        <div className="mb-2">
          {teachrHours?.totalHours ? (
            <p className="text-sm text-center">
              {Functions.renderTeachrHours(teachrHours?.totalHours)}
            </p>
          ) : (
            <div className="w-1/2 mx-auto ">
              <Skeleton width="100%" />
            </div>
          )}
        </div>
      </div>
      <div className={proposal.description ? "" : "translate-y-4 "}>
        <div className="flex /space-x-4 ">
          <div className="w-1/6 pt-4 /pl-3">
            <img src={money} alt="" className="w-8 md:w-10" />
          </div>
          <div className="w-5/6">
            {proposal.hourlyPrice ? props.course.isRemote ? "" : (
              <p className="text-sm font-regular md:text-base text-first">
                Prix après crédit d’impôt : {(JSON.parse(proposal.hourlyPrice!) / 200).toFixed(2) + "€/h"}
              </p>
            ) : (
              <Skeleton width="100%" />
            )}
            <div className="text-sm md:text-base text-gray">
              {proposal.hourlyPrice ? (
                <span>
                  Prix {props.course.isRemote ? "" : "avant crédit d’impôt "}:{" "}
                  {(JSON.parse(proposal.hourlyPrice!) / 100).toFixed(2) + "€/h"}
                </span>
              ) : (
                <Skeleton width="100%" />
              )}
            </div>
          </div>
        </div>
      </div>
      {proposal.description ? (
        <div className="flex mt-4 text-sm lg:text-base">
          <div className="w-full -mt-2 space-y-2">
            <div>
              <p className="text-first font-regular "> Message de candidature : </p>
            </div>
            {/* <Skeleton width="50%" />  */}
            <div className=" w-[100%] text-gray h-20 pt-1 whitespace-pre-wrap overflow-hidden ">
              {proposal.description ? (
                <p className="w-[100%]">{proposal.description}</p>
              ) : (
                <Skeleton width="50%" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex mt-4 text-sm lg:text-base">
          <div className="w-full space-y-2 ">
            <div className="mt-2">
              <p className="text-first font-regular"> Description : </p>
            </div>
            {/* <Skeleton width="50%" />  */}
            <div className=" w-[100%] text-gray h-20 pt-1 whitespace-pre-wrap overflow-hidden ">
              {teachr?.description ? (
                <p className="w-[100%]">{teachr?.description}</p>
              ) : (
                <Skeleton width="50%" />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={"flex -translate-y-2 justify-around mb-2"}
      >
        <div>
          <button
            onClick={() => handleChange()}
            className="border-[2px] px-8 p-1 md:text-[16px] translate-y-3 text-sm text-first  border-first font-bold active:text-white rounded-lg"
          >
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResponseTuilesPunctualCourse;
