import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
function MessageCourse(props:any) {
    const [description, setDescription] = useState('')
    const [error, setError] = useState(false)
    useEffect(() => {
        props.getDescrition(description)
    },[description])
    const formatMessage = (value:string)=> {
       const regex = /^[aeiouy]+$/
       if ( value.split('')[0].toLocaleLowerCase().match(regex)) {
        return `d'${value}`
       }
       return   `de ${value}`
    }
    const validate = (value:string)=> {
       if(value.length > 0 && value.length < 20){
            setError(true)
        }else{
            setError(false)
        }
    }
    return (
        <div>
            <div className='shadow-course-select lg:w-[383px] p-6 rounded-[20px] mx-auto'>
                <div className="flex flex-col w-full gap-5">
                    <p className='flex items-center  gap-4 text-[20px] font-bold uppercase text-first whitespace-nowrap'>Message de candidature <CheckIcon className={`text-white  rounded-full p-[1px]  ${description.length < 50 ? 'bg-[rgba(0,0,0,0.25)]' : 'bg-radio'}`} /></p>
                    <p className='text-[#4D4D4D] lg:text-[16px] text-sm font-regular leading-[22px]'><span className='uppercase'>é</span>crivez un message {props.child.class >= 0 ? 'à '+(props.child.firstName) : 'à '+props.child.firstName} pour lui donner des précisions sur votre candidature.
                        Ce message est en complément de votre description qui est déjà dans votre profil.</p>
                    <p className='text-third text-sm lg:text-[14px] font-regular leading-[22px] flex items-center gap-4'><WarningIcon />Attention aux fautes d’orthographes</p>
                    <div>
                        <div>
                            {error && <p className='py-4 text-sm font-regular text-red-600'>Votre description doit contenir minimum 20 caractères</p>}
                            <textarea  onChange={(e)=> {setDescription(e.target.value); validate(e.target.value)}} className="lg:h-[160px] md:h-[100px] h-[160px] w-full resize-none border-2 border-[rgba(0,0,0,0.3)]  p-3 text-normal outline-none rounded-xl"   placeholder="Expliquez ici au client pourquoi il devrait retenir votre candidature et pas une autre. Pensez à soigner l’orthographe 😉"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageCourse