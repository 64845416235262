import React, { useEffect, useState } from 'react';
import ProfileImg from '../../../../Assets/Icons/prestation.svg'
import ClockIcon from '../../../../Assets/Icons/hours.svg'
import CalendarIcon from '../../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../../Assets/Icons/address.svg'
import LocationIcon from '../../../../Assets/Icons/address.svg'
import euroIcon from '../../../../Assets/Icons/euro-circle.svg'
import { SubscriptionCourse } from '../../../../Objects/models/SubscriptionCourse';
import SubscriptionCourseRepository from '../../../../Repositories/SubscriptionCourseRepository';
import { SubscriptionCourseProposal } from '../../../../Objects/models/SubscriptionCourseProposal';
import { Subject } from '../../../../Objects/models/Subject';
import SubjectRepository from '../../../../Repositories/SubjectRepository';
import { Address } from '../../../../Objects/models/Address';
import AddressRepository from '../../../../Repositories/AddressRepository';
import Child from '../../../../Objects/models/Child';
import Client from '../../../../Objects/models/Client';
import ChildRepository from '../../../../Repositories/ChildRepository';
import ClientRepository from '../../../../Repositories/ClientRepository';
import Functions from '../../../../Helpers/Functions';
import moment from 'moment';
import { Skeleton } from '@mui/material';
const days = [
    {
        fr: 'lundi',
        en: 'monday'
    },
    {
        fr: 'mardi',
        en: 'tuesday'
    },
    {
        fr: 'mercredi',
        en: 'wednesday'
    },
    {
        fr: 'jeudi',
        en: 'thursday'
    },
    {
        fr: 'vendredi',
        en: 'friday'
    },
    {
        fr: 'samedi',
        en: 'saturday'
    },
    {
        fr: 'dimanche',
        en: 'sunday'
    }
]
const RegularCandidacyComponent = (proposal: SubscriptionCourseProposal) => {
    const [course, setCourse] = useState<SubscriptionCourse>()
    const [subject, setSubject] = useState<Subject>()
    const [address, setAddress] = useState<Address>()
    const [child, setChild] = useState<Child | Client>()
    useEffect(() => {
        SubscriptionCourseRepository.fetchSubCourseByIdForTeachr(proposal.teachr_id, proposal.subscriptionCourse_id).then((response) => {
            setCourse(response)
            if (response) {
                SubjectRepository.fetchSubjectById(response.subjectAsked_id).then((response) => {
                    setSubject(response)
                })
                if (response.address_id) {
                    AddressRepository.fetchAddressById(response.address_id).then((response) => {
                        setAddress(response)
                    })
                }
    
                if (response.forChild_id) {
                    ChildRepository.fetchChildByIdForTeachr(response.forChild_id, proposal.teachr_id).then((response) => {
                        setChild(response)
    
                    })
                } else if (response.forClient_id) {
                    ClientRepository.fetchClientByIdForTeachr(proposal.teachr_id, response.forClient_id).then((response) => {
                        setChild(response)
                    })
                }
            }
        })
      
    }, [])
    const translateDay = (day: string) => {
        const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
        if (translate.length >= 0) {
            return translate[0].fr
        }
        return ''

    }
    const formatTime = (time: string | undefined) => {
        let format = ''
        if (time) {
            const regex = "(.*):(.*)"
            const match = time.match(regex)
            if (match) {
                format = `${match[1]}h${match[2]}`
            }
        }
        return format
    }
    return (
        <div className="w-[340px] p-6 mx-auto rounded-lg /shadow-recap-card  border-[#758FDA] border-[1px]">
        <div className="flex flex-col">
            <div className="flex flex-col gap-4">
                <div>
                    <p className='text-xl font-bold /whitespace-nowrap text-first'>Récapitulatif de la prestation :</p>
                </div>
                <div className="flex items-center gap-x-4">
                    <img src={child?.profilePicture ? child?.profilePicture : ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                    <div>
                        {!subject ?
                            <Skeleton
                                animation="wave"
                                height={20}
                                width="200px"
                            />
                            : <p className='overflow-hidden whitespace-nowrap w-[210px] text-ellipsis text-lg font-semibold text-first' title={subject?.fr_FR}>{subject?.fr_FR}</p>
                        }
                        {/* {!child ? <Skeleton
                            animation="wave"
                            height={14}
                            width="200px"
                        /> :
                            <p className='text-[#4D4D4D] text-sm'>Pour {child.firstName}</p>} */}
                    </div>
                </div>
                {!course?.isRemote
                    ? <div className="flex items-center gap-4">
                        <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        {address ? <a href={`https://www.google.com/maps/search/?api=1&query=${address?.streetName}%2C+${address?.city}`} target='_blanck' className='text-lg font-semibold underline text-first'>{address?.streetName + ', ' + address?.city}</a> :
                            <Skeleton
                                animation="wave"
                                height={16}
                                width="200px"
                            />}

                    </div> :
                    <div className="flex items-center gap-4">
                        <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <p className='text-lg font-semibold text-first'>Cours à distance</p>
                    </div>}
                <div className="flex items-center gap-4">
                    <img src={euroIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                    <p className='text-lg font-regular text-first'>{Functions.renderPaymentMean(course?.paymentMean!, false)}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 py-4">
                <div>
                    <p className='text-xl font-semibold /whitespace-nowrap text-first'>Dates de la prestation régulière</p>
                </div>
                <div className="flex gap-4">
                    <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                    <div>
                        {course ? <div>
                            <p className='flex flex-wrap text-lg font-semibold text-first'>Tous les&#160;{course?.daysOfTheWeek.map((day: string, key: number) => <span key={key} className="after:content-[','] after:pr-1 last:after:content-[''] text-regular">{`${translateDay(day)}s`}</span>)}</p>
                            <p className='text-[#4D4D4D] text-sm whitespace-nowrap'><span className='uppercase'>à</span> partir du {moment(course?.startDate).format('dddd DD MMMM')}</p>
                        </div> :
                            <Skeleton
                                animation="wave"
                                height={41}
                                width="200px"
                            />}
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                    <div>
                        {course ? <div>
                            <p className='text-lg font-semibold text-first'><span className='uppercase'>à</span> partir de {formatTime(course.startTime)}</p>
                            <p className='text-[#4D4D4D] text-sm'>{Functions.renderDuration(course.durationPerDay)} / jour</p>
                        </div> :
                            <Skeleton
                                animation="wave"
                                height={41}
                                width="200px"
                            />}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default RegularCandidacyComponent;