import React from 'react';

const NoOcr = () => {
    return (
        <div className='flex flex-col py-8 pb-28 lg:w-2/3 lg:py-14 gap-y-10 md:w-1/2'>
            <h3 className='font-bold text-first lg:text-2xl lg:leading-[33.6px] text-base'>
                Félicitations, nous avons reçu les deux documents que vous nous avez envoyé 🥳.
                <br />
                Notre équipe de vérification vous fera un retour d’ici 2 jours ouvrés.
                <br /> 
                Passé ce délai, si vous n’avez pas de retour de notre part, vous pouvez nous contacter au 01.84.80.26.15 ou par mail à l’adresse support@teachr.fr
            </h3>

        </div>
    );
};

export default NoOcr;
