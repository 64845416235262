import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import RadioButton from "../../../../../Components/Utilitis/RadioButton";
import SubjectsSelects from "../../../../../Components/Utilitis/SelectMatiere";
import StepperStore from "../../../../../LocalForage/StepperStore";
import { Subject } from "../../../../../Objects/models/Subject";
import { auth } from "../../../../../Services/FirebaseService";
import validateSelects from "../../../../../Validations/MultiStep";
// import StepPunctual from "./CourseTypeDeclare/StepPunctual";
// import StepSubscription from "./CourseTypeDeclare/StepSubscription";
import React from "react";

const validateTerms = (value: any) => {
  if (!value) {
    return {
      isValid: false,
      message: "Vous devez vous engager à respecter cette condition pour faire bénéficier de l'Avance Immédiate Crédit d'Impôt à votre élève",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};


const StepLocation = (props: any) => {
  const {handleChangeStep} = props
  const [user] = useAuthState(auth)
  const [matiere, setMatiere] = useState<Subject[]>();
  const [cours, setCours] = useState<any>();
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [error, setError] = useState({
    matiere: "null",
    termsTeachr: "null"
  });
  const getMatiere = (value: Subject[]) => {
    setMatiere(value);
  };
  useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem("cours"+user?.uid);
      // const location: any = await StepperStore.getItem("location"+user?.uid);
      StepperStore.removeItem("activeStepundefined")
      if (value !== null) {
        setCours(value);
      }
    })();
  });

  const validate = () => {
    let errMatiere = true;
    let errTermsTeachr = true;
    if (!validateSelects(matiere).isValid) {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errMatiere = true;
    } else {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errMatiere = false;
    }
    if (!validateTerms(termsTeachr).isValid) {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errTermsTeachr = true;
    } else {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errTermsTeachr = false;
    }

    if (errMatiere || errTermsTeachr) {
      return false;
    } else {
      return true;
    }
  };

  const handleNext = () => {
    // if (validate()) {
      StepperStore.setItem("location"+user?.uid, {
        matiere: matiere,
      });
      if (cours.cours === "punctual") {
        handleChangeStep(4)
      } else  if (cours.cours === "regulier") {
        handleChangeStep(3)
      }
    // }
  };

  return (
    <div className="overflow-y-auto list-teacher relative bg-white md:py-8 py-4 xl:px-14 px-4 md:h-[100%] h-[100%] rounded-[11px] ">
      <div className="space-y-4 md:h-[80%]">
        <div className="mt-8">
          <p className="text-first font-bold lg:text-2xl text-lg //text-base">
            Comment souhaitez-vous effectuer votre prestation ?
          </p>
        </div>
        <div className=" xl:translate-y-7 lg:-translate-y-5 md:translate-y-1 -translate-y-4 font-sans md:mx-auto">
          <FormControl>
            <RadioGroup row name="customized-radios" defaultValue="female">
              <div className="md:flex w-full">
                <div className="font-sans md:w-1/2 ">
                  <FormControlLabel
                    checked
                    sx={{ color: "black", fontFamily: "Nunito-Regular" }}
                    value="female"
                    control={
                      <RadioButton border="1px solid #fff" bg="#219CFF" />
                    }
                    label={
                      <Typography
                        style={{ fontFamily: "Nunito" }}
                        className="md:text-base lg:text-lg text-sm"
                      >
                        {" "}
                        À domicile
                      </Typography>
                    }
                  />
                </div>
                <div className=" md:w-1/2 xl:ml-10 lg:ml-0 md:ml-4 ">
                  <FormControlLabel
                    sx={{ color: "black" }}
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontFamily: "Nunito" }}
                        className="md:text-base lg:text-lg text-sm text-[#B7B7B7]"
                      >
                        À distance
                      </Typography>
                    }
                  />
                </div>
                <div className="">
                  <p className="text-sm text-[#B7B7B7]">
                    Impossible de choisir une prestation à distance pour bénéficier de
                    l’avance immédiate crédit d’impôt (attention)
                  </p>
                </div>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        <div className="xl:translate-y-14 md:translate-y-2 lg:-translate-y-6 -translate-y-3 //mt-8 md:space-y-1 space-y-[1px]">
          <div>
            <div className=" bg-input-color relative rounded-lg mx-auto shadow-md py-1 shadow-[rgba(0,0,0,25%)]">
              <SubjectsSelects
                callBack={getMatiere}
                validation={validateSelects}
                value={matiere}
                placeholder="Ex : Mathématiques"
              />
            </div>
            <div className="flex space-x-2 text-lg ///d:translate-y-4 translate-y-2 ">
              {/* {error.matiere === "null" || error.matiere === ""
                ? " "
                : validateSelects(matiere).icon} */}
              <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
                {error.matiere === "null" || error.matiere === ""
                  ? null
                  : validateSelects(matiere).message}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden absolute inset-x-0 bottom-4 flex justify-around space-x-4 ">
        <div className="flex w-1/2 rounded-xl bg-white text-orange border-2 border-orange font-bold justify-center">
          <div
            onClick={() => {
              handleChangeStep(1);
            }}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={() => {
              handleNext();
            }}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-xs border-first text-first uppercase rounded-xl active:bg-first active:text-[#4d4d4d]  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="hidden px-12 absolute inset-x-0 bottom-4 md:flex justify-between">
        <div>
          <button
            onClick={() => {
              handleChangeStep(1);
            }}
            className="py-2 font-bold text-first uppercase rounded-xl text-sm px-14 bg-white border-2 border-first"
          >
            precedent
          </button>
        </div>
        <div className="">
          <button
            onClick={() => {
              handleNext();
            }}
            className="py-2 font-bold text-white uppercase rounded-xl border-2 border-first text-sm px-14 bg-first"
          >
            SUIVANT
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepLocation;
