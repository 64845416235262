import React, { useEffect, useState } from 'react';


import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from '../../../../Components/Admin/DateInput';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import ValidateSiret from '../../../../Validation/Siret';
import RadioButton from '../../../../Components/utils/RadioButton';
import { validatePhoneNumber } from '../../../../Validation/Phone';
import Functions from '../../../../Helpers/Functions';
import validateEmail from '../../../../Validation/Email';


const dateInputStyle = {
    backgroundColor: '#fff',
    padding: '2px 8px',
    boxShadow: 'none',
    border: '1px solid rgba(77,77,77,0.29)',
    borderRadius: '9.3px'

}
const Third = (props: any) => {
    const [apeCode, setApedCode] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [enterprisePhone, setEnterprisePhone] = useState('')
    const [enterpriseEmail, setEnterpriseEmail] = useState('')
    const [gender, setGender] = useState<number>()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [chiefPhone, setChiefPhone] = useState('')
    const [error, setError] = useState({
        apeCode: true,
        createdAt: true,
        enterprisePhone: true,
        enterpriseEmail: true,
        gender: true,
        firstName: true,
        lastName: true,
        chiefPhone: true
    })
    const validate = () => {
        let error_firstName = true
        let error_code_ape = true
        let error_LastName = true
        let error_created_at = true
        let error_enterprise_phone = true
        let error_enterprise_email = true
        let error_gender = true
        let error_chief_phone = true
        if (apeCode.length === 5) {
            error_code_ape = false
        } else {
            error_code_ape = true
        }
        if (firstName === '') {
            error_firstName = true
        } else {
            error_firstName = false
        }
        if (lastName === '') {
            error_LastName = true
        } else {
            error_LastName = false
        }
        if (createdAt === '') {
            error_created_at = true
        } else {
            error_created_at = false
        }
        if (validatePhoneNumber(Functions.formatPhoneNumber(enterprisePhone)) === '') {
            error_enterprise_phone = false
        } else {
            error_enterprise_phone = true
        }
        error_enterprise_email = !validateEmail(enterpriseEmail).isValid
        if (gender !== undefined && gender >= 0) {
            error_gender = false
        } else {
            error_gender = true
        }
        if (validatePhoneNumber(Functions.formatPhoneNumber(chiefPhone)) === '') {
            error_chief_phone = false
        } else {
            error_chief_phone = true
        }

        setError({
            apeCode: error_code_ape,
            createdAt: error_created_at,
            enterprisePhone: error_enterprise_phone,
            enterpriseEmail: error_enterprise_email,
            gender: error_gender,
            firstName: error_firstName,
            lastName: error_LastName,
            chiefPhone: error_chief_phone
        })
        if (!error_code_ape && !error_created_at && !error_enterprise_phone && !error_enterprise_email && !error_gender && !error_firstName && !error_LastName && !error_chief_phone) return true
        return false
    }
    const getBirthday = (value: string) => {
        setCreatedAt(value)
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(parseInt((event.target as HTMLInputElement).value));
    };

    const nextStep = () => {
        if (validate()) {
            props.callBack(3)
        }
    }
    useEffect(() => {
        if (apeCode !== "" || createdAt !== "" || enterprisePhone !== "" || enterpriseEmail !== "" || lastName !== "" || firstName !== "" || chiefPhone !== "") {
            props.sendData({
                apeCode: apeCode,
                createdAt: createdAt,
                enterprisePhone: enterprisePhone,
                enterpriseEmail: enterpriseEmail,
                chiefGender: gender,
                chiefFirstName: firstName,
                chiefLastName: lastName,
                chiefPhone: chiefPhone
            })

        }
    }, [apeCode, chiefPhone, createdAt, enterpriseEmail, enterprisePhone, firstName, gender, lastName])

    return (
        <div className='flex h-full gap-10'>

            <form action="" autoComplete='false'>
                <div className='relative w-full'>
                    <div className='flex items-center pb-10 gap-x-4'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#0B2775" />
                            <path d="M20.4263 20.712C21.3063 20.712 21.7463 21.086 21.7463 21.834C21.7463 22.6113 21.3063 23 20.4263 23H12.9903C12.0956 23 11.6483 22.6113 11.6483 21.834C11.6483 21.086 12.0956 20.712 12.9903 20.712H15.2783V10.68L13.1663 11.978C12.961 12.0953 12.7776 12.154 12.6163 12.154C12.3083 12.154 12.0443 12.022 11.8243 11.758C11.619 11.494 11.5163 11.2007 11.5163 10.878C11.5163 10.4527 11.707 10.1227 12.0883 9.888L15.4983 7.776C15.9383 7.512 16.3563 7.38 16.7523 7.38C17.163 7.38 17.493 7.50467 17.7423 7.754C17.9916 8.00333 18.1163 8.348 18.1163 8.788V20.712H20.4263Z" fill="white" />
                        </svg>
                        <h2 className='text-first lg:text-[22px]'>Votre procuration :</h2>
                    </div>
                    <div className="grid justify-between w-full grid-cols-2 gap-4 items-end">
                        <div className="w-full">
                            {error.apeCode ? <p className='text-third pb-1'>Veuillez renseigner un code APE</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Code APE</label>
                                <input type="text" onBlur={(e) => setApedCode(e.target.value)} className='placeholder:text-[#808080] text-base bg-transparent w-full block' placeholder='8559B' />
                            </div>
                        </div>
                        <div className="w-full">
                            {error.createdAt ? <p className='text-third pb-1'>Veuillez renseigner une date</p> : null}
                            <DateInput label='Date de création entreprise' field='birthday' style={dateInputStyle} callBack={getBirthday} />
                        </div>
                        <div className="w-full">
                            {error.enterprisePhone ? <p className='text-third pb-1'>Veuillez renseigner un numéro de téléphone</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Téléphone de l'établissement</label>
                                <input type="text" onBlur={(e) => setEnterprisePhone(e.target.value)} placeholder='0X XX XX XX XX' className='placeholder:text-[#808080] text-base bg-transparent' />
                            </div>
                        </div>
                        <div className="w-full">
                            {error.enterpriseEmail ? <p className='text-third pb-1'>Veuillez renseigner une adresse mail</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Adresse mail de l'établissement</label>
                                <input type="text" onBlur={(e) => setEnterpriseEmail(e.target.value)} placeholder='example@mail.com' className='placeholder:text-[#808080] text-base bg-transparent' />
                            </div>
                        </div>

                    </div>
                    <div className='w-full'>
                        <hr className='text-[rgba(128,128,128,0.80)] border-dashed my-8' />
                        <div className="flex flex-col gap-y-4">

                            <div className="w-full">
                                {error.gender ? <p className='text-third pb-1'>vous devez faire un choix</p> : null}
                                <div className="flex items-center gap-x-10">
                                    <label className='text-normal text-xl font-regular' htmlFor="">Civilité :</label>
                                    <RadioGroup row
                                        value={gender}
                                        name="customized-radios"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Monsieur" />
                                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Madame" />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    {error.firstName ? <p className='text-third pb-1'>Veuillez renseigner un prénom</p> : null}
                                    <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                        <label htmlFor="" className='block text-base'>Prénom</label>
                                        <input type="text" onBlur={(e) => setFirstName(e.target.value)} placeholder='Julie' className='placeholder:text-[#808080] text-base bg-transparent' />
                                    </div>
                                </div>
                                <div className="w-full">
                                    {error.lastName ? <p className='text-third pb-1'>Veuillez renseigner un nom</p> : null}
                                    <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                        <label htmlFor="" className='block text-base'>Nom</label>
                                        <input type="text" onBlur={(e) => setLastName(e.target.value)} placeholder='Dupont' className='placeholder:text-[#808080] text-base bg-transparent' />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                {error.chiefPhone ? <p className='text-third pb-1'>Veuillez renseigner un numéro de téléphone</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='block text-base'>Numéro de téléphone</label>
                                    <input type="text" onBlur={(e) => setChiefPhone(e.target.value)} placeholder='0X XX XX XX XX' className='placeholder:text-[#808080] text-base bg-transparent' />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-end py-10">
                        <div className="flex justify-between w-full gap-10">
                            <Button onClick={() => props.callBack(1)} className='flex w-1/3 py-3 text-xl font-regular rounded-full gap-x-4 text-normal'>
                                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                PRÉCÉDENT
                            </Button>
                            <Button onClick={nextStep} className='w-1/3 py-3 text-xl font-bold text-white rounded-lg bg-first'>SUIVANT</Button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default Third;