import { Button, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Simulator from '../../../../../Objects/models/Simulator';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
}
const Legend = (props: {
    callBack: any,
    response: Simulator
}) => {
    const navigate = useNavigate()
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: color,
          borderRadius: '13px',
          padding: '20px',
          border: color === '#EFF5FF' ? '2px solid #8097EC' : '2px solid #92D3FF'
        },
      }));
    const [color, setColor] = useState('')
    const [open, setOpen] = useState(false)
    const [openC, setOpenC] = useState(false)
    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    const [marker, setMarker] = useState(0)
    useEffect(() => {
        if (props.response && props.response.hourlyPay.liberatoire) {
            if (props.response.hourlyPay.liberatoire > props.response.hourlyPay.classic) {
                setMarker(0)
            } else {
                setMarker(1)
            }
        }else{
            setMarker(1)
        }
    }, [props])

    return (
        <div>
            {props.response && simulatorData ?
           
                <div className='lg:rounded-[30px] rounded-[17px]  lg:border-[3px] border-radio lg:shadow-simulator-dashboard flex flex-col p-4 md:p-8 bg-white gap-y-8 py-8'>
                    <div className='hidden lg:block'>
                        <h3 className='text-normal text-[22px] font-regular'>En facturant {simulatorData.weekHours}h de cours/semaine à {simulatorData.hourlyPrice}€/h après CI <sup className='text-third sup'>(1)</sup></h3>
                        <ul className='m-0'>
                            <li className='text-lg font-regular text-normal'>Vous gagnerez : <span className={marker === 0 ? 'text-radio' : 'text-[#8097EC]'}>{(marker === 0 ? props.response.hourlyPay.liberatoire * simulatorData.weekHours : props.response.hourlyPay.classic * simulatorData.weekHours).toFixed(2)}€/semaine</span></li>
                        </ul>
                    </div>
                    <div>
                        <table>
                            <caption className="pt-0 text-sm font-regular lg:text-lg text-normal" style={{ "captionSide": "top" }}>
                                En déclarant vos impôts avec le régime :
                            </caption>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className='text-xs text-[#8097EC] lg:px-3 py-2 px-1'>
                                        <span className='flex items-center gap-x-2'>
                                            Classique
                                            <LightTooltip 
                                            onOpen={()=> setColor('#EFF5FF')}
                                            open = {openC}
                                            onMouseEnter={()=> setOpenC(true)}
                                            onMouseLeave={()=> setOpenC(false)}
                                            onClick={()=> setOpen(!openC)}
                                            classes={{
                                                popper: 'bg-white'
                                            }}
                                             title={
                                                <div  className='text-sm text-normal font-sans '>
                                                    En utilisant <span className='font-regular text-[#96B2F3]'>le barème progressif</span> de l’imposition sur le revenu, vos impôts doivent être réglés une fois par an. Vos revenus sont soumis aux different barème de progression.
                                                    Plus d’informations sur le régime classique
                                                    <a className='block pt-2 underline text-normal' href="https://www.service-public.fr/particuliers/vosdroits/F1419#:~:text=Jusqu'%C3%A0%2010%20777%20%E2%82%AC%20%3A%200%20%25,30%20%25%20%3D%20756%2C60%20%E2%82%AC" target='_blank'>Plus d’informations sur le régime classique</a>
                                                </div>
                                            }>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.24972 7.23783H6.02653V4.81464C6.02653 4.68711 5.93088 4.55957 5.77146 4.55957H4.75117C4.59175 4.55957 4.49609 4.65522 4.49609 4.81464V5.35667C4.49609 5.48421 4.59175 5.61174 4.75117 5.61174H4.94247V7.23783H4.75117C4.59175 7.23783 4.49609 7.36537 4.49609 7.52479V8.03493C4.49609 8.19435 4.59175 8.29 4.75117 8.29H6.24972C6.40914 8.29 6.53667 8.19435 6.53667 8.03493V7.52479C6.53667 7.36537 6.40914 7.23783 6.24972 7.23783Z" fill="#7C95F2" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48406 0C8.54493 0 11 2.45507 11 5.51594C11 8.54493 8.54493 11 5.48406 11C2.45507 11 0 8.54493 0 5.51594C0 2.45507 2.45507 0 5.48406 0ZM5.48406 10.1391C8.03478 10.1391 10.1391 8.06667 10.1391 5.51594C10.1391 2.93333 8.06667 0.86087 5.48406 0.86087C2.93333 0.86087 0.86087 2.96522 0.86087 5.51594C0.86087 8.06667 2.93333 10.1391 5.48406 10.1391Z" fill="#7C95F2" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45409 2.70996C5.80481 2.70996 6.09177 2.99692 6.09177 3.37953C6.09177 3.73025 5.80481 4.01721 5.45409 4.01721C5.10336 4.01721 4.81641 3.73025 4.81641 3.37953C4.81641 2.99692 5.10336 2.70996 5.45409 2.70996Z" fill="#7C95F2" />
                                                </svg>
                                            </LightTooltip>
                                        </span>
                                    </th>
                                    <th className='px-1 py-2 text-xs lg:px-3 text-radio'>
                                        <span className='flex items-center gap-x-2'>
                                            Libératoire
                                            <LightTooltip onOpen={()=> setColor('#F2F5FB')}
                                             open = {open}
                                             onMouseEnter={()=> setOpen(true)}
                                             onMouseLeave={()=> setOpen(false)}
                                             onClick={()=> setOpen(!open)}
                                            title={
                                                <div className='text-sm text-normal font-sans '>
                                                    En utilisant <span className='font-regular text-radio'>le versement libératoire</span>, les impôts doivent être réglés en même temps que les cotisations sociales. De plus, ce régime impose un impôt fixe de 2,2% pour les prestations de services.
                                                    <a className='block pt-2 underline text-normal' href="https://www.economie.gouv.fr/entreprises/micro-entreprise-auto-entreprise-versement-liberatoire-impot-revenu" target='_blank'>Plus d’informations sur le régime libératoire</a>
                                                </div>
                                            }>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.24972 7.23783H6.02653V4.81464C6.02653 4.68711 5.93088 4.55957 5.77146 4.55957H4.75117C4.59175 4.55957 4.49609 4.65522 4.49609 4.81464V5.35667C4.49609 5.48421 4.59175 5.61174 4.75117 5.61174H4.94247V7.23783H4.75117C4.59175 7.23783 4.49609 7.36537 4.49609 7.52479V8.03493C4.49609 8.19435 4.59175 8.29 4.75117 8.29H6.24972C6.40914 8.29 6.53667 8.19435 6.53667 8.03493V7.52479C6.53667 7.36537 6.40914 7.23783 6.24972 7.23783Z" fill="#219CFF" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48406 0C8.54493 0 11 2.45507 11 5.51594C11 8.54493 8.54493 11 5.48406 11C2.45507 11 0 8.54493 0 5.51594C0 2.45507 2.45507 0 5.48406 0ZM5.48406 10.1391C8.03478 10.1391 10.1391 8.06667 10.1391 5.51594C10.1391 2.93333 8.06667 0.86087 5.48406 0.86087C2.93333 0.86087 0.86087 2.96522 0.86087 5.51594C0.86087 8.06667 2.93333 10.1391 5.48406 10.1391Z" fill="#219CFF" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45409 2.70996C5.80481 2.70996 6.09177 2.99692 6.09177 3.37953C6.09177 3.73025 5.80481 4.01721 5.45409 4.01721C5.10336 4.01721 4.81641 3.73025 4.81641 3.37953C4.81641 2.99692 5.10336 2.70996 5.45409 2.70996Z" fill="#219CFF" />
                                                </svg>
                                            </LightTooltip>

                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='border border-r-0 lg:border-[rgba(0,0,0,0.11)] border-transparent text-normal font-regular lg:text-sm text-[12px] lg:p-3 p-1'>Prix par heure de cours après <br /> cotisation sociales et impots </td>
                                    <td className={`${marker === 1 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-r-8 border-r-white lg:border-r-white border-y lg:border-[rgba(0,0,0,0.11)] border-transparent lg:text-sm text-[12px] font-regular lg:p-3 p-1 bg-[rgba(214,229,255,0.6)] text-center`}>{props.response.hourlyPay.classic.toFixed(2) + "€"}</td>
                                    <td className={`${marker === 0 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-l-0 border-y border-r lg:border-[rgba(0,0,0,0.11)] border-transparent font-regular lg:text-sm text-[12px] lg:p-3 p-1 bg-[rgba(146,211,255,0.37)] text-center`}>{props.response.hourlyPay.liberatoire ? props.response.hourlyPay.liberatoire.toFixed(2) + "€" : "Pas éligible"}</td>
                                </tr>
                                <tr>
                                    <td className='border border-r-0 lg:border-[rgba(0,0,0,0.11)] border-transparent text-normal font-regular lg:text-sm text-[12px] lg:p-3 p-1'>Impôts payé par le foyer fiscal hors AE <sup className='text-third'>(2)</sup></td>
                                    <td className={`${marker === 1 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-r-8 border-r-white lg:border-r-white border-y lg:border-[rgba(0,0,0,0.11)] border-transparent lg:text-sm text-[12px] font-regular lg:p-3 p-1 bg-[rgba(214,229,255,0.6)] text-center`}>{props.response.externalIncomeTax.toFixed(2) + "€"}</td>
                                    <td className={`${marker === 0 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-l-0 border-y border-r lg:border-[rgba(0,0,0,0.11)] border-transparent font-regular lg:text-sm text-[12px] lg:p-3 p-1 bg-[rgba(146,211,255,0.37)] text-center`}>{props.response.externalIncomeTax.toFixed(2) + "€"}</td>
                                </tr>
                                <tr>
                                    <td className='border border-r-0 lg:border-[rgba(0,0,0,0.11)] border-transparent text-normal font-regular lg:text-sm text-[12px] lg:p-3 p-1'>Impôts payé par les revenus AE <sup className='text-third'>(2)</sup></td>
                                    <td className={`${marker === 1 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-r-8 border-r-white lg:border-r-white border-y lg:border-[rgba(0,0,0,0.11)] border-transparent lg:text-sm text-[12px] font-regular lg:p-3 p-1 bg-[rgba(214,229,255,0.6)] text-center`}>{props.response.teachrIncomeTax.classic.toFixed(2) + "€"}</td>
                                    <td className={`${marker === 0 ? 'text-normal' : 'text-[#B7B7B7]'} lg:w-[90px] border-l-0 border-y border-r lg:border-[rgba(0,0,0,0.11)] border-transparent font-regular lg:text-sm text-[12px] lg:p-3 p-1 bg-[rgba(146,211,255,0.37)] text-center`}>{props.response.teachrIncomeTax.liberatoire ? props.response.teachrIncomeTax.liberatoire.toFixed(2) + "€" : "pas éligible"}</td>
                                </tr>
                                <tr>
                                    <td className='border border-r-0 lg:border-[rgba(0,0,0,0.11)] border-transparent text-normal font-regular lg:text-sm text-[12px] lg:p-3 p-1'>Chiffre d’affaire NET à l’année</td>
                                    <td className={`${marker === 1 ? 'lg:text-lg text-sm font-bold text-normal' : 'lg:text-sm text-[12px] font-regular text-[#B7B7B7]'} lg:w-[90px] border-r-8 border-r-white lg:border-r-white border-y lg:border-[rgba(0,0,0,0.11)] border-transparent  lg:p-3 p-1 bg-[rgba(214,229,255,0.6)] text-center`}>{props.response.turnover.classic.toFixed(2) + "€"}</td>
                                    <td className={`${marker === 0 ? 'lg:text-lg text-sm font-bold text-normal' : 'lg:text-sm text-[12px] font-regular text-[#B7B7B7]'} lg:w-[90px] border-l-0 border-y border-r lg:border-[rgba(0,0,0,0.11)] border-transparent  lg:p-3 p-1 bg-[rgba(146,211,255,0.37)] text-center`}>{props.response.turnover.liberatoire ? props.response.turnover.liberatoire.toFixed(2) + "€" : 'pas éligible'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='flex gap-x-8'>
                        <span className='lg:text-xs text-[12px]'>Crédit d’impôt <sup className='text-third'>(1)</sup></span>
                        <span className='lg:text-xs text-[12px]'>Auto-entrepreneur <sup className='text-third'>(2)</sup></span>
                    </div>
                    <div className='justify-between hidden lg:flex'>
                        <Button className='px-0 bg-transparent text-normal font-regular text-lg lg:rounded-[30px] rounded-[17px] ' onClick={props.callBack}>PRÉCÉDENT</Button>
                        <Button onClick={()=> navigate('/declarons/fonctionnement')} className='bg-radio text-white font-bold text-lg lg:rounded-[30px] rounded-[17px]  px-8'>DÉTAILS DES CALCULS</Button>
                    </div>
                </div>
                :
                <Skeleton className='lg:rounded-[30px] rounded-[17px]' variant="rectangular" width="100%"  height="523px" />
                }
        </div>
    );
};

export default Legend;