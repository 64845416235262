import { FormControl, FormControlLabel, RadioGroup } from '@mui/material'
import React, {  useState } from 'react';
import RadioButton from '../../../../../Components/utils/RadioButton';
import SelectComponent from '../../../../../Components/Admin/Select';
import Functions from '../../../../../Helpers/Functions';
// import SubjectRepository from '../../../../../Repositories/SubjectRepository';
import Teachr_Repository from '../../../../../Repositories/Teachr_Repository';
import TeachrBusinessType from '../../../../../Objects/models/TeachrBusinessType';
import Teachr from '../../../../../Objects/models/Teachr';

const Account = (props: any) => {
    const [isAe, setIsAe] = useState("0")
    const [businessType, setBusinessType] = useState("AE")
    const [isOk, setIsOk] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    
    const [isCodeApe, setIsCodeApe] = useState("")
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAe((event.target as HTMLInputElement).value);
    };

    const getBusinessType = (value: any) => {
        if (value) {
            setBusinessType(value)
            setIsOk(true)
            Teachr_Repository.getCurrentTeachr().then((teachr) => {
                setTeachr(teachr)
                if (teachr.id) {
                    const businessTypes = new TeachrBusinessType(value)
                    Teachr_Repository.teachrUpdateBusinessType(teachr.id, businessTypes)
                }

            })
        }
    }
    const IsAeScreen = () => {

        return (
            <div className='flex flex-col pt-4 font-regular txt-base pb-28 lg:text-lg md:w-3/5 text-normal md:gap-y-8'>
                <p className=''>Vous avez créé le statut auto-entrepreneur ? Exellente décision !</p>
                <div>
                    <label className='py-4 translate-y-3 text-lg font-bold lg:text-2xl text-first'>Quel est le statut de votre structure ?</label>
                    <div className='pt-1'>
                        <SelectComponent
                            // defaultValue={businessType}
                            field='level'
                            placeholder='Ex: Auto-entrepreneur'
                            callBack={getBusinessType}
                            // label='Statut juridique'
                            data={Functions.businessTypeList}
                        />
                    </div>
                    {isOk ?
                        <FormControl>
                            <label className='py-4 text-lg font-bold lg:text-2xl text-first'>Disposez-vous du bon code APE ? </label>
                            <p>Afin que profil soit validé, Veuillez vérifier que vous avez le bon code APE : <strong>8559B</strong> (soutien scolaire). Sans celui-ci, les démarches administratives concernant l'obtention du crédit d'impôt instantané pourraient vous être refusées.</p>

                            <RadioGroup row
                                value={isCodeApe}
                                name="customized-radios"
                                onChange={(e, value) => { setIsCodeApe(value); value === '1' ? props.getIsCodeAe(true) : props.getIsCodeAe(false); props.nextStep(2) }}
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                            </RadioGroup>
                        </FormControl> : ""
                    }
                </div>

            </div>)
    }
    const IsNotAeScreen = () => {
        return (
            <div className='flex flex-col py-8 font-regular md:w-3/5 lg:text-lg text-normal gap-y-8'>
                <p className=''>Les Teach’rs ayant le statut auto-entrepreneur sont mieux payés et ont accès à davantage de missions que les autres. </p>
                <p className=''>Vous souhaitez obtenir plus d’information sur le statut auto entrepreneur, <br />
                    vous pouvez vous rendre sur notre page dédié. <br />
                    Vous souhaitez vous créer le statut ? Rien de plus simple ! <a href="https://app.tango.us/app/workflow/Comment-se-cr-er-le-statut--auto-entrepreneur------614444692b674480ad3850581c000efa" className='font-regular underline text-normal' target="_blank">Cliquez ici.</a></p>
                <a href='https://drive.google.com/drive/folders/1ua_E5nxAJQzB8NIE4E-4fI6hkwD_SS0e' target='_blank' className='py-2 text-base font-regular text-center text-white rounded-full lg:text-xl md:px-8 bg-first'>SE RENSEIGNER SUR LE STATUT</a>

            </div>)
    }
    return (
        <div>
            <FormControl>
                <label className='py-4 text-lg font-bold lg:text-2xl text-first'>Avez-vous créé votre statut auto-entrepreneur ?</label>
                <RadioGroup row
                    value={isAe}
                    name="customized-radios"
                    onChange={handleChange}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui" />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </FormControl>
            {isAe === "1" ?
                IsAeScreen()
                :
                IsNotAeScreen()
            }
        </div>
    );
};

export default Account;