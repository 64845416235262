import { useState } from "react";
import FirstImage from "../../../Assets/Images/FirstImage (1).png";
// import eye from "../../../Assets/Icons/eye.png";
import Loader from "../../../Components/utils/Loader";
import {
  Checkbox,
  // FormControl,
  FormControlLabel,
  // RadioGroup,
  Typography,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import validateName from "../../../Validations/Name";
import validateFirstName from "../../../Validations/FirstName";
import { validatePhoneNumber } from "../../../Validations/Phone";
import validateEmail from "../../../Validations/Email";
import errorIcon from "../../../Assets/Images/error.png";
import {
  auth, getJWTToken,
} from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
// import validatePassword from "../../../Validations/Password";
// import {
//   indexedDBLocalPersistence,
//   inMemoryPersistence,
//   UserCredential,
// } from "firebase/auth";

import ClientRepository from "../../../Repositories/ClientRepository";
import Client from "../../../Objects/models/Client";
import ActionInterface from "../../../Objects/interfaces/ActionInterface";
// import RadioButtonDark from "../../../Components/Utilitis/RadioButtonDark";
import StepperStore from "../../../LocalForage/StepperStore";
import ApiService from "../../../Services/ApiService";
// import { userClientSetAccountAction } from "../../../Redux/Actions/UserActions";
import NavBar from "../../../Components/ComponentsUtils/NavBar";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Functions from "../../../Helpers/Functions";

function CompleteProfile(props: { dispatch: (arg0: ActionInterface) => void }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [phone, setPhone] = useState("");
  const [termsMarket, setTermsMarket] = useState(false);
  const [remember, setRemember] = useState(false);
  // useEffect(() => {
  //   if (user) {
  //     navigate("/user/welcome");
  //   }
  // }, [user]);

  // const Swal = require("sweetalert2");
  // let timerInterval: any;
  const [form, setForm] = useState({
    name: "",
    firstName: "",
    email: "",
  });
  const [error, setError] = useState({
    name: "null",
    firstName: "null",
    email: "null",
    terms: "null",
    termsTeachr: "null",
    phone: "null",
  });

  const validateTerms = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez cocher cette case",
      };
    } else
      return {
        isValid: true,
        message: "",
      };
  };

  const validate = () => {
    let erremail = true;
    let errname = true;
    let errfirstName = true;
    let errterms = true;
    let errtermsTeachr = true;
    let errphone = true;

    if (!validateEmail(form.email).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      erremail = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      erremail = false;
    }
    if (!validateName(form.name).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errname = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errname = false;
    }
    if (!validateFirstName(form.firstName).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = false;
    }
    if (!validateTerms(terms).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errterms = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errterms = false;
    }
    if (!validateTerms(termsTeachr).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,

        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errtermsTeachr = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        phone: validatePhoneNumber(phone),
      });
      errtermsTeachr = false;
    }
    if (validatePhoneNumber(phone)) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(phone),
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errphone = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(phone),
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errphone = false;
    }
    if (user?.phoneNumber) {
      if (errname || errfirstName || errterms || erremail || errtermsTeachr) {
        return false;
      } else {
        return true;
      }
    } else {
      if (user?.email) {
        if (errname || errfirstName || errphone || errterms || errtermsTeachr) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const createClient = async () => {
    if (user) {
      let clientObject = new Client(
        await user.getIdToken(),
        form.firstName,
        form.name,
        user.phoneNumber
          ? user.phoneNumber
          : Functions.formatPhoneNumber(phone),
        terms,
        "firebaseMessagingToken", // TODO registrationToken
        true, // TODO parent/child
        false,
        undefined
      );

      if (user?.email) {
        clientObject.mail = user?.email;
      } else if (form.email) {
        clientObject.mail = form.email;
      }

      ClientRepository.createClient(clientObject)
        .then((cresponse) => {
          getJWTToken(user).then((token) => {
            ApiService.enableAuth(undefined, undefined, "client " + token);
            navigate("/user/welcome");
          });
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  const handleSubmit = (event: any) => {
    if (validate()) {
      if (user) {
        StepperStore.setItem(
          user ? "user" + user?.uid : "user",
          {
            name: form.name,
            firstName: form.firstName,
            phone: user.phoneNumber
            ? user.phoneNumber
            : Functions.formatPhoneNumber(phone),
            email: user?.email ? user?.email : form.email,
          },
          function () {}
        );
        
      }
      createClient();

    
      navigate("/user/welcome");
      StepperStore.clear();
    }
    event.preventDefault();
  };

  return (
    <div className="bg-[#004066] md:overflow-hidden  relative h-full md:h-screen font-sans">
      <div className="z-40 w-full bg-white //-mt-4 first-letter: mt lg:bg-transparent">
        <Loader />
        <NavBar theme="dark" />
      </div>
      <div className=" lg:-mt-6 xl:mt-0 md:mt-0 //translate-y-16">
        <div className="block mt-10 ml-10 md:flex md:ml-24">
          <div>
            <div className="w-4/5 md:w-full ">
              <p className="mt-3 text-xl font-regular text-white lg:text-3xl md:w-full md:text-2xl lg:mt-0 md:mt-0 font-sans">
                Dernière étape ! <br />
                Pour finaliser votre inscription,
                <br /> Veuillez remplir les champs
              </p>
            </div>
            <div className="mt-6 md:w-full">
              <div className="flex flex-col pr-10 -mt-4 gap-x-2 gap-y-4">
                <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
                  <div className="w-full">
                    <div className="w-full px-3 py-2 bg-white rounded-md">
                      <label htmlFor="">Prénom</label>
                      <input
                        type="text"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="Ex : John"
                        onChange={(e) =>
                          setForm({
                            name: form.name,
                            firstName: e.target.value,
                            email: form.email,
                          })
                        }
                      />
                    </div>
                    <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                      {error.firstName === "null" || error.firstName === "" ? (
                        " "
                      ) : (
                        <img src={errorIcon} alt="" className="w-4 h-4" />
                      )}
                      <p className="text-[#FF724F] h-6 w-full text-sm ">
                        {error.firstName === "null" || error.firstName === ""
                          ? null
                          : error.firstName}
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className="w-full px-3 py-2 bg-white rounded-md"
                    >
                      <label htmlFor="">Nom</label>
                      <input
                        type="text"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="Ex : Pierre"
                        onChange={(e) =>
                          setForm({
                            name: e.target.value,
                            firstName: form.firstName,
                            email: form.email,
                          })
                        }
                      />
                    </div>
                    <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                      {error.name === "null" || error.name === "" ? (
                        " "
                      ) : (
                        <img src={errorIcon} alt="" className="w-4 h-4" />
                      )}
                      <p className="text-[#FF724F] h-6 w-full text-sm ">
                        {error.name === "null" || error.name === ""
                          ? null
                          : error.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
                  <div className="w-full">
                    <div
                      className="w-full px-3 py-2 bg-white rounded-md"
                    >
                      <label htmlFor="">Adresse email</label>
                      <input
                        type="email"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="Ex : mail@gmail.com"
                        value={user?.email ? user?.email : form.email}
                        onChange={(e) =>
                          setForm({
                            name: form.name,
                            firstName: form.firstName,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    {user?.email ? (
                      ""
                    ) : (
                      <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                        {error.email === "null" || error.email === "" ? (
                          " "
                        ) : (
                          <img src={errorIcon} alt="" className="w-4 h-4" />
                        )}
                        <p className="text-[#FF724F] h-6 w-full text-sm ">
                          {error.email === "null" || error.email === ""
                            ? null
                            : error.email}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="w-full px-3 py-2 bg-white rounded-md">
                      <label htmlFor="">Numéro de téléphone</label>
                      <input
                        type="phone"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="Ex : +33 7 55 27 96 87"
                        value={user?.phoneNumber ? user?.phoneNumber : phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    {user?.phoneNumber ? (
                      ""
                    ) : (
                      <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                        {error.phone === "null" || error.phone === ""
                          ? " "
                          : // <img src={errorIcon} alt="" className="w-4 h-4" />
                            ""}
                        <p className="text-[#FF724F] h-6 w-full text-sm ">
                          {error.phone === "null" || error.phone === ""
                            ? null
                            : validatePhoneNumber(phone)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full -mt-6 md:mt-4 md:block">
                  <div className="mt-8 md:-mt-6 md:w-full">
                    {/* -translate-y-16 lg:-translate-y-6 md:translate-y-4 md:-ml- lg:ml-0 -ml-20 */}
                    {/* -translate-y-24 font-sans  -ml-20 md:-ml- md:translate-y-8 lg:ml-0 lg:-translate-y-16 */}
                    <div className="space-y-6 ">
                      <FormControlLabel
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          // marginLeft: "4rem",
                          fontFamily: "Nunito",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                                fontFamily: "Nunito",
                              },
                            }}
                            onClick={() => setTerms(!terms)}
                          />
                        }
                        label={
                          <Typography style={{ fontFamily: "nunito" }}>
                            {" "}
                            <a
                              target={"_blank"}
                              href="https://www.teachr.fr/policy"
                              className="text-white underline"
                              rel="noreferrer"
                            >
                              J’accepte la politique des données.
                            </a>{" "}
                          </Typography>
                        }
                      />

                      <div className="flex ml-4 space-x-2 -translate-y-6 ">
                        {error.terms === "null" || error.terms === "" ? (
                          " "
                        ) : (
                          <img src={errorIcon} alt="" className="w-4 h-4" />
                        )}
                        <p className="text-[#FF724F] h-6 w-full text-sm ">
                          {error.terms === "null" || error.terms === ""
                            ? null
                            : error.terms}
                        </p>
                      </div>
                    </div>
                    <div className="-mt-10">
                      <FormControlLabel
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          //marginLeft: "4rem",
                          fontFamily: "nunito",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                                fontFamily: "nunito",
                              },
                            }}
                            onClick={() => setTermsTeachr(!termsTeachr)}
                          />
                        }
                        label={
                          <Typography style={{ fontFamily: "nunito" }}>
                            {" "}
                            <a
                              target={"_blank"}
                              href="https://www.teachr.fr/cgu_cgv"
                              className="text-white underline"
                              rel="noreferrer"
                            >
                              J'accepte les CGUs et CGVs.
                            </a>
                          </Typography>
                        }
                      />

                      <div className="flex ml-4 space-x-2 -translate-y-2 ">
                        {error.termsTeachr === "null" ||
                        error.termsTeachr === "" ? (
                          " "
                        ) : (
                          <img src={errorIcon} alt="" className="w-4 h-4" />
                        )}
                        <p className="text-[#FF724F] h-6 w-full text-sm ">
                          {error.termsTeachr === "null" ||
                          error.termsTeachr === ""
                            ? null
                            : error.termsTeachr}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3 md:-mt-6 md:w-full">
                    <div className="space-y-6 ">
                      <FormControlLabel
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          fontFamily: "Nunito",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                                fontFamily: "Nunito",
                              },
                            }}
                            onClick={() => setTermsMarket(!termsMarket)}
                          />
                        }
                        label={
                          <Typography style={{ fontFamily: "nunito" }}>
                            {" "}
                            Rester connecté
                          </Typography>
                        }
                      />
                    </div>
                    <div className="-mt-10">
                      <FormControlLabel
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          //marginLeft: "4rem",
                          fontFamily: "nunito",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "white",
                                fontFamily: "nunito",
                              },
                            }}
                            onClick={() => setRemember(!remember)}
                          />
                        }
                        label={
                          <Typography style={{ fontFamily: "nunito" }}>
                            {" "}
                            J'accepte recevoir des offres promotionnelles de la
                            part de Teach'r
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="hidden text-lg font-regular md:-mt-1 md:-translate-y-4 md:flex translate-y-28 ">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="flex my-4 bg-[#219CFF] text-sm  rounded-full py-2 text-white w-full  items-center justify-center gap-x-3 no-underline uppercase px-4"
                  >
                    Valider votre inscription
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-2/3 md:translate-x-4 //lg:translate-x-20 //xl:-translate-y-32 //md:translate-y-4">
            <div className="hidden md:flex //xl:mt-4 lg:-mt-14">
              <img
                className="md:h-[%] md:w-[%]"
                src={FirstImage}
                alt="downloadimage"
              />
            </div>
            <div className="block w-4/5 pb-4 mx-auto text-lg font-regular -translate-x-5 md:-mt-4 //translate-y-28 md:hidden ">
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="flex my-4 bg-[#219CFF] text-sm  rounded-full py-2 text-white w-full  items-center justify-center gap-x-3 no-underline uppercase px-4"
              >
                Valider votre inscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteProfile;
