import React from 'react';

const NovaLoader = () => {
    return (
        <div id="loader">
            <div className='right-[74px] left-[74px] bottom-[148px] top-0'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: ".5s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[20px] left-[128px] bottom-[128px] top-[20px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: ".7s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className=' right-[0px] left-[148px] bottom-[74px] top-[74px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: ".9s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[20px] left-[128px] bottom-[20px] top-[128px]' >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: "1.1s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[74px] left-[74px] bottom-[0px] top-[140px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: "1.3s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[128px] left-[20px] bottom-[20px] top-[128px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: "1.5s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[148px] left-[0px] bottom-[74px] top-[74px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: "1.7s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
            <div className='right-[128px] left-[20px] bottom-[128px] top-[20px]'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{ animationDelay: "1.9s" }} d="M10.2632 19.5981C7.70816 19.5981 5.3008 18.609 3.49214 16.8114C1.68672 15.0174 0.691406 12.6271 0.691406 10.0862C0.691406 7.54727 1.68671 5.15507 3.49569 3.35781C7.11014 -0.233854 13.4151 -0.233854 17.0328 3.35781C18.8382 5.15186 19.8336 7.5422 19.8336 10.083C19.8336 12.622 18.8383 15.0142 17.0293 16.8114C15.2239 18.6073 12.8184 19.5981 10.2632 19.5981ZM10.2632 6.91578C9.41212 6.91578 8.6109 7.24487 8.00732 7.84467C7.40373 8.44445 7.07253 9.23886 7.07253 10.0863C7.07253 10.9321 7.40371 11.7283 8.00732 12.328C9.21271 13.5294 11.3155 13.5258 12.5191 12.328C13.1227 11.7283 13.4539 10.9338 13.4539 10.0863C13.4539 9.24062 13.1227 8.44445 12.5191 7.84467C11.9155 7.24489 11.1143 6.91578 10.2632 6.91578Z" fill="#CBE1F7" />
                </svg>
            </div>
        </div>
    );
};

export default NovaLoader;