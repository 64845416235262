import First from "./PrestationStep/First";
import { useEffect, useState } from "react";
import Third from "./PrestationStep/Third";
import Five from "./PrestationStep/Five";
import { Step, StepLabel, Stepper } from "@mui/material";
import Second from "./PrestationStep/Second";
import Fourth from "./PrestationStep/Fourth";
import LastStep from "./PrestationStep/Last";
import icon1 from "../../Assets/Icons/icone1.svg";
import icon2 from "../../Assets/Icons/icone2.svg";
import icon3 from "../../Assets/Icons/icone3.svg";
import icon4 from "../../Assets/Icons/icone4.svg";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import logo from "../../Assets/Images/logo_dec_bleu.svg";
import frameLayout from "../../Assets/Images/Frame.svg";
import StepperStore from "../../LocalForage/StepperStore";
import { auth } from "../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import TimerClient from "../ComponentsUtils/TimerClient";

const Index = (props: any) => {

  useEffect(() => {
    document.body.classList.remove("bg-bg-home");
  }, []);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const stepsTab = ["", "", "", "", ""];
  const [activeStep, setActiveStep] = useState<any>(0);
  const currentComponent = (current: number, component: any) => {
    setActiveStep(current);
    setBreak(component);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };
  const [BreakComponent, setBreak] = useState(
    <Second
      currentComponent={currentComponent}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  );

  useEffect(() => {
    StepperStore.setItem(
      user ? "currentStep" + user?.uid : "currentStep",
      activeStep,
      function () { }
    );
  }, [activeStep, user]);
  const getCurrent = async () => {
    try {
      const value = await StepperStore.getItem(
        user ? "currentStep" + user?.uid : "currentStep"
      );
      switch (value) {
        case null:
          setActiveStep(0);
          break;
        case 7:
          setActiveStep(0);
          break;
        default:
          setActiveStep(value);
          break;
      }
    } catch (err) {
      setActiveStep(0);
    }
  };
  useEffect(() => {
    getCurrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function _conditionalComponent(step: number) {
    switch (step) {
      case 0:
        return <First handleNext={handleNext} />;
      case 1:
        return (
          <Second
            currentComponent={currentComponent}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return BreakComponent;
      case 3:
        return <Third handleNext={handleNext} handleBack={handleBack} />;
      case 4:
        return (
          <Fourth handleNext={handleNext} handleBack={handleBack} />
        );
      case 5:
        return <Five handleNext={handleNext} handleBack={handleBack} />;
    }
  }
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
    },
  }));
  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "blue",
      height: 22,
      ...(ownerState.active && {
        color: "blue",
      }),
      "& .QontoStepIcon-completedIcon": {
        marginLeft: 80,
        marginTop: -28,
        color: "white",
        zIndEx: 1,
        fontSize: "24",
        backgroundColor: "#8CC63F",
        borderRadius: "50%",
        width: 30,
        height: 30,
      },
      "& .MuiStepLabel-root": {
        color: "gray !important",
      },
      "& .QontoStepIcon-circle": {
        marginLeft: 80,
        marginTop: -31,
        width: 30,
        height: 30,
        borderRadius: "50%",
      },
    })
  );
  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <div className="xl:w-7 lg:w-6 font-regular mt-1 ">
          <img src={icon1} alt='icon' />
        </div>
      ),
      2: (
        <div className="xl:w-7 lg:w-6 font-regular mt-1">
          <img src={icon2} alt='icon' />
        </div>
      ),
      3: (
        <div className="xl:w-7 lg:w-6 font-regular mt-1">
          <img src={icon3} alt='icon' />
        </div>
      ),
      4: (
        <div className="xl:w-7 lg:w-6 font-regular mt-1">
          <img src={icon4} alt='icon' />
        </div>
      ),
    };

    const steps: { [index: string]: React.ReactElement } = {
      1: (
        <p className=" font-sans font-regular  xl:text-[24px] lg:text-[20px] -mt-7">
          Quelle prestation ?
        </p>
      ),
      2: (
        <p className=" font-sans font-regular xl:text-[24px] lg:text-[20px]  -mt-7">
          Quel type de prestation ?
        </p>
      ),
      3: (
        <p className=" font-sans font-regular xl:text-[24px] lg:text-[20px]  -mt-7">
          Lieu de la prestation
        </p>
      ),
      4: (
        <p className=" font-sans font-regular xl:text-[24px] lg:text-[20px]  -mt-7">
          Presque fini !
        </p>
      ),
    };
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (

          <div className="flex space-x-4 ">
            <Check className="QontoStepIcon-completedIcon lg:w-7 border-gray-800 bg-first" />
            <div className="MuiStepLabel-root ">{steps[String(props.icon)]}</div>
          </div>
        ) : (
          <div className="flex space-x-4">
            <div className="QontoStepIcon-circle ">
              {icons[String(props.icon)]}
            </div>
            <div className="text-first">{steps[String(props.icon)]}</div>
          </div>
        )}
      </QontoStepIconRoot>
    );
  }
  const handleHome = () => {
    if (user) {
      navigate("/user/prestations");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="box-border list-teacher  h-screen //overflow-hidden  lg:flex md:h-screen font-sans">
      <div className="box-border  fixed z-40 items-center justify-between w-full p-4 font-regular bg-white  lg:hidden">
        <div className="mb-4 " onClick={() => handleHome()}>
          <img className="cursor-pointer w-[84px]" src={logo} alt="" />
        </div>
        <div className="mx-auto font-sans">
          <Stepper
            activeStep={
              activeStep >= 6
                ? activeStep - 2
                : activeStep === 3 || activeStep === 4
                  ? activeStep - 1
                  : activeStep
            }
          >
            {stepsTab.map((step, index) => (
              <Step key={index}>
                {index === 5 ? null : <StepLabel></StepLabel>}
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div className="shadow-xl  hidden h-screen overflow-hidden lg:w-1/3 bg-[#EDF0FB] lg:block text-md xl:text-xl">
        <div className="mt-[50px] w-[459px] h-[40px] cursor-pointer /ml-[58px]" onClick={() => handleHome()}>
          <img className="cursor-pointer -ml-4 w-full h-full" src={logo} alt="" />
        </div>
        <div className="">
          <Stepper
            sx={{ gap: "0", marginTop: "5rem" }}
            activeStep={activeStep === 2 || activeStep === 3 || activeStep === 4 ? activeStep - 1 : activeStep === 5 || activeStep === 6 ? activeStep - 2 : activeStep}
            orientation="vertical"
            connector={<QontoConnector />}
          >
            {stepsTab.map((step, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className=" /-translate-y-20">
          <img src={frameLayout} alt="" className="mx-auto w-[375px] /h-[437px]" />
        </div>
      </div>
      <div className="h-screen overflow-y-scroll overflow-x-hidden md:list-teacher lg:w-2/3 md:mt-8 lg:mt-0">
        {activeStep === stepsTab.length + 1 ? (
          <LastStep handleNext={handleNext} />
        ) : activeStep === stepsTab.length + 2 ? (
          <TimerClient />
        ) : (
          <div className=" w-full h-screen">
            <div className="w-4/5 mx-auto py-16">
              <h1 className="lg:text-[32px] lg:text-xl md:text-lg text-sm font-bold text-first ">RÉSERVER UNE PRESTATION</h1>
            </div>
            <div className="h-[75%]">
              {_conditionalComponent(activeStep)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
