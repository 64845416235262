import { useEffect, useState } from "react";
import profileImage from '../../../../Assets/Images/register-profile.svg'

import Input from "../../../../Components/utils/Input";
import SimpleSelect from "../../../../Components/utils/SimpleSelect";
import DateInput from "../../../../Components/utils/DateInput";
import { Button } from "@mui/material";
import validateName from "../../../../Validation/Name";
import validateSelect from "../../../../Validation/Select";
import validateDate from "../../../../Validation/Date";
import UploadButton from "../../../../Components/utils/UploadButton";
import { useNavigate } from "react-router-dom";
import StepperStore from "../../../../LocalForage/StepperStore";
import dayjs from 'dayjs';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, UpdateUserEmail } from "../../../../Services/FirebaseService";
import validateEmail from "../../../../Validations/Email";
import Functions from "../../../../Helpers/Functions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { validatePhoneNumber } from "../../../../Validation/Phone";
function First(props) {

  const generalTeachr = useSelector((state) => state.teachr)
  const dispatch = useDispatch()
  const [teachr, setTeachr] = useState()
  const [file, setFile] = useState();
  const [image, setImage] = useState(profileImage);
  const [erroImage, setErrorImage] = useState('')
  useEffect(() => {
    // console.log(generalTeachr);
    if (generalTeachr.user) {
      setTeachr(generalTeachr.user)
    }
    if (generalTeachr.file) {
      if (typeof (generalTeachr.file) === Blob | File) {
        setFile(generalTeachr.file)
        setImage(URL.createObjectURL(generalTeachr.file))
      }
    }
  }, [generalTeachr])
  useEffect(() => {
    if (teachr) {
      setfirstName(teachr.firstName)
      setLastName(teachr.lastName)
      setGender(teachr.sex)
      setBirthday(moment(teachr.birthday))
    }
  }, [teachr])
  const [user] = useAuthState(auth)
  const navigate = useNavigate();
  const getFile = (value, error) => {
    if (error) {
      setErrorImage('Mauvais format de fichier')
    } else {
      setErrorImage('')
      Functions.resizeFile(value, false).then((response) => {
        setFile(response);
        setImage(URL.createObjectURL(response))
      })
    }
  }
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('')
  const [showPhone, setShowPhone] = useState('')
  const [firstName, setfirstName] = useState("");
  const getFirstName = (value) => {
    setfirstName(value);
  };
  const getEmail = (value) => {
    setEmail(value);
  };
  const getPhone = (value) => {
    setPhone(value);
  };
  const [lastName, setLastName] = useState("");
  const getLastName = (value) => {
    setLastName(value);
  };
  const [gender, setGender] = useState("");
  const getGender = (value) => {
    switch (value) {
      case 1:
        setGender("WOMAN");
        break;

      default:
        setGender("MAN");
        break;
    }
  };
  const [birthday, setBirthday] = useState();
  const getBirthDay = (value) => {
    if (value !== "") {
      setBirthday(value);
    }
  };

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    bithday: "",
  });
  useEffect(() => {
    if (user) {
      if (user.providerData[0].providerId === "google.com") {
        setShowPhone(true)
      } else {
        setShowPhone(false)
      }
    }
  }, [user])
  const [errorEmail, setErrorEmail] = useState('')
  const [errorPhone, setErrorPhone] = useState(false)
  const validate = () => {
    let erremail = false
    let errfirstname = true;
    let errlastname = true;
    if (user?.providerData[0].providerId === 'phone') {
      erremail = true
      if (!validateEmail(email).isValid) {
        setErrorEmail(validateEmail(email).message)
        erremail = true
      } else {
        setErrorEmail(validateEmail(email).message)
        erremail = false
      }
    }
    if (user?.providerData[0].providerId === 'google.com') {
      if (validatePhoneNumber(phone) !== "") {
        setErrorPhone(true)
      } else {
        setErrorPhone(false)
      }
    }
    if (!validateName(firstName).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message
      });

      errfirstname = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message
      });
      errfirstname = false;
    }
    if (!validateName(lastName).isValid) {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message
      });
      errlastname = true;
    } else {
      setErrors({
        firstname: validateName(firstName).message,
        lastname: validateName(lastName).message
      });
      errlastname = false;
    }
    if (errlastname || errfirstname || erremail || erroImage !== "" || errorPhone) return false;
    else return true;
  };
  const handleSubmit = (event) => {
    if (validate() && user) {
      if (teachr) {
        teachr.firstName = firstName
        teachr.lastName = lastName
        if (user.providerData[0].providerId === "google.com") {
          teachr.phone = Functions.formatPhoneNumber(phone)
        }
        if (file) {
          dispatch({
            type: 'SET_INFO',
            payload: {
              teachr: teachr,
              file: file,
              authorization: ""
            }
          })
        } else {
          dispatch({
            type: 'SET_INFO',
            payload: {
              teachr: teachr,
              file: undefined,
              authorization: ""
            }
          })
        }
      }
      if (user.providerData[0].providerId === 'phone') {
        teachr.phone = user.phoneNumber ? Functions.formatPhoneNumber(user.phoneNumber) : ""
        UpdateUserEmail(email).then(() => {
          props.nextStep(3)
        }).catch((error) => {
          setErrorEmail('Ce mail est déjà utilisé')
        });
      } else {
        props.nextStep(3)
      }
    }
    event.preventDefault();
  };
  const renderGenderInput = () => {
    switch (teachr && teachr.sex) {
      case 'MAN':
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-[6px] /shadow-md /shadow-[rgba(0,0,0,25%)] text-[16px] '
          defaultValue={2}
        />)
      case "WOMAN":
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-[6px] /shadow-md /shadow-[rgba(0,0,0,25%)] text-[16px] '
          defaultValue={1}
        />)
      default:
        return (<SimpleSelect
          callback={getGender}
          label="Vous êtes"
          data={["Une femme", "Un homme"]} customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-[6px] /shadow-md /shadow-[rgba(0,0,0,25%)] text-[16px] '
        />)
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-start gap-6 xl:gap-8 p-4 md:w-4/5  md:mx-0 mx-4 lg:flex-row bg-[#FFFFFF] border-[#758FDA78] border-[1px] rounded-lg">
        <div className="w-full">
          <h1 className='text-base font-bold text-first xl:text-3xl lg:text-2xl md:text-lg' >Bienvenue parmi nous ! </h1>
          <h2 className='pt-4 text-lg font-bold text-first xl:text-xl lg:text-lg md:text-base' >Complétez votre profil : </h2>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col md:flex-row gap-x-4">
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.firstname === "" ? null : errors.firstname}
                </p>
                <Input
                  inset={true}
                  callback={getFirstName}
                  label="Prénom"
                  placeholder="John"
                  customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-[6px] //shadow-md //shadow-[rgba(0,0,0,25%)] text-[16px]'
                  defaultValue={teachr && teachr.firstName !== "" ? teachr.firstName : null}
                />
              </div>
              <div className="w-full">
                <p className="h-6 font-bold text-third">
                  {errors.lastname === "" ? null : errors.lastname}
                </p>
                <Input
                  inset={true}
                  callback={getLastName}
                  label="Nom"
                  placeholder="Doe"
                  customClass='bg-[#F4F7F8] border-[1px] border-[#80808050] py-[6px] //shadow-md //shadow-[rgba(0,0,0,25%)] text-[16px] '
                  defaultValue={teachr && teachr.lastName !== "" ? teachr.lastName : null}
                />
              </div>
            </div>
            
            <h2 className='pt-4 text-lg font-bold text-first xl:text-xl lg:text-lg md:text-base' >Ajoutez votre photo de profil : </h2>
            <div className="flex flex-col items-center pt-4 md:flex-row gap-x-4 md:gap-y-0 gap-y-2">
              <div className="">
                <div className="w-[90px] h-[90px] rounded-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${image})` }}></div>
              </div>
              <div className="flex flex-col w-full //gap-5">
                <p className="font-bold text-third ">{erroImage}</p>
                <UploadButton type="button" getFile={getFile} />
              </div>
            </div>
            <p className="pt-2 text-center">
              Si vous n'avez pas de photo, vous pouvez sauter cette étape pour le moment.
            </p>
            {user?.phoneNumber &&
              <div className="">
                <p className="h-6 font-bold text-third">
                  {errorEmail === "" ? null : errorEmail}
                </p>
                <Input
                  inset={true}
                  callback={getEmail}
                  label="E-mail"
                  placeholder="mail@mail.com"
                  customClass='bg-[#CACACA80] border-[1px] border-[#80808050] py-[6px] //shadow-md //shadow-[rgba(0,0,0,25%)] text-[16px]'
                />
              </div>}
            {showPhone &&
              <div className="">
                <p className="h-6 font-bold text-third">
                  {errorPhone ? validatePhoneNumber(phone) : ""}
                </p>
                <Input
                  inset={true}
                  callback={getPhone}
                  label="Numéro de téléphone"
                  placeholder="06 XX XX XX XX"
                  customClass='bg-[#CACACA80] border-[1px] border-[#80808050] py-[6px] //shadow-md //shadow-[rgba(0,0,0,25%)] text-[16px]'
                />
              </div>}
            <div className="flex flex-col py-3 gap-x-4 md:flex-row">
              <Button
                className="w-full px-4 py-2 font-bold text-white uppercase rounded-lg md:order-last font-nunito bg-first"
                onClick={handleSubmit}
              >
                suivant
              </Button>
              <Button
                className=" md:bg-first rounded-lg font-nunito font-bold py-2 md:text-white text-first  focus:border-solid border-[1px] border-none  uppercase px-4 w-full"
                onClick={() => props.prevStep(1)}
              >
                précédent
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="w-3/4">
          <p className="lg:leading-[61px] lg:text-[40px] leading-[30px] text-[18px] text-white font-bold whitespace-nowrap">
            Ajoutez votre photo de profil !{" "}
          </p>
          <p className="py-2 text-white">
            Augmentez vos chances d'être sélectionné par les parents ! Si vous
            n'avez pas de photo sous la main, vous pouvez sauter cette étape
            pour le moment.{" "}
          </p>
          <div className="flex flex-col items-center pt-8 md:flex-row gap-x-14 gap-y-8">
            <div className="p-8 pb-0 bg-left bg-no-repeat bg-contain bg-bg-profile">
              <div className="w-[130px] h-[130px] rounded-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${image})` }}></div>
            </div>
            <div className="flex flex-col w-full gap-5">
              <p className="font-bold text-third">{erroImage}</p>
              <UploadButton type="button" getFile={getFile} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default First;
