import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto";

const ComponentYearChart = (props) => {
    // const {durationByYear} = props
    // const chartRef = useRef(null);

    // useEffect(() => {
    //     const chartData = {
    //       labels: Object.values(durationByYear),
    //       datasets: [{
    //         label: 'heure de cours',
    //         data: Object.values(durationByYear),
    //         borderWidth: 0,
    //         backgroundColor: '#0B2775',
    //         borderColor: '#0B2775',
    //         borderRadius: 10,
    //         width: 40,
    //       }]
    //     };
    
    //     const chartConfig = {
    //       type: 'bar',
    //       data: chartData,
    //       options: {
    //         scales: {
    //           y: {
    //             display: false
    //           }
    //         },
    //         barPercentage: 0.3,
    //         // responsive: true,
    //       }
    //     };
    //     const canvas = chartRef.current;
    //     canvas.style.width = '50px'; // Set custom width
    //     canvas.style.height = '120px'; // Set custom height
    
    //     const ctx = canvas.getContext('2d');
    //     const myChart = new Chart(ctx, chartConfig)
    
    
    //     return () => {
    //       myChart.destroy();
    //     };
    //   }, [durationByYear]);
    
    return (
        <div>
            {/* <canvas className="" ref={chartRef} />
             */}
             <p className='md:text-xl font-regular text-center pt-6 ' >Cette version n'est pas encore disponible</p>
        </div>
    );
};

export default ComponentYearChart;