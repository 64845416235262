import { Button } from '@mui/material';
import React from 'react';

const Layout = (props:any) => {
    return (
        <div className='p-4 md:p-8 bg-white border-[rgba(0,0,0,0.1)]  border-[1px] shadow-dashboard-setup-intro-form lg:mt-10 rounded-xl relative lg:w-[90%] w-full'>
            {props.children}
            <div className={`${props.prev ? 'justify-between' : 'justify-end'} flex flex-col   md:flex-row gap-4`}>
                <div className='order-last md:order-first'>
                {props.prev}
                </div>
                <div>
                {props.next}
                </div>
            </div>
        </div>
    );
};

export default Layout;