



const ValidateSiret = (siret: string) => {
    if ((siret.length !== 14) || isNaN(Number(siret))) {
        return false; // SIRET number must be a 14 digit number
    }

    // We apply Luhn algorithm (or "modulus 10")
    let sum = 0;
    for (let counter = 0; counter < siret.length; counter++) {
        let digit: number;
        if ((counter % 2) === 0) { // For every 2nd digit starting from the end
            digit = 2 * Number(siret.charAt(counter)); // We multiply it by 2
            if (digit > 9) {
                digit -= 9; // If the result is > 9, we add the two digits of the result
            }
        } else {
            digit = Number(siret.charAt(counter));
        }
        sum += digit;
    }
    return ((sum % 10) === 0);

};

export default ValidateSiret;
