import { Button } from '@mui/material';
import React from 'react';

const Activity = (props: any) => {
    return (
        <div>
            <h2 className='text-base font-bold text-first lg:text-2xl'>Choix des activités </h2>
            <div className="flex flex-col pt-4 gap-y-2">
                <p className='text-sm font-regular text-first lg:text-lg lg:leading-[24.55px]'>
                    Choisissez ensuite services aux famille (+ de 3 ans) et spécifiquement : soutien scolaire ou cours à domicile. Vous n’avez pas à remplir les cases suivantes mais seulement à cliquer sur suivant.
                </p>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-between w-full py-8 pb-4 md:px-8">
                <Button onClick={() => props.getStep(1)} className='px-8 font-bold uppercase rounded-full text-[#B7B7B7] lg:text-xl'>PRÉCEDENT</Button>
                <Button onClick={() => props.getStep(3)} className='px-8 font-bold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
            </div>
        </div>
    );
};

export default Activity;