// import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Layout = (props: any) => {
    const [name, setName] = useState("")

    useEffect(() => {
        if (props.teachr) {
            setName(props.teachr)
        }
    }, [props])
    return (
        <div className='p-4 md:p-8 relative lg:w-[90%] w-full'>
            <h1 className='text-lg font-bold lg:text-3xl text-first'>En remplissant ces informations, vous bénéficierez de l'Avance Immédiate Crédit d'Impôt pour vos prestations avec <span className="capitalize"> {name} !</span></h1>
            <p className='my-8 text-base font-regular lg:text-lg text-gray'>Votre prestateur <span className="capitalize"> {name} </span> vous demande de compléter et vérifier ce formulaire afin de pouvoir déclarer vos prestations particulières auprès de l’URSSAF</p>
            <div className='p-4 md:p-8 bg-white border-[rgba(0,0,0,0.1)]  border-[1px] shadow-dashboard-setup-intro-form lg:mt-10 rounded-xl relative lg:w-[90%] w-full'>
                <h1 className='pb-8 text-base font-regular lg:text-2xl text-first lg:text-[1.2vw]'>Voici les informations remplies par <span className="capitalize"> {name}</span>, vous pouvez les valider ou les modifier en cliquant sur les champs correspondants. <br />
                Vous prenez des prestations pour vous-même ? Veuillez dans ce cas recopier les informations ci-après dans la catégorie "Client". </h1>
                {props.children}
                <div className={props.prev ? 'justify-between flex  flex-col md:flex-row gap-y-4 pt-4' : 'justify-end flex  flex-col md:flex-row pt-4'}>
                    <div className={`order-last w-full md:order-first`}>
                    {props.prev}
                    </div>
                    <div className='w-full md:w-max'>
                    {props.next}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;