import * as React from 'react';
import { useEffect, useState } from "react";
import Child from "../../../../../../../../Objects/models/Child";
import { Subject } from "../../../../../../../../Objects/models/Subject";
import SubjectRepository from "../../../../../../../../Repositories/SubjectRepository";
import ChildRepository from "../../../../../../../../Repositories/ChildRepository";
import moment from "moment";
import Functions from "../../../../../../../../Helpers/Functions";
import MultipleSelect from "../../../../../../../../Components/Utilitis/MultiSelect";
// import { TimePicker } from "antd";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { format } from 'date-fns';
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { InputBase } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import TeachrRepository from "../../../../../../../../Repositories/TeachrRepository";
import SubscriptionCourseProposalRepository from "../../../../../../../../Repositories/SubscriptionCourseProposalRepositorys";
import { SubscriptionCourseDashboardUpdate } from "../../../../../../../../Objects/models/SubscriptionCourseDashboardUpdate";
import SubscriptionCourseRepositorys from "../../../../../../../../Repositories/SubscriptionCourseRepository";
// import { SubscriptionCourseProposal } from "../../../../../../../Objects/models/SubscriptionCourseProposal";
import { SubscriptionCourseProposalDashboardUpdate } from "../../../../../../../../Objects/models/SubscriptionCourseProposalDahboardUpdate";

const dureeListe: Array<any> = [
    { label: "1h", value: 2 },
    { label: "1h30", value: 3 },
    { label: "2h", value: 4 },
    { label: "2h30", value: 5 },
    { label: "3h", value: 6 },
    { label: "3h30", value: 7 },
    { label: "4h", value: 8 },
];
const days = [
    {
        fr: 'Lundi',
        en: 'monday'
    },
    {
        fr: 'Mardi',
        en: 'tuesday'
    },
    {
        fr: 'Mercredi',
        en: 'wednesday'
    },
    {
        fr: 'Jeudi',
        en: 'thursday'
    },
    {
        fr: 'Vendredi',
        en: 'friday'
    },
    {
        fr: 'Samedi',
        en: 'saturday'
    },
    {
        fr: 'Dimanche',
        en: 'sunday'
    }
]
const translateDay = (day: string) => {
    const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
    if (translate.length >= 0) {
        return translate[0].fr
    }
    return ''

}
const dayss = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
};
const TeachrInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        fontSize: 14,
    },
}));
const TeachrInputs = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        fontSize: 17,
    },
}));
const Menu = styled(MenuItem)(({ theme }) => ({
    "&": {
        border: "2px solid #0B2775",
        borderRadius: "5px",
        color: "#0B2775",
        padding: ".5rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        margin: ".2rem",
        textAlign: "center !important",
    },
    "&.Mui-selected": {
        border: "2px solid #0B2775",
        borderRadius: "5px",
        color: "white",
        backgroundColor: "#0B2775",
    },
}));
const useStyles = makeStyles({
    select: {
        "& ul": {
            backgroundColor: "F2F5FB !important",
            display: "grid !important",
            gridTemplateColumns: "30% 30% 30% !important",
            gap: "10px !important",
            paddingLeft: "3.5rem ",
            fontFamily: "Nunito",
        },
        "& li": {
            fontSize: 15,
        },
    },
});
const useStylesMobile = makeStyles({
    select: {
        "& ul": {
            backgroundColor: "F2F5FB !important",
            display: "block !important",
            gridTemplateColumns: "30% 30% 30% !important",
            gap: "10px !important",
            paddingLeft: "3rem ",
            paddingRight: "2rem ",
            fontFamily: "Nunito",
        },
        "& li": {
            fontSize: 12,
        },
    },
});

const EditChoiceSubscription = (props: any) => {
    const { option } = props
    const [load, setLoad] = useState(false);
    const [subject, setSubject] = useState<Subject>()
    const [proposal, setProposal] = useState<any>()
    const [child, setChild] = useState<Child>()
    const [duration, setDuration] = useState<any>((option?.durationPerDay));
    const startTimes = moment().set({ hour: option.startTime.substr(0, 2), minute: option.startTime.substr(3, 5) });
    const [startTime, setStartTime] = useState<any>(startTimes.toDate());
    const price = proposal?.totalDailyPrice.toString()
    const [isEdits, setIsEdits] = useState(false)
    const [isEditsPrix, setIsEditsPrix] = useState(false)
    const [teachrPrice, setTeachrPrice] = useState<any>();
    const classes = useStyles();
    const classe = useStylesMobile()
    const [day, setDay] = useState([]);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setIsEdits(true)
        setDay(typeof value === "string" ? value.split(",") : value);
    };
    const getDuration = (value: any) => {
        setIsEdits(true)
        setDuration(value);
    };


    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubjectRepository.fetchSubjectById(option.subjectAsked_id).then((subject) => {
                    setSubject(subject)
                })
                SubscriptionCourseProposalRepository.fetchSubscriptionCourseProposalByIdTeachr(teachr.id, option.acceptedProposal_id).then((res) => {
                    setProposal(res)
                })
                ChildRepository.fetchChildByIdForTeachr(option.forChild_id, option.directAskedTeachrId).then((child) => {
                    setChild(child)
                })
            }
        })
    }, [option.acceptedProposal_id, option.directAskedTeachrId, option.forChild_id, option.subjectAsked_id])
    const updatePunctualCourse = () => {
        setLoad(true)
        if (isEdits) {
            TeachrRepository.getCurrentTeachr().then((teachr) => {
                setLoad(true)
                let startTimes = moment(startTime).format("HH:mm");
                const dataCourse = [
                    {
                        "startTime": startTimes === option.startTime ? option.startTime : startTimes,
                        "teachrPrice": teachrPrice,
                        "durationPerDay": duration === option?.durationPerDay ? duration : duration.value,
                    }
                ]
                const courseSub = new SubscriptionCourseDashboardUpdate(
                    day.length === 0 ? option.daysOfTheWeek : day,
                    option?.startDate,
                    // eslint-disable-next-line no-self-compare
                    startTimes === option.startTime ? option.startTime : startTimes,
                    duration === option?.durationPerDay ? duration : duration.value,
                    dataCourse
                )
                if (teachr.id) {
                    SubscriptionCourseRepositorys.updateSubscriptionCourseCourseByTeachr(teachr.id, option.id, courseSub).then((res) => {
                        setLoad(true)
                        const proposals = new SubscriptionCourseProposalDashboardUpdate(
                            isEditsPrix ? teachrPrice === undefined ? price : (teachrPrice).toString() : price,
                        )
                        SubscriptionCourseProposalRepository.updateSubscriptionCourseProposalCourseByTeachr(proposal?.id, proposals).then((res) => {
                            props.backStep(1);
                            setIsEdits(false)
                            setIsEditsPrix(false)
                        }).catch((err) => {
                            setLoad(false)
                        })
                    }).catch((err) => {
                        setLoad(false)
                    })

                }
            }).catch((err) => {
                setLoad(false)
            })
            setIsEdits(false)
        } else {
            props.backStep(1);
        }

        setLoad(false)
    }



    return (
        <>
            <div className="//h-[90%] border-[3px] border-[#D2D2D2] rounded-xl  hidden md:block " key={option.id}>
                <table className="w-full  rounded-xl  text-sm text-left font-regular   ">
                    <thead className="text-xs rounded-xl  //md:text-sm lg:text-base font-bold text-[#4d4d4d] //uppercase bg-gray-50 border-b-[2px] border-[#D2D2D2]">
                        <tr className=" w-full ">
                            <th scope="col " className="py-2 bg-white text-center rounded-tl-xl  px-1 w-[16%]  ">
                                Date du debut
                            </th>
                            <th scope="col " className="py-2 text-center px-1 w-[13%] ">
                                Élève
                            </th>
                            <th scope="col " className="py-2 text-center bg-white px-1 w-[16%] ">
                                Matière
                            </th>
                            <th
                                scope="col "
                                className="py-2 w-[12%] text-center //border-2"
                            >
                                <div className="//text-center">
                                    <p>Jours </p>
                                </div>
                            </th>
                            <th
                                scope="col "
                                className="py-2 bg-white"
                            >
                                <div className="text-center">
                                    <p>Rémunération</p>
                                </div>
                            </th>
                            <th scope="col " className="py-2 px-1 text-center w-[12%] ">
                                Durée
                            </th>
                            <th
                                scope="col "
                                className="py-2 bg-white rounded-tr-xl"
                            >
                                <div className="text-center">
                                    <p>Durée totale </p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-[#4D4D4D]">
                        <tr className="//border-b-white //border-b-8  rounded-xl w-full">
                            <td className="py-2 px-1  bg-white text-center rounded-bl-xl hidden lg:flex">{moment(option?.startDate).format('dddd DD/MM/YYYY')}</td>
                            <td className="py-2 px-1 text-center rounded-bl-xl lg:hidden">{moment(option?.startDate).format('DD/MM/YY')}</td>
                            <td className="py-2 px-1 text-center ">{child?.firstName}</td>
                            <td className="py-2 px-1 text-center bg-white">{subject?.fr_FR}</td>
                            <td className="py-2 px-1 text-center   //w-6 //border-2">{option?.daysOfTheWeek.map((day: string, key: any) => <span key={key} className="after:content-[','] last:after:content-['']">&#160;{translateDay(day)}</span>)}</td>
                            <td className="py-2 px-1 text-center  bg-white text-center //flex justify-around">
                                <div>{proposal?.totalDailyPrice}
                                </div>
                            </td>
                            <td className="py-2 px-1 text-center //text-center">{Functions.renderDuration(option?.durationPerDay)}</td>
                            <td className="py-2 px-1 bg-white rounded-br-xl text-center //text-center">{Functions.renderDuration(option?.durationPerDay * option?.daysOfTheWeek.length)}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <div className="">
                    <h1 className="lg:text-xl md:text-lg text-sm font-regular text-first">
                        Champs modifiables :{" "}
                    </h1>
                </div>
                <div className="">
                    <div className="mt-2 grid grid-cols-2 gap-4 md:px-10 md:space-x-2 //w-4/5 mx-auto  space-y-3 md:space-y-0 justify-between md:text-lg text-sm bold">
                        <div className=" ml-2 w-full">
                            <div>
                                <p className="text-[#4d4d4d] font-regular">Durée :</p>
                            </div>
                            <MultipleSelect
                                data={dureeListe}
                                callBack={getDuration}
                                placeholder="Ex : CE2"
                                value={duration === option?.durationPerDay ? Functions.renderDuration(option?.durationPerDay) : Functions.renderDuration(duration.value)}
                                customClass="text-sm border-2 border-first text-center "
                            />
                        </div>
                        <div className="w-full ">
                            <div>
                                <p className="text-[#4d4d4d] font-regular">
                                    Heure de cours :
                                </p>
                            </div>
                            <div className="border-2 border-first rounded-lg text-center py-2">
                                <DatePicker
                                    selected={startTime}
                                    // value={startTime}
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                        setIsEdits(true);
                                    }}

                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    timeCaption=""
                                    locale="fr"
                                    placeholderText="Sélectionner l'heure"
                                    customInput={<input className='//border-2 //text-center mx-auto w-[100%] //border-2 pl-4 py-2 bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                                    // timeInputLabel="Heure :"
                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                                        <div className="time-picker-header border-4 border-red-2 flex justify-between items-center h-12 bg-red-400 px-4">
                                            <button className="text-red-700 text-2xl" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{'<'}</button>
                                           
                                            <button className="text-red-700 text-2xl" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="text-sm rounded-md w-full font-sans lg:text-lg md:text-base text-sm">
                            <div>
                                <p className="text-[#4d4d4d] font-regular">Choisissez vos jours :</p>
                            </div>
                            <div className="relative border-2 px-3 py-3.5 rounded-md border-first  hidden  font-sans md:block">
                                <Select
                                    sx={{
                                        width: "100%",
                                        paddingLeft: "",
                                        fontFamily: "nunito",
                                        // background: "#F2F5FB !important",
                                    }}
                                    multiple
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    label={"Text"}
                                    value={day.length === 0 ? option.daysOfTheWeek : day}
                                    onChange={handleChange}
                                    input={<TeachrInputs />}
                                    placeholder="Lundi, Mardi, Mercredi..."
                                    className="text-xs"
                                >
                                    {Object.entries(dayss).map(([key, day]) => (
                                        <Menu
                                            key={day}
                                            value={key}
                                            // style={getStyles(day, day, theme)}
                                            placeholder="Lundi, Mardi, Mercredi..."
                                            className="text-sm //text-center border lg:text-lg md:text-base"
                                        >
                                            {day}
                                        </Menu>
                                    ))}
                                </Select>
                            </div>
                            <div className="relative mt-1  font-sans md:hidden">
                                <Select
                                    sx={{
                                        width: "100%",
                                        paddingLeft: "",
                                        fontFamily: "nunito",
                                        background: "#F2F5FB",
                                    }}
                                    multiple
                                    label={"Text"}
                                    value={day}
                                    onChange={handleChange}
                                    input={<TeachrInput />}
                                    MenuProps={{ classes: { paper: classe.select } }}
                                    placeholder="Lundi, Mardi, Mercredi..."
                                    className="text-xs displayDay"
                                >
                                    {Object.entries(dayss).map(([key, day]) => (
                                        <Menu
                                            key={day}
                                            value={key}
                                            // style={getStyles()}
                                            placeholder="Lundi, Mardi, Mercredi..."
                                            className="text-sm text-center border lg:text-lg md:text-base "
                                        >
                                            {day}
                                        </Menu>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="block md:w-full">
                            <div>
                                <p className="text-[#4d4d4d] font-regular">Prix horaire payé :</p>
                            </div>
                            <div className="border-2 border-first  rounded-lg w-full text-center py-4">
                                <input
                                    type="text"
                                    value={teachrPrice === undefined ? price : teachrPrice}
                                    className="//text-center pl-4  w-full"
                                    onChange={(e) => { setIsEditsPrix(true); setIsEdits(true); setTeachrPrice(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden flex justify-around space-x-4 pb-4 translate-y-6">
                <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-bold justify-center">
                    <div
                        onClick={() => {
                            props.backStep(1);
                        }}
                        className="text-xs pt-2 w-full text-center uppercase"
                    >
                        Précédent
                    </div>
                </div>
                <div className="flex justify-center w-1/2 ">
                    <div
                        onClick={() => {
                            updatePunctualCourse()
                        }}
                        className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
                    >
                        {load ? "chargement..." : "Enregistrer"}
                    </div>
                </div>
            </div>
            <div className=" hidden translate-y-10 p-2 -mt-1 md:flex justify-between  right-0 px-14">
                <div>
                    <button
                        onClick={() => {
                            props.backStep(1);
                        }}
                        className="py-2 font-bold text-first uppercase rounded-full text-sm px-14 bg-white border-2 border-first"
                    >
                        precedent
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => {
                            updatePunctualCourse()
                        }}
                        className="py-2 font-bold text-white uppercase rounded-full text-sm px-14 bg-first"
                    >
                        {load ? "chargement..." : "ENREGISTRER"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditChoiceSubscription;