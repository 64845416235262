import React, { useEffect } from "react";
import { useState } from "react";
import StepperStore from "../../../../../LocalForage/StepperStore";
import Functions from "../../../../../Helpers/Functions";
import moment from "moment";
import PunctualCourseRepository from "../../../../../Repositories/PunctualCourseRepository";
import { APIErrorEnum } from "../../../../../Objects/enums/APIErrorEnum";
import { PunctualCourseDashboard } from "../../../../../Objects/models/PunctualCourseDashboard";
import CourseProposal from "../../../../../Objects/models/CourseProposal";
import TeachrRepository from "../../../../../Repositories/TeachrRepository";
import CourseProposalRepository from "../../../../../Repositories/CourseProposal";
import ChildRepository from "../../../../../Repositories/ChildRepository";
import AddressRepository from "../../../../../Repositories/AddressRepository";
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import { auth } from "../../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { SubscriptionCourseDashboard } from "../../../../../Objects/models/SubscriptionCourseDashboard";
import SubscriptionCourseProposalRepository from "../../../../../Repositories/SubscriptionCourseProposalRepositorys";
import { SubscriptionCourseProposal } from "../../../../../Objects/models/SubscriptionCourseProposal";
import SubscriptionCourseRepositorys from "../../../../../Repositories/SubscriptionCourseRepository";
// import TeachrPutTva from "../../../../../Objects/models/TeachrPutTva";
import Swal from "sweetalert2";

const days = [
    {
        fr: 'Lundi',
        en: 'monday'
    },
    {
        fr: 'Mardi',
        en: 'tuesday'
    },
    {
        fr: 'Mercredi',
        en: 'wednesday'
    },
    {
        fr: 'Jeudi',
        en: 'thursday'
    },
    {
        fr: 'Vendredi',
        en: 'friday'
    },
    {
        fr: 'Samedi',
        en: 'saturday'
    },
    {
        fr: 'Dimanche',
        en: 'sunday'
    }
]
const translateDay = (day: string) => {
    const translate = days.filter((item: any) => item.en.toLowerCase() === day.toLowerCase())
    if (translate.length >= 0) {
        return translate[0].fr
    }
    return ''

}

const StepConfirmation = (props: any) => {
    const { handleChangeStep } = props
    const [user] = useAuthState(auth);
    const [datas, setDatas] = useState<any>({});
    const [dataPast, setDataPast] = useState<any>({});
    const [load, setLoad] = useState(false);
    const [datasPunctual, setDatasPunctual] = useState<any>({});
    const [child, setChild] = React.useState<any>();
    const [prices, setPrices] = useState<any>();
    const [cours, setCours] = useState<any>();
    // const [teachr, setTeachr] = useState<TeachrPutTva>()
    // const [datePunctual, setDatePunctual] = useState<any>();


    useEffect(() => {
        Teachr_Repository.getCurrentTeachr().then((res: any) => {
            // setTeachr(res)
            if (res.id) {
                PunctualCourseRepository.getPunctualCourseByTeachr(res.id).then(
                    (course) => {
                        // course.map((start: any) => {
                        //     // setDatePunctual(start);
                        // })

                    }
                );
            }
        })
    }, [])
    // const Swal = require("sweetalert2");
    let timerInterval: any;

    useEffect(() => {
        StepperStore.removeItem("activeStepundefined")
    }, [])
    useEffect(() => {
        (async () => {
            try {
                const cours: any = await StepperStore.getItem("cours" + user?.uid);
                const location: any = await StepperStore.getItem("location" + user?.uid);
                const price: any = await StepperStore.getItem("price" + user?.uid);
                const regulier: any = await StepperStore.getItem("regulier" + user?.uid);
                const punctual: any = await StepperStore.getItem("punctual" + user?.uid);
                const student: any = await StepperStore.getItem("student" + user?.uid);
                const dataPast: any = await StepperStore.getItem("dataPast" + user?.uid);
                setCours(cours.cours)

                if (price !== null) {
                    setPrices(((price.price) * 0.9 * 2).toString());
                }

                if (
                    location !== null &&
                    student != null &&
                    price !== null &&
                    regulier !== null
                ) {
                    setDatas({
                        cours: cours.cours,
                        student: student.value,
                        matiere: location.matiere.fr_FR,
                        price: price.price,
                        dateSub: regulier.date,
                        days: regulier.day,
                        startTime: regulier.startTime,
                        day: regulier.day,
                        hour: regulier.duration * 2,
                    });
                }
                if (
                    dataPast !== null
                ) {
                    setDataPast({
                        cours: cours.cours,
                        student: student.value,
                        matiere: dataPast.matiere.fr_FR,
                        price: price.price,
                        hour: dataPast.duration * 2,
                    });
                    // setPrices(((dataPast.price) * 0.9 * 2).toString());
                }

                if (
                    location !== null &&
                    student != null &&
                    price !== null &&
                    punctual !== null
                ) {
                    setDatasPunctual({
                        cours,
                        student: student.value,
                        matiere: location.matiere.fr_FR,
                        price: price.price,
                        datePunctual: punctual.date,
                        endTime: punctual.endTime,
                        startTime: punctual.startTime,
                        hour: punctual.duration * 2,
                    });
                }
            } catch (error) {
                console.warn(error);
            }
        })();
    }, [user?.uid]);


    useEffect(() => {
        // if (value !== "") {
        StepperStore.getItem("student" + user?.uid).then((student: any) => {
            if (student !== null) {
                const id = parseInt(student?.value);
                ChildRepository.fetchChildById(id)
                    .then((res) => {
                        setChild(res);
                    })
                    .catch((re) => console.warn(re));
            }
        });
        //  TeachrRepository.getCurrentTeachr().then((res) => {
        //     console.log(res);

        //  });

        // }
    }, [user?.uid]);


    const createSubscriptionCourse = async () => {
        setLoad(true)
        try {
            const location: any = await StepperStore.getItem("location" + user?.uid);
            const regulier: any = await StepperStore.getItem("regulier" + user?.uid);
            const teachrs = await TeachrRepository.getCurrentTeachr();
            const forChild_id = child.id;
            const isRemote = false
            // const address_id = address_id
            const extraInformation = ""
            const daysOfTheWeek = regulier.day
            const subjectAsked_id = location.matiere.id;
            let startTime = moment(regulier.startTime).format("HH:mm");
            let startDate = moment(regulier.date).format("Y-MM-DD");
            const durationPerDay = regulier.duration * 2
            const paymentMean = "CARD";
            const aiciActivated = true
            const isDirectCourse = true
            const teachrPrice = prices
            const dataCourse = [
                {
                    "startTime": startTime,
                    "teachrPrice": teachrPrice,
                    "durationPerDay": durationPerDay,
                }
            ]
            AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then((address) => {
                setLoad(true)
                address.map((address_id) => {
                    if (address_id.isDefault === true) {
                        if (address_id.id) {
                            const subscriptionCourse = new SubscriptionCourseDashboard(
                                forChild_id,
                                isRemote,
                                address_id.id,
                                subjectAsked_id,
                                daysOfTheWeek,
                                startDate,
                                startTime,
                                durationPerDay,
                                paymentMean,
                                extraInformation,
                                aiciActivated,
                                isDirectCourse,
                                teachrs.id,
                                dataCourse
                            )



                            if (teachrs.id) {
                                SubscriptionCourseRepositorys.createSubscriptionCourseByTeachr(subscriptionCourse, teachrs.id).then((course: any) => {
                                    setLoad(true)
                                    if (teachrs.id && course.id) {
                                        const teachrPrice = prices;
                                        //to-do
                                        const proposal = new SubscriptionCourseProposal(course.id, teachrs.id, teachrPrice)
                                        SubscriptionCourseProposalRepository.createSubscriptionCourseProposalByTeachr(proposal, teachrs.id, course.id).then((proposalCourse: any) => {
                                            setLoad(true)
                                            if (teachrs.id && proposalCourse.id) {
                                                SubscriptionCourseProposalRepository.tryAcceptSubCourseProposalByTeachr(teachrs.id, proposalCourse.id).then((accept) => {
                                                    setLoad(true)
                                                    handleChangeStep(8);

                                                }).catch((err) => {
                                                    console.warn(err);
                                                    setLoad(false)
                                                })
                                            }
                                        }).catch((err) => {
                                            console.warn(err);
                                            setLoad(false)
                                        })
                                    }
                                }).catch((err) => {
                                    console.warn(err);
                                    setLoad(false)
                                })


                            }

                        }
                    }
                })

            }).catch((error) => {
                setLoad(false)
                console.warn(error);
            })
        } catch (error) {
            setLoad(false)
            console.warn(error);
        }
    }
    const createPonctualCourse = async () => {
        setLoad(true)
        try {
            const location: any = await StepperStore.getItem("location" + user?.uid);
            const punctual: any = await StepperStore.getItem("punctual" + user?.uid);
            const teachrs: any = await TeachrRepository.getCurrentTeachr();
            const paymentMean = "CARD";
            const duration = punctual.duration * 2;
            const subjectAsked_id = location.matiere.id;
            const forChild_id = child.id;
            const isFiscalReductionEnabled = false;
            const chapter = "";
            const teachr_id = teachrs.id;
            const isRemote = false;
            const isCourseInTheHour = false;
            const isDirectCourse = true;
            let startTime = moment(punctual.startTime);
            let endTime = moment(punctual.endTime);
            let startDate = moment(punctual.date);
            let startDateTime = startDate
                .set("hour", startTime.hours())
                .set("minutes", startTime.minutes())
                .format("YYYY-MM-DD HH:mm");
            let endDateTime = startDate
                .set("hour", endTime.hours())
                .set("minutes", endTime.minutes())
                .format("YYYY-MM-DD HH:mm");
            const chapterExtraInformation = ""
            const globalExtraInformation = ""
            AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then(
                (addr) => {
                    setLoad(true)
                    addr.map((add) => {
                        if (add.isDefault === true) {
                            if (add.id) {
                                const course = new PunctualCourseDashboard(
                                    forChild_id,
                                    chapter,
                                    isRemote,
                                    add.id,
                                    subjectAsked_id,
                                    startDateTime,
                                    endDateTime,
                                    teachr_id,
                                    isCourseInTheHour,
                                    duration,
                                    isDirectCourse,
                                    isFiscalReductionEnabled,
                                    paymentMean,
                                    true,
                                    chapterExtraInformation,
                                    globalExtraInformation
                                );
                                PunctualCourseRepository.createPunctualCourseByTeacher(course)
                                    .then((res) => {
                                        setLoad(true)
                                        if (prices) {
                                            const punctual: any = `/punctual_courses/${res.id}`;
                                            const teachr = "/teachrs/" + teachrs.id;
                                            const teachrPrice = prices;

                                            const description = null;
                                            const proposal = new CourseProposal(
                                                teachr,
                                                punctual,
                                                startDateTime,
                                                endDateTime,
                                                teachrPrice,
                                                description
                                            );
                                            proposal.isFromTeachr = true;


                                            CourseProposalRepository.createCourseProposal(
                                                proposal
                                            ).then((res: any) => {
                                                setLoad(true)
                                                PunctualCourseRepository.tryAcceptPunctualCourseByTeachr(
                                                    res.punctualCourse.forChild.parent.id,
                                                    res.id
                                                )
                                                    .then((res) => {
                                                        if (teachrs.id) {
                                                            if (teachrs.isFirstCourse === false || teachrs.isFirstCourse === null || teachrs.isFirstCourse === undefined) {
                                                                PunctualCourseRepository.firstCourseDashboard(teachrs.id)
                                                            }
                                                            setLoad(true)
                                                            handleChangeStep(8);
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        console.warn(err)
                                                        setLoad(false)
                                                    });
                                            }).catch((error) => {
                                                setLoad(false)

                                                if (error._description === "The Teach'r has already an accepted course for this timeslot.") {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "Attention !",
                                                        text: "Vous avez déjà un cours prévu pour ce créneau horaire.",
                                                        timer: 5000,
                                                        willClose: () => {
                                                            clearInterval(timerInterval);
                                                        },
                                                    })
                                                } else
                                                    if (error._arrayInvalidAttributes[0].description === "This value should be between 11 and 250.") {
                                                        Swal.fire({
                                                            icon: "warning",
                                                            title: "Attention !",
                                                            text: "Le prix par heure doit se situer dans une fourchette allant de 11 à 250 euros.",
                                                            timer: 5000,
                                                            willClose: () => {
                                                                clearInterval(timerInterval);
                                                            },
                                                        })
                                                    }
                                                if (error._arrayInvalidAttributes[0].description === "This value should be between 9 and 23.") {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "Attention !",
                                                        text: "Le prix par heure doit se situer dans une fourchette allant de 9 à 23 euros.",
                                                        timer: 5000,
                                                        willClose: () => {
                                                            clearInterval(timerInterval);
                                                        },
                                                    })
                                                }
                                            }
                                            )
                                        }
                                    })
                                    .catch((error) => {
                                        console.warn(error)
                                        setLoad(false)
                                        switch (error.description) {
                                            case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous devez définir au moins une adresse dans votre compte.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous avez déjà un cours prévu à cette date",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous avez déjà trop de demandes de prestation en attente.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Il ne s'agit pas d'un Prestation dans l'heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Il s'agit d'un Prestation dans l'heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;

                                            default:
                                                break;
                                        }
                                    });
                            }
                        }
                    });
                }
            ).catch((err) => {
                console.warn(err)
                setLoad(false)
            }
            )
        } catch (error: any) {
            setLoad(false)
            console.warn(error);

        }
    };
    const createPonctualCoursePast = async () => {
        setLoad(true)
        try {
            const dataPast: any = await StepperStore.getItem("dataPast" + user?.uid);
            const teachrs = await TeachrRepository.getCurrentTeachr();
            const paymentMean = "CARD";
            const duration = dataPast.duration * 2;
            const subjectAsked_id = dataPast.matiere.id;
            const forChild_id = child.id;
            const isFiscalReductionEnabled = false;
            const chapter = "punctual_past";
            const teachr_id = teachrs.id;
            const isRemote = false;
            const isCourseInTheHour = false;
            const isDirectCourse = true;
            let startTime = moment();
            let endTime = moment().set({ hour: 23, minute: 59 });
            let startDate = moment();
            let startDateTime = startDate
                .set("hour", startTime.hours())
                .set("minutes", startTime.minutes())
                .format("YYYY-MM-DD HH:mm");
            let endDateTime = startDate
                .set("hour", endTime.hours())
                .set("minutes", endTime.minutes())
                .format("YYYY-MM-DD HH:mm");
            const chapterExtraInformation = dataPast.month
            const globalExtraInformation = dataPast.monthEnd
            AddressRepository.getClientAddresses(child.parent["@id"].substr(9)).then(
                (addr) => {
                    setLoad(true)
                    addr.map((add) => {
                        if (add.isDefault === true) {
                            if (add.id) {
                                const course = new PunctualCourseDashboard(
                                    forChild_id,
                                    chapter,
                                    isRemote,
                                    add.id,
                                    subjectAsked_id,
                                    startDateTime,
                                    endDateTime,
                                    teachr_id,
                                    isCourseInTheHour,
                                    duration,
                                    isDirectCourse,
                                    isFiscalReductionEnabled,
                                    paymentMean,
                                    true,
                                    chapterExtraInformation,
                                    globalExtraInformation
                                );

                                PunctualCourseRepository.createPunctualCourseByTeacher(course)
                                    .then((res) => {
                                        setLoad(true)
                                        if (prices) {
                                            const punctual: any = `/punctual_courses/${res.id}`;
                                            const teachr = "/teachrs/" + teachrs.id;
                                            const teachrPrice = prices;

                                            const description = null;
                                            const proposal = new CourseProposal(
                                                teachr,
                                                punctual,
                                                startDateTime,
                                                endDateTime,
                                                teachrPrice,
                                                description
                                            );
                                            proposal.isFromTeachr = true;


                                            CourseProposalRepository.createCourseProposal(
                                                proposal
                                            ).then((res: any) => {
                                                setLoad(true)
                                                PunctualCourseRepository.tryAcceptPunctualCourseByTeachr(
                                                    res.punctualCourse.forChild.parent.id,
                                                    res.id
                                                )
                                                    .then((res) => {
                                                        setLoad(true)
                                                        handleChangeStep(8);
                                                    })
                                                    .catch((err) => {
                                                        console.warn(err)
                                                        setLoad(false)
                                                    });
                                            }).catch((error) => {
                                                setLoad(false)

                                                if (error._description === "The Teach'r has already an accepted course for this timeslot.") {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "Attention !",
                                                        text: "Vous avez déjà un cours prévu pour ce créneau horaire.",
                                                        timer: 5000,
                                                        willClose: () => {
                                                            clearInterval(timerInterval);
                                                        },
                                                    })
                                                } else
                                                    if (error._arrayInvalidAttributes[0].description === "This value should be between 11 and 250.") {
                                                        Swal.fire({
                                                            icon: "warning",
                                                            title: "Attention !",
                                                            text: "Le prix par heure doit se situer dans une fourchette allant de 11 à 250 euros.",
                                                            timer: 5000,
                                                            willClose: () => {
                                                                clearInterval(timerInterval);
                                                            },
                                                        })
                                                    }
                                                if (error._arrayInvalidAttributes[0].description === "This value should be between 9 and 23.") {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "Attention !",
                                                        text: "Le prix par heure doit se situer dans une fourchette allant de 9 à 23 euros.",
                                                        timer: 5000,
                                                        willClose: () => {
                                                            clearInterval(timerInterval);
                                                        },
                                                    })
                                                }
                                            }
                                            )
                                        }
                                    })
                                    .catch((error) => {
                                        console.warn(error)
                                        setLoad(false)
                                        switch (error.description) {
                                            case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : est supérieure à la plage horaire définie.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous devez définir au moins une adresse dans votre compte.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous avez déjà un cours prévu à cette date",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous avez déjà trop de demandes de prestation en attente.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : ne peut pas dépasser les 12 heures.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "La Nombre d’heures par jour : ne peut pas être inférieure à 1 heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Il ne s'agit pas d'un Prestation dans l'heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Il s'agit d'un Prestation dans l'heure.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;
                                            case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: "Attention !",
                                                    text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
                                                    timer: 2000,
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                });
                                                break;

                                            default:
                                                break;
                                        }
                                    });
                            }
                        }
                    });
                }
            ).catch((err) => {
                console.warn(err)
                setLoad(false)
            }
            )
        } catch (error: any) {
            setLoad(false)
            console.warn(error);

        }
    };

    const handleNext = () => {
        StepperStore.setItem("type" + user?.uid, {
            cours: datas.cours ? datas.cours : dataPast.cours ? dataPast.cours : datasPunctual.cours,
        });
        switch (cours) {

            case "regulier":
                createSubscriptionCourse()
                break;
            case "punctual":
                createPonctualCourse()
                break;
            case "punctualPast":
                createPonctualCoursePast()
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        // if (cours === "punctualPast") {
        //     handleChangeStep(5)
        // } else {
        handleChangeStep(6)
        // }
    }


    return (
        <div className="overflow-y-auto list-teacher relative bg-white md:py-8 py-4 xl:px-14 px-4 md:h-[100%] h-[100%] rounded-[11px]">
            <div className="space-y-">
                <div className="//w-1/2">
                    <p className="//underline //underline-offset-[16px] text-first font-bold lg:text-xl text-lg //text-base">
                        Confirmation :
                    </p>
                    {/* <button onClick={createSubscriptionCourse}>Allez les lions</button> */}
                    <div className="//-translate-y-2 text-[#4d4d4d] lg:text-lg  md:text-base text-sm">
                        <span>Veuillez vérifier vos informations</span>
                    </div>
                </div>
                <div className=" mt-4  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Prestation :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {cours === "regulier"
                                ? datas.matiere :
                                cours === "punctualPast" ?
                                    dataPast.matiere
                                    : datasPunctual.matiere}
                        </strong>
                    </p>
                </div>
                <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Durée :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {cours === "regulier"
                                ? Functions.renderDuration(datas.hour) :
                                cours === "punctualPast"
                                    ? Functions.renderDuration(dataPast.hour)
                                    : Functions.renderDuration(datasPunctual.hour)}
                        </strong>{" "}
                    </p>
                </div>
                {/* <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Prestation :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            À domicile
                        </strong>{" "}
                    </p>
                </div> */}
                <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Type de prestation :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {cours === "regulier" ? "Régulière" : cours === "punctualPast" ? "Mes prestations du mois passé" : "Ponctuelle"}
                        </strong>{" "}
                    </p>
                </div>
                {cours === "punctualPast" ? "" : <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Date et Heure :
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {cours === "regulier"
                                ? moment(datas.dateSub).format(" dddd DD MMMM YYYY") + " à " + Functions.renderTime(datas.startTime)
                                : moment(datasPunctual.datePunctual).format("dddd DD MMMM YYYY") + " à " + Functions.renderTime(datasPunctual.startTime)}
                        </strong>{" "}
                    </p>
                </div>}
                {cours === 'regulier' && <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Tous les :{" "}
                        {datas?.days?.map((day: string, key: any) => <span key={key} className="after:content-[','] last:after:content-[''] lg:text-lg md:text-base text-sm font-normal text-first">&#160;{translateDay(day)}</span>)}
                    </p>
                </div>}
                <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Pour :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {/* {datas.cours === "regulier" ? datas.student : datasPunctual.student} */}
                            {child === undefined ? "chargement..." : child.firstName}
                        </strong>{" "}
                    </p>
                </div>
                <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Prix total payé par prestation :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {cours === "regulier" ? ((datas.price) * datas.hour / 2).toFixed(2) : cours === "punctualPast" ? ((dataPast.price) * dataPast.hour / 2).toFixed(2) : ((datasPunctual.price) * datasPunctual.hour / 2).toFixed(2)}€
                        </strong>{" "}
                    </p>
                </div>
                {datas.cours === "regulier" ? datas?.days?.length === 1 ? "" :
                    <div className="  space-x-2 md:flex">
                        <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                            Prix total payé par semaine :{" "}
                            <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                                {datas.cours === "regulier" ? (((datas.price) * datas.hour / 2) * datas?.days?.length).toFixed(2) : (((datasPunctual.price) * datasPunctual.hour / 2) * datas?.days?.length).toFixed(2)} €
                            </strong>{" "}
                        </p>
                    </div> : ''
                }
                <div className="  space-x-2 md:flex">
                    <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                        Votre rémunération totale par séance :{" "}
                        <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                            {datas.cours === "regulier" ? ((datas.price) * 0.9 * datas.hour / 2 * 2).toFixed(2) : cours === "punctualPast" ? ((dataPast.price) * dataPast.hour / 2 * 0.9 * 2).toFixed(2) : ((datasPunctual.price) * 0.9 * datasPunctual.hour / 2 * 2).toFixed(2)} €
                        </strong>{" "}
                    </p>
                </div>
                {cours === "regulier" ?
                    datas?.days?.length === 1 ? "" :
                        <div className="  space-x-2 md:flex">
                            <p className="lg:text-lg md:text-base text-sm //truncate font-regular text-[#4d4d4d]">
                                Votre rémunération totale par semaine :{" "}
                                <strong className="lg:text-lg md:text-base text-sm font-normal text-first ">
                                    {datas.cours === "regulier" ? (((datas.price) * 0.9 * datas.hour / 2 * 2) * datas?.days?.length).toFixed(2) : (((datasPunctual.price) * 0.9 * datasPunctual.hour / 2 * 2) * datas?.days?.length).toFixed(2)} €
                                </strong>{" "}
                            </p>
                        </div> : ""
                }
                <div className="lg:text-lg md:text-base text-sm text-black font-regular mt-2">
                    <p>
                        Votre client sera alerté directement par mail par l'Urssaf dès lors que la demande de paiement lui parviendra. Il aura alors deux jours pour contester ou valider le paiement.
                        Sans action de sa part pendant ces 48 heures, le paiement sera considéré comme automatiquement validé.
                        Vous recevrez alors votre paiement dans les 14 jours suivants le cours sur votre compte bancaire.{" "}
                    </p>
                </div>
            </div>
            <div className="md:hidden absolute inset-x-0 bottom-0 flex justify-around space-x-4 ">
                <div className="flex w-1/2 rounded-xl bg-white text-orange border-2 border-orange font-bold justify-center">
                    <div
                        onClick={() => {
                            handleBack();
                        }}
                        className="text-xs pt-2 w-full text-center uppercase"
                    >
                        Précédent
                    </div>
                </div>
                <div className="flex justify-center w-1/2 ">
                    <div
                        onClick={() => {
                            handleNext();
                        }}
                        className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-bold hover:font-bold text-xs border-first text-first uppercase rounded-xl active:bg-first active:text-[#4d4d4d]  "
                    >
                        Suivant
                    </div>
                </div>
            </div>
            <div className="hidden px-12 absolute inset-x-0 bottom-4 md:flex justify-between">
                <div>
                    <button
                        onClick={() => {
                            handleBack();
                        }}
                        className="py-2 font-bold text-first uppercase rounded-xl text-sm px-14 bg-white border-2 border-first"
                    >
                        precedent
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => {
                            handleNext();
                        }}
                        className="py-2 font-bold text-white uppercase rounded-xl border-2 border-first text-sm px-14 bg-first"
                    >
                        SUIVANT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StepConfirmation;
