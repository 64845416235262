import React, { useEffect, useState } from 'react';
import ChildRepository from '../../../../../Repositories/ChildRepository';

function AddSutentValid(props: any) {
    const { child } = props

    const [children, setChildren] = useState<any>()

    useEffect(() => {
        ChildRepository.getChildren(child.id).then((children) => {
            setChildren(children);

        })
    }, [])



    return (
        <>
            {child?.id_client_tiers_prestation !== null &&
                children?.map((child: any, key: any) => (
                    <div className="flex w-full" key={key}>
                        <div className=' //w-1/4 //md:pl-2 font-sans'>
                            <div className="border-2 w-[32px] h-[32px] rounded-full text-white bg-first text-center pt-1 uppercase font-bold ">
                                {child?.firstName === "" || child?.firstName === undefined ? child?.mail[0] : child?.firstName[0]}
                            </div>
                        </div>
                        <div className={child.idchildation === null ? 'font-sans w-2/4 lg:text-lg md:text-base text-base //pt-3 pt-1 pl-2 md:pl-2 text-[#4d4d4d] font-regular' : 'font-sans w-2/4 lg:text-lg md:text-base text-base //pt-3 pt-1 pl-2 md:pl-2 text-first font-regular'}>
                            {child?.firstName}
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default AddSutentValid;