import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';

const Sixth = (props:any) => {
    const [otherActivity, setOtherActivity] = useState<number>()
    const [error, setError] = useState(false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherActivity(parseInt((event.target as HTMLInputElement).value));
    };
    const [option, setOption] = useState('')
    const getOptions = (value: string) => {
        if (value && value !== '') {
            setOption(value)
        }
    }
    useEffect(()=>{
        props.callBack({
            otherActivity: otherActivity,
            activitiesOptions: option 
        })
        if ( otherActivity !== undefined) {
            setError(false)
            if (otherActivity === 1 ) {
                if (option !== '') {
                    props.nextStep(7) 
                }
            }else{
                props.nextStep(7) 
            }
            
         }else{
            setError(true)
         }
    },[otherActivity,option])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-4">
            {error ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-xl font-regular' htmlFor="">Souhaitez-vous effectuer une ou plusieurs autres activités de service à la personne ? <a href="https://www.servicesalapersonne.gouv.fr/beneficier-des-sap/activites-de-services-la-personne" target='_blank'> 👉 Liste des services à la personne 👈</a></label>
                <RadioGroup row
                    value={otherActivity}
                    name="customized-radios"
                    onChange={handleChange}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0B2775' bg='#0B2775' />} label="Non" />
                </RadioGroup>
            </div>
            {
                otherActivity === 1 && 
                <div className='flex flex-col gap-y-4'>
                    {option === '' ? <p className='text-third pb-1'>Vous devez sélectionner un élément</p> : null}
                <label htmlFor="" className='text-normal text-xl font-regular'>Précisez : </label>
                <SimplOptions  callback={getOptions} label="Veuillez sélectionner parmi la liste déroulante" data={[
                    'Entretien de la maison et travaux ménagers',
                    'Petits travaux de jardinage',
                    'Travaux de petit bricolage',
                    'Garde d’enfants de plus de 3 ans à domicile',
                    'Soutien scolaire ou cours à domicile',
                    'Soins d’esthétique à domicile pour les personnes dépendantes',
                    'Préparation de repas à domicile',
                    'Livraison de repas à domicile',
                    'Livraison de courses à domicile',
                    'Collecte et livraison à domicile de linge repassé',
                    'Assistance informatique à domicile',
                    'Soins et promenades d’animaux de compagnie pour les personnes dépendantes',
                    'Maintenance, entretien et vigilance temporaires à domicile',
                    'Assistance administrative à domicile',
                    'Accompagnement des enfants de plus de 3 ans dans leurs déplacements',
                    'Télé-assistance et visio-assistance',
                    'Interprète en langue des signes',
                    'Assistance aux personnes ayant besoin d’une aide temporaire à leur domicile',
                    'Conduite du véhicule des personnes en cas d’invalidité temporaire',
                    'Accompagnement des personnes présentant une invalidité temporaire',
                    'Coordination et délivrance des services à la personne',
                    'Garde d’enfants de moins de 3 ans et de moins de 18 ans handicapés à domicile',
                    'Accompagnement d’enfants de moins de 3 ans et de moins de 18 ans handicapés',
                    'Assistance aux personnes âgées et aux personnes handicapées',
                    'Conduite du véhicule des personnes ayant des difficultés de mobilité',
                    'Accompagnement des personnes en dehors de leur domicile',

                ]} customClass='bg-white py-[6px] text-lg border-normal' />
            </div>
            }
        </div>
    );
};

export default Sixth;