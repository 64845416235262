import { Box, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import NavBar from '../../../../Components/Dashboard/NavBar';
import Profile from './Profile';
import AccountInformation from './AccountInformation';
import AeScreen from './StatusAE/Index';
import LoaderContext from '../../../../Components/ComponentsUtils/LoadingContext';

const Index = () => {
    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index : number;
        value: number;
    }


    const [value, setValue] = useState(0);
    const theme = useTheme();
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
   
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ pt: 4 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div>
            <LoaderContext />
            <NavBar active={2} />
            <div className='lg:px-14 md:mt-8'>
                <div className="lg:px-4">
                    <Tabs sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#758FDA',
                            height: '4px'
                        }
                    }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab className='font-regular text-[#243862]' label="mon compte" />
                        <Tab className='font-regular text-[#243862]' label="Informations du compte" />
                        <Tab className='font-regular text-[#243862]' label="Facturation" />
                        <Tab className='font-regular text-[#243862]' label="Filtre géographique" />
                    </Tabs>
                </div>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Profile />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <AccountInformation />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <AeScreen />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    {/* <Zone /> */}
                </TabPanel>
            </div>
        </div>
    );
};

export default Index;