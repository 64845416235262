import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import eytanimage from "../../../../Assets/Images/eytan.png"
import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../../Components/utils/RadioButton';
import NavBar from '../../../../Components/ComponentsUtils/NavBar';
import validateEmail from '../../../../Validation/Email';
import { useLocation, useNavigate } from 'react-router-dom';
import InterestedTeachr from '../../../../Objects/models/InterestedTeachr';
import InterestedTeachrRepository from '../../../../Repositories/InterestedTeachrRepository';
import { auth } from '../../../../Services/FirebaseService';
import { signOut } from 'firebase/auth';
import ApiService from '../../../../Services/ApiService';
import { useSelector } from 'react-redux';
import Breadcrumb from './Breadcrumb';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../../Components/Admin/Input';
import 'swiper/css';
import { validatePhoneNumber } from '../../../../Validation/Phone';
import Functions from '../../../../Helpers/Functions';
import SimplOptions from './SelectOptions';
interface SimulatorResult {
    status: number
    weekHours: number
    hourlyPrice: number
    age: number
    netPricePerHour: number
    netPricePerMonth: number
    netPricePerYear: number
}

const Index = () => {
    const location = useLocation();
    const generalSimulator = useSelector((state: any) => state.simulator)
    useEffect(() => {
        if (generalSimulator) {


            setResult(generalSimulator)
        }
    }, [generalSimulator])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    useEffect(() => {
        signOut(auth).then(() => {
            ApiService.disableAuth()
        }).catch((error) => {
            // An error happened.
        });
    }, [])
    const navigate = useNavigate()
    const [isAe, setIsAe] = useState<boolean>()
    const [isSap, setIsSap] = useState<number>()
    const [wantProcuration, setWantProcuration] = useState<boolean>()
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [emailError, setEmailError] = useState(true)
    const [result, setResult] = useState<SimulatorResult>()
    const [active, setActive] = useState(1)
    const [preferenceForACall, setPreferenceForACall] = useState("")
    const [interestedTeachr, setInterestedTeachr] = useState<InterestedTeachr>()
    const [errorEmail, setErrorEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState<string>()
    const [errorPhone, setErrorPhone] = useState("")
    const [open, setOpen] = useState(false)
    const [option, setOption] = useState('')
    const [openRdvDial, setOpenRdvDial] = useState(false)
    const [index, setIndex] = useState(1)
    const [ipAddress, setIpAddress] = useState<string>()
    useEffect(() => {
        if (email !== "") {
            setEmailError(!validateEmail(email).isValid)
        }
        Functions.getClientIP().then((response) => {
            setIpAddress(response.data.ip)
        })
    }, [email])
    const getOptions = (value: string) => {
        if (value) {
            setOption(value)
        }
    }
    const handleClick = () => {
        if (!emailError) {
            const interestedTeachr = new InterestedTeachr("")
            interestedTeachr.mail = email
            interestedTeachr.isSap = isSap === 0 ? true : false
            interestedTeachr.isAe = isAe
            interestedTeachr.wantProcuration = wantProcuration
            if (ipAddress) {
                interestedTeachr.ipAddress = ipAddress
            }
            if (result) {
                interestedTeachr.age = result.age
                interestedTeachr.averageNumberHoursPerWeek = result.weekHours
                interestedTeachr.hourlyPrice = result.hourlyPrice
                interestedTeachr.netPricePerHour = result.netPricePerHour
                interestedTeachr.netPricePerMonth = result.netPricePerMonth
                interestedTeachr.netPricePerYear = result.netPricePerYear
                interestedTeachr.awareOfTeachrWith = option
                interestedTeachr.recommandedBy = name
                switch (result.status) {
                    case 1:
                        interestedTeachr.isAcre = true
                        break;
                    default:
                        interestedTeachr.isAcre = false
                        break;
                }
            }
            setEmailError(true)
            InterestedTeachrRepository.createInterestedTeachr(interestedTeachr).then(() => {
                setActive(3)
                if (isSap) {
                    goToSlide(3)
                } else {
                    if (wantProcuration) {
                        goToSlide(3)
                    } else {
                        goToSlide(6)
                    }
                }
            }).catch(() => {
                setEmailError(false)
            })
        }
    }
    const swiperRef = useRef<any>(null);

    const goToSlide = (index: number) => {
        setIndex(index)
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };
    const goBack = () => {
        switch (index) {
            case 1:
                navigate('/declarons/avance-immediate')
                break;
            case 2:
                goToSlide(1)
                setActive(1)
                break;
            case 3:
                goToSlide(2)
                setActive(1)
                break;
            case 4:
                goToSlide(2)
                setActive(1)
                break;
            case 5:
                goToSlide(4)
                setActive(2)
                break;
            case 6:
                goToSlide(4)
                setActive(2)
                break;
        
            default:
                navigate('/declarons/avance-immediate')
                break;
        }
    };

    const getPhoneNumber = (value: string) => {
        if (value) {
            setPhoneNumber(value)
        }
    }
    const getPreferenceForACall = (value: string) => {
        if (value) {
            setPreferenceForACall(value)
        }
    }
    const logout = () => {
        signOut(auth).then(() => {
            ApiService.disableAuth()
        }).catch((error) => {
            // An error happened.
        });

    }
    const createRdv = (phone: string) => {
        logout()
        const interestedTeachr = new InterestedTeachr(phone)
        interestedTeachr.preferenceForACall = preferenceForACall
        if (result) {
            interestedTeachr.age = result.age
            interestedTeachr.averageNumberHoursPerWeek = result.weekHours
            interestedTeachr.hourlyPrice = result.hourlyPrice
            interestedTeachr.netPricePerHour = result.netPricePerHour
            interestedTeachr.netPricePerMonth = result.netPricePerMonth
            interestedTeachr.netPricePerYear = result.netPricePerYear
            switch (result.status) {
                case 1:
                    interestedTeachr.isAcre = true
                    break;
                default:
                    interestedTeachr.isAcre = false
                    break;
            }
        }
        InterestedTeachrRepository.createInterestedTeachr(interestedTeachr).then((response) => {
            setInterestedTeachr(response)
            // setOpen(true)
        })
    }
    const updateRdv = () => {
        if (email !== "" && validateEmail(email).isValid) {
            setErrorEmail("")
            if (interestedTeachr) {
                interestedTeachr.mail = email
                InterestedTeachrRepository.updateInterestedTeachr(interestedTeachr).then(() => {
                    setOpen(false)
                }).catch((error) => {

                })
            }
        } else if (email !== "" && !validateEmail(email).isValid) {
            setErrorEmail(validateEmail(email).message)
        }

    }
    const validateRdv = () => {
        if (phoneNumber) {
            if (validatePhoneNumber(phoneNumber) === "") {
                setOpenRdvDial(false)
                createRdv(phoneNumber)
            } else {
                setErrorPhone(validatePhoneNumber(phoneNumber))
            }
        } else {
            setErrorPhone(validatePhoneNumber(""))
        }
    }
    return (
        <>
            <header className='lg:bg-white bg-[rgba(245,250,255,1)]'>
                <NavBar />
            </header>
            <main className='bg-[rgba(245,250,255,1)] px-4 md:px-8 lg:px-0 lg:h-[calc(100vh_-_5rem)] lg:py-20 py-10 h-[calc(100vh_-_3.5rem)] overflow-y-auto'>
                <div className="">
                    <div className='flex items-center justify-center pb-10 lg:pb-20 md:mx-auto lg:w-4/5 '>
                        <Breadcrumb active={active} />
                    </div>
                    <Swiper
                      
                        noSwiping
                        noSwipingClass='swiper-no-swiping'
                        ref={swiperRef}
                        slidesPerView={1}
                        loop
                        speed={700}
                        navigation={{
                            nextEl: ".next-slide"
                        }}
                        modules={[Navigation]}
                        effect="slide"
                    >
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div className='flex flex-col  md:mx-auto lg:w-4/5 gap-y-8 md:shadow-meet rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid md:bg-white lg:p-10'>
                                        <div className='md:bg-[#F5FAFF] lg:p-8 md:rounded-2xl'>
                                            <div className="flex items-center gap-x-4">
                                                <img src={eytanimage} alt="" />
                                                <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                            </div>
                                            <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] text-base flex flex-col lg:gap-y-8 lg:py-8 gap-y-4 py-4'>
                                                <p>Bonjour, je m’appelle Eytan. Je vais vous poser quelques questions afin de vous accompagner dans la mise à disposition de l’Avance Immédiate pour vos élèves !</p>
                                                <p>Avant de commencer, comment avez-vous entendu parler de nous ?</p>
                                            </div>
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="md:w-1/2">
                                                    <SimplOptions defaultValue={option} callback={getOptions} label="Veuillez sélectionner l’une des options suivantes :" data={[
                                                        'Directement contacté par nous',
                                                        'Recommandation par un tiers',
                                                        'Réseaux sociaux',
                                                        'Article sur internet',
                                                        'Recherche google',
                                                        'Recherche sur l’App Store ou Google Play',
                                                        'Publicité en ligne',
                                                        'Autre'

                                                    ]} customClass='bg-white py-[6px] text-lg border-normal' />
                                                </div>
                                                {
                                                    option.search("Directement contacté par nous") >= 0 || option.search('Recommandation par un tiers') >= 0 ?
                                                        <div className={`border-normal bg-white rounded-[9px] p-2  text-normal placeholder:text-[#848484]  md:w-1/2 lg:text-xl w-full  border-[1px] flex flex-col`}>
                                                            <label htmlFor="" className='text-black lg:text-lg'>Veuillez nous indiquer le nom de la personne concerné (optionnel)</label>
                                                            <input onChange={(e) => setName(e.target.value)} type="text" placeholder='Ex : Thomas E...' />
                                                        </div> : null
                                                }
                                            </div>

                                            <div className='flex justify-end pt-10'>
                                                <Button onClick={() => {
                                                    goToSlide(2);
                                                }} className='text-white lg:text-xl rounded-lg bg-first md:w-1/4 disabled:bg-[#D9D9D9] w-1/2 py-2' disabled={option !== "" ? false : true}>Suivant</Button>
                                            </div>
                                        </div>
                                    </div> : <div></div>
                            )}

                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div className='flex flex-col  md:mx-auto lg:w-4/5 gap-y-8 md:shadow-meet rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid md:bg-white lg:p-10'>
                                        <div className='md:bg-[#F5FAFF] lg:p-8 md:rounded-2xl'>
                                            <div className="flex items-center gap-x-4">
                                                <img src={eytanimage} alt="" />
                                                <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                            </div>
                                            <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] text-base flex flex-col lg:gap-y-8 lg:py-8 gap-y-4 py-4'>
                                                <p>Merci pour votre réponse.</p>
                                                <p> Commençons par évaluer votre situation. Bénéficiez-vous déjà d’une déclaration de Services à la Personne ?</p>
                                            </div>
                                            <RadioGroup row
                                                name="customized-radios"
                                                className='pl-1 md:mx-auto gap-x-4 w-max'
                                                value={isSap}
                                            >
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => setIsSap(0)} value={0} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Oui" />
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => setIsSap(1)} value={1} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Non" />
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => setIsSap(2)} value={2} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Je ne sais pas" />
                                            </RadioGroup>
                                        </div>
                                        <div className={`${isSap && isSap >= 1 ? 'block' : 'hidden'} bg-[#F5FAFF] rounded-2xl lg:p-4`}>
                                            <p className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] md:pb-8'>Disposez-vous actuellement d’un statut auto-entrepreneur ou d’une société afin de facturer vos prestations de cours particuliers ?</p>
                                            <RadioGroup row
                                                name="customized-radios"
                                                className='pl-1 md:mx-auto gap-x-4 w-max'
                                            >
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => { setIsAe(true); goToSlide(4); setActive(2); }} value={true} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Oui" />
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => { setIsAe(false); goToSlide(4); setActive(2); }} value={false} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Non" />
                                            </RadioGroup>
                                        </div>
                                        <div className={`${isSap === 0 ? 'block' : 'hidden'} md:bg-[#F5FAFF] md:rounded-2xl lg:p-4`}>
                                            <div className='flex flex-col gap-y-4 md:bg-[#F5FAFF] md:rounded-2xl'>
                                                <p className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px]'>Sur quelle adresse mail puis-je vous faire parvenir nos conditions de services ?</p>
                                                {emailError && email !== "" && <p className='text-third'>{validateEmail(email).message}</p>}

                                                <div className='flex flex-col items-center md:flex-row md:justify-between gap-y-8'>
                                                    <div className={`${emailError ? 'border-normal' : 'border-first'} bg-white rounded-[9px] p-2  text-normal placeholder:text-[#848484]  md:w-1/2 lg:text-xl w-full  border-[1px] flex flex-col`}>
                                                        <label htmlFor="" className='text-black lg:text-lg'>E-mail</label>
                                                        <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder='email@example.com' value={email} />
                                                    </div>
                                                    <Button onClick={handleClick} className='text-white md:text-[16px] rounded-lg bg-first md:w-1/4 disabled:bg-[#D9D9D9] w-1/2 py-2' disabled={emailError}>envoyer</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <div></div>
                            )}

                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div>
                                        <div className='flex flex-col  md:mx-auto lg:w-4/5 gap-y-8 md:shadow-meet rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid md:bg-white lg:p-10'>
                                            <div className='bg-[#F5FAFF] lg:p-8 rounded-2xl'>
                                                <div className="flex items-center gap-x-4">
                                                    <img src={eytanimage} alt="" />
                                                    <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                                </div>
                                                <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] flex flex-col gap-y-8 py-8'>
                                                    <p>Parfait ! Un mail contenant nos conditions de services vient de vous être envoyé sur votre adresse mail {email} <br />
                                                        S’il n’apparait pas dans votre boite de réception, pensez à consulter vos spams 😉</p>

                                                    <p>Une fois lues et signées, vous pouvez nous les retourner en réponse à notre mail.
                                                        Veuillez également joindre votre déclaration de Services à la Personne afin que votre dossier soit complet.</p>

                                                    <p>Si vous avez des questions, l’un de mes collègues ou moi-même serons ravis de vous éclairer.
                                                        Vous pouvez nous écrire en réponse au mail que vous venez de recevoir, ou solliciter l’aide d’un conseiller en cliquant sur le téléphone en bas à droite.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='py-8'>
                                            <div className="flex justify-center ">
                                                <Button className='text-white text-[16px] rounded-lg bg-first lg:w-[12%] w-3/4 disabled:bg-[#D9D9D9] font-bold' onClick={() => navigate('/declarons/avance-immediate')}>accueil</Button>
                                            </div>
                                            <a href="../../../../Assets/Download/Conditions_de_services_PDF.pdf" download target='_blank' className='block pt-2 text-xs text-center underline lg:text-base text-normal'>Vous n’avez pas reçu le tutoriel ? Cliquez ici pour le télécharger</a>
                                        </div>
                                    </div>
                                    : <div></div>
                            )}


                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div className='flex flex-col  md:mx-auto lg:w-4/5 md:gap-y-8 gap-y-4 md:shadow-meet md:rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid md:bg-white lg:p-10'>
                                        <div className="md:bg-[#F5FAFF] lg:p-8 md:rounded-2xl">
                                            <div className="flex items-center gap-x-4">
                                                <img src={eytanimage} alt="" />
                                                <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                            </div>
                                            <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] md:py-8 py-4'>
                                                <p>Parfait ! En ce moment, notre équipe propose de réaliser les démarches administratives à votre place, gratuitement.
                                                    <br />
                                                    Souhaitez-vous en profiter ?</p>
                                            </div>
                                            <RadioGroup row
                                                className='pl-1 md:mx-auto gap-x-4 w-max'
                                                name="customized-radios"
                                            >
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => setWantProcuration(true)} value={true} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Oui" />
                                                <FormControlLabel className='text-xs lg:text-xl' sx={{
                                                    '& .MuiTypography-root': {
                                                        fontFamily: 'nunito',
                                                        color: '#4D4D4D',
                                                        fontWeight: 'bold',
                                                        fontSize: 'inherit'
                                                    }
                                                }} onClick={() => setWantProcuration(false)} value={false} control={<RadioButton border='2px solid #0B2775' bg='#0B2775' />} label="Non, je préfère m’en occuper moi-même" />
                                            </RadioGroup>
                                        </div>
                                        <div className={wantProcuration || wantProcuration === false ? 'block' : 'hidden'}>
                                            <div className='flex flex-col gap-y-8 bg-[#F5FAFF] rounded-2xl lg:p-4 '>
                                                {
                                                    !wantProcuration === true ?
                                                        <label htmlFor="" className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px]'>C’est bien noté ! Sur quelle adresse mail souhaitez-vous recevoir notre tutoriel ?</label>
                                                        :
                                                        <label htmlFor="" className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px]'>C’est bien noté ! Nous allons vous faire parvenir une procuration. Sur quelle adresse mail souhaitez-vous la recevoir ?</label>
                                                }
                                                {emailError && email !== "" && <p className='text-third'>{validateEmail(email).message}</p>}
                                                <div className='flex flex-col items-center md:flex-row md:justify-between gap-y-8'>
                                                    <div className={`${emailError ? 'border-normal' : 'border-first'} bg-white rounded-[9px] p-2  text-normal placeholder:text-[#848484]  md:w-1/2 lg:text-xl w-full  border-[1px] flex flex-col`}>
                                                        <label htmlFor="" className='text-black lg:text-lg'>E-mail</label>
                                                        <input className="" onChange={(e) => setEmail(e.target.value)} type="text" placeholder='email@example.com' value={email} />
                                                    </div>
                                                    <Button onClick={handleClick} className='text-white md:text-[16px] rounded-lg bg-first md:w-1/4 disabled:bg-[#D9D9D9] w-1/2 py-2' disabled={emailError}>envoyer</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <div></div>
                            )}

                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div>
                                        <div className='flex flex-col  md:mx-auto lg:w-4/5 gap-y-8 shadow-meet rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid bg-white lg:p-10'>
                                            <div className='bg-[#F5FAFF] lg:p-8 rounded-2xl'>
                                                <div className="flex items-center gap-x-4">
                                                    <img src={eytanimage} alt="" />
                                                    <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                                </div>
                                                <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] flex flex-col gap-y-8 py-8'>
                                                    <p>Parfait ! Un mail contenant une procuration vient de vous être envoyé sur votre adresse mail {email} <br />
                                                        Si elle n’apparait pas dans votre boite de réception, pensez à consulter vos spams 😉</p>

                                                    <p>
                                                        Une fois le document complété et signé, vous pouvez nous le renvoyer en réponse à notre mail.
                                                        Notre équipe prendra alors en charge les démarches administratives dans les meilleurs délais afin que vous puissiez proposer l’Avance Immédiate à vos élèves. Nous vous tiendrons informés de l’avancée de votre dossier régulièrement.
                                                    </p>

                                                    <p>
                                                        Si vous avez des questions, l’un de mes collègues ou moi-même serons ravis de vous éclairer.
                                                        Vous pouvez nous écrire en réponse au mail que vous venez de recevoir, ou solliciter l’aide d’un conseiller en cliquant sur le téléphone en bas à droite.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='py-8'>
                                            <div className="flex justify-center ">
                                                <Button className='text-white text-[16px] rounded-lg bg-first lg:w-[12%] w-3/4 disabled:bg-[#D9D9D9] font-bold' onClick={() => navigate('/declarons/avance-immediate')}>accueil</Button>
                                            </div>
                                            <a href='https://drive.google.com/drive/folders/1YgNj7vlY1knRvTGSINhBwmdGfqEYOvky' target='_blank' className='block pt-2 text-xs text-center underline lg:text-base text-normal'>Vous n’avez pas reçu le tutoriel ? Cliquez ici pour le télécharger</a>
                                        </div>
                                    </div>
                                    : <div></div>

                            )}


                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                isActive ?
                                    <div>
                                        <div className='flex flex-col  md:mx-auto lg:w-4/5 gap-y-8 shadow-meet rounded-[27px] border-[rgba(33,156,255,0.13)] border-solid bg-white lg:p-10'>
                                            <div className='bg-[#F5FAFF] lg:p-8 rounded-2xl'>
                                                <div className="flex items-center gap-x-4">
                                                    <img src={eytanimage} alt="" />
                                                    <p className='text-lg font-bold text-normal lg:text-2xl'>Eytan</p>
                                                </div>
                                                <div className='font-regular text-normal lg:text-[22px] lg:leading-[30.01px] flex flex-col gap-y-8 py-8'>
                                                    <p>Parfait ! Un mail contenant notre tutoriel vient de vous être envoyé sur votre adresse mail {email} <br />
                                                        S’il n’apparait pas dans votre boite de réception, pensez à consulter vos spams 😉</p>

                                                    <p>Une fois les étapes réalisées, vous pouvez nous le faire savoir en réponse à notre mail.
                                                        Cela nous permettra de vous contacter au moment opportun pour vous présenter notre outil de déclaration de cours.</p>

                                                    <p>Si vous avez des questions, l’un de mes collègues ou moi-même serons ravis de vous éclairer.
                                                        Vous pouvez nous écrire en réponse au mail que vous venez de recevoir, ou solliciter l’aide d’un conseiller en cliquant sur le téléphone en bas à droite.</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div className='py-8'>
                                            <div className="flex justify-center ">
                                                <Button className='text-white text-[16px] rounded-lg bg-first w-3/4 lg:w-[12%] disabled:bg-[#D9D9D9] font-bold' onClick={() => navigate('/declarons/avance-immediate')}>accueil</Button>
                                            </div>
                                            <a href={isAe ? "../../../../Assets/Download/Pouvoir_creation_nova.pdf" : "../../../../Assets/Download/Pouvoir_procuration_creation_auto-entreprise-TeachR_VDEF.pdf"} download target='_blank' className='block pt-2 text-xs text-center underline lg:text-base text-normal'>Vous n’avez pas reçu le tutoriel ? Cliquez ici pour le télécharger</a>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                            )}


                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='relative bottom-0 left-0 z-50 w-full px-0 py-8 pb-4 md:pb-8 lg:pb-0'>
                    <div className='relative md:mx-auto lg:w-4/5'>
                        <Button className='flex items-center px-0 text-sm font-regular rounded-full cursor-pointer lg:px-4 w-max disabled:opacity-50 text-first gap-x-3 lg:text-xl' onClick={goBack} >
                            <span className='lg:hidden'>
                                <svg width="10" height="20" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-0.00191071 11.4951C-0.00191069 11.0353 0.174283 10.5988 0.496027 10.2715L10.1024 0.505627C10.7689 -0.172449 11.8413 -0.172449 12.5001 0.505627C13.1666 1.1837 13.1666 2.27486 12.5001 2.94514L4.0965 11.4951L12.5001 20.0451C13.1666 20.7232 13.1666 21.8143 12.5001 22.4846C11.8337 23.1627 10.7612 23.1627 10.1024 22.4846L0.496026 12.7188C0.174282 12.3914 -0.00191073 11.955 -0.00191071 11.4951Z" fill="#0B2775" />
                                </svg>
                            </span>
                            <span className='hidden lg:inline-block'>
                                <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-0.00191071 11.4951C-0.00191069 11.0353 0.174283 10.5988 0.496027 10.2715L10.1024 0.505627C10.7689 -0.172449 11.8413 -0.172449 12.5001 0.505627C13.1666 1.1837 13.1666 2.27486 12.5001 2.94514L4.0965 11.4951L12.5001 20.0451C13.1666 20.7232 13.1666 21.8143 12.5001 22.4846C11.8337 23.1627 10.7612 23.1627 10.1024 22.4846L0.496026 12.7188C0.174282 12.3914 -0.00191073 11.955 -0.00191071 11.4951Z" fill="#0B2775" />
                                </svg>
                            </span>
                            <span>précédent</span>
                        </Button>
                        <div className='fixed z-10 cursor-pointer lg:right-10 right-4 bottom-4 btn-rdv-appel lg:bottom-10' onClick={() => setOpenRdvDial(true)}>
                            <div className='hidden p-2 rounded-full shadow-call-btn bg-first w-max lg:block'>
                                <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.8381 45.657C27.5034 45.657 25.4929 44.9436 24.5851 44.5546C18.4242 41.8955 12.5873 36.1888 12.3279 35.9291C8.82605 32.4273 5.84258 28.6006 3.50787 24.5798C2.14573 22.3098 1.17317 19.8457 0.524404 16.7976C-0.513348 12.1928 0.265189 9.66354 0.524404 8.88553C0.978595 7.52339 2.21046 6.03188 2.92385 5.44832H2.98854L7.13913 2.14094C7.98236 1.49222 9.14946 1.62206 9.86296 2.40015L18.0999 11.8037C18.4242 12.1928 18.6187 12.7116 18.6187 13.2952C18.5541 13.8141 18.2944 14.333 17.9053 14.6574L14.8573 16.9921C14.4682 17.3164 13.9493 17.9 13.8195 18.5487C13.7548 19.0029 13.8842 19.3268 14.014 19.7158C15.1811 23.0232 18.2944 26.3956 18.2944 26.3956C18.2944 26.3956 20.6291 28.9249 23.4176 30.4815C23.7419 30.676 24.131 30.8705 24.6499 30.8705C25.2986 30.9352 25.817 30.8059 26.1414 30.4815L29.4488 27.6931C30.2269 26.9796 31.4591 27.1095 32.1726 27.8876L40.344 37.0967C41.0574 37.8748 40.9927 39.107 40.2142 39.8851C39.76 40.2742 39.3062 40.7284 38.7874 41.1821C37.6849 42.2198 36.6472 43.1924 35.8039 43.8412C34.3133 45.2029 31.7193 45.6571 29.8384 45.6571L29.8381 45.657ZM14.0788 34.1132C14.2733 34.3077 19.9153 39.7553 25.5579 42.2198C26.2066 42.479 27.828 43.1277 29.8383 43.1277C31.6542 43.1277 33.47 42.6735 34.3133 41.9606H34.378C35.0914 41.377 36.1938 40.404 37.1664 39.4313C37.4908 39.107 37.8798 38.7826 38.2042 38.4587L30.6811 30.0272L27.8275 32.4918C26.9842 33.2052 25.8817 33.5295 24.5848 33.4644C23.4177 33.3997 22.5093 32.9455 22.1854 32.751C19.0725 30.9998 16.608 28.276 16.4782 28.2114C16.3483 28.0816 13.041 24.4499 11.6789 20.6883C11.4844 20.1694 11.2248 19.3262 11.4197 18.2889C11.6143 17.1218 12.263 16.0188 13.3654 15.111L15.8947 13.1006L8.17708 4.47519L4.48023 7.39346C3.96134 7.84765 3.11808 8.88496 2.8589 9.66355C2.66438 10.3123 2.01567 12.3226 2.92359 16.2136C3.57231 18.9374 4.41509 21.2074 5.64742 23.2177C7.91785 27.1737 10.7063 30.8054 14.0788 34.1132Z" fill="white" />
                                    <path d="M43.5222 19.7155H43.4575C42.7441 19.6509 42.2252 19.0668 42.2904 18.3534C42.5496 14.2675 41.1233 10.3115 38.2696 7.3285C35.5458 4.34503 31.7843 2.65909 27.7636 2.52927C27.0502 2.52927 26.5312 1.94569 26.5312 1.23232C26.5312 0.518916 27.1148 0 27.8282 0C32.5623 0.129833 36.9074 2.14015 40.0854 5.57736C43.3281 9.07919 45.0141 13.684 44.7548 18.4831C44.7544 19.1965 44.2355 19.7154 43.522 19.7154L43.5222 19.7155Z" fill="white" />
                                    <path d="M38.1414 20.1693C37.3633 20.1693 36.8445 19.5206 36.8445 18.8724C36.9743 16.0187 35.9365 13.3596 34.056 11.2846C32.1755 9.27422 29.6462 8.0419 26.8573 7.91208C26.1439 7.84739 25.625 7.26336 25.625 6.61514C25.6897 5.90173 26.2737 5.38281 26.9219 5.38281C30.3591 5.57734 33.5371 7.00414 35.8718 9.5334C38.2712 12.1273 39.5034 15.4351 39.3736 18.937C39.3736 19.5857 38.8548 20.1693 38.1414 20.1693Z" fill="white" />
                                    <path d="M32.6933 20.4937C31.9799 20.4937 31.3963 19.9101 31.3963 19.1968C31.3963 17.6402 30.8128 16.2133 29.775 15.0462C28.7372 13.9437 27.3756 13.295 25.8836 13.1657C25.1702 13.101 24.6513 12.5169 24.7165 11.8035C24.7812 11.0901 25.3652 10.5712 26.0786 10.6364C28.2188 10.8309 30.1645 11.8035 31.656 13.3602C33.1475 14.9815 33.9261 17.0571 33.9261 19.262C33.9903 19.9749 33.4067 20.4938 32.6933 20.4938V20.4937Z" fill="white" />
                                </svg>
                            </div>
                            <div className='p-2 rounded-full shadow-call-btn bg-first w-max lg:hidden'>
                                <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.1593 34.1008C20.4352 34.1008 18.9505 33.574 18.2801 33.2867C13.7305 31.3231 9.42023 27.1089 9.22866 26.9172C6.6427 24.3312 4.43952 21.5054 2.71543 18.5362C1.70954 16.8598 0.991338 15.0402 0.512252 12.7893C-0.254088 9.38882 0.320832 7.52105 0.512252 6.94653C0.847655 5.94063 1.75734 4.83922 2.28415 4.40828H2.33192L5.39697 1.9659C6.01966 1.48685 6.88152 1.58273 7.40841 2.15732L13.4911 9.10149C13.7306 9.38879 13.8742 9.77196 13.8742 10.2029C13.8265 10.5861 13.6347 10.9693 13.3474 11.2088L11.0965 12.9329C10.8092 13.1724 10.426 13.6034 10.3302 14.0824C10.2824 14.4178 10.3779 14.657 10.4738 14.9442C11.3357 17.3866 13.6347 19.8771 13.6347 19.8771C13.6347 19.8771 15.3588 21.7448 17.418 22.8943C17.6575 23.038 17.9448 23.1816 18.328 23.1816C18.8071 23.2294 19.1899 23.1338 19.4294 22.8943L21.8718 20.8352C22.4464 20.3083 23.3564 20.4042 23.8832 20.9788L29.9175 27.7794C30.4443 28.354 30.3965 29.2639 29.8216 29.8385C29.4862 30.1258 29.1511 30.4612 28.768 30.7963C27.9539 31.5626 27.1876 32.2808 26.5648 32.7599C25.4641 33.7655 23.5485 34.1009 22.1595 34.1009L22.1593 34.1008ZM10.5216 25.5762C10.6653 25.7198 14.8317 29.7426 18.9985 31.5626C19.4776 31.754 20.6749 32.2331 22.1594 32.2331C23.5004 32.2331 24.8413 31.8977 25.464 31.3712H25.5118C26.0386 30.9402 26.8528 30.2217 27.571 29.5034C27.8105 29.2639 28.0978 29.0244 28.3373 28.7852L22.7818 22.5589L20.6745 24.3788C20.0518 24.9057 19.2377 25.1452 18.2799 25.0971C17.4181 25.0493 16.7472 24.7139 16.508 24.5702C14.2093 23.2771 12.3894 21.2656 12.2935 21.2179C12.1976 21.122 9.75526 18.4402 8.74945 15.6624C8.6058 15.2793 8.41405 14.6565 8.55803 13.8905C8.70168 13.0287 9.18072 12.2142 9.99486 11.5438L11.8626 10.0592L6.16346 3.68966L3.43348 5.84469C3.0503 6.18009 2.42758 6.94611 2.23619 7.52106C2.09254 8.00011 1.6135 9.48469 2.28396 12.358C2.76301 14.3695 3.38538 16.0457 4.2954 17.5303C5.97203 20.4517 8.03121 23.1335 10.5216 25.5762Z" fill="white" />
                                    <path d="M32.2659 14.9439H32.2182C31.6913 14.8962 31.3081 14.4649 31.3563 13.938C31.5477 10.9208 30.4944 7.99942 28.3871 5.79658C26.3757 3.59341 23.598 2.3484 20.6288 2.25253C20.1019 2.25253 19.7188 1.82159 19.7188 1.29479C19.7188 0.767965 20.1497 0.384766 20.6765 0.384766C24.1725 0.480643 27.3811 1.96518 29.728 4.50343C32.1225 7.0894 33.3676 10.4899 33.1762 14.0338C33.1758 14.5606 32.7927 14.9438 32.2658 14.9438L32.2659 14.9439Z" fill="white" />
                                    <path d="M28.2897 15.2786C27.7152 15.2786 27.332 14.7996 27.332 14.3209C27.4279 12.2136 26.6615 10.2499 25.2728 8.71758C23.8842 7.23303 22.0164 6.32301 19.9569 6.22714C19.4301 6.17937 19.0469 5.74809 19.0469 5.2694C19.0946 4.74258 19.5259 4.35938 20.0046 4.35938C22.5429 4.50302 24.8896 5.55666 26.6137 7.42443C28.3856 9.33991 29.2956 11.7826 29.1997 14.3686C29.1997 14.8477 28.8166 15.2786 28.2897 15.2786Z" fill="white" />
                                    <path d="M24.2696 15.5187C23.7428 15.5187 23.3119 15.0877 23.3119 14.5609C23.3119 13.4115 22.881 12.3578 22.1146 11.4959C21.3483 10.6818 20.3427 10.2027 19.241 10.1072C18.7141 10.0594 18.3309 9.62815 18.3791 9.10131C18.4269 8.57448 18.8581 8.19129 19.385 8.23943C20.9654 8.38308 22.4022 9.10131 23.5036 10.2509C24.6051 11.4482 25.18 12.9809 25.18 14.6091C25.2274 15.1356 24.7965 15.5188 24.2696 15.5188V15.5187Z" fill="white" />
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>

                <div className=''>
                    <Dialog
                        fullWidth
                        open={openRdvDial}
                        onClose={() => setOpenRdvDial(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                            "& .MuiPaper-root": {
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }
                        }}
                    >
                        <div className="flex flex-col p-4 pt-0 bg-white md:p-8 md:pt-0 gap-y-4 lg:rounded-[42px] shadow-simulator-popup rounded-[10px]">
                            <DialogTitle id="alert-dialog-title" className='lg:mt-10 mt-4 text-base font-bold uppercase text-first lg:text-2xl leading-[21.82px] px-0'>
                                PRENDRE RENDEZ VOUS AVEC UN CONSEILLer
                            </DialogTitle>
                            <DialogContent className='flex flex-col gap-y-8'
                                sx={{
                                    '&': {
                                        padding: 0
                                    }
                                }}
                            >
                                <DialogContentText id="alert-dialog-description" className='px-0 text-xs font-regular text-normal lg:text-lg'>
                                    Pour pouvoir accéder à l’aide financière de l’état et à notre plateforme de déclaration de cours, Veuillez renseigner votre numéro de téléphone.
                                </DialogContentText>
                                <div className='flex flex-col gap-y-4'>
                                    <div>
                                        <p className={`${errorPhone !== "" ? 'block' : 'hidden'} text-third text-xs md:text-sm pb-1`}>{errorPhone}</p>
                                        <Input callBack={getPhoneNumber} field='telephone' label='Téléphone portable' placeholder='Ex: 06 XX XX XX XX' require />
                                    </div>
                                    <div className='flex flex-row items-center gap-x-2 lg:flex-col lg:items-start'>
                                        <h3 className='text-xs font-regular text-normal lg:text-lg'>Indiquez vos disponibilités :</h3>
                                        <p className='text-xs font-medium lg:text-sm'>(Optionnel)</p>
                                    </div>
                                    <div className='w-full'>
                                        <Input callBack={getPreferenceForACall} field='disponibilities' label='Jours et heures de préférence pour un rappel :' placeholder='“Les lundis de 17h à 19h et les mercredis de 14h à 16h”' />
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className="flex flex-col gap-y-4">
                                    <p className='font-medium lg:text-sm text-normal text-[10px] text-center lg:text-left'>En soumettant ce formulaire, j’accepte d’être recontacté selon les disponibilités des conseillers.</p>
                                    <Button className='block w-3/4 py-2 mx-auto font-bold text-white rounded-full lg:py-3 lg:w-3/5 bg-first' onClick={validateRdv}>VALIDER</Button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
            </main >
        </>
    );
};

export default Index;