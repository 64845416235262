import { useState } from 'react';
import Navbar from '../../../../Components/Admin/Navbar';
import PunctualCourse from './CourseType/CoursePunctual/PunctualCourse';
import SubscriptionCourse from './CourseType/CourseSubscription/SubscriptionCourse';
import React from 'react';

const Courses = () => {
    const [current, setCurrent] = useState(2)

    const stepCurrent = (value:number) => {
        switch (value) {
            case 1:
                return <SubscriptionCourse />
            case 2:
                return <PunctualCourse />
            default:
                return <PunctualCourse />
        }
    }

    return (
        <div className='h-screen overflow-hidden bg-white'>
            <Navbar index={1} />
            <div className='lg:py-8 lg:px-16 md:px-8 px-4 //py-4 ///border-2 h-[100%] /border-2'>
                <div className='md:flex lg:text-2xl md:text-lg text-base font-bold text-first gap-x-8 mb-4 md:mt-0 mt-2'>
                    <div
                        onClick={() => setCurrent(2)} 
                        className={current === 2 ?  'underline underline-offset-8 cursor-pointer font-bold text-first' : 'cursor-pointer '}>
                        <h2 className='font-bold text-first lg:text-2xl'>Mes déclarations ponctuelles</h2>
                    </div>
                </div>
                <div className='h-[100%] /border-2'>
                    {stepCurrent(current)}
                </div>
            </div>
        </div>
    );
};

export default Courses;